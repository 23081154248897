import {
  Box,
  Button,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import "./PreviewStyles.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { dsgTheme } from "../../../styles/theme";
import { Question } from "./QuestionInterface";
interface ObjectState {
  questionText: string;
  answerText: string;
  positiveMarks: number;
  category: string;
  difficulty: string;
  skill: string;
  timeToSolve: number;
  negativeMarks: number;
  addInfo: string;
  isBulkUpload: string;
  id: number;
  tags: string;
}
interface ChildComponentProps {
  sharedData: Question;
  optionsData: string[];
  checkCorrectAnswer: (number | null)[];
  correctAnswer: number | null;
  onReturnData: () => void;
}

const Preview: React.FC<ChildComponentProps> = ({
  sharedData,
  optionsData,
  checkCorrectAnswer,
  correctAnswer,
  onReturnData,
}) => {
  const handleReturn = () => {
    onReturnData();
  };

  const comQuestion = (value: string) => {
    return (
      <Typography
        style={{ margin: "0", padding: "0", lineHeight: "auto" }}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  };

  return (
    <ThemeProvider theme={dsgTheme}>
      <Typography
        variant="h5"
        sx={{ paddingTop: "2%" }}
      >
        Preview Question
      </Typography>
      <Box className="preview-box">
        <Box
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "row",
            paddingBottom: "1%",
            alignItems: "baseline",
          }}
        >
          <Typography variant="body1">
            Question :{comQuestion(sharedData.questionText)}{" "}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{ paddingRight: "1%" }}
        >
          Options:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Stack>
            {optionsData && optionsData.length > 0 ? (
              optionsData.map((option: any, index: number) => (
                <Box
                  display="flex"
                  alignItems="baseline"
                  key={index}
                  style={{ height: "auto", margin: 0, padding: 0 }}
                >
                  <Typography variant="body1">{index + 1}. </Typography>
                  <Typography variant="body2">{comQuestion(option)}</Typography>
                </Box>
              ))
            ) : (
              <Typography>No options available.</Typography>
            )}
          </Stack>
        </Box>
        <Grid
          container
          spacing={2.5}
        >
          <Grid
            item
            xs={12}
            md={9}
          >
            <Stack
              direction="row"
              spacing={2}
              marginTop={1}
            >
              {sharedData.questionType == "MCQ" ? (
                <Typography>
                  Correct Answer :{" "}
                  {correctAnswer == 0 ||
                  correctAnswer == null ||
                  optionsData.length < correctAnswer
                    ? ""
                    : "Option" + correctAnswer}
                </Typography>
              ) : (
                <Typography>
                  Correct Answer:{" "}
                  <b>
                    Option [
                    {checkCorrectAnswer.map((option: any) => option).join(", ")}
                    ]
                  </b>
                </Typography>
              )}
              <Typography>Type: {sharedData.questionType}</Typography>
              <Typography>Skills: {sharedData.skill}</Typography>
              <Typography>Marks : {sharedData.positiveMarks}M</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography>Level : {sharedData.difficulty}</Typography>
              <Typography>
                <AccessTimeIcon className="time-icon" />
              </Typography>
              <Typography>{sharedData.timeToSolve} Mins</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        className="button-stack"
      >
        <Button
          variant="outlined"
          onClick={handleReturn}
          sx={{ width: "10%" }}
        >
          Ok
        </Button>
      </Stack>
    </ThemeProvider>
  );
};

export default Preview;
