import {
  Container,
  Stack,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";

import { red } from "@mui/material/colors";

import {  useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";

import roleClass from "./roleClass";

import PermissionsClass from "./PermissionsClass";

import RolesAvailableService from "../../../clients/RolesAvailableService";

import RolesAndPermissionClass from "./RolesAndPermissionClass";

import UserService from "../../../clients/UserService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import "../roles-and-permissions/RolesAndPermissions.css";
import { dsgTheme } from "../../../styles/theme";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { CustomModal } from "../list-of-users/CustomModal";

export const RolesAndPermissions = () => {
  const [rows, setRows] = useState([PermissionsClass.Permission]);

  const [headings, setHeadings] = useState([roleClass.role]);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [saveStatus, setsaveStatus] = useState<boolean>(true);

  const [checkedList, setCheckedList] = useState<string[]>([]);

  const [updatedList]=useState<string[]>([]);
  const [reload,setReload] = useState<boolean>(false);
  const [RolesAndPremissionsByDefault, setRolesAndPremissionsByDefault] =
    useState([RolesAndPermissionClass.Role_Permission]);
    

  const [RolesAndPermissionsFromDB, setRolesAndPermissionsFromDB] = useState([
    RolesAndPermissionClass.Role_Permission,
  ]);

  const navigate = useNavigate();



  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);


  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
          }).catch((error) => {
            if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
            }
          })
  };

  useEffect(() => {
    setReload(true)
    RolesAvailableService.getAllRolesFromDatabase()
      .then((res) => {
        setReload(false)
        if(res.status==200)
        {
        setHeadings(res.data);
        }
        else if(res.status==500)
        {    
          alert("please do refresh the page")
        }
      })
      .catch((error) => {
        setReload(false)
        if (error.response.status === 401) {
        
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
      });

    RolesAvailableService.getAllPermissions()
      .then((res) => {
        setReload(false)
        setRows(res.data);
      })
      .catch((error) => {
        setReload(false)
        if (error.response.status === 401) {
         setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
      });

    RolesAvailableService.getAllRolesAndPermissions()
      .then((res) => {
        setReload(false)
        setRolesAndPremissionsByDefault(res.data);

        setRolesAndPermissionsFromDB(res.data);
      })
      .catch((error) => {
        setReload(false)
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
      });
  }, []);

 
  const handleRemovePermission = (index: number) => {
    setRolesAndPremissionsByDefault((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const navigateToListOfAssessment = () => {
    navigate("/assessmentlist");
  };

  const handleCheckboxChange = (
    event: any,
    permissionId: number,
    roleId: number
  ) => {
    if(UserService.getLoginUserObject().role.roleName==="RS_ADMIN" && roleId!==1){
    const isChecked = event.target.checked;

    let hasPermission = false;

    let RolesAndPermissionsObject;

    let ind = 0;


    if (checkedList.includes(roleId.toString() + permissionId.toString())) {
    
      checkedList.splice(
        checkedList.indexOf(roleId.toString() + permissionId.toString()), 1
      );
      
      setCheckedList(checkedList);
    } else {
      checkedList.push(roleId.toString() + permissionId.toString());
     
      setCheckedList(checkedList);
    }
   

    if(checkedList.length == 0){
      setsaveStatus(true);
    }
    else{
      setsaveStatus(false);
    }
    for (let i = 0; i < RolesAndPremissionsByDefault.length; i++) {
      if (
        roleId === RolesAndPremissionsByDefault[i].role.id &&
        permissionId === RolesAndPremissionsByDefault[i].permission.id
      ) {
        hasPermission = true;

        RolesAndPermissionsObject = RolesAndPremissionsByDefault[i];

        ind = i;

        break;
      }
    }

    if (hasPermission) {
      if (RolesAndPermissionsObject?.id == 0) {
        handleRemovePermission(ind);
      } else {
        setRolesAndPremissionsByDefault((prevState) => {
          const updatedPermissions = prevState.map((item, index) => {
            if (
              item.permission.id === permissionId &&
              item.role.id === roleId
            ) {
              return {
                ...item,
                permissionFlag: isChecked ? "YES" : "NO",
              };
            }

            return item;
          });

          return updatedPermissions;
        });
      }
    }
  }
  };

  const handleModalSignOut = () => {
    navigate("/login");
  };


  const handleSave = () => {
    let counter=0;
    let itemp = false;
    let updatedMessage=false;
    let login_RolesAndPermissions_permission=false;
    for (let b = 0; b < headings.length; b++) {
      let list = [];
      
      for (let a = 0; a < RolesAndPremissionsByDefault.length; a++) {
        if (
          RolesAndPremissionsByDefault[a].role.roleName == headings[b].roleName
        ) {
          if (RolesAndPremissionsByDefault[a].permissionFlag == "YES") {
            list.push(a);
            if(headings[b].roleName == UserService.getLoginUserObject().role.roleName){
              updatedList.push(rows[RolesAndPremissionsByDefault[a].permission.id-1].permissionName)
             }
          }
         
        }
      }  
      if (list.length == 0) {
        itemp = true;
        toast.error(headings[b].roleName + " Permissions Cannot be empty",{autoClose:1000});
        RolesAvailableService.getAllRolesAndPermissions()
          .then((res) => {
            setRolesAndPremissionsByDefault(res.data);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setOpenSessionModal(true)
            } else if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
            }
          });

        break;
      }
    }
    if (itemp) {
      itemp = false;
      
    } else {
      for (let i = 0; i < RolesAndPremissionsByDefault.length; i++) {
        for (let j = 0; j < RolesAndPermissionsFromDB.length; j++) {

          if (
            RolesAndPremissionsByDefault[i].id ==
            RolesAndPermissionsFromDB[j].id
          ) {
         

            if (
              RolesAndPremissionsByDefault[i].permissionFlag ===
              RolesAndPermissionsFromDB[j].permissionFlag
            ) {
            
            } else {
              if(UserService.getLoginUserObject().role.roleName==RolesAndPremissionsByDefault[i].role.roleName)
              {
                if(RolesAndPremissionsByDefault[i].permission.permissionName=='Roles And Permissions')
                {
                
                console.log(RolesAndPremissionsByDefault[i].role.roleName,"===",UserService.getLoginUserObject().role.roleName)
                login_RolesAndPermissions_permission=true
                }
              }
              RolesAndPremissionsByDefault[i].updatedBy =
                UserService.getLoginUserObject().userName;

             
           
              RolesAvailableService.updatePermission(
                RolesAndPremissionsByDefault[i]
              )
                .then((res) => {
                  if(res.data!=null)
                  {
                   
                    if(counter==0)
                    {
                      console.log("conter-",counter)
                      toast.success("Permissions Updated Successfully",{autoClose:1000})
                      counter+=1;
                      setTimeout(()=>{
                     
                      UserService.setLoginObjectPermissionList(updatedList);
                      if(login_RolesAndPermissions_permission)
                      {
                        navigate('/assessmentlist')
                        window.location.reload()
                      }
                      else{
                        window.location.reload()
                      }
                    },1000)
                     
                  
                    }
                    updatedMessage=true;
                  }
                
                  setsaveStatus(true);
                  setCheckedList([])
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    setOpenSessionModal(true);
                  } else if (error.response.status === 402) {
                    setClientPlanExpiredModal(true);
                  }
                });
            }
          }
        }
        for (let b = 0; b < headings.length; b++) {
    }}

  }

};
if (reload) {
  return (
    <Box className="loader">
      <CircularProgress disableShrink />
    </Box>
  );
}

  return (
    <ThemeProvider theme={dsgTheme}>
      <div>
        <Container className="rootContainer tableContainer">
          <Typography className="heading3" variant="h5">Roles and Permissions</Typography>

          <Box>
            <TableContainer sx={{ maxHeight: 300 }} className="tableBody">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="tableHeader">
                    <TableCell align="center" className="tableHeader">
                      Permission
                    </TableCell>
                    {headings.map((heading) => (
                      <TableCell
                        key={heading.id}
                        align="center"
                        className="tableHeader"
                      >
                        <strong style={{ textTransform: "capitalize" }}>
                        
                          {heading.roleName==="RS_ADMIN"?"RS_Admin":heading.roleName.toLowerCase()}
                        </strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "bold", border: "1px solid black" }}
                      >
                        {row.permissionName}
                      </TableCell>

                      {headings.map((heading) => {
                        const permissionId = row.id;

                        const roleId = heading.id;

                        const isDefaultChecked =
                          RolesAndPremissionsByDefault.some(
                            (item) =>
                              item.permission.id === permissionId &&
                              item.role.id === roleId &&
                              item.permissionFlag === "YES"
                          );
                          

                          const AdminDisable=RolesAndPremissionsByDefault.some(
                            (item)=>
                            item.permission.id === permissionId &&
                            item.role.id === roleId &&
                              item.role.id===1 && item.permission.permissionName==='Roles And Permissions'
                          );  

                        return (
                          <TableCell
                            key={heading.id}
                            align="center"
                            sx={{ minWidth: 100, border: "1px solid black" }}
                          >
                            <Checkbox
                              sx={{
                                color: red[800],

                                "&.Mui-checked": {
                                  color: red[600],
                                },
                              }}
                              checked={isDefaultChecked}
                              disabled={AdminDisable }
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event,
                                  permissionId,
                                  roleId
                                )
                              }
                              className="checkcss"
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Stack
            className="stack-root"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            <Button
              variant="outlined"
              className="button"
              onClick={navigateToListOfAssessment}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              className="button"
              onClick={handleSave}
              disabled={saveStatus}
            >
              Save
            </Button>
          </Stack>
        </Container>
        

        <SessionExtensionModal
          open={openSessionModal}
          onClose={handleModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>
        <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
             
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>

        <ToastContainer position="bottom-left" />
      </div>
    </ThemeProvider>
  );
};