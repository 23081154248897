import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Radio,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dsgTheme } from "src/styles/theme";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CreateClient from "../create-client/CreateClient";
import SubscriptionDetails from "../subscription/SubscriptionDetails";
import { ClientInterface } from "../dummydata/ClientInterface";
import UserService from "src/clients/UserService";
import { CustomModal } from "src/components/modal/CustomModal";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import Pagination from "src/components/pagination/Pagination";
import Plans from "../list-of-plans/Plans";
import ClientPlans from "../list-of-client-plans/ClientPlans";

const ListOfClients = () => {
  // const demoListOfClients: ClientInterface[] = [];
  const [demoListOfClients, setDemoListOfClients] = useState<
    ClientPlanInterface[]
  >([]);
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState<number>(-1);
  const [selectedClientJson, setSelectedClientJson] = React.useState<
    ClientPlanInterface | undefined
  >();
  const [showClient, setShowClient] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [showSubscribe, setShowSubscribe] = useState<boolean>(false);
  const [reload,setReload] = useState<boolean>(false);
  // const [viewPlanFlag, setViewPlanFlag] = useState<boolean>(false);
  const [showViewPlan, setShowViewPlan] = useState<boolean>(false);
  // const [activateFlag, setActivateFlag] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [createFlag, setCreateFlag] = useState<boolean>(false);
  const [subscriptionFlag, setSubscriptionFlag] = useState<boolean>(false);
  // const [editFlag, setEditFlag] = useState<boolean>(false);
  const [viewClientFlag, setViewClientFlag] = useState<boolean>(false);
  // const [renew, setRenew] = useState<boolean>(false);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [showClientPlan, setShowClientPlan] = useState<boolean>(false);
  const [rows, setRows] = useState("3");
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = Number(rows);
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;

  const handleChange = (event: any) => {
    setRows(event.target.value);

    setCurrentPage(1);
  };
  const rowsPerPageArray = ["3", "5", "10"];
  const currentClient = demoListOfClients.slice(
    indexOfFirstClient,
    indexOfLastClient
  );

  useEffect(() => {
    setReload(true);
    UserService.getAllClients()
      .then((res: any) => {
        setReload(false);
        console.log(res.data);
        setDemoListOfClients(res.data);
        setCurrentPage(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setReload(false);
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setReload(false);
          setClientPlanExpiredModal(true);
        }else{
          setReload(false);
          console.log("Something went wrong")
        }
      });
  }, []);

  useEffect(() => {
    if (selectedClientJson != null) {
      setCreateFlag(true);
      setViewClientFlag(true);
      setSubscriptionFlag(true);
      // setViewPlanFlag(true);
      // setRenew(false);
    } else {
      setSubscriptionFlag(false);
      setViewClientFlag(false);
      // setRenew(true);
      setCreateFlag(false);
      // setViewPlanFlag(false);
    }
    // if (selectedClientJson?.clients?.clientStatus == "ACTIVE") {
    //   // setActivateFlag(true);
    //   // setEditFlag(true);
    //   setViewClientFlag(true);
    //   // setSubscriptionFlag(false);
    //   // setRenew(true);
    // } else if (selectedClientJson?.clients?.clientStatus == "INACTIVE") {
    //   // setEditFlag(false);
    //   // setSubscriptionFlag(false);
    //   // setRenew(true);
    // } else if (selectedClientJson?.clients?.clientStatus == "NEW") {
    //   if (!selectedClientJson.subscriptionPlans) {
    //     // setSubscriptionFlag(true);
    //     // setEditFlag(false);
    //     // setRenew(false);
    //   } else {
    //     // setEditFlag(false);
    //     // setSubscriptionFlag(false);
    //   }
    // } else {
    //   // setRenew(false);
    // }
  }, [selectedClientJson]);

  // console.log("selected : ", selectedClientJson);

  // const handleActivate = (status: string) => {
  //   if (selectedClientJson && selectedClientJson.clients?.id) {
  //     setOpenModal(true);
  //   } else {
  //     console.log("Client ID is undefined or null");
  //   }
  // };

  const handleSessionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleSessionModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  // const handleClick = (mode: string) => {
  //   if (mode === "subscribe") {
  //     // setShowSubscribe(true);
  //     setMode("New");
  //   } else if (mode === "edit") {
  //     // setShowSubscribe(true);
  //     setMode("Edit");
  //   } else if (mode === "renewClient") {
  //     // setShowSubscribe(true);
  //     setMode("Renew");
  //   } else if (mode === "viewPlans") {
  //     setShowViewPlan(true);
  //   } else {
  //     setSelectedClientJson(undefined);
  //     setShowClient(true);
  //   }
  // };
  

  const handleModalConfirm = () => {
    if (selectedClientJson && selectedClientJson.clients?.id) {
      let status =
        selectedClientJson?.clients?.clientStatus == "INACTIVE"
          ? "ACTIVE"
          : "INACTIVE";
      UserService.changeStatus(selectedClientJson.clients?.id, status)
        .then((res: any) => {
          setOpenModal(false);

          UserService.getAllClients()
            .then((res: any) => {
              setDemoListOfClients(res.data);
              setCurrentPage(1);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                console.log("Unauthorized path..");
              } else if (error.response.status === 402) {
                setClientPlanExpiredModal(true);
              }
            });

          setSelectedClientJson(undefined);
          setSelectedClient(-1);
        
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log("Unauthorized path..");
            setOpenSessionModal(true);
          } else if (error.response.status === 402) {
            setClientPlanExpiredModal(true);
          } else if (
            error.response.status === 409 ||
            error.response.status === 417
          ) {
            setOpenModal(false);
            alert(error.response.data.message);
          }
        });
    } else {
      console.log("Client ID is undefined or null");
    }
  };

    
  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <ThemeProvider theme={dsgTheme}>
      {!showClient && !showViewPlan &&!showSubscribe && !showClientPlan && (
        <Container className="mainContainer tableContainer">
          <Box>
            <Typography variant="h5" sx={{ padding: "2%" }}>
              List of Clients
            </Typography>
            <Grid item xs={12} sx={{ mt: "3%" }}>
              <Stack
                direction="row"
                spacing={2}
                marginBottom="2%"
                alignItems="center"
              >
                <Box sx={{ marginRight: "auto" }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: "small" }}>
                      Clients per page
                    </Typography>

                    <FormControl variant="standard">
                      <Select
                        disableUnderline={true}
                        value={rows}
                        label="Age"
                        onChange={handleChange}
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        {rowsPerPageArray.map((value, index) => (
                          <MenuItem value={value} key={value + String(index)}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography sx={{ color: "#191F1C" }}>
                      {indexOfFirstClient + 1}-
                      {indexOfFirstClient + currentClient.length} out of{" "}
                      {demoListOfClients.length}
                    </Typography>
                  </Stack>
                </Box>

                <Pagination
                  rows={rows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalDataLength={demoListOfClients.length}
                  dataPerPage={clientsPerPage}
                />
              </Stack>
            </Grid>
            <TableContainer sx={{ maxHeight: 300 }} className="tableBody">
              <Table>
                <TableHead className="table-row-odd">
                  <TableRow>
                    <TableCell className="tableHeader">Select</TableCell>
                    <TableCell className="tableHeader">Client Name</TableCell>
                    <TableCell className="tableHeader">Client Id</TableCell>
                    <TableCell className="tableHeader">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentClient.length > 0 &&
                    currentClient.map((client, index) => (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        // key={client.id}
                        className={
                          client.clients?.id === selectedClient
                            ? "selected-row"
                            : index % 2 === 0
                            ? "table-row-even"
                            : "table-row-odd"
                        }
                        // onClick={() => handleSelectedClient(client)}
                      >
                        <TableCell>
                          <Radio
                            name="selectedClient"
                            checked={
                              selectedClientJson !== undefined &&
                              selectedClientJson.clients?.id ===
                                client.clients?.id
                            }
                            onClick={() => {
                              if (
                                selectedClientJson &&
                                selectedClientJson.clients?.id ===
                                  client.clients?.id
                              ) {
                                setSelectedClientJson(undefined);
                                setSelectedClient(-1);
                              } else {
                                setSelectedClientJson(client);
                                setSelectedClient(client.clients?.id ?? -1);
                              }
                            }}
                            className="radiocss"
                          />
                        </TableCell>
                        <TableCell>{client.clients?.clientName}</TableCell>
                        <TableCell>{client.clients?.clientId}</TableCell>
                        <TableCell>{client.clients?.clientStatus}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack
            className="stack-root"
            direction="row"
            justifyContent="flex-end"
            position="relative"
            spacing={2}
          >
            {subscriptionFlag && selectedClientJson?.clients.clientStatus === "NEW" && (
              <Button
                variant="outlined"
                className="button"
                disabled={selectedClientJson === undefined}
                onClick={() => {
                  setShowSubscribe(true);
                  setMode("New")
                }}
              >
                Subscribe
              </Button>
            )}
            {viewClientFlag && selectedClientJson?.clients.clientStatus !== "NEW" && (
              <Button
                variant="outlined"
                className="button"
                onClick={() => {
                  setShowSubscribe(true);
                  setMode("Edit")
                }}
              >
                Edit Client
              </Button>
            )}
            
            { viewClientFlag && selectedClientJson?.clients.clientStatus !== "NEW" &&
              <Button
                variant="outlined"
                className="button"
                onClick={() => setShowClientPlan(true)}
              >
                View Client
              </Button>
            }
             <Button
                variant="outlined"
                className="button"
                onClick={() => setShowViewPlan(true)}
              >
                View Plans
              </Button>
              <Button
                variant="outlined"
                className="button"
                disabled={createFlag}
                onClick={() => setShowClient(true)}
              >
                Create New Client
              </Button>
            
          </Stack>
        </Container>
      )}
      {showViewPlan && (
        <Plans
          onBack={() => {
            setShowViewPlan(false);
            setSelectedClientJson(undefined);
          }}
        />
      )}
      {showClient && (
        <CreateClient
          selectedClientJson={selectedClientJson}
          setNewPlan={() => {}}
          editable={false}
          onBack={() => {
            setShowClient(false);
            setSelectedClientJson(undefined);
          }}
        />
      )}
      {showClientPlan && (
        <ClientPlans onBack={() => {
          setShowClientPlan(false);
          setSelectedClientJson(undefined);
        }}
        selectedClientJson = {selectedClientJson}
        />
      )

      }
      {showSubscribe && (
        <SubscriptionDetails
          selectedClientJson={selectedClientJson}
          mode={mode}
          onBack={() => {
            setShowSubscribe(false);
            setShowClient(false);
            setSelectedClientJson(undefined);
          }}
        />
      )}

      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleSessionModalCancel}
        onContinue={handleSessionModalContinue}
      ></SessionExtensionModal>
      {/* <CustomModal
        open={openModal}
        heading="Session Dialog"
        cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
        continueButton={{ label: "Confirm", onClick: handleModalConfirm }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />

          <Typography fontWeight="bold">
            Are you sure,you want to{" "}
            {selectedClientJson?.clients?.clientStatus === "ACTIVE"
              ? "In-Activate"
              : "Activate"}{" "}
            the client?({selectedClientJson?.clients?.clientName})
          </Typography>
        </Stack>
      </CustomModal> */}
      <CustomModal
        open={openClientPlanExpiredModal}
        cancelButton={{ label: "Sign out", onClick: handleSessionModalCancel }}
        continueButton={{
          label: "",
          onClick: handleModalConfirm,
        }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
          <Typography fontWeight="bold">
            Your subscription plan has expired; for further information, please
            contact our RS_ADMIN.
          </Typography>
        </Stack>
      </CustomModal>
    </ThemeProvider>
  );
};
export default ListOfClients;
