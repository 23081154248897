import axios from "axios";
import TokenService from "./TokenService";
const assessment_host = process.env.REACT_APP_BASE_URL;
const baseURL = `${assessment_host}/api/v1/questionBank/`;

const QuestionBankClient = {
  getQuestions: (
    skills: string[],
    questionType: string[],
    category: string[],
    difficulty: string[]
  ) => {
    const params = new URLSearchParams();
    skills.forEach((skill) => params.append("skills", skill));
    questionType.forEach((questype) => params.append("questionType", questype));
    category.forEach((cat) => params.append("category", cat));
    difficulty.forEach((diff) => params.append("difficulty", diff));

    return axios.get(baseURL + "questions", {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },

  getQuestions2: (
    skills: string[],
    questionType: string[],
    difficulty: string[],
    category: string[],
    createdBy: string[]
  ) => {
    const user = localStorage.getItem("currentUser");
    let userName: string;
    let userOrganization: string;
    let organizationarray: string[] = [];
    let loadarray: string[] = [];
    let a: string[] = [];
    if (user) {
      userName = JSON.parse(user).userName;
      userOrganization = JSON.parse(user).clientPlans.clients.clientName;
      a.push(userName);
      organizationarray.push(userOrganization);
      loadarray.push(userOrganization);
      loadarray.push(userName);
    }

    const data = {
      initialLoad:
        createdBy.length == 0 &&
        skills.length == 0 &&
        questionType.length == 0 &&
        category.length == 0 &&
        difficulty.length == 0
          ? loadarray
          : [],
      // "visibility":createdBy.includes("My questions") ? ["PUBLIC","PRIVATE"] : ["PUBLIC"],
      difficulty: difficulty,
      skill: skills,
      questionType: questionType,
      category: category,
      createdBy: a,
      baseFlag:
        createdBy.length === 0 || createdBy.includes("Default")
          ? [true]
          : [false],
      organization:
        (createdBy.includes("Default") || createdBy.includes("My questions")) &&
        !createdBy.includes("My organization")
          ? []
          : organizationarray,
    };

    return axios.post(baseURL + "questions", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },

  getFilterType(name: string) {
    return axios.get(assessment_host + "/Masterdata/name/type/" + name, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },
  saveQuestions(assessmentRequestBody: any) {
    return axios.post(
      `${assessment_host}/assessments/saveQuestions`,
      assessmentRequestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenService.getToken()}`,
        },
      }
    );
  },
  getAssessmentHeader(id: number) {
    return axios.get(`${assessment_host}/assessments/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },
  deleteQuestionById(id: number) {
    return axios.delete(`${baseURL}delete-question/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },
  getNotifications(userName: string) {
    console.log(baseURL + "all/" + userName);

    return fetch(`${baseURL}all/${userName}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },
  deleteNotifications(userName: string) {
    return fetch(`${baseURL}notifications/${userName}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  },
};

export default QuestionBankClient;
