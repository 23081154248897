import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Radio,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dsgTheme } from "src/styles/theme";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CreateClient from "../create-client/CreateClient";
import SubscriptionDetails from "../subscription/SubscriptionDetails";
import { ClientInterface } from "../dummydata/ClientInterface";
import UserService from "src/clients/UserService";
import { CustomModal } from "src/components/modal/CustomModal";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import Pagination from "src/components/pagination/Pagination";
import { SubscriptionPlansInterface } from "../dummydata/SubscriptionPlansInterface";
import { NewSubscriptionDetails } from "../subscription/NewSubscriptionDetails";
import { formatDate } from "src/components/assessment/assessments/AssessmentUtils";
import AuditDetails from "./AuditDetails";

interface ClientPlanProps {
  onBack: () => void;
  selectedClientJson?: ClientPlanInterface;
}

const ClientPlans = ({ onBack, selectedClientJson }: ClientPlanProps) => {
  const [clientPlans, setClientPlans] = useState<ClientPlanInterface[]>([]);

  const navigate = useNavigate();
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [openSubscriptionPlan, setOpenSubscriptionPlan] =
    useState<boolean>(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState<
    ClientPlanInterface | undefined | null
  >();
  const [openActivateModal, setOpenActivateModal] = useState<boolean>(false);

  const [openAuditModal, setOpenAuditModal] = useState<boolean>(false);
  const [rows, setRows] = useState("3");
  const [currentPage, setCurrentPage] = useState(1);
  const plansPerPage = Number(rows);
  const indexOfLastPlan = currentPage * plansPerPage;
  const indexOfFirstPlan = indexOfLastPlan - plansPerPage;
  const handleChange = (event: any) => {
    setRows(event.target.value);
    setCurrentPage(1);
  };

  const initialSubscriptionPlan: SubscriptionPlansInterface = {
    id: 0,
    planName: "",
    numberOfUsers: 0,
    numberOfInvitations: 0,
    cost: 0,
    createdBy: "",
    timePeriod: 0,
    subscriptionPlanStatus: ""
  };

  const clients: ClientInterface = {
    id: 0,
    clientName: "",
    clientId: "",
    clientStatus: "",
    clientLogo: "",
    autoRenew: false
  };
  const defaultClientData: ClientPlanInterface = {
    id: 0,
    planStartDate: new Date(),
    planEndDate: new Date(),
    clientPlanStatus: "",
    subscriptionPlans: initialSubscriptionPlan,
    clients: clients,
  };

  const [viewAuditPlanData, setViewAuditPlanData] =
    useState<ClientPlanInterface>(defaultClientData);
  const rowsPerPageArray = ["3", "5", "10"];
  const currentPlans = clientPlans.slice(indexOfFirstPlan, indexOfLastPlan);

  console.log(selectedClientJson?.clients);
  useEffect(() => {
    getClientPlans();
  }, []);

  useEffect(() => {
    getClientPlans();
  },[openSubscriptionPlan])

  const getClientPlans = () => {
    UserService.getClientPlansbyClient(selectedClientJson?.clients.id)
      .then((res: any) => {
        const data: ClientPlanInterface[] = res.data;
        const sortedClientPlans = [...data].sort((a, b) => {
          const statusOrder: { [key: string]: number } = {
            ACTIVE: 1,
            NEW: 2,
            EXPIRED: 3,
          };
          return (
            statusOrder[a.clientPlanStatus] - statusOrder[b.clientPlanStatus]
          );
        });

        setClientPlans(sortedClientPlans);
        setCurrentPage(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log(error);
        }
      });
  };

  const handleSessionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleSessionModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  const handleAuditModal = (plan: ClientPlanInterface) => {
    setViewAuditPlanData(plan);
    setOpenAuditModal(true);
  };
  const handleModalCancel = () => {
    setOpenActivateModal(false);
  };

  const handleModalContinue = () => {

    if(clientPlans.some((plan) => plan.clientPlanStatus === "ACTIVE")){
      toast.error(`You cannot activate the plan till the active plan will expire`, { autoClose: 1000 });
      setOpenActivateModal(false);
      return;
    }
    setSelectedPlanDetails((prev: any) => ({
      ...prev,
      planStartDate: dayjs(selectedPlanDetails?.planStartDate).format(
        "YYYY-MM-DD"
      ),
      planEndDate: dayjs(selectedPlanDetails?.planEndDate).format("YYYY-MM-DD"),
    }));
    console.log("selectedPlanDetails", selectedPlanDetails);
    UserService.changeClientPlanStatus(selectedPlanDetails)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Plan activated successfully`, { autoClose: 1000 });
          setOpenActivateModal(false);
          getClientPlans();
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          toast.error(`Something went wrong`);
        }
      });
  };

  function checkRenew() {
    if(clientPlans.some((plan) => plan.clientPlanStatus === "NEW")){
      toast.error(`You cannot renew the subscription until the upcoming plan will set to active`,{autoClose:1000})
      return;
    }else if(clientPlans[0].clients.autoRenew){
      toast.error(`Please disable auto-renew before renewing subscription.`,{autoClose:1000});
      return;
    }
    setOpenSubscriptionPlan(true);
  }

  return (
    <ThemeProvider theme={dsgTheme}>
      {!openSubscriptionPlan && (
        <Container className="mainContainer tableContainer">
          <Box>
          <Typography sx={{mt:'1%'}}>
              <b>Client Name</b>: {selectedClientJson?.clients?.clientName}
            </Typography>
            <Typography variant="h5" sx={{ padding: "2%" }}>
              Subscription History
            </Typography>
            <Grid item xs={12} sx={{ mt: "3%" }}>
              <Stack
                direction="row"
                spacing={2}
                marginBottom="2%"
                alignItems="center"
              >
                <Box sx={{ marginRight: "auto" }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: "small" }}>
                      Plans per page
                    </Typography>

                    <FormControl variant="standard">
                      <Select
                        disableUnderline={true}
                        value={rows}
                        label="Age"
                        onChange={handleChange}
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        {rowsPerPageArray.map((value, index) => (
                          <MenuItem value={value} key={value + String(index)}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography sx={{ color: "#191F1C" }}>
                      {indexOfFirstPlan + 1}-
                      {indexOfFirstPlan + currentPlans.length} out of{" "}
                      {clientPlans.length}
                    </Typography>
                  </Stack>
                </Box>

                <Pagination
                  rows={rows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalDataLength={clientPlans.length}
                  dataPerPage={plansPerPage}
                />
              </Stack>
            </Grid>
            <TableContainer sx={{ maxHeight: 300 }} className="tableBody">
              <Table>
                <TableHead className="table-row-odd">
                  <TableRow>
                    <TableCell className="tableHeader">Select</TableCell>
                    <TableCell className="tableHeader">Plan Name</TableCell>
                    <TableCell className="tableHeader">Start Date</TableCell>
                    <TableCell className="tableHeader">End Date</TableCell>
                    <TableCell className="tableHeader">Status</TableCell>
                    {/* <TableCell className="tableHeader">
                        Amount($/month)
                      </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPlans.length > 0 &&
                    currentPlans.map((plan, index) => (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className={
                          plan?.id === selectedPlanDetails?.id
                            ? "selected-row"
                            : index % 2 === 0
                            ? "table-row-even"
                            : "table-row-odd"
                        }
                      >
                        <TableCell>
                          <Radio
                            name="selectedClient"
                            checked={selectedPlanDetails?.id === plan?.id}
                            onClick={() => {
                              if (
                                selectedPlanDetails &&
                                selectedPlanDetails?.id === plan?.id
                              ) {
                                setSelectedPlanDetails(undefined);
                              } else {
                                setSelectedPlanDetails(plan);
                              }
                            }}
                            className="radiocss"
                          />
                        </TableCell>
                        <TableCell>
                          <a
                            href="#"
                            onClick={() => {
                              handleAuditModal(plan);
                            }}
                          >
                            {plan?.subscriptionPlans.planName}
                          </a>
                        </TableCell>
                        <TableCell>
                          {formatDate(plan?.planStartDate.toString())}
                        </TableCell>
                        <TableCell>
                          {formatDate(plan?.planEndDate.toString())}
                        </TableCell>
                        <TableCell>{plan?.clientPlanStatus}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack
            className="stack-root"
            direction="row"
            justifyContent="flex-end"
            position="relative"
            spacing={2}
          >
            <Button variant="outlined" className="button" onClick={onBack}>
              Back
            </Button>
            {selectedPlanDetails && (
              <Button
                variant="outlined"
                className="button"
                disabled={selectedPlanDetails.clientPlanStatus !== "NEW"}
                onClick={() => {
                  setOpenActivateModal(true);
                }}
              >
                ACTIVATE
              </Button>
            )}
            <Button
              variant="outlined"
              className="button"
              onClick={() => {
                checkRenew();
              }}
            >
              Renew Subscription
            </Button>
          </Stack>
        </Container>
      )}
      {openSubscriptionPlan && (
        <SubscriptionDetails
          selectedClientJson={selectedClientJson}
          mode={"Renew"}
          onBack={() => {
            setOpenSubscriptionPlan(false);
          }}
        />
      )}
      <ToastContainer position="bottom-left" />
      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleSessionModalCancel}
        onContinue={handleSessionModalContinue}
      ></SessionExtensionModal>
      {openAuditModal && (
        <AuditDetails
          planDetails={viewAuditPlanData}
          onSave={() => {
            setOpenAuditModal(false);
          }}
        />
      )}
      <CustomModal
        open={openActivateModal}
        heading="Session Dialog"
        cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
        continueButton={{ label: "Confirm", onClick: handleModalContinue }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />

          <Typography fontWeight="bold">
            Are you sure, you want to activate the plan? 
          </Typography>
        </Stack>
      </CustomModal>
      <CustomModal
        open={openClientPlanExpiredModal}
        cancelButton={{ label: "Sign out", onClick: handleSessionModalCancel }}
        continueButton={{
          label: "",
          onClick: handleSessionModalCancel,
        }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
          <Typography fontWeight="bold">
            Your subscription plan has expired; for further information, please
            contact our RS_ADMIN.
          </Typography>
        </Stack>
      </CustomModal>
    </ThemeProvider>
  );
};
export default ClientPlans;
