import { useEffect, useRef, useState } from "react";

import { Button, ThemeProvider } from "@mui/material";

import Container from "@mui/material/Container";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import screenfull from "screenfull";
import { dsgTheme } from "../../styles/theme";
import "./Instruct.css";
import ResponsiveAppBar from "./navbar";

import { Typography } from "@mui/material";
import LinkExpired from "./LinkExpired";
import Webcam from "react-webcam";
import { stream } from "xlsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useHistory } from "react-router-dom";

const Instructions = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assessmentId = searchParams.get("assessmentId");
  const candidateId = searchParams.get("candidateId");
  const navigate = useNavigate();
  const [launchDisabled, setLaunchDisabled] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDateTime, setendDate] = useState(new Date());
  const [assessmentHeader, setAssessmentHeader] = useState<any>();
  const candidate_host = process.env.REACT_APP_BASE_URL;
  const [configurations, setConfigurations] = useState<string[]>();
  const webcamRef = useRef<Webcam>(null);
  
  useEffect(() => {
    axios
      .get(`${candidate_host}/candidates/assessments/${assessmentId}`)
      .then((response) => {
        setAssessmentHeader(response.data);
        console.log(response.data,"assessment data");
      });

    axios
      .get(`${candidate_host}/candidates/configirations/${assessmentId}`)
      .then((response) => {
        // console.log(response.data);
        let l = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].configurationFlag) {
            l.push(response.data[i].configurationName);
          }
        }
        setConfigurations(l);
      });

    axios
      .get(
        `${candidate_host}/candidates/candidate-information-by-id/${candidateId}`
      )
      .then((response) => {
        console.log(response.data);
        const startDateTimeArray = response.data.startDateTime;
        const endDateTimeArray = response.data.endDateTime;
        const startDateTime = new Date(startDateTimeArray);
        const endDate = new Date(endDateTimeArray);
        setStartDate(startDateTime);
        setendDate(endDate);
        console.log(startDateTime);
      });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    if (currentDate >= startDate && currentDate <= endDateTime) {
      setLaunchDisabled(false);
    } else {
      setLaunchDisabled(true);
    }
    // Check if current date is after the start date every minute
    const intervalId = setInterval(() => {
      const currentDate = new Date();
      if (currentDate >= startDate) {
        setLaunchDisabled(false);
        clearInterval(intervalId); // Clear the interval to stop checking
      }
    }, 10000); // Check every minute (adjust as needed)

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, [startDate, endDateTime]);
  if (new Date() > endDateTime) {
    return (
      <div>
        <LinkExpired />
      </div>
    );
  }
  const handleSubmit = async () => {
    const currentStream = webcamRef.current?.stream;
  
    if (currentStream?.active) {
      if (configurations?.includes("FULLSCREENMODE")) {
        screenfull.request();
      }
  
      navigate("/candidateMain", {
        state: {
          assessmentId: assessmentId,
          candidateId: candidateId,
          configurations: configurations,
        },
      });
    } else {
      try {
        // Check and request webcam permissions
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        if (stream) {
          if (webcamRef.current) {
            if (webcamRef.current.video) {
              webcamRef.current.video.srcObject = stream;
              setLaunchDisabled(false); // Enable launch button once webcam permission is granted
              toast.success("Webcam permission granted. You can now launch the assessment.");
            } else {
              toast.error("Webcam video element is null or undefined.");
            }
          } else {
            toast.error("Webcam reference is null or undefined.");
          }
        } else {
          toast.error("Webcam permission denied. Please enable webcam permissions and refresh the page.");
        }
      } catch (error) {
        console.error("Error accessing webcam:", error);
        toast.error("Failed to access webcam. Please check your webcam and permissions.");
      }
    }
  };
  
  const handleBack = () => {
    window.history.back();
  };
  if (assessmentHeader == null) {
    return <div>Loading</div>;
  }
  return (
    <div>
      {" "}
      <ResponsiveAppBar />
      <ThemeProvider theme={dsgTheme}>
        <Container>
          <Typography variant="h5">Instructions</Typography>
          <div className="instruction">
            <b> Read the instructions carefully.</b>
            <p>
              &#11162; This assessment has{" "}
              {assessmentHeader.noOfSections == 1
                ? "only one section"
                : assessmentHeader.noOfSections + " sections"}
              .<br></br>
              &#11162; The assessment will take {
                assessmentHeader.totalTime
              }{" "}
              minutes in total. <br></br>
              &#11162; The specified time must be used to complete each section.
              <br></br>
              &#11162; After the timer expires, each section and assessment will
              be automatically saved and submitted.<br></br>
              &#11162; Ensure that each descriptive question is saved individually.<br></br>
              &#11162; Keep the full-screen mode active while the assessment is
              running.<br></br>
              &#11162; Keep in mind that the copy and paste (Ctrl + C and Ctrl +
              V, respectively) buttons are not functioning.<br></br>
              &#11162; Ensure that cache, video capturing is enabled in the browser's options.
            </p>
            <Webcam
        style={{ width: '10%' }}
        audio={false}
        ref={webcamRef}
        hidden
        videoConstraints={{
          width: { ideal: 200 },
          height: { ideal: 200 },
        }}
      />

            <table
              style={{
                borderCollapse: "collapse",
                width: "70%",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  {/* <th style={{ border: "1px solid black", padding: "8px" }}>
                    Section
                  </th> */}
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "25%",
                    }}
                  >
                    Section Name
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "25%",
                    }}
                  >
                    No of Questions
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "25%",
                    }}
                  >
                    Total Marks
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "25%",
                    }}
                  >
                    Total Time (minutes)
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from(
                  { length: assessmentHeader.noOfSections },
                  (_, index) => (
                    <tr key={index}>
                      {/* <td style={{ border: "1px solid black", padding: "8px" }}>
                        Section {index + 1}
                      </td> */}
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "25%",
                        }}
                      >
                        {assessmentHeader.section[index].sectionName}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "25%",
                        }}
                      >
                        {
                          assessmentHeader.section[index]
                            .assessmentQuestionDetail.length
                        }
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "25%",
                        }}
                      >
                        {assessmentHeader.section[index].totalMarks}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "25%",
                        }}
                      >
                        {assessmentHeader.section[index].totalTime}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>

          <div className="button-stack">
            <Button
              variant="outlined"
              onClick={() => {
                handleBack();
              }}
            >
              back
            </Button>
            <Button
              variant="outlined"
              className="button"
              disabled={launchDisabled}
              onClick={handleSubmit}
            >
              Launch Assessment
            </Button>
            <ToastContainer position="bottom-left" />
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Instructions;
