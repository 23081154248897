import React, { useState } from "react";
import { Button, Stack, ThemeProvider, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { TextField, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ToastContainer,toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./login.css";
import UserService from "../../../clients/UserService";
import SideMenu from "../../menu-bar/NavbarAndSidebar";
import { dsgTheme } from "../../../styles/theme";
import TokenService from "../../../clients/TokenService";
import { domainToASCII } from "url";


export const Login = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);


  const navigate=useNavigate();

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemail(event.target.value);
    setUserNameError(false);
  
    setPasswordError(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };


  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const checkPermissionList = (permissionList:any[]) => {

    const bool = permissionList.some((action) => {
      switch (action) {
        case "View Users":
            navigate("/listOfUsers");
            return true;
        case "View Questions":
          navigate("/questionbank");
          return true;
        case "View Assessments":
          navigate("/assessmentlist");
          return true;
        case "View Assessment Results":
          navigate("/assessment");
          return true;
        case "Edit Masterdata":
          navigate("/editMasterData");
          return true;
        case "Roles And Permissions":
          navigate("/rolesAndPermissions");
          return true;
        default:
            return false;
    }
    })

    if(bool){
      return 0;
    }else{
      return -1;
    }
    
  }



  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const currentDate = new Date();
    event.preventDefault();
    UserService.getByUsernameAndPassword(email, password)
    .then((res) => {


      // Convert the date string to a Date object
      // const targetStartDate = new Date(res.data?.planStartDate);
      // const targetEndDate = new Date(res.data?.planEndDate);
      
      console.log(res.data , "data")
      if (!res.data) {
        
      } 
      else if (res.data.status === null) {
        toast.error('Server Not Responding', { autoClose: 1000 });
      } else {
        UserService.setLoginUserObject(res.data);
        TokenService.setToken(res.data.token);
     
  // console.log(currentDate>res.data?.clientId.planEndDate,currentDate,res.data?.clientId.planEndDate)
// if(res.data?.clientId.clientStatus=== 'INACTIVE')
// {
//   toast.error('Inactive User', { autoClose: 1000 });
// }
// else if(currentDate < targetStartDate)
// {
// toast.error("Your subscription date not yet started")
// }
// else if(currentDate > targetEndDate)
// {
// toast.error("Your subscription plan has Expired")
// }
        if (res.data?.status === 'ACTIVE') {
          
          UserService.setisAuthenticated();
          if( UserService.getLoginUserObject().permissionslist.includes("View Assessments")){
            navigate('/assessmentlist');
          } else{
            if(checkPermissionList(UserService.getLoginUserObject().permissionslist) ==-1){
              navigate('/no-data')
            }
          }
          
          window.location.reload();
          // sessionStorage.setItem('flag', 'true');
        } else if (res.data?.status === 'RESET'|| res.data?.status === 'NEW') {
          UserService.setisAuthenticated();
          navigate('/resetPassword');
          window.location.reload();
          // sessionStorage.setItem('flag', 'true');
        } else if (res.data?.status === 'INACTIVE') {
          toast.error('Your account has been inactivated please contact your admin, for further information contact RS_ADMIN', { autoClose: 1000 });
        } 
//         else if(currentDate>=targetEndDate){
//           console.log(res.data?.clientId.id,res.data.token)
// UserService.InactiveAllUsersByClientId(res.data?.clientId.id)
//           toast.error('Your Plan Exipered')
//         }else {
//           toast.error('Server error', { autoClose: 1000 });
//         }
      }
    })
    .catch((error) => {
      
      if (error.response && error.response.status === 404) {
        setUserNameError(true);
      } else if (error.response && error.response.status === 402) {
        console.log(error)
        toast.error(error.response.data.message, { autoClose: 1000 });
        // setPasswordError(true);
      }else if(error.response.status == 400){
        setPasswordError(true);
        toast.error(error.response.data.message, { autoClose: 1000 })
      }
      else if (error.name === 'AxiosError') {
        toast.error('Server Not Responding');
      }
    });
  
  }


 
  
  return (
    <ThemeProvider theme={dsgTheme}>
    <>
    <SideMenu/>
    <Container className="login-container">
      <Container className="login-form-container">
        <form onSubmit={handleSubmit}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="h5">Sign In</Typography>
          <Container className="password-error-container">
            {passwordError && (
              <Typography className="password-message">
               The username or password isnt right
              </Typography>
            )}
          
          </Container>
            <TextField
              type="text"
              value={email}
              fullWidth
              id="filled-basic"
              size="small"
              label="Email"
              variant="outlined"
              onChange={handleUserNameChange}
              required
              error={userNameError}
              helperText={
                userNameError ? (
                  <span className="error-message">Username not found</span>
                ) : (
                  ""
                )
              }
            />

            <TextField
              id="password-input"
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              size="small"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="current-password"
              variant="outlined"
              
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                     {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              error={passwordError}
             
            />
          </Stack>
          <Stack direction="column" spacing={2} alignItems="center">
            <Link href="forgotPassword" className="login-link">
              Forgot password
            </Link>
            <Button type="submit">
              Sign In
            </Button>
          </Stack>
        </form>
      </Container>
   
    </Container>
    
    <ToastContainer position="bottom-left"/>
    </>
    </ThemeProvider>
  );
};
