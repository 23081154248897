import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import WifiOffIcon from '@mui/icons-material/WifiOff';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import screenfull from "screenfull";

import {
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextareaAutosize,
  ThemeProvider,
  Typography,
} from "@mui/material";
import axios, { Axios, AxiosError } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dsgTheme } from "../../styles/theme";
import { SessionExpiredModal } from "../admin/list-of-users/SessionExpiredModal";
import "./Candidate.css";
import Webcam from "react-webcam";
import { ClientInterface } from "../admin/dummydata/ClientInterface";
import { getClientThankYouData } from "src/clients/CandidateClient";
import UserService from "src/clients/UserService";
import { AdminConfigurationInterface } from "../admin/dummydata/AdminConfigurationInterface";
import { formatDateStr } from "src/utils/ValidationUtils";
import { Wifi } from "@mui/icons-material";

interface Assessment {
  id: number;
  assessmentName: string;
  noOfSections: number;
  questionsPerSection: number;
  totalTime: number;
  totalMarks: number;
  shuffleQuestionsStatus: boolean;
  // videoChunks: number;
  section: {
    sectionId: number;
    totalTime: number;
    totalMarks: number;
    sectionName: string;
    skillName: string;
    assessmentQuestionDetail: {
      questionId: number;
      assessmentQuestionNumber: number;
    }[];
  }[];
}

interface Question {
  id: number;
  questionText: string;
  executionText: string;
  questionType: string;
  difficulty: string;
  skill: string;
  addInfo: string | null;
  createdDate: Date | null;
  createdBy: string | null;
  updateDate: Date | null;
  updatedBy: string | null;
  status: string | null;
  maximumCharacters: number;
  isBulkUpload: number;
  timeToSolve: number;
  language: string[];
  positiveMarks: number;
  negativeMarks: number;
  answers: {
    id: number;
    optionId: number;
    optionValue: string;
    answerFlag: boolean;
  }[];
}

interface Response {
  candidateId: number;
  assessmentId: number;
  noOfSections: number;
  flag: boolean;
  sectionResponses: {
    sectionId: number;
    sectionScore: number;
    sectionStartTimestamp: string;
    sectionEndTimestamp: string;
    questionResponses: {
      questionId: number | undefined;
      assessmentQuestionNo: number;
      response: string;
      isCorrect: boolean | undefined;
    }[];
  };
  totalScore: number;
}

interface AssessmentState {
  st: string;
  showAlert: boolean;
  sectionNo: number;
  sectionScore: number;
  questionNo: number;
  review: string[];
  answers: {
    id: number;
    answer: string;
    isCorrect: boolean;
    descriptiveAttempted: boolean;
  }[];
  submitClick: boolean;
  attempted: number;
  unattempted: number;
  questionsCount: number;
}

function Candidate() {
  const location = useLocation();
  const navigate = useNavigate();
  const [chances, setchances] = useState(2);
  const [countdown, setCountdown] = useState(10);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [timerRunning, setTimerRunning] = useState(true);

  const storedList = localStorage.getItem("list");
  const [list, setList] = useState<number[][]>(
    storedList != null ? JSON.parse(storedList) : []
  );
  const [internetError, setInternetError] = useState<boolean>(false);
  const [reloadAttempts, setReloadAttempts] = useState<number>(3);
  const candidateId: number = location.state?.candidateId;
  const assessment: Assessment = location.state?.assessment;
  const videoChunks: number =
    Number(localStorage.getItem("videoChunks")) ?? 20000;

  const configurations: string[] = location.state?.configurations;
  const [question, setQuestion] = useState<Question>();
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>(false);
  const [descriptiveData, setDescriptiveData] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [executionText, setExecutionText] = useState<string>("");
  const [language, setLanguage] = useState<string[]>([]);
  const [videoChunksConfiguration, setVideoChunksConfiguration] =
    useState<AdminConfigurationInterface>();
  const [organization, setOrganzation] = useState<ClientInterface>({
    id: 0,
    clientName: "",
    clientId: "",
    clientStatus: "",
    clientLogo: "",
    autoRenew: false,
  });

  const createInitialArray = (length: number) => {
    return Array(length).fill("-1");
  };
  const initialResponse: Response = {
    candidateId: candidateId,
    assessmentId: assessment.id,
    noOfSections: assessment.section.length,
    flag: false,
    sectionResponses: {
      sectionId: -1,
      sectionScore: 0,
      sectionStartTimestamp: formatDateStr(new Date(), 'YYYY-MM-DDTHH:mm:ss',true),
      sectionEndTimestamp: formatDateStr(new Date(), 'YYYY-MM-DDTHH:mm:ss',true),
      questionResponses: [
        {
          questionId: 0,
          response: "0",
          assessmentQuestionNo: 0,
          isCorrect: false,
        },
      ],
    },
    totalScore: 0,
  };

  const intitialState: AssessmentState = {
    st: formatDateStr(new Date(), 'YYYY-MM-DDTHH:mm:ss',true),
    showAlert: false,
    sectionNo: 0,
    sectionScore: 0,
    questionNo:
      assessment.section[0].assessmentQuestionDetail[0]
        .assessmentQuestionNumber,
    review: createInitialArray(
      assessment.section[0].assessmentQuestionDetail.length
    ),
    answers: assessment.section[0].assessmentQuestionDetail.map((question) => ({
      id: question.assessmentQuestionNumber,
      answer: "0",
      isCorrect: false,
      descriptiveAttempted: false,
    })),
    submitClick: false,
    attempted: 0,
    unattempted: 0,
    questionsCount: assessment.section[0].assessmentQuestionDetail.length,
  };

  const [response, setResponse] = useState<Response>(initialResponse);

  const storedState = localStorage.getItem("state");
  const [state, setState] = useState<AssessmentState>(
    storedState != null ? JSON.parse(storedState) : intitialState
  );
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "r") {
      event.preventDefault();
    }
  };
  document.addEventListener("keydown", handleKeyPress);
  useEffect(() => {
    const stored = localStorage.getItem("reloadAttempts");
    
    if (stored != null) {
      if (parseInt(stored) == 0) {
        setReloadAttempts(0);
        if (storedState != null) {
          const parsedState = JSON.parse(storedState);
          if (parsedState.sectionNo + 1 >= assessment.section.length) {
            sendSectionResponse(false);
          } else {
            sendSectionResponse(true);
          }
        }
        screenfull.exit();
        localStorage.removeItem("reloadAttempts");
        localStorage.removeItem("state");
        localStorage.removeItem("list");
        navigate("/Feedbackscreen");
      } else {
        setReloadAttempts(parseInt(stored));
      }
    }
  }, []);

  useEffect(() => {
    if (list.length == 0) {
      extractQuestionIds();
    } else {
      getQuestionById(state.questionNo, list, state.sectionNo);
    }
  }, []);

  const getQuestionById = async (
    questionId: number,
    questionList: number[][],
    sectionNo: number
  ) => {
    try {
      const id = questionList?.[sectionNo]?.[questionId - 1];
      const response: any = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/candidates/question-bank/question-by-id/${id}`
      );
      
      setQuestion(response.data);
    } catch (error: any) {
      if (error.message === "Network Error") {
        const a = reloadAttempts - 1;
        localStorage.setItem("reloadAttempts", a.toString());
        localStorage.setItem("state", JSON.stringify(state));
        localStorage.setItem("list", JSON.stringify(list));
        setInternetError(true);
      }
    }
  };

  const sendSectionResponse = async (flag: boolean) => {
    try {
      
      const sectionResponse = {
        sectionId: state.sectionNo + 1,
        sectionScore: 0,
        sectionStartTimestamp: state.st,
        sectionEndTimestamp: formatDateStr(new Date(), 'YYYY-MM-DDTHH:mm:ss',true),
        questionResponses: state.answers.map((answer, index) => ({
          questionId: list?.[state.sectionNo]?.[index],
          assessmentQuestionNo: answer.id,
          response: answer.answer,
          isCorrect: answer.isCorrect,
        })),
      };
      response.sectionResponses = sectionResponse;
      response.flag = flag;
     
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/candidates/save-sections`,
        response,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
    } catch (error: any) {
      if (error.message === "Network Error") {
        
        const a = reloadAttempts - 1;
        localStorage.setItem("reloadAttempts", a.toString());
        localStorage.setItem("state", JSON.stringify(state));
        localStorage.setItem("list", JSON.stringify(list));
        setInternetError(true);
      }
    }
  };

  const shuffleList = (shuffledList: number[]) => {
    // Using Fisher-Yates shuffle algorithm
    for (let i = shuffledList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
    // Update the state with the shuffled list

    return shuffledList;
  };

  useEffect(() => {
    getClientThankYouData(assessment.assessmentName ?? "")
      .then((response) => response.json())
      .then((data) => {
       
        setOrganzation(data);
        localStorage.setItem("clientName", data.clientName);
      })
      .catch((error) => {
        console.error("Error capturing video:", error);
      });
  }, []);

  
  const extractQuestionIds = () => {
    const questionIdsList: number[][] = [];
    assessment.section.forEach((section) => {
      let questionIds: number[] = [];
      section.assessmentQuestionDetail.forEach((detail) => {
        questionIds.push(detail.questionId);
      });
      if (assessment.shuffleQuestionsStatus) {
        questionIds = shuffleList(questionIds);
      }
      questionIdsList.push(questionIds);
    });

    getQuestionById(state.questionNo, questionIdsList, state.sectionNo);
    setList(questionIdsList);
  };

 

  useEffect(() => {
    if (question?.questionType === "DESCRIPTIVE") {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_BASE_URL
            }/candidates/descriptive-response/${candidateId}/${
              state.sectionNo + 1
            }/${state.questionNo}`
          );
          if (response.data.response != null) {
          
            setDescriptiveData(response.data.response);
          }
        } catch (error: any) {
          if (error.message === "Network Error") {
          
            const a = reloadAttempts - 1;
            localStorage.setItem("reloadAttempts", a.toString());
            localStorage.setItem("state", JSON.stringify(state));
            localStorage.setItem("list", JSON.stringify(list));
            setInternetError(true);
          }
        }
      };
      fetchData();
    } else if (question?.questionType === "CODING") {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_BASE_URL
            }/candidates/compiler-response/${candidateId}/${
              state.sectionNo + 1
            }/${state.questionNo}`
          );
          if (response.data.response != null) {
        
            setDescriptiveData(response.data.response);
            setSelectedLanguage(response.data.language);
          } else {
            if (question.executionText.length != 0) {
              const textContent = question.executionText.replace(
                /(<([^>]+)>)/gi,
                ""
              );
              // const textContent = htmlElement.question.executionText;
              setDescriptiveData(textContent);
            }
          }
        } catch (error: any) {
          if (error.message === "Network Error") {
            
            const a = reloadAttempts - 1;
            localStorage.setItem("reloadAttempts", a.toString());
            localStorage.setItem("state", JSON.stringify(state));
            localStorage.setItem("list", JSON.stringify(list));
            setInternetError(true);
          }
        }
      };
      fetchData();
    }
  }, [question, state.submitClick]);

  useEffect(() => {
    if (openSessionModal && timerRunning) {
      const modalTimer = setTimeout(() => {
        setOpenSessionModal(false);
        setCountdown(10); // Reset the countdown to 5 seconds
      }, 10000);
      const countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => {
        clearTimeout(modalTimer);
        clearInterval(countdownTimer);
      };
    }
  }, [openSessionModal]);

  useEffect(() => {
    if (countdown == 1) {
      if (state.sectionNo + 1 >= assessment.section.length) {
        sendSectionResponse(false);
      } else {
        sendSectionResponse(true);
      }
      localStorage.removeItem("reloadAttempts");
      localStorage.removeItem("state");
      localStorage.removeItem("list");
      stopCapture();
      navigate(`/Feedbackscreen`);
      setStopWebCam(false);
      localStorage.setItem("assessmentName", assessment.assessmentName);
      window.location.reload();
      screenfull.exit();
    }
  }, [countdown]);

  function handleModalSignOut() {
    setOpenSessionModal(false);
    setTimerRunning(false);
    if (chances == 1) {
      if (state.sectionNo + 1 >= assessment.section.length) {
        sendSectionResponse(false);
      } else {
        sendSectionResponse(true);
      }
      localStorage.removeItem("reloadAttempts");
      localStorage.removeItem("state");
      localStorage.removeItem("list");
      stopCapture();
      navigate(`/Feedbackscreen`);
      setStopWebCam(false);
      localStorage.setItem("assessmentName", assessment.assessmentName);
      window.location.reload();
      screenfull.exit();
    } else {
      screenfull.request();
      setCountdown(10); // Reset the countdown to 10 seconds
      setTimerRunning(true);
      setchances(chances - 1);
    }
  }

  useEffect(() => {
    if (configurations?.includes("FULLSCREENMODE")) {
      document.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          setOpenSessionModal(true);
        }
      });
      window.addEventListener("blur", () => {
        setOpenSessionModal(true);
      });
    }
  }, []);

  const currentOptions = question?.answers;

  const [seconds, setSeconds] = useState(
    assessment.section[state.sectionNo].totalTime * 60
  );
  const [minutes, setMinutes] = useState(Math.floor(seconds / 60));

  //Timer
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000); // Change the interval to 1000ms (1 second) instead of 10000ms (10 seconds)

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      setSeconds(assessment.section[state.sectionNo].totalTime);
    }
    setMinutes(Math.floor(seconds / 60));
  }, [assessment.section, seconds, state.sectionNo]);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      handleSectionSubmit();
    }
  }, [seconds, minutes]);

  useEffect(() => {
    if (minutes === 0) {
      setState((prevState) => ({
        ...prevState,
        showAlert: true,
      }));
      toast.error(
        "Last few Minutes please submit or it will be automatically submitted"
      );
    }
  }, [minutes]);

  // Methods
  const mark = () => {
    const newReview = [...state.review];
    newReview[state.questionNo - 1] =
      newReview[state.questionNo - 1] === "-1" ? "0" : "-1";
    setState((prevState) => ({
      ...prevState,
      review: newReview,
    }));
    
  };

  const handleOptionChange = async (
    questionId: number,
    selectedOption: number,
    section: number
  ) => {
   
    try {
      const prevSelectedAnswer = state.answers.find(
        (answer) => answer.id === questionId
      )?.answer;
      let response = "";
      const isSingleChoice = question?.questionType === "MCQ";
      if (isSingleChoice) {
        response =
          prevSelectedAnswer === selectedOption.toString()
            ? "0"
            : selectedOption.toString();
      } else {
        if (prevSelectedAnswer?.includes(selectedOption.toString())) {
          response = prevSelectedAnswer.replace(selectedOption.toString(), "");
          if (response.length === 0) {
            response = "0";
          }
        } else {
          response = (prevSelectedAnswer + selectedOption.toString()).replace(
            "0",
            ""
          );
        }
      }
      const mcqResponseDTO = {
        assessmentId: assessment.id,
        candidateId: candidateId,
        sectionId: assessment.section[section].sectionId,
        assessmentQuestionNo: questionId,
        questionId: list?.[state.sectionNo]?.[questionId - 1],
        response: response,
      };
      setState((prevState) => {
        const isAnswered = prevState.answers.some(
          (answer) => answer.id === questionId && answer.answer !== "0"
        );
        const updatedAnswers = prevState.answers.map((answer) => {
          if (answer.id === questionId) {
            return {
              ...answer,
              answer: answer.id === questionId ? response : answer.answer,
              isCorrect: checkIsCorrect(response),
            };
          }
          return answer;
        });
        let attempted = prevState.attempted;
        if (response === "0") {
          attempted = isAnswered ? attempted - 1 : attempted;
        } else {
          attempted = isAnswered ? attempted : attempted + 1;
        }
        attempted = Math.max(0, attempted);
        return {
          ...prevState,
          attempted: attempted,
          answers: updatedAnswers,
        };
      });
    } catch (error: any) {
      if (error.message === "Network Error") {
        const a = reloadAttempts - 1;
        setInternetError(true);
      }
    }
  };

  const checkIsCorrect = (response: string): boolean => {
    if (!question) {
      return false;
    }

    const isSingleChoice = question.questionType === "MCQ";
    const options = question.answers;

    if (!options || options.length === 0) {
      return false;
    }

    if (isSingleChoice) {
      const correctOptionId: any =
        options.find((opt) => opt.answerFlag)?.optionId.toString() || "";
     
      return response === correctOptionId;
    } else {
      const selectedOptions = response.split("");
      
      const selectedOptionIds = selectedOptions.map((option) =>
        parseInt(option)
      );
      

      const correctOptionIds = options
        .filter((opt) => opt.answerFlag)
        .map((option) => option.optionId);
     

      const isCorrect =
        selectedOptionIds.sort().toString() ===
        correctOptionIds.sort().toString();

      return isCorrect;
    }
  };

  const handleDescriptiveQuestion = async (question: Question) => {
    if (question.questionType == "CODING") {
      return handlecompilerQuestion(selectedLanguage);
    } else if (question.questionType == "DESCRIPTIVE") {
      const descriptiveResponseDTO = {
        assessmentId: assessment.id,
        candidateId: candidateId,
        sectionId: assessment.section[state.sectionNo].sectionId,
        assessmentQuestionNo: state.questionNo,
        questionId: list?.[state.sectionNo]?.[state.questionNo - 1],
        response: descriptiveData,
      };

      try {
        const responseURL = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/candidates/save-single-descriptive-response`,
          descriptiveResponseDTO
        );

        if (responseURL.status == 200) {
          const updatedAnswers = state.answers.map((answer) => {
            if (answer.id === state.questionNo) {
              return {
                ...answer,
                descriptiveAttempted: true, // Set descriptiveAttempted to true for this answer
              };
            }
            return answer; // Return the answer as it is if it doesn't need updating
          });
          setState((prevState) => {
            const isAnswered = prevState.answers.some(
              (answer) =>
                answer.id === state.questionNo && answer.descriptiveAttempted
            );
            const updatedAnswers = state.answers.map((answer) => {
              if (answer.id === state.questionNo) {
                return {
                  ...answer,
                  descriptiveAttempted: true, // Set descriptiveAttempted to true for this answer
                };
              }
              return answer; // Return the answer as it is if it doesn't need updating
            });
            return {
              ...prevState,
              answers: updatedAnswers, // Update the answers array in state
              attempted: isAnswered
                ? prevState.attempted
                : prevState.attempted + 1,
            };
          });
          toast.success("Response saved successfully", { autoClose: 1000 });
        }
      } catch (error: any) {
        if (error.message === "Network Error") {
        
          const a = reloadAttempts - 1;
          localStorage.setItem("reloadAttempts", a.toString());
          localStorage.setItem("state", JSON.stringify(state));
          localStorage.setItem("list", JSON.stringify(list));
          setInternetError(true);
        }
      }
    }
  };
  const handlecompilerQuestion = async (language: string) => {
    if (selectedLanguage.length === 0) {
      toast.error("Please select programming language...",{autoClose:1000});
      return;
    }else if(descriptiveData.includes("Scanner")){
      toast.error("Don't use Scanner or Input readable's , Instead implement the logic",{autoClose:1000});
      return;
    }
    const compilerResponseDTO = {
      assessmentId: assessment.id,
      candidateId: candidateId,
      sectionId: assessment.section[state.sectionNo].sectionId,
      assessmentQuestionNo: state.questionNo,
      questionId: list?.[state.sectionNo]?.[state.questionNo - 1],
      response: descriptiveData,
      language: language,
    };
    try {
      const responseURL = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/candidates/save-single-compiler-response`,
        compilerResponseDTO
      );
      if (responseURL.status === 200) {
        const updatedAnswers = state.answers.map((answer) => {
          if (answer.id === state.questionNo) {
            return {
              ...answer,
              descriptiveAttempted: true, // Set descriptiveAttempted to true for this answer
            };
          }
          return answer; // Return the answer as it is if it doesn't need updating
        });
        setState((prevState) => {
          const isAnswered = prevState.answers.some(
            (answer) =>
              answer.id === state.questionNo && answer.descriptiveAttempted
          );
          const updatedAnswers = state.answers.map((answer) => {
            if (answer.id === state.questionNo) {
              return {
                ...answer,
                descriptiveAttempted: true, // Set descriptiveAttempted to true for this answer
              };
            }
            return answer; // Return the answer as it is if it doesn't need updating
          });
          return {
            ...prevState,
            answers: updatedAnswers, // Update the answers array in state
            attempted: isAnswered
              ? prevState.attempted
              : prevState.attempted + 1,
          };
        });
        toast.success("Response saved successfully", { autoClose: 1000 });
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
       
      }
    }
  };

  const handleNavigate = (index: number) => {

    if (
      question?.questionType == "DESCRIPTIVE" ||
      question?.questionType == "CODING"
    ) {
      setDescriptiveData("");
      setSelectedLanguage("");
    }
    getQuestionById(index + 1, list, state.sectionNo);
    setState((prevState) => ({
      ...prevState,
      questionNo: index + 1,
      submitClick: false,
    }));
  };

  const handlePreviousQuestion = () => {
 
    if (
      question?.questionType == "DESCRIPTIVE" ||
      question?.questionType == "CODING"
    ) {
      setDescriptiveData("");
      setSelectedLanguage("");
    }
    if (state.questionNo > 1) {
      getQuestionById(state.questionNo - 1, list, state.sectionNo);
      setState((prevState) => ({
        ...prevState,
        questionNo: prevState.questionNo - 1,
      }));
    }
  };

  const handleNextQuestion = () => {
    if (
      question?.questionType == "DESCRIPTIVE" ||
      question?.questionType == "CODING"
    ) {
      setDescriptiveData("");
      setSelectedLanguage("");
    }
   
    if (
      state.questionNo <=
      assessment.section[state.sectionNo].assessmentQuestionDetail.length
    ) {
     
      getQuestionById(state.questionNo + 1, list, state.sectionNo);
      setState((prevState) => ({
        ...prevState,
        questionNo: prevState.questionNo + 1,
      }));
    }
  };

  const handleSummarySubmit = () => {
    if (
      question?.questionType == "DESCRIPTIVE" ||
      question?.questionType == "CODING"
    ) {
      setDescriptiveData("");
      setSelectedLanguage("");
    }
    setState((prevState) => ({
      ...prevState,
      submitClick: true,
    }));
  };

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value as string);
  };

  const handleMoveToNextSection = (nextSectionNo: number) => {
    setState((prevState) => ({
      ...prevState,
      sectionScore: 0,
      sectionNo: nextSectionNo,
      review: createInitialArray(
        assessment.section[nextSectionNo].assessmentQuestionDetail.length
      ),
      answers: assessment.section[nextSectionNo].assessmentQuestionDetail.map(
        (question) => ({
          id: question.assessmentQuestionNumber,
          answer: "0",
          isCorrect: false,
          descriptiveAttempted: false,
        })
      ),
      submitClick: false,
      questionNo:
        assessment.section[nextSectionNo].assessmentQuestionDetail[0]
          .assessmentQuestionNumber,
      attempted: 0,
      unattempted: 0,
      st: formatDateStr(new Date(), 'YYYY-MM-DDTHH:mm:ss',true),
      showAlert: false,
    }));

   
    setSeconds(assessment.section[nextSectionNo].totalTime * 60);
    const questionId =
      assessment.section[nextSectionNo].assessmentQuestionDetail[0]
        .assessmentQuestionNumber;

    getQuestionById(questionId, list, nextSectionNo);
  };

  const handleSectionSubmit = () => {
    sendSectionResponse(false);
    handleClose();
  
    if (state.sectionNo + 1 >= assessment.section.length) {
      localStorage.removeItem("reloadAttempts");
      localStorage.removeItem("state");
      localStorage.removeItem("list");
      stopCapture();
      setStopWebCam(false);
      localStorage.setItem("assessmentName", assessment.assessmentName);
      screenfull.exit();
      setTimeout(() => {
        navigate(`/Feedbackscreen`);
        window.location.reload();
      },1000)
    } else {
     
      setDescriptiveData("");
      setSelectedLanguage("");
      handleMoveToNextSection(state.sectionNo + 1);
    }
  };

  const onDescriptiveDataChange = (s: string) => {
    if (question?.maximumCharacters !== undefined) {
      // Check if the new length is within the allowed range
      if (s.length <= question.maximumCharacters) {
        setDescriptiveData(s);
      }
    }
  };

  //DialogBox
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const comQuestion = (value: any) => {
    return <Typography dangerouslySetInnerHTML={{ __html: value }} />;
  };

  const countQuestionsWithValue0 = (reviewArray: any) => {
    let count = 0;
    for (const value of reviewArray) {
      if (value === "0") {
        count++;
      }
    }
    return count;
  };

  const handleCopy = (event: React.ClipboardEvent) => {
    if (configurations?.includes("NOCOPY")) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    if (configurations?.includes("NOPRINT")) {
      event.preventDefault();
    }
  };

  const handleRightClick = (event: React.MouseEvent) => {
    if (configurations?.includes("NORIGHTCLICK")) {
      event.preventDefault();
    }
  };

  const handleReloadClick = () => {
    const isOnline = navigator.onLine;
    if (!isOnline) {
      setInternetError(true);
    } else {
      setInternetError(false);
      window.location.reload(); // Reload the page when the browser is back online
    }
  };

  
  // Calculate the number of questions marked for review
  const numberOfQuestionsMarkedForReview = countQuestionsWithValue0(
    state.review
  );

  const webcamRef = useRef<Webcam>(null);
  let recordedChunks: BlobPart[] = [];
  let mediaRecorder: MediaRecorder | null = null;
  let captureInterval: any;

  
  const [stopWebCam, setStopWebCam] = useState<boolean>(true);
  const videoRef = useRef(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isCaptureRunning, setIsCaptureRunning] = useState(false);

  const startCapture = () => {
    if (!isCaptureRunning && stopWebCam) {
      setIsCaptureRunning(true);

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          if (webcamRef.current && webcamRef.current.video) {
            webcamRef.current.video.srcObject = stream;
          }

          mediaRecorder = new MediaRecorder(stream, {
            mimeType: "video/webm; codecs=h264",
          });
          let chunkNumber = 1;

          mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
              recordedChunks.push(event.data);
            }
          };

          mediaRecorder.onstop = () => {
            const blob = new Blob(recordedChunks, {
              type: mediaRecorder?.mimeType,
            });

            const mp4VideoData = new FormData();
            const candidateName = localStorage.getItem("candidateName");
            const clientName = localStorage.getItem("clientName");
            mp4VideoData.append("candidateId", candidateId.toString());
            mp4VideoData.append("video", blob);
            mp4VideoData.append("assessmentName", assessment.assessmentName);
            mp4VideoData.append(
              "candidateName",
              candidateName === null ? "" : candidateName
            );
            
            mp4VideoData.append(
              "clientName",
              clientName === null ? "" : clientName
            );
            mp4VideoData.append("chunkNumber", chunkNumber.toString());
            
            if (chunkNumber !== 0) {
              fetch(`${process.env.REACT_APP_BASE_URL}/proctoring/capture_video`, {
                method: "POST",
                body: mp4VideoData,
              })
                .then((response) => response.json())
                .then((data) => {
                
                })
                .catch((error) => {
                  console.error("Error capturing video:", error);
                });
            }
            chunkNumber++;
           
            recordedChunks = [];
          };

          mediaRecorder.start();
          captureInterval = setInterval(() => {
            mediaRecorder?.stop();
            mediaRecorder?.start();
          }, videoChunks); // Adjusted interval to 10 seconds
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }
  };


  const stopCapture = () => {
    clearInterval(captureInterval);

    // Stop the media recorder
    if (mediaRecorder) {
      mediaRecorder.stop();
    }

   

    const video = webcamRef.current?.video;

    if (video && video.srcObject) {
      const tracks = (video.srcObject as MediaStream)?.getTracks();

      if (tracks) {
        tracks.forEach((track) => {
        
          track.stop();
        });
      }

      // Stop the webcam stream
      const webcamStream = video.srcObject as MediaStream;
      webcamStream.getTracks().forEach((track) => track.stop());

      // Clear the webcam stream
      video.srcObject = null;
    }

    

    // Clear recorded chunks and update state
    recordedChunks = [];
    setIsCaptureRunning(false);

    const Data = new FormData();
    const candidateName = localStorage.getItem("candidateName");
    const clientName = localStorage.getItem("clientName");
    Data.append("candidateId", candidateId.toString());
    Data.append("assessmentName", assessment.assessmentName);
    Data.append("candidateName", candidateName === null ? "" : candidateName);
    Data.append("clientName", clientName === null ? "" : clientName);
    Data.append("assessmentId", String(assessment.id));
    Data.append("chunkTime",String(videoChunks))

    fetch(`${process.env.REACT_APP_BASE_URL}/proctoring/stop_capture?videoId=${candidateId}`, {
      method: "POST",
      body: Data,
    })
      .then((response) => response.json())
      .then((data) => {
        
      })
      .catch((error) => {
        console.error("Error stopping capture:", error);
      });

    setIsCapturing(false);
  };

  useEffect(() => {
    startCapture();
    

    return () => {
      clearInterval(captureInterval);
    };
  }, []);

  return (
    <ThemeProvider theme={dsgTheme}>
      {internetError ? (
        <Box className="network">
          <Box>
          <IconButton><WifiOffIcon sx={{fontSize:'100px'}}/></IconButton>  
          <h2 style={{display:'flex',alignItems:'center'}}> No Network</h2>
          
          <Button onClick={handleReloadClick}>reload</Button>
          <p style={{fontSize:'13px'}}> Your chances left: <strong>{reloadAttempts - 1}</strong></p>
          </Box>
         
        </Box>  
      ) : (
        <div
          className="screen-wrapper"
          onCopy={(event) => handleCopy(event)}
          onPaste={(event) => handlePaste(event)}
          onContextMenu={(event) => handleRightClick(event)}
        >
          <Box
            className="test-screen-body"
            sx={{
              boxShadow: state.submitClick
                ? "none"
                : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div>
              {stopWebCam && (
                <Webcam
                  style={{ width: "10%" }}
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={{
                    width: { ideal: 200 },
                    height: { ideal: 200 },
                  }}
                />
              )}
            </div>
            <Grid container>
              <Grid
                item
                xs={2.2}
                md={2}
                lg={2}
                className="shadow leftGrid"
                sx={{
                  height: state.submitClick ? "65vh" : "auto",
                }}
              >
                <Stack>
                  <Typography className="sectionDetails">
                    {assessment.section[state.sectionNo].sectionName}
                  </Typography>
                  <Box>
                    {assessment.section[
                      state.sectionNo
                    ].assessmentQuestionDetail.map((question, index) => (
                      <Button
                        key={index}
                        className="roundButton"
                        sx={{
                          marginRight: 1,
                          backgroundColor: () => {
                            const isFlagged = state.review[index] === "0";
                            const isDescriptive =
                              state.answers[index]?.descriptiveAttempted;
                            const isAnswered =
                              state.answers[index]?.answer !== "0";
                            let backgroundColor;
                            if (isFlagged) {
                              backgroundColor = "var(--flagged-color)";
                            } else if (!isAnswered && !isDescriptive) {
                              backgroundColor = "var(--unanswered-color)";
                            } else {
                              backgroundColor = "var(--answered-color)";
                            }
                            return backgroundColor;
                          },
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() => handleNavigate(index)}
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </Box>
                  <Box className="answerTags">
                    <Stack
                      direction="row"
                      className="answertag"
                    >
                      <Button
                        className="roundButton unanswered"
                        variant="outlined"
                      ></Button>
                      <Typography className="answertagText">
                        Unanswered
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      className="answertag"
                    >
                      <Button
                        className="roundButton answered"
                        variant="outlined"
                      ></Button>
                      <Typography className="answertagText">
                        Answered
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      className="answertag"
                    >
                      <Button
                        className="roundButton flagged"
                        variant="outlined"
                      ></Button>
                      <Typography className="answertagText">
                        Marked for Review
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid
                xs={8.8}
                md={9}
                lg={9.5}
                sx={{
                  marginLeft: 2,
                  display: state.submitClick ? "none" : "block",
                }}
              >
                <Grid
                  xs={12}
                  sx={{ height: 80 }}
                >
                  <Box className="questionTimer">
                    <p>
                      Question: {state.questionNo}/
                      {
                        assessment.section[state.sectionNo]
                          .assessmentQuestionDetail.length
                      }
                      <br></br>
                      <Box sx={{ color: state.showAlert ? "red" : "black" }}>
                        Remaining Time: {minutes < 10 ? "0" : ""}
                        {minutes}:{seconds % 60 < 10 ? "0" : ""}
                        {seconds % 60} mins
                      </Box>
                    </p>
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  className="shadow questionTextContainer"
                  sx={{ marginBottom: 1 }}
                >
                  <Box
                    display="flex"
                    alignItems="baseline"
                    className="questionsOptions"
                  >
                    <Typography>{state.questionNo}.&nbsp;</Typography>
                    {comQuestion(question?.questionText)}
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  className="shadow"
                  sx={{ marginBottom: 1 }}
                >
                  <Stack
                    direction="column"
                    className="options"
                  >
                    {question?.questionType == "MCQ" &&
                      currentOptions &&
                      currentOptions.map((option) => (
                        <FormControlLabel
                          key={option.optionId}
                          value={option.optionId.toString()}
                          control={
                            <Radio
                              color={
                                state.answers.find(
                                  (answer) => answer.id === state.questionNo
                                )?.answer === option.optionId.toString()
                                  ? "primary"
                                  : "default"
                              }
                              checked={
                                state.answers.find(
                                  (answer) => answer.id === state.questionNo
                                )?.answer == option.optionId.toString()
                              }
                              // onChange={() =>
                              //   handleOptionChange(
                              //     state.questionNo,
                              //     option.optionId,
                              //     state.sectionNo
                              //   )
                              // }
                            />
                          }
                          label={comQuestion(option.optionValue)}
                          onClick={() =>
                            handleOptionChange(
                              state.questionNo,
                              option.optionId,
                              state.sectionNo
                            )
                          }
                        />
                      ))}

                    {
                      question?.questionType == "MSQ" &&
                        currentOptions &&
                        currentOptions.map((option) => (
                          <FormControlLabel
                            key={option.optionId}
                            control={
                              <Checkbox
                                // color={
                                //   state.answers.find(
                                //     (answer) => answer.id === state.questionNo
                                //   )?.answer === option.optionId
                                //     ? "primary"
                                //     : "default"
                                // }
                                checked={state.answers
                                  .find(
                                    (answer) => answer.id === state.questionNo
                                  )
                                  ?.answer.includes(option.optionId.toString())}
                                onChange={() =>
                                  handleOptionChange(
                                    state.questionNo,
                                    option.optionId,
                                    state.sectionNo
                                  )
                                }
                              />
                            }
                            label={comQuestion(option.optionValue)}
                          />
                        ))

                      // onClick={() =>
                      //   handleOptionChange(state.questionNo, option.optionId,state.sectionNo)
                      // }
                      // />
                    }

                    {question?.questionType === "DESCRIPTIVE" && (
                      <>
                        <Typography
                          sx={{ marginLeft: "auto", color: "#fe524a" }}
                        >
                          Max Characters :
                          {question?.maximumCharacters - descriptiveData.length}
                        </Typography>
                        <TextareaAutosize
                          minRows={10}
                          maxRows={10}
                          placeholder="Enter your answer here......"
                          style={{
                            resize: "none",
                            height: "200px",
                            width: "100%",
                            border: "1px solid gray",
                            borderRadius: "3px",
                            fontSize: "16px",
                            outline: "none",
                          }}
                          value={descriptiveData}
                          onChange={(e) => {
                            onDescriptiveDataChange(e.target.value);
                          }}
                        />
                      </>
                    )}
                    {question?.questionType === "CODING" && (
                      <>
                        <Box
                          sx={{
                            marginBottom: "1%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FormControl sx={{ width: "20%" }}>
                            <InputLabel id="demo-simple-select-label">
                              Language
                            </InputLabel>
                            <Select
                              id="demo-simple-select"
                              label="Language"
                              value={selectedLanguage}
                              onChange={handleLanguageChange}
                              size="medium"
                            >
                              {question?.language.map((language) => (
                                <MenuItem value={language}>{language}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <TextareaAutosize
                          minRows={10}
                          maxRows={10}
                          placeholder="Enter your code here..."
                          style={{
                            resize: "none",
                            height: "200px",
                            width: "100%",
                            border: "1px solid gray",
                            borderRadius: "3px",
                            fontSize: "16px",
                            outline: "none",
                          }}
                          value={descriptiveData}
                          onChange={(e) => {
                            setDescriptiveData(e.target.value);
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  xs={12}
                  sx={{ marginBottom: 3, height: 50 }}
                >
                  <Button
                    variant="outlined"
                    onClick={mark}
                  >
                    Mark For Review
                  </Button>
                  <Box className="navigateButtons">
                    <Stack
                      direction="row"
                      spacing={2}
                    >
                      {question?.questionType === "CODING" && (
                        <Button
                          variant="outlined"
                          className="button"
                          disabled = {descriptiveData.length === 0}
                          onClick={() => handleDescriptiveQuestion(question)}
                        >
                          Save
                        </Button>
                      )}
                      {question?.questionType === "DESCRIPTIVE" && (
                        <Button
                          variant="outlined"
                          className="button"
                          disabled={
                            question?.maximumCharacters -
                              descriptiveData.length <
                              0 || descriptiveData.length === 0
                          }
                          onClick={() => handleDescriptiveQuestion(question)}
                        >
                          Save
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        className="button"
                        onClick={handlePreviousQuestion}
                        disabled={state.questionNo === 1}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        className="button"
                        sx={{
                          display:
                            state.questionNo ===
                            assessment.section[state.sectionNo]
                              .assessmentQuestionDetail.length
                              ? "none"
                              : "block",
                        }}
                        onClick={handleNextQuestion}
                      >
                        Next
                      </Button>
                      <Button
                        variant="outlined"
                        className="button"
                        sx={{
                          display:
                            state.questionNo ===
                            assessment.section[state.sectionNo]
                              .assessmentQuestionDetail.length
                              ? "block"
                              : "none",
                        }}
                        onClick={handleSummarySubmit}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>

              <Box
                className="summary shadow"
                sx={{ display: state.submitClick ? "block" : "none" }}
              >
                <TableContainer className="summaryGrid">
                  <Table sx={{ width: "96%" }}>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell">
                        Total Questions
                      </TableCell>
                      <TableCell className="summaryCellRight">
                        {
                          assessment.section[state.sectionNo]
                            .assessmentQuestionDetail.length
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell">
                        Answered Questions
                      </TableCell>
                      <TableCell className="summaryCellRight">
                        {state.attempted}
                      </TableCell>
                    </TableRow>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell">
                        Unanswered Questions
                      </TableCell>
                      <TableCell className="summaryCellRight">
                        {assessment.section[state.sectionNo]
                          .assessmentQuestionDetail.length - state.attempted}
                      </TableCell>
                    </TableRow>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell">
                        Marked for review
                      </TableCell>
                      <TableCell className="summaryCellRight">
                        {numberOfQuestionsMarkedForReview}
                      </TableCell>
                    </TableRow>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell">Time</TableCell>
                      <TableCell className="summaryCellRight">
                        {assessment.section[state.sectionNo].totalTime} mins
                      </TableCell>
                    </TableRow>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell">
                        Remaining Time
                      </TableCell>
                      <TableCell className="summaryCellRight">
                        {" "}
                        {minutes < 10 ? "0" : ""}
                        {minutes}:{seconds % 60 < 10 ? "0" : ""}
                        {seconds % 60} mins
                      </TableCell>
                    </TableRow>
                    <TableRow className="summaryrow">
                      <TableCell className="summeryCell"></TableCell>
                      <TableCell className="summaryCellRight">
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setState((prevState) => ({
                                ...prevState,
                                submitClick: false,
                              }));
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={handleClickOpen}
                          >
                            Submit
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <ToastContainer position="bottom-left" />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  width: "40%", // Set the maximum width of the dialog
                  fontSize: "20px",
                },
              }} // Set the background color to white
            >
              <DialogTitle
                id="alert-dialog-title"
                textAlign="center"
                style={{
                  color: "#444343",
                  fontWeight: "bold",
                  height: "auto",
                  padding: "1%",
                }}
              >
                {"Re-Confirm Submission"}
              </DialogTitle>

              <DialogContent>
                <DialogContentText
                  style={{
                    color: "black",
                    fontSize: "15px",
                    textAlign: "center",
                    height: "auto",
                  }}
                >
                  On submitting the section You will not be able to route back.
                  Please click on confirm to proceed.
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "white" }}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSectionSubmit}
                  variant="outlined"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <SessionExpiredModal
            open={openSessionModal}
            SignOutButton={
              chances >= 2
                ? { label: "Back", onClick: handleModalSignOut }
                : { label: "Continue", onClick: handleModalSignOut }
            }
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <ReportProblemIcon style={{ color: "#fe524a" }} />
              {chances >= 2 ? (
                <Typography fontWeight="bold">
                  Warning! Your exam will be submitted in {countdown} seconds
                </Typography>
              ) : (
                <Typography fontWeight="bold">
                  Your exam has been ended
                </Typography>
              )}
            </Stack>
          </SessionExpiredModal>
        </div>
      )}
    </ThemeProvider>
  );
}

export default Candidate;
