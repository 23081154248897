import React, { ChangeEvent, useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { MasterHeaderInterface } from "../../../types/MasterHeaderInterface";

import {
  getAllByType,
  getAllHeaders,
  postMasterDetail,
  updateMasterDetail,
} from "../../../clients/AssessmentsClient";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ThemeProvider,
  Button,
  Stack,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";

import { MasterDataInterface } from "../../../types/MasterDataInterface";

import { dsgTheme } from "../../../styles/theme";

import { CustomModal } from "../../modal/CustomModal";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import UserService from "src/clients/UserService";

export default function CreateMasterData() {
  const [headersList, setheadersList] = useState<MasterHeaderInterface[]>();

  const [selectedOption, setSelectedOption] = useState("Skill");

  const [masterDetailList, setMasterDetailList] =
    useState<MasterDataInterface[]>();

  const [openModal, setopenModal] = useState(false);

  const [masterDetail, setMasterDetail] = useState<MasterDataInterface>({
    id: 14,
    headerId: 1,
    description: "",
    name: "",
  });

  const [myMap, setMymap] = useState(new Map());

  const [isExist, setExist] = useState(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [isEmpty, setEmpty] = useState(false);
  const [edit, setEdit]=useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const [reload,setReload] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);

  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  const [isQuestions, setQuestions] = useState(false);

  const fetchData = async (type: string) => {
    setReload(true);
    const response = await getAllHeaders();
    if(response.ok)
    {
      setReload(false);
      const data = await response.json();

      setheadersList(data);
  
      setSelectedOption(type);
  
      const resp = await getAllByType(type);
  
      const datajson = await resp.json();
  
      const newMap = new Map();
  
      datajson.map((item: any) => {
        newMap.set(item.name, item.description);
      });
  
      setMymap(newMap);
  
  
      setMasterDetailList(datajson);
  
    }
    if(response.status===401){
      setReload(false);
      console.log(response.status)
      setOpenSessionModal(true)
    } else if (response.status === 402) {
      setReload(false);
      setClientPlanExpiredModal(true);
    }
    else
    {
      setReload(false);
      console.log(response.status)
      console.log(response)
    }
     

  };

  useEffect(() => {
    const selectedUserParam = searchParams.get("type");

    if (selectedUserParam != undefined) {
      setQuestions(true);
    }
    

    fetchData(selectedUserParam ? selectedUserParam : "Skill");
  }, []);

  const dropdownhandle = (event: any) => {
    setSelectedOption(event.target.value);

    fetchData(event.target.value);
  };

  const handleCreateButton = () => {
    setopenModal(true);
  };

  const handleModalCancel = () => {
    setopenModal(false);

    setExist(false);

    setEdit(false);

    setMasterDetail({
      id:15,
      headerId:1,
      description:"",
      name:"",
    });

    setEmpty(false);
  };

  const handleModalConfirm = async () => {
    if(edit===false){
    const stringArray = Array.from(myMap.keys());

    if (masterDetail.name == "" || masterDetail.description == "") {
      setEmpty(true);
    } else if (stringArray.includes(masterDetail.name.toUpperCase())) {
      setExist(true);
    } else {
      setopenModal(false);

      masterDetail.name =
        masterDetail.name.toUpperCase() + "/" + selectedOption;

    const response= await postMasterDetail(masterDetail);
    if(response.ok){
      toast.success(masterDetail.name.split('/').at(0) + " saved successfully to "+masterDetail.name.split('/').at(1),{autoClose:1000});
    }
    else{
      toast.error("Failed to add Master Data",{autoClose:1000});
    }
      setMasterDetail({
        id:15,
        headerId:1,
        description:"",
        name:"",
      });

      setExist(false);

      fetchData(selectedOption);

      if (isQuestions) {
        navigate(`/createQuestion`);
      }
    }
  }
  else{
    let existing=false;
    masterDetailList?.forEach((e)=>{
      if (e.id!==masterDetail.id && e.name===masterDetail.name) {
        setExist(true);
        existing=true;
      }
    })
    console.log(existing)
    if (masterDetail.name == "" || masterDetail.description == "") {
      setEmpty(true);
    }
    else if(!existing) {
      setopenModal(false);

      masterDetail.name =
        masterDetail.name.toUpperCase() + "/" + selectedOption;

    const response= await updateMasterDetail(masterDetail);
    if(response.ok){
      toast.success(masterDetail.name.split('/').at(0) + " updated successfully",{autoClose:1000});
    }
    else{
      toast.error("Failed to add Master Data",{autoClose:1000});
    }
      setMasterDetail({
        id:15,
        headerId:1,
        description:"",
        name:"",
      });

      setEdit(false)

      setExist(false);

      fetchData(selectedOption);

      if (isQuestions) {
        navigate(`/createQuestion`);
      }
    }

  }

  };


  const handleSessionModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
    
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
       
      })
          })
  };

  const goToLoginPage = () => {
    navigate("/login");
    window.location.reload();
  }
  
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmpty(false);

    setExist(false);

    const { name, value } = event.target;

    setMasterDetail((prevState) => ({
      ...prevState,

      [name]: value, 
    }));
  };
  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
<ThemeProvider theme={dsgTheme}>
  <Box sx={{width:"75%",paddingLeft:"20%"}}>
    <Box sx={{width:"75%",paddingTop:"4%"}}>
    <Typography variant="h5">{selectedOption}</Typography>
    </Box>
    <Box sx={{ display:"flex",alignItems:"center",justifyContent:"space-between",paddingBottom:"3%",width:"75%"}}>
        <Box>
          <FormControl  variant="outlined">
            <InputLabel
              id="demo-simple-select-label"
            >
              Select Type
            </InputLabel>

            <Select
              value={selectedOption}
              onChange={dropdownhandle}
              style={{ width: "200px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Type"
            >
              {headersList?.map((option, index) => (
                <MenuItem key={index} value={option.type}>
                  {option.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
              <Button
                type="submit"
                variant="outlined"
                className="button"
                onClick={() => handleCreateButton()}
                disabled={["Configuration","Visibility","Language","Category","QuestionType"].some((configs) => configs === selectedOption)}
                style={{ width: "180px", height: "50px" }}
              >
                Create New {selectedOption}
              </Button>
        </Box>
    </Box>
        <Box
          
          sx={{display:"flex"}}
        >
       
          <TableContainer
         
            sx={{width:"75%"}}
          >
            <div
              className="scroll-table-container"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <Table>
                <TableHead
                  className="table-row-odd tableHeaderrow"
                  style={{ position: "sticky", top: "0", zIndex: "1" }}
                >
                  <TableRow>
                    <TableCell className="tableHeader tableCell">
                      Name
                    </TableCell>

                    <TableCell className="tableHeader tableCell">
                      Description
                    </TableCell>
                    <TableCell className="tableHeader tableCell"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {masterDetailList?.map((header, index) => (
                    <TableRow
                      key={header.id}
                      className={
                        index % 2 === 0 ? "table-row-even" : "table-row-odd"
                      }
                    >
                      <TableCell className="tableCell">{header.name}</TableCell>

                      <TableCell className="tableCell">
                        {header.description}
                      </TableCell>
                      <TableCell className="tableCell">
                      <Stack spacing={1} direction='row'>
                                    <Button 
                                      onClick={() => {
                                        setMasterDetail(header);
                                        setEdit(true);
                                        handleCreateButton();
                                      }}
                                      className="delete-and-edit"
                                      sx={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#faefef' }}
                                    >
                                    <EditOutlinedIcon
                                      fontSize="small"
                                    />
                                    </Button>
                                  </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Box>
  </Box>

  <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: goToLoginPage }}
              continueButton={{
                label: "",
                onClick: handleModalConfirm,
              }}
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>

        <CustomModal
          open={openModal}
        

          cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
          continueButton={{ label: "Confirm", onClick: handleModalConfirm,disabled:!masterDetail.name || !masterDetail.description}}
        >
          <Stack direction="row" spacing={2}>
            <Typography fontWeight="bold">
             
              {edit ? `Edit ${selectedOption} or Description` : `Create New ${selectedOption} with Description`}
              <Stack direction="row" spacing={2} paddingTop="10px">
                <TextField
                  type="text"
                  label={"Enter " + selectedOption}
                  variant="outlined"
                  value={masterDetail.name}
                  name="name"
                  required
                  disabled={edit}
                  onChange={handleInputChange}
                ></TextField>

                <TextField
                  type="text"
                  label="Enter Description"
                  variant="outlined"
                  value={masterDetail.description}
                  name="description"
                  required
                  onChange={handleInputChange}
                ></TextField>
              </Stack>
              {isExist && "This " + selectedOption + " already exists"}
              {isEmpty && "Empty fields are not allowed"}
            </Typography>
          </Stack>
          
        </CustomModal>
        <SessionExtensionModal
          open={openSessionModal}
          onClose={handleSessionModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>
    <ToastContainer position="bottom-left" />
    </ThemeProvider>
  );
}