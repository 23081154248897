import TokenService from "./TokenService";
const host = "http://localhost:8085";

export const fetchData = (id: number) => {
	const apiUrl = `${host}/info/${id}`;
	// Perform the API request or any asynchronous operation here
	return fetch(apiUrl);
};


const assessment_host = process.env.REACT_APP_BASE_URL;

export const handleDownloadApiCall = (id:number) => {
  const apiUrl = `${assessment_host}/reports/CandidateReportDetails/${id}`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const handlegetVideo=(candidateId:number)=>
{
  const apiUrl = `${assessment_host}/reports/get-video/${candidateId}`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
    

}