import React, { useEffect, useState } from "react";

import {
  Box,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { dsgTheme } from "../../../styles/theme";

import { ButtonInterface } from "../../../types/ButtonInterface";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {  useNavigate } from "react-router-dom";

import { CustomModal } from "../../modal/CustomModal";

import { AssessmentSectionInterface } from "../../../types/AssessmentSectionInterface";

import { MasterDataInterface } from "../../../types/MasterDataInterface";

import { getSection } from "../../../clients/AssessmentsClient";

import DeleteIcon from "@mui/icons-material/Delete";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import UserService from "src/clients/UserService";
import { toast, ToastContainer } from "react-toastify";

interface AssessmentSectionProps {
  section: AssessmentSectionInterface[];

  mode: string;

  onSave: (assessmentSections: AssessmentSectionInterface[]) => void;
}

const SectionDetails = ({
  section,

  mode,

  onSave: onBack,
}: AssessmentSectionProps) => {
  const [openModal, setOpenModal] = useState(true);

  const [allTheSectionSkillNames, setAllTheSectionSkillNames] = useState<
    string[]
  >([]);

  const [assessmentSections, setAssessmentSections] =
    useState<AssessmentSectionInterface[]>(section);

  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(true);

  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  const [masterDataSections, setMasterDataSections] = useState<
    MasterDataInterface[]
  >([]);

  const initialSkills: string[] = [];
  section.forEach((e) => {
    if (e.skillName) {
      initialSkills.push(...e.skillName.split(","));
    }
  });
  const [skills, setSkills] = useState<string[]>(initialSkills);
  const [sectionNameErrorIndex, setSectionNameErrorIndex]=useState<number>(0);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const defaultSection: AssessmentSectionInterface = {
    skillName: "",

    totalTime: 0,

    sectionName: "",

    totalMarks: 0,

    sectionId: 0,

    assessmentQuestionDetail: [],
  };

  const navigate= useNavigate();
  const handleCancelButton = () => {
    setOpenModal(false); //remove this if required and write cancel button functionality

    onBack(section);
  };

  const handleContinueButton = async () => {
    
    const bool = assessmentSections.some((section) => !isNaN(parseInt(section.sectionName[0])));
    if(bool){
      toast.error(`Section name should start with alphabet`,{autoClose:1000})
    }else{
      setOpenModal(false);
      onBack(assessmentSections || []);
    }
    
  };

  const cancelButton: ButtonInterface = {
    label: "Cancel",

    onClick: handleCancelButton,
  };

  const continueButton: ButtonInterface = {
    label: mode === "view" ? "Okay" : "Save",
    disabled: !isAddButtonEnabled || sectionNameErrorIndex!=0,
    onClick: handleContinueButton,
  };

  const fetchMasterDataSections = async () => {
    const res = await getSection();

    if (res.ok) {
      const data = await res.json();

      setMasterDataSections(data);
    }
    else if(res.status===401){
      setOpenSessionModal(true)
    }else if (res.status === 402) {
      setClientPlanExpiredModal(true);
    }
  };

  useEffect(() => {
    fetchMasterDataSections();

    if (mode === "add" && assessmentSections.length === 0) {
      setAssessmentSections([defaultSection]);

      setIsAddButtonEnabled(false);
    }
  }, []);

  useEffect(() => {
    if (
      assessmentSections[0]?.sectionName.length > 0 &&
      assessmentSections[0]?.totalTime > 0 &&
      assessmentSections[0]?.skillName.length > 0 &&
      assessmentSections.length <= 1
    ) {
      setIsAddButtonEnabled(true);
    }
  }, [assessmentSections]);

  const handleAddRow = () => {
    setAssessmentSections([...assessmentSections, defaultSection]);

    setIsAddButtonEnabled(false);
  };

  const handleChange = (event: any, index: number) => {
    let { name, value } = event.target;
    if(name==="totalTime"){
      value=Number(value);
    }
    if(name==="sectionName"){
      const isSectionNamePresent = assessmentSections.some((sections) => sections.sectionName === value);
  if (isSectionNamePresent) {
    setSectionNameErrorIndex(index);
  } else {
    setSectionNameErrorIndex(0);
  }
    }
    const updatedSections = [...assessmentSections];
    if (name === "skillName") {
      if (allTheSectionSkillNames.includes(value)) {
        allTheSectionSkillNames.splice(value);
      } else {
        setAllTheSectionSkillNames(allTheSectionSkillNames.concat(value));
      }

      const array = updatedSections.at(index)?.skillName.split(",");

      const k = array?.filter((s: string) => !value.includes(s));

      let selectedSkills = value.join(",");
      if (selectedSkills.startsWith(",")) {
        selectedSkills = selectedSkills.substring(1);
      }

      const skillIndex = allTheSectionSkillNames.indexOf(selectedSkills);

      if (skillIndex !== -1) {
        allTheSectionSkillNames.splice(skillIndex, 1); // Remove the selected skillName
      } else {
        allTheSectionSkillNames.push(selectedSkills); // Add the selected skillName
      }

      updatedSections[index] = {
        ...updatedSections[index],
        [name]: selectedSkills,
      };
      setSkills((prevSkills) => {
        // Remove deselected skills
        const updatedSkills = prevSkills.filter(
          (skill: string) => !k?.includes(skill)
        );
        // Add new skills
        return [...updatedSkills, ...value];
      });
    } else {
      updatedSections[index] = {
        ...updatedSections[index],

        [name]: name==="sectionName" && (value !== null || value !== undefined)?value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(): value
      };
    }
    setAssessmentSections(updatedSections);

    const isSectionFilleds =
      updatedSections[index].sectionName.length > 0 &&
      updatedSections[index].totalTime > 0 &&
      updatedSections[index].skillName.length > 0;

    setIsAddButtonEnabled(isSectionFilleds);
  };

  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
        // console.log(res1.data);
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
        // console.log(TokenService.getToken())
      })
          })
  };

  const handleDeleteRow = (index: number) => {
    if(sectionNameErrorIndex===index || assessmentSections.at(sectionNameErrorIndex)?.sectionName===assessmentSections.at(index)?.sectionName)
    {
      setSectionNameErrorIndex(0);
    }
    const updatedSections = [...assessmentSections];

    updatedSections.splice(index, 1);

    setAssessmentSections(updatedSections);

    const updatedValues = [...selectedValues];

    updatedValues.splice(index, 1);

    setSelectedValues(updatedValues);

    const updatedSection = [...assessmentSections];

    const deletedSection = updatedSection[index]?.skillName.split(",");

    if (deletedSection) {
      setSkills((prevSkills) =>
        prevSkills.filter((skill) => !deletedSection.includes(skill))
      );
    }

    // Remove skills from the skills string array

    if (updatedSection.length == assessmentSections.length) {
      setIsAddButtonEnabled(false);
    }

    const indexValue = updatedSection.length - 1;

    const isSectionFilleds =
      updatedSection[indexValue].sectionName.length > 0 &&
      updatedSection[indexValue].totalTime > 0 &&
      updatedSection[indexValue].skillName.length > 0;

    setIsAddButtonEnabled(isSectionFilleds);
  };

  return (
    <Box>
      <CustomModal
        open={openModal}
        // onClose={handleCloseModal}
        cancelButton={mode !== 'view' ? cancelButton : undefined} 
        continueButton={continueButton}
      >
        <ThemeProvider theme={dsgTheme}>
        <Typography variant="h5" sx={{paddingBottom:"5%"}}>Section</Typography>
          <Box sx={{ display: "flex", maxHeight: 250, minHeight: 100,paddingLeft:"10px" }}>
            <FormGroup sx={{ flexWrap: "nowrap" }}>
              {assessmentSections.map((section, index) => (
                <>
                <Box
                  sx={{
                    display: "flex",

                    paddingBottom: "2%",

                    alignItems: "center",

                    width:"100%"
                  }}
                  key={index}
                >
                  <Box sx={{width:"90%"}}>
                    <TextField
                      sx={{ width: "32%", padding: "2px" }}
                      value={section.sectionName && section.sectionName
                        .toLowerCase()
                        .split(' ')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                      label="Section Name"
                      name="sectionName"
                      variant="outlined"
                      onChange={(event) => handleChange(event, index)}
                      InputProps={mode == "view" ? { readOnly: true } : {}}
                    />
                    <TextField
                      sx={{ width: "32%", padding: "2px" }}
                      value={String(section.totalTime)}
                      label="Duration"
                      variant="outlined"
                      name="totalTime"
                      type="number"
                      onChange={(event) => handleChange(event, index)}
                      InputProps={mode == "view" ? { readOnly: true } : {}}
                    />
                    <FormControl sx={{ width: "33%", padding: "2px" }}>
                      <InputLabel>Skill Name</InputLabel>
                      <Select
                        multiple
                        value={section.skillName.split(",")} // Convert comma-separated string to array
                        label="skillName"
                        name="skillName"
                        onChange={(event) => handleChange(event, index)}
                        readOnly={mode === "view"}
                        renderValue={(selected) => selected.join(",")} // Display selected values as comma-separated string
                      >
                        {masterDataSections.map((masterDatasection) => (
                          <MenuItem
                            key={masterDatasection.name}
                            value={masterDatasection.name}
                            // disabled={
                            //   skills.includes(masterDatasection.name) &&
                            //   !assessmentSections
                            //     .at(index)
                            //     ?.skillName.includes(masterDatasection.name)
                            // }
                          >
                            <ListItemText primary={masterDatasection.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: "15%" }}>
                    {index === assessmentSections.length - 1 ? (
                      <Stack direction={"row"}>
                        {assessmentSections.length > 1 ? (
                          <IconButton disabled={mode === "view"}>
                            <DeleteIcon
                              onClick={() => handleDeleteRow(index--)}
                            />
                          </IconButton>
                        ) : null}
                        <IconButton
                          style={{
                            border: !isAddButtonEnabled || mode === "view" ? "none" : "",
                          }}
                          disabled={!isAddButtonEnabled || mode === "view" || (sectionNameErrorIndex===index && index!=0)}
                        >
                          <AddCircleOutlineIcon onClick={handleAddRow} />
                        </IconButton>
                      </Stack>
                    ) : assessmentSections.length > 1 ? (
                      <IconButton disabled={mode === "view"}>
                        <DeleteIcon onClick={() => handleDeleteRow(index--)} />
                      </IconButton>
                    ) : null}
                  </Box>
                </Box>
                  <Box>
                  {    
                    (sectionNameErrorIndex!=0) && (sectionNameErrorIndex===index)?<Typography sx={{color:"#de2222"}}>{section.sectionName} is already present</Typography>:null
                  }
                  </Box>
                </>
              ))}
            </FormGroup>
          </Box>
        </ThemeProvider>
      </CustomModal>
      <ToastContainer position="bottom-left"/>
      <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
              continueButton={{
                label:"",
                onClick:handleCancelButton
              }}
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
      
      <SessionExtensionModal
          open={openSessionModal}
          onClose={handleModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>
    </Box>
  );
};

export default SectionDetails;
