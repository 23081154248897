import React, { useState } from "react";
import { Button, Stack, ThemeProvider, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { TextField, Link,Box } from "@mui/material";
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ForgotPassword.css"; 
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import UserService from "../../../clients/UserService";
import { dsgTheme } from "../../../styles/theme";
import SideMenu from "src/components/menu-bar/NavbarAndSidebar";
import { CustomModal } from "../list-of-users/CustomModal";
import { useNavigate } from "react-router-dom";


export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const handleemailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setUserNameError(false);
    setPasswordError(false); 
  };


  const goToLoginPage = () => {
    navigate("/login");
    window.location.reload();
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (email.match(emailRegex)) {
        
        UserService.getDetailsForForgotPassword(email).then((res)=>{
          if(res.data=='Email Not Found' || res.data=='Invali Email')
          {
            setPasswordError(true)
          }
          else if(res.data=='INACTIVE user')
          {
                toast.error("INACTIVE Account ",{autoClose:1000})
          }
          else
          {
          
            toast.success('OTP sent',{autoClose:1000})
          }
        }).catch((error) => {
          if (error.data.status === 402) {
            setClientPlanExpiredModal(true);
          }
        })
      } 
      else 
      {
        setPasswordError(true);
      }
  };

  return (
    <ThemeProvider theme={dsgTheme}>
         <SideMenu />
    <div>
      
      <Container className="gowrr">
      <Typography variant="h5" sx={{paddingBottom:'2%'}}>
            Forgot Password
          </Typography>
        <form onSubmit={handleSubmit}>
          <Stack direction="column" alignItems="center" spacing={2}>
        
          <Container>
            <Box sx={{paddingBottom:"2%",}}>
            {passwordError && (
              <Typography className="password-message" >
               Oops ! Looks like you are not registered with us!
              </Typography>
            )}
          </Box>
          
            <TextField
              type="text"
              value={email}
              fullWidth
              id="filled-basic"
              size="small"
              label="Email"
              variant="outlined"
              onChange={handleemailChange}
              error={userNameError}
              required
              helperText={
                userNameError ? (
                  <span className="error-message">Username not found</span>
                ) : (
                  ""
                )
              }
            />
             </Container>
            </Stack>
            <Stack direction="column" spacing={2} alignItems="center">
            <Link href="login" className="login-link">
              Back to Sign In
            </Link>
            <Button type="submit">
             Submit
            </Button>
          </Stack>
        </form>
      </Container>
      <ToastContainer position="bottom-left"/>
      <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: goToLoginPage }}
             
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
      </div>
      </ThemeProvider>
  );
};
