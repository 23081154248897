import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grow } from '@mui/material';

import "./CustomModal.css";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";




interface ButtonInterface{

  label:string;

  onClick:()=>void;

}

interface ModalProps {

  open: boolean;


  heading?: string;

  children: React.ReactNode;

  cancelButton:ButtonInterface;

  continueButton?:ButtonInterface;

}




export const CustomModal: React.FC<ModalProps> = ({ open,heading, children,cancelButton,continueButton}) => {

  return (

    <StyledEngineProvider injectFirst>

      <Dialog

          maxWidth={heading === "Subscription Plans"?"sm":"xs"}

          fullWidth

          open={open}

          disableEscapeKeyDown={true}

          TransitionComponent={Grow}

          aria-labelledby="modal-title"

          aria-describedby="modal-description">

          {

            heading &&

            <DialogTitle id="modal-title" className='DialogTitle'>{heading}</DialogTitle>

          }

          <DialogContent>

              {children}

          </DialogContent>

          <DialogActions>

              <Button variant='outlined' onClick={cancelButton.onClick} className='Button'>{cancelButton.label}</Button>

            {continueButton?(<Button variant='outlined' onClick={continueButton?.onClick} className='Button'>{continueButton?.label}</Button>):(<></>)}  

          </DialogActions>

      </Dialog>

    </StyledEngineProvider>

  );

};