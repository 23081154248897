import React, { useState, useEffect } from "react";
import { getAllByType } from "../../../clients/AssessmentsClient";
import Container from "@mui/material/Container";
import { Button, Stack, ThemeProvider, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import "../bulk-upload/styles/Upload_css.css";
import "../bulk-upload/styles/Upload.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { dsgTheme } from "../../../styles/theme";
import TokenService from "../../../clients/TokenService";
import UserService from "src/clients/UserService";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";

function BulkUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [showProgressBar] = useState(false);
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [questionType, setQuestionType] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [difficulties, setDifficulties] = useState([]);
  const difficultiesArray: string[] = difficulties;

  interface Skill {
    id: number;
    name: string;
    description: string;
  }
  interface QuestionType {
    id: number;
    name: string;
    description: string;
  }
  interface Difficulty {
    id: number;
    name: string;
    description: string;
  }

  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllByType("Skill");
        const response2 = await getAllByType("QuestionType");
        const response3 = await getAllByType("Difficulty");
        if (
          response.status === 401 ||
          response2.status === 401 ||
          response3.status === 401
        ) {
          setOpenSessionModal(true);
        } else if (
          response.status === 402 ||
          response2.status === 402 ||
          response3.status === 402
        ) {
          setClientPlanExpiredModal(true);
        } else if (response.ok && response2.ok && response3.ok) {
          const data = await response.json();
          const data2 = await response2.json();
          const data3 = await response3.json();
          const skills = data.map((item: Skill) => item.name);
          const questionType = data2.map((item: QuestionType) => item.name);
          const difficulties = data3.map((item: Difficulty) => item.name);
          setSkills(skills);
          setQuestionType(questionType);
          setDifficulties(difficulties);
        } else {
          console.error("Error fetching skills data: ", response.statusText);
          console.error(
            "Error fetching questionType,vdata: ",
            response2.statusText
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const downloadMergedCSV = () => {
    const worksheet1Columns = ["S.No", "FIELD", "DATA TYPE", "DESCRIPTION"];

    const worksheet1Data = [
      worksheet1Columns,
      [
        1,
        "QuestionType",
        "String",
        "QuestionType should be from [ " + questionType + " ]",
      ],
      [2, "Skill", "String", "Skill should be from [ " + skills + " ]"],
      [
        3,
        "QuestionText",
        "String",
        "Question Text cannot be empty and accepts single line questions",
      ],
      [4, "AddInfo", "String", "Additional Information. It's optional."],
      [5, "TimeToSolve(mins)", "Integer", "Accepts value from 1 to 59"],
      [
        6,
        "PositiveMarks",
        "Integer",
        "Accepts value from 1 to 99 and positive marks should be greater than negative marks.",
      ],
      [7, "NegativeMarks", "Integer", "Accepts value from 0 to 9"],
      [
        8,
        "Difficulty",
        "String",
        "Difficulty should be from [ " + difficultiesArray + " ]",
      ],
      [
        9,
        "OptionValue",
        "String",
        "Minimum 2 Non Empty option values should be present",
      ],
      [
        10,
        "AnswerFlag",
        "Integer",
        "Should be an integer from 1 to 4 and based on non-empty option values",
      ],

      [
        11,
        "Visibility",
        "String",
        "accepts public or private. public for displaying to all the people and private only to that organization",
      ],
      [
        12,
        "Tags",
        "String",
        "accepts 5 to 10 characters. It tells about the role(used while searching)",
      ],
      [
        13,
        "ExecutionText",
        "String",
        "Accepts Only if the QuestionType is CODING(Optional)",
      ],
      [
        14,
        "MaximumCharacters",
        "String",
        "Accepts only for the Question type DESCRIPTIVE",
      ],
      [
        15,
        "Language",
        "String",
        "Accepts JAVA, C# only if the QuestionType is CODING",
      ],
    ];

    const workbook = XLSX.utils.book_new();

    const worksheet1 = XLSX.utils.aoa_to_sheet(worksheet1Data);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Instructions");

    const worksheet2Data = [
      ["Skill", "QuestionType"],
      ...skills.map((skill, index) => [skill, questionType[index] || ""]),
    ];

    const worksheet3Data = [
      [
        "QuestionType",
        "Skill",
        "QuestionText",
        "AddInfo",
        "TimeToSolve(mins)",
        "PositiveMarks",
        "NegativeMarks",
        "Difficulty",
        "OptionValue1",
        "OptionValue2",
        "OptionValue3",
        "OptionValue4",
        "AnswerFlag",
        "Visibility",
        "Tags",
        "ExecutionText",
        "MaximumCharacters",
        "Language",
      ],
    ];

    const worksheet2 = XLSX.utils.aoa_to_sheet(worksheet2Data);
    const worksheet3 = XLSX.utils.aoa_to_sheet(worksheet3Data);

    XLSX.utils.book_append_sheet(workbook, worksheet2, "Master Data");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Upload Template");

    const blob = new Blob(
      [s2ab(XLSX.write(workbook, { bookType: "xlsx", type: "binary" }))],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Template.xlsx";
    link.click();
    URL.revokeObjectURL(url);
  };
  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }
  const user = localStorage.getItem("currentUser");
  let userName: string;
  let userOrganization: string;
  if (user) {
    userName = JSON.parse(user).userName;
    userOrganization = JSON.parse(user).clientPlans.clients.clientName;
  }


  const reload = ()=>{
      window.location.reload();
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    setSelectedFile(file);
    setFileName(file ? file.name : "");
    if (file) {
      const allowed = [".xlsx"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (fileExtension && !allowed.includes(`.${fileExtension}`)) {
        toast.error("Invalid file type", {
          position: "bottom-left",
          autoClose: 1000,
        });
        setTimeout(() => {
          reload();
        },2000)
        setFileName("");
      } else {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
          const fileData = event.target?.result;

          if (fileData instanceof ArrayBuffer) {
            Papa.parse(file, {
              header: true,
              dynamicTyping: true,
              complete: (
                results: Papa.ParseResult<{ [key: string]: string }>
              ) => {
                const dataRows = results.data;
                if (dataRows.length === 1) {
                  toast.error("File is empty. Add questions and upload", {
                    position: "bottom-left",
                    autoClose: 1000,
                  });
                  setTimeout(() => {
                    reload()
                  },1500)
                  return;
                }

                const formData = new FormData();

                formData.append("file", file);

                reader.readAsArrayBuffer(file);
                setApiCall(true);
              },
            });
          }
        };

        reader.readAsArrayBuffer(file);
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedFile != null && apiCall == true) {
      handleApiCall(selectedFile);
    }
  }, [selectedFile, apiCall]);

  const handleApiCall = (file: File) => {
    const formData = new FormData();
    const createdBy = userName;
    const organisation = userOrganization;

    formData.append("file", file);
    console.log(userName);
    console.log(userOrganization);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/questionBank/savedata/${createdBy}/${organisation}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`,
        },
        body: formData,
      }
    )
      .then(async (response) => {
        if (response.status === 401) {
          setOpenSessionModal(true);
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        }
        if (response.ok) {
          console.log(response);

          toast.success("File uploaded successfully!", {
            position: "bottom-left",
            autoClose: 1000,
          });
          const data = await response.text();
          console.log("the output is ----> ");
          console.log(data);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
          setApiCall(false);
          setSelectedFile(null);
        } else if (response.status == 400) {
          const data = await response.text();
          console.log("the output is ----> ");
          console.log(data);
          toast.warn(data, {
            position: "bottom-left",
            autoClose: 1000,
          });
          setApiCall(false);
          setSelectedFile(null);
          setTimeout(() => {
            reload()
          },3000)
          
        }
      })
      .catch((error) => {
        if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
        console.error("Error:", error);
      });
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;

    fileInput && fileInput.click();
  };
  const [progress] = useState(0);
  const progressBarStyles = {
    position: "fixed" as "fixed",
    bottom: "10px",
    right: "10px",
  };

  const handleModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  const textStyles = {
    marginLeft: "10px",
    color: "red",
    fontWeight: "bold",
  };
  return (
    <ThemeProvider theme={dsgTheme}>
      <Box sx={{ overflowX: "hidden" }}>
        <Typography variant="h5">Bulk Upload: Questions</Typography>

        <Box
          className="info"
          style={{
            display: "flex",
            position: "relative",
            left: "405px",
            top: "85px",
          }}
        >
          <ul>
            <li>&#11162; Download the sample Bulk Question Template</li>

            <li>
              &#11162; Please upload the spreadsheet in <b>.XLSX</b> format.
            </li>
          </ul>
        </Box>
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",

            justifyContent: "center",

            alignItems: "center",

            height: "45vh",
          }}
        >
          <Stack
            direction="column"
            spacing={2}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={4}
            >
              <input
                style={{ display: "none" }}
                title="fileInput"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                id="fileInput"
              />

              <br />

              <Stack direction="row">
                <Button
                  variant="outlined"
                  onClick={downloadMergedCSV}
                >
                  Download Template
                </Button>
                <Box sx={{ width: "12px" }} />
                <Button
                  variant="outlined"
                  onClick={handleButtonClick}
                  startIcon={<UploadIcon />}
                >
                  Upload in bulk
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>

        <ToastContainer />
        {showProgressBar && progress !== null && (
          <div style={progressBarStyles}>
            <div style={textStyles}>
              {progress < 93.75 ? "In progress..." : null}
              {progress === 93.75 ? "Uploaded" : null}
            </div>
          </div>
        )}
      </Box>
      <CustomModal
        open={openClientPlanExpiredModal}
        cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
      >
        <Stack
          direction="row"
          spacing={1}
        >
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
          <Typography fontWeight="bold">
            Your subscription plan has expired; for further information, please
            contact our RS_ADMIN.
          </Typography>
        </Stack>
      </CustomModal>

      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleModalCancel}
        onContinue={handleModalContinue}
      ></SessionExtensionModal>
    </ThemeProvider>
  );
}

export default BulkUpload;
