import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
const LinkExpired: React.FC = () => {
  const styles = {
    color: "#fe524a",
    border: "1px solid #fe524a",
    backgroundColor: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)",
    fontWeight: "medium",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#fe524a",
      color: "white",
      alignItems: "center",
      borderColor: "#fe524a",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
        width: "50%",
        margin: "auto",
        marginTop: "5%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Link Expired
      </Typography>
      <IconButton>
        <WarningAmberIcon sx={{ fontSize: "70px", color: "#fe524a" }} />
      </IconButton>
      <Typography variant="body1" gutterBottom>
        The link you have accessed has expired.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Assessment is already started
      </Typography>
      {/* <Button variant="contained" sx={{...styles,marginTop:"10px"}} onClick={handleRetryClick}>
                close
            </Button> */}
    </Box>
  );
};

export default LinkExpired;
