import axios from "axios";
import UserService from "./UserService";
import TokenService from "./TokenService";

const auth_host = process.env.REACT_APP_BASE_URL;
const url = `${auth_host}/role/`;
// const url1 = `${auth_host}/permissions/`;

class RoleService {
  getAllRolesFromDatabase() {
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        // token: this.LoginUserObject.token
      },
    };
    return axios.get(url + "get_all_roles", config);
  }

  getAllPermissions() {
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        // token: this.LoginUserObject.token
      },
    };
    return axios.get(url + "get_all_permissions", config);
  }

  savePermission(permission: object) {
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        // token: this.LoginUserObject.token
      },
    };
    return axios.post(url + "save_a_permission", permission, config);
  }

  updatePermission(permission: object) {
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        // token: this.LoginUserObject.token
      },
    };
    return axios.post(url + "update_a_permission", permission, config);
  }

  getAllRolesAndPermissions() {
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        // token: this.LoginUserObject.token
      },
    };
    return axios.get(url + "get_all_roles_and_permissions", config);
  }

  // getPermissionById(permissionId:number)
  // {
  //   const config = {
  //     headers: {
  //       'Authorization':`Bearer ${UserService.LoginUserObject.token}`
  //       // token: this.LoginUserObject.token
  //     }
  //   };
  //   return axios.get(`${url+'get_permission_by_id'}/${permissionId},config);
  // }
}
export default new RoleService();
