import React, { ReactNode } from "react";
import {
  LibraryBooks,
  Assignment,
  Analytics,
  AdminPanelSettings,
} from "@mui/icons-material";

export interface NavbarItem {
  id: number;
  icon: ReactNode;
  label: string;
  route: string;
  condition: boolean;
  options?: Option[];
}

export interface Option {
  id: number;
  label: string;
  route: string;
}
let role:boolean=false;
let viewUserPermission:boolean=false;
let grant:boolean = false;
let viewResultsPermission:boolean=false;
let ViewQuestionPermission:boolean=false;
let viewAssessmentPermission:boolean=false;
let editMasterDataPermission:boolean=false;
let isRSAdmin:boolean=false;
const CurrentUser=localStorage.getItem("currentUser")

if(CurrentUser!=null)
{
 const loginUser=JSON.parse(CurrentUser);
viewUserPermission=loginUser.permissionslist?.includes("View Users");
viewResultsPermission=loginUser.permissionslist?.includes("View Assessment Results")
ViewQuestionPermission=loginUser.permissionslist?.includes("View Questions")
viewAssessmentPermission=loginUser.permissionslist?.includes("View Assessments")
role=loginUser.permissionslist?.includes("Roles And Permissions");
editMasterDataPermission=loginUser.permissionslist?.includes("Edit Masterdata");
grant = loginUser.permissionslist?.includes("Grant");
isRSAdmin=loginUser.role.roleName=="RS_ADMIN"?true:false;
}
const Navbaritems: NavbarItem[] = [
  {
    id: 0,
    icon: React.createElement(LibraryBooks),
    label: "QuestionBank",
    route: "/questionbank",
    condition: (ViewQuestionPermission),
    options: [],
  },

  {
    id: 1,
    icon: React.createElement(Assignment),
    label: "Assessments",
    route: "/assessmentlist",
    condition: (viewAssessmentPermission),
    options: [],
  },
  {
    id: 2,
    icon: React.createElement(Analytics),
    label: "Reports",
    route: "/assessment",
    condition: (viewResultsPermission),
    options: [],
  },
  {
    id: 3,
    icon: React.createElement(AdminPanelSettings),
    label: "Admin",
    route: "#",
    condition: (grant ),
    options: [
      {
        id: 0,
        label: "Users",
        route: viewUserPermission? "/listOfUsers":"#",
       
      },
      {
        id: 1,
        label:"Edit Masterdata",
        route:editMasterDataPermission? "/editMasterData":"#",
    
        
      },
      {
        id:2,
        label: "Roles & Permissions",
        route: role? "/rolesAndPermissions":"#",
        
      },
      ...(isRSAdmin ? [
        {
          id: 3,
          label: "Clients",
          route: "/listOfClients"
        },
        {
          id: 4,
          label: "Configurations",
          route: "/configurations"
        },
        {
          id: 5,
          label: "Subscription Plans",
          route: "/subscription-plans"
        }
      ] : [])
    ],
  },
];

export default Navbaritems;