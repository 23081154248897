import React, { useEffect, useState } from 'react';
import { Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grow, Stack, Typography } from '@mui/material';
import { StyledEngineProvider } from "@mui/material/styles";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "./CustomModal.css";



interface SessionExtensionModalProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void; 
  children?: React.ReactNode;
}

export const SessionExtensionModal: React.FC<SessionExtensionModalProps> = ({ open, onClose,  onContinue }) => {
  const [countdown, setCountdown] = useState(330);
  const [timerRunning] = useState(true);

  useEffect(() => {
    if (open && timerRunning) {
      const modalTimer = setTimeout(() => {
        setCountdown(330); 
      }, 10000); 

      const countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearTimeout(modalTimer);
        clearInterval(countdownTimer);
      };
    }
  }, [open, timerRunning]);

  const formatCountdown = () => {
    const minutes = Math.floor(countdown / 60).toString().padStart(2, '0');
    const seconds = (countdown % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (countdown === 1) {
      onClose(); 
      window.location.reload();
    }
  }, [countdown, onClose]);

  return (
    <StyledEngineProvider injectFirst>
      <ClickAwayListener onClickAway={() => {}}>
      <Dialog
        maxWidth='xs'
        fullWidth
        open={open}
        onClose={onClose}
       
        disableEscapeKeyDown={true}
        TransitionComponent={Grow}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <DialogTitle id="modal-title" className='DialogTitle'>
          Session Expired!
        </DialogTitle>
        <DialogContent>
          
           <Stack direction="row" spacing={1}>
            <ReportProblemIcon style={{ color: "#fe524a" }} />

            <Typography fontWeight="bold">Your session has expired. It will end automatically in {' '}
            <Typography component="span" sx={{ color: "#fe524a", whiteSpace: "nowrap", overflow: "hidden" }}>
              {formatCountdown()}
            </Typography>{' '}
            minutes. Would you like to continue?</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onClose} className='Button'>
            SignOut
          </Button>
          <Button variant='outlined' onClick={onContinue} className='Button'>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      </ClickAwayListener>
    </StyledEngineProvider>
  );
};
