

import {
  Container,
  Stack,
  TextField,
  Typography,
  Box,
  Button,
  ThemeProvider,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import RoleService from "../../../clients/RolesAvailableService";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./CreateUser.css";

import UserService from "../../../clients/UserService";
import CreateUserClass from "./CreateUserClass";
import { CustomModal } from "../list-of-users/CustomModal";
import { dsgTheme } from "../../../styles/theme";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { ClientInterface } from "../dummydata/ClientInterface";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import { UserDtoInterface } from "../dummydata/UserDtoInterface";
import { message } from "antd";



export const CreateUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedUserParam = searchParams.get("selectedUser");
  // const [lastNameError, setLastNameError] = useState<boolean>(false);
  // const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const selectedUser =
    selectedUserParam !== null ? parseInt(selectedUserParam, 10) : null;
  const [userData, setUserData] = useState<UserDtoInterface>(CreateUserClass.User);

  console.log("CreateUser",userData)
  const [oldRole, setOldRole] = useState("");
  const [oldOrg, setOldOrg] = useState("");
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
  
  const [clients, setClients] = useState<ClientPlanInterface[]>([]);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);


  const [role] = useState({
    id: 0,
    roleName: "",
  });

  const [backdrop, setBackDrop] = React.useState(false);

  // const [roleListFromDB, setRoleListFromDB] = useState([role]);


  const [roleList, setRoleList] = useState([role]);

  const navigate = useNavigate();
  const user = localStorage.getItem("currentUser");
  let roleName:any;
  if (user) {
    roleName = JSON.parse(user).role.roleName;
  }

  



  useEffect(() => {

    setUserData((prevState) => ({
      ...prevState,
      clientPlans: UserService.getLoginUserObject().clientPlans,
    }));

  }, [])

  useEffect(() => {
    UserService.getAllActiveOnly().then((response) => {
      setClients(response.data);
    })
  }, [])

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,6}$/.test(email);
  };

  const isOrganization = (org: string) => {
    return /^(?![@!#$%^&*()\-_=+[\]{};:'",.<>?/\\])[\w\s!@#$%^&*()\-_=+[{}\]{};:'",.<>?/\\]{1,}$/.test(org);
  }

  useEffect(() => {
    RoleService.getAllRolesFromDatabase().then((res) => {
      // const filteredData = res.data.filter((item: any) => item.id !== 1);
      if(UserService.getLoginUserObject().role.roleName === "RS_ADMIN"){
        setRoleList(res.data)
      }else{
        setRoleList(res.data.filter((item: any) => item.id !== 1))
      }
      

      if (selectedUser != null) {
        UserService.getDetailsByID(selectedUser).then((res: any) => {
          setUserData(res.data);
          setOldRole(res.data.role.roleName);
          setOldOrg(res.data.clientPlans.clients.clientId)
        }).catch((error) => {
          if (error.response.status === 401) {
            setOpenSessionModal(true);
          }else if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
  
            console.log(error.response.data, "asljdasdn");
          }
        });
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        setOpenSessionModal(true);
      }else if (error.response.status === 402) {
        setClientPlanExpiredModal(true)

        console.log(error.response.data, "asljdasdn");
      }
    });

  }, []);

  // const [organizationTemp, setOrganizationTemp] = useState<boolean>(false);
  // useEffect(() => {

  //   if (userData.email.match(`^[a-zA-Z0-9._%+-]+@red-shift\.us$`)&& userData.clientPlans.clients.id===1) {
  //     setOrganizationTemp(true)

  //     const updateClientPlan: ClientPlanInterface = {
  //       id:userData.clientPlans.id,
  //       planStartDate: userData.clientPlans.planStartDate,
  //       planEndDate: userData.clientPlans.planEndDate,
  //       clientPlanStatus: userData.clientPlans.clientPlanStatus,
  //       subscriptionPlans: userData.clientPlans.subscriptionPlans,
  //       clients:  {
  //         id: userData.clientPlans.clients?.id ?? 0,
  //         clientName: userData.clientPlans.clients?.clientName ?? '',
  //         clientId: userData.clientPlans.clients?.clientId ?? '',
  //         clientStatus: userData.clientPlans.clients?.clientStatus ?? '',
  //         clientLogo: userData.clientPlans.clients?.clientLogo ?? '',
  //         autoRenew: userData.clientPlans.clients?.autoRenew ?? false
  //       },
  //     };

  //     setUserData((prevState) => ({
  //       ...prevState,
  //       clientPlans: updateClientPlan,
  //     }));
  //     setDisableOrganization(true);
  //     // setRoleList(roleListFromDB);
  //   } else {
  //     // setRoleList(roleListFromDB.filter((item) => item.id !== 1));
  //     setDisableOrganization(false);
  //     setOrganizationTemp(false)
  //     if (userData.role.id === 1) {
  //       const emptyRole = { id: 0, roleName: '' };
  //       setUserData((prevState) => ({
  //         ...prevState,
  //         role: emptyRole,
  //       }));
  //     }
  //   }
  // }, [userData.email]);

  // useEffect(() => {

  //   if (userData.email.match(`^[a-zA-Z0-9._%+-]+@red-shift\.us$`)) {
  //   }
  //   else {
  //     if (organizationTemp) {
  //       const emtyString = '';
  //       setUserData({
  //         ...userData,
  //         clientPlans: {
  //           ...userData.clientPlans,
  //           clients: {
  //             ...userData.clientPlans.clients,
  //             clientName: emtyString,
  //           },
  //         },
  //       });
        
        
  //     }
  //   }
  // }, [organizationTemp, userData.email])





  const [openModal, setOpenModal] = useState<boolean>(false);



  // useEffect(() => {
  //   if (firstNameError == true) {
  //     setFirstNameError(false);
  //   }
  //   if (lastNameError == true) {
  //     setLastNameError(false);
  //   }
  // }, [userData.lastName, userData.firstName]);


  useEffect(() => {
    const value =
      userData.clientPlans.clients?.clientName[0] + "_" +
      userData.firstName[0]?.toUpperCase()+userData.firstName.slice(1,userData.firstName.length) +
      userData.lastName[0]?.toUpperCase() + userData.lastName.slice(1,userData.lastName.length);
    if (userData.lastName.length >= 2 && userData.firstName !== "" && userData?.clientPlans?.clients?.clientName && userData?.clientPlans?.clients?.clientName?.length >= 1){
      setUserData((prevState) => ({
        ...prevState,
        userName: value,
      }));
    }
    else {
      setUserData((prevState) => ({
        ...prevState,
        userName: "",
      }));
    }
  }, [userData.firstName, userData.lastName, userData.clientPlans.clients?.clientName]);


  const handleSessionModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };

  const handleModalCancel = () => {
    setOpenModal(false)
  }



  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
    })
  };

  const handleModalConfirm = () => {
    userData.updatedBy = UserService.getLoginUserObject().userName;

    UserService.updateUser(userData)
      .then((res) => {
        if (res.status == 200) {

          toast.success(
            "Information for " + res.data.userName + " updated successfully.",
            { autoClose: 1000 }
          );
          setTimeout(() => {
            navigate("/listOfUsers");
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {

        }else if (error.response.status === 402) {
          setClientPlanExpiredModal(true)

          console.log(error.response.data, "asljdasdn");
        }
      });
  };

  const handleOrganizationChange = (event: any) => {
    const selectedClientName = event.target.value;
  
    // Find the selected client object based on the client name
    const selectedClient = clients.find(client => client.clients?.clientName === selectedClientName);
  
    if (selectedClient) {
      setUserData((prevState) => ({
        ...prevState,
        clientPlans: selectedClient // Set the entire client object
      }));
      console.log("Selected organization:", selectedClient);
    } else {
      // Handle scenario where selected client is not found
      console.log("Selected organization not found:", selectedClientName);
    }
  };
  

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const selectedRole = roleList.find((role) => role.roleName === value);

    if (selectedRole) {

      setUserData((prevState) => ({
        ...prevState,
        role: selectedRole,
      }));
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {

    if (oldRole == userData.role.roleName && selectedUser != null) {
      setDisableConfirm(true)
    }
    else {
      setDisableConfirm(false)
    }
  }, [userData.role.roleName])

  const handleCancel = () => {
    navigate("/listOfUsers");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(userData)

    let userNameExp = "^[a-zA-Z]{2,}$";

      if(userData.firstName.includes(" ")){
        toast.error("firstname should not contain spaces",{autoClose:1000})
      }else if(userData.lastName.includes(" ")){
        toast.error("lastName should not contain spaces",{autoClose:1000})
      }
      else if (!userData.firstName.match(userNameExp)) {
        toast.error("firstname should contain atleast 2 characters",{autoClose:1000})
        // setFirstNameError(true);
      }
      else if (!userData.lastName.match(userNameExp)) {
        // setLastNameError(true);
        toast.error("lastname should contain atleast 2 characters",{autoClose:1000})
      }
    else if(!isEmail(userData.email)){
      toast.error("Invalid Email", { autoClose: 1000 })
    }
    else if(userData.clientPlans.clients?.clientName === "Red-Shift Innovations Private Ltd" && !userData.email.match(`^[a-zA-Z0-9._%+-]+@red-shift\.us$`)){
      toast.error("You need to use organization mail", { autoClose: 1000 })
    }
    else if(!isOrganization(userData.clientPlans.clients?.clientName == undefined ?'':userData.clientPlans.clients?.clientName)){
      toast.error("Invalid Organization Name", { autoClose: 1000 })
    }else if(userData.role.id === 0){
      toast.error("Role Required", { autoClose: 1000 });
    }else if (userData.userName == "") {
      toast.error("UserName Requirements Doesn't match");
    }else {

      userData.status = "NEW";
      userData.createdBy = UserService.getLoginUserObject().userName;

      setBackDrop(true);
      console.log("user data : ", userData)
      UserService.createUser(userData)
        .then((res: any) => {

          if (res.data == "UserName Already Exists") {
            setBackDrop(false);
            toast.error("UserName Already Taken", { autoClose: 1000 });
          } else if (res.data == "Email already exists") {
            setBackDrop(false);
            toast.error("Email already exists", { autoClose: 1000 });
          }else if(res.data=="User with admin role already exists")
          {
            toast.error("User with admin role already exists for this organization",{ autoClose: 1000 })
            setBackDrop(false);
          }
          else if(res.data=="No. of users count exceeded")
          {
            toast.error("No of users count exceeded for this organization",{ autoClose: 1000 })
            setBackDrop(false);
          } else if (res.data == "Success") {
            setBackDrop(false);

            toast.success(
              "User Created Successfully",
              { autoClose: 1000 }
            );
            setTimeout(() => {
              navigate("/listOfUsers");
            }, 2000);

          } else {
            setBackDrop(false);

            toast.error("Something Went Wrong", { autoClose: 1000 });
          }
        })
        .catch((error) => {

          if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
  
            console.log(error.response.data, "asljdasdn");
          }
          else if (error.response.status === 401) {
            setOpenSessionModal(true);
          }
        });
      }     

  };
  function goToLoginPage(): void {
    navigate("/login");
    window.location.reload();
  }

  return (
    <ThemeProvider theme={dsgTheme}>
      <div>
        <Container className="root-container">
          <div>
            <Typography variant="h5" sx={{ padding: "3%" }}>
              {selectedUser !== null ? "Edit User" : "Create User"}
            </Typography>
          </div>
          <Box>
            <form onSubmit={handleSubmit}>
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={10}>
                  <TextField
                    type="text"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    disabled={selectedUser !== null}
                    name="firstName"
                    value={userData.firstName}
                    onChange={handleInputChange}
                    // helperText={firstNameError ? "Incorrect First Name" : ""}
                    required
                  />
                  <TextField
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    disabled={selectedUser !== null}
                    name="lastName"
                    value={userData.lastName}
                    onChange={handleInputChange}
                    // helperText={lastNameError ? "Incorrect Last Name" : ""}
                    required
                  />
                </Stack>

                <Stack direction="row" spacing={10}>
                  <TextField
                    type="text"
                    className="userfield"
                    label={<Typography variant="body1">Username</Typography>}
                    variant="outlined"
                    fullWidth
                    name="userName"
                    value={userData.userName}
                    onChange={handleInputChange}
                    disabled
                  />
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    disabled={selectedUser !== null}
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Stack>
                <Stack direction="row" spacing={10}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" required>
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      className="custom-select1"
                      id="demo-simple-select"
                      value={userData.role.roleName}
                      label="Role"
                      
                      onChange={handleRoleChange}
                    >
                      {roleList.map((role) => (
                        <MenuItem key={role.id} value={role.roleName}>
                          {role.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" required>
                      Organization
                    </InputLabel>
                    
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="userfield"
                      variant="outlined"
                      label="Organization"
                      fullWidth
                      value={userData.clientPlans.clients?.clientName}
                      disabled={roleName !== "RS_ADMIN" }
                      onChange={handleOrganizationChange}
                      required
                    >
                      {clients.map((client, index) => (
                        <MenuItem key={index} value={client.clients?.clientName}>
                          {client.clients?.clientName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                marginTop="3%"
                justifyContent="flex-end"
                spacing={2}
                marginBottom="3%"
              >
                <Button
                  variant="outlined"
                  className="csButton"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button type="submit" className="button" variant="outlined" disabled={disableConfirm}>
                  Confirm
                </Button>
              </Stack>
            </form>
          </Box>
          <SessionExtensionModal
            open={openSessionModal}
            onClose={handleSessionModalCancel}
            onContinue={handleModalContinue}
          >
          </SessionExtensionModal>

        </Container>
        <CustomModal
          open={openModal}

          cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
          continueButton={{ label: "Confirm", onClick: handleModalConfirm }}
        >

          <Stack direction="row" spacing={1}>
            <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
            <Typography fontWeight="bold">
            Are you sure you want to change the information for the user?(
              {userData.userName})
            </Typography>
          </Stack>
        </CustomModal>
        <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: goToLoginPage }}
             
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
        <Backdrop
          sx={{ color: "red", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer position="bottom-left" />
      </div>

      <ToastContainer position="bottom-left" />
    </ThemeProvider>
  );
};
