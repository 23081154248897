import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router";
import { dsgTheme } from "../../../styles/theme";
import { ButtonInterface } from "../../../types/ButtonInterface";
import { CustomModal } from "../../modal/CustomModal";
import { AssessmentConfigurationInterface } from "../../../types/AssessmentConfigurationInterface";
import { MasterDataInterface } from "../../../types/MasterDataInterface";
import {
  getAllConfigurations,
  getConfigurationById,
} from "../../../clients/AssessmentsClient";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import UserService from "src/clients/UserService";

interface AssessmentConfigurationProps {
  configurationDetails:AssessmentConfigurationInterface[],
  mode: string;
  onSave: (configurationList: AssessmentConfigurationInterface[]) => void;
}

const AssessmentConfiguration = ({
  configurationDetails,
  mode,
  onSave: onBack,
}: AssessmentConfigurationProps) => {
  const [configurationCheckboxList, setConfigurationCheckboxList] =
    useState<AssessmentConfigurationInterface[]>(configurationDetails);
  const [openModal, setOpenModal] = useState(true);

  const [enableButton,setEnableButton]=useState<Boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const navigate = useNavigate();

  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelButton = () => {
    setOpenModal(false); //remove this if required and write cancel button functionality
    onBack(configurationDetails || []);
  };

  const handleContinueButton = async () => {
    setOpenModal(false);
    onBack(configurationCheckboxList || []);
  };

  const cancelButton: ButtonInterface = {
    label: "Cancel",
    onClick: handleCancelButton,
  };

  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
          })
  };

  const continueButton: ButtonInterface = {
    label: mode === "view" ? "Okay" : "Save",
    onClick: handleContinueButton,
    disabled: mode!=="view" ? !enableButton : undefined
  };

  
  const handleConfigurationCheckboxChange = (configurationName: string) => {
    setEnableButton(true);
    setConfigurationCheckboxList((prevList) =>
      prevList?.map((item) =>
        item.configurationName === configurationName
          ? { ...item, configurationFlag: !item.configurationFlag }
          : item
      )
    );
  };


  //NEWLY ADDED CODE FOR LABELLING
  const [mymap, setMymap] = useState(new Map());

  const fetchData = async () => {
   
      const response = await getAllConfigurations();
      if(response.ok){
        console.log(response.status)
        const masterData = await response.json();
        const newMap = new Map();
        masterData.map((item: any) => {
          newMap.set(item.name, item.description);
        });
        setMymap(newMap);
      }
      else if(response.status===401)
      {
        console.log(response.status)
      setOpenSessionModal(true)
      }else if (response.status === 402) {
        setClientPlanExpiredModal(true);
      }
    
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <CustomModal

              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
             continueButton={{
              label:"",
              onClick:handleModalCancel
             }}
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
      <CustomModal
        open={openModal}
        // onClose={handleCloseModal}
        heading={"Configurations"}
        cancelButton={mode !== 'view' ? cancelButton : undefined} 
        continueButton={continueButton}
      >
        <ThemeProvider theme={dsgTheme}>
          <Stack direction={"column"} spacing={2}>
            <FormGroup>
              {configurationCheckboxList?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      size="small"
                      sx={{ color: "#fe524a" }}
                      checked={item.configurationFlag}
                      onChange={
                        mode === "view"
                          ? undefined
                          : () =>
                              handleConfigurationCheckboxChange(
                                item.configurationName
                              )
                      }
                    />
                  }
                  label={item.description? item.description:mymap.get(item.configurationName)}
                />
              ))}
                           
            </FormGroup>
          </Stack>
        </ThemeProvider>
      </CustomModal>
      <SessionExtensionModal
          open={openSessionModal}
          onClose={handleModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>
    </Box>
  );
};
export default AssessmentConfiguration;
