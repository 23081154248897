import axios from "axios";
import TokenService from "./TokenService";
import { ClientInterface } from "src/components/admin/dummydata/ClientInterface";

const auth_host = process.env.REACT_APP_BASE_URL;
const url = `${auth_host}/admin/`;
const url1 = `${auth_host}/admin1/`;
const clientUrl = `${auth_host}/clients/`;

class UserService {

  createAxiosInstance() {
    return axios.create({
      baseURL: url,

      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  }

  createAxiosInstance1() {
    return axios.create({
      baseURL: url1,

      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  }

  axiosInstanceWithOutToken1(){
    return axios.create({
      baseURL: url1,
    });
  }

  createAxiosInstanceForClient() {
    return axios.create({
      baseURL: clientUrl,
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  }


  setLoginUserObject(obj: any) {
    localStorage.setItem("currentUser", JSON.stringify(obj));
  }

  isAuthenticated = false;

  setisAuthenticated() {
    localStorage.setItem("login", String(true));
  }

  setLoginObjectPermissionList(permissionList:any[])
  {
    const currentUser = localStorage.getItem("currentUser");

    if (currentUser) {
      const user = JSON.parse(currentUser);
      user.permissionslist=permissionList;
    

    localStorage.setItem("currentUser", JSON.stringify(user));
    }
  }


  getisAuthenticated() {
    const isAuthenticated = localStorage.getItem("login");

    if (isAuthenticated) {
      const flag = JSON.parse(isAuthenticated);


      return flag;
    } else {
      return false;
    }
  }

  //changed

  getLoginUserObject() {
    const currentUser = localStorage.getItem("currentUser");

    if (currentUser) {
      const user = JSON.parse(currentUser);

      return user;
    } else {
      return null;
    }
  }

  createUser(user: any) {
    return this.createAxiosInstance1().post("saveDetailsOfUsers", user);
  }

  getByUsernameAndPassword(email: string, password: string) {
    return axios.post(url + "login", { email, password });
  }

  InactiveAllUsersByClientId(cid:number)
  {
return this.createAxiosInstance1().put(`inactivateAllUsersByClientId/${cid}`);
  }

  getAllPermissionsListByRoleId()
  {
    return axios.get(`${url1}get-permission-list/${this.getLoginUserObject().role.id}`)
  }

  getAllUsers(organisation:number) {
    return axios.get(`${url1}get_all_users/`+organisation, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  }

  getAllUsersForAdmin() {
    return axios.get(`${url1}get_all_users`, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  }

  getByUserNameOrEmail(UserName: string) {
    return this.createAxiosInstance1().get(`get_user/${UserName}`);
  }

  getDetailsForForgotPassword(email: string) {

    return axios.post(`${url}forgot-password/${email}`);
  }

  getDetailsByID(id: number) {

    return this.createAxiosInstance1().get(`get_by_id/${id}`);
  }

  updateUser(user: any) {
    return this.createAxiosInstance1().put(
      `edit_user/${this.getLoginUserObject().userName}`,

      user
    );
  }

  editUser(statusDTO: any) {
    return this.createAxiosInstance1().put(
      `edit-user-status/${this.getLoginUserObject().userName}`,statusDTO
    );
  }

  updatePassword(email: string, password: string) {

    return this.createAxiosInstance().put(
      "update-password",

      { email, password }
    );
  }

  deleteUser(user: any) {
    return this.createAxiosInstance1().put(
      `delete_user/${this.getLoginUserObject().userName}`,

      user
    );
  }

  EncryptEmail()
  {
    return axios.get(`${url1}/encryptEmail/${this.getLoginUserObject().email}`)
  }

  extendToken(encryptEmail:any)
  {
    return axios.get(`${url}/extendToken/${encryptEmail}`)
  }

  ActivateUser(user: any) {
    return this.createAxiosInstance1().put(
      `activate_user/${this.getLoginUserObject().userName}`,

      user
    );
  }

  checkByUsernameAndPassword(email: string, password: string) {
    return this.createAxiosInstance1().post("exists-by-username-password", {
      email,

      password,
    });
  }

  createClient(client: ClientInterface) {
    return this.createAxiosInstanceForClient().post(`${clientUrl}create`, client);
  }
  newSubscription(subscriptionPlans: any) {
    return this.createAxiosInstanceForClient().post(`${clientUrl}save-subscription`, subscriptionPlans);
  }

  deleteSubscription(id: number){
    return this.createAxiosInstanceForClient().delete(`${clientUrl}delete-subscription-plan/${id}`)
  }
  renewSubscription(clientAndSubscriptionDTO: any) {
    return this.createAxiosInstanceForClient().post(`${clientUrl}renew-subscription`, clientAndSubscriptionDTO);
  }

  getAllSubscriptionPlans() {
    return this.createAxiosInstanceForClient().get(`${clientUrl}get-all-subscription-plans`)
  }

  getAllClients() {
    return this.createAxiosInstanceForClient().get(`${clientUrl}get-all-clients`)
  }

  getAllActiveOnly() {
    return this.createAxiosInstanceForClient().get(`${clientUrl}getAllClientsToCreateUserPage`)
  }
  changeStatus(id: number, clientStatus: string) {
    return this.createAxiosInstanceForClient().post(`${clientUrl}change-status/${id}/${clientStatus}`);
  }

  updateClientDetails(dto:any){
    return this.createAxiosInstanceForClient().put(`${clientUrl}edit-client`,dto)
  }

  getLatestDateForClientPlan(id: number){
    return this.createAxiosInstanceForClient().get(`${clientUrl}get-latest-date/${id}`)
  }

  getClientPlansbyClient(id: any){
    return this.createAxiosInstanceForClient().get(`${clientUrl}get-client-plans/${id}`);
  }

  changeClientPlanStatus(client: any){
    return this.createAxiosInstanceForClient().put(`${clientUrl}change-client-plan-status`,client)
  }

  checkClientNameAndEmail(email:string,clientName:string){
    return this.createAxiosInstanceForClient().get(`${clientUrl}check-email-clientname/${email}/${clientName}`)
  }

  saveUser(user: any){
    return this.createAxiosInstance1().post(`${url1}save-user`,user);
  }

  getAdminConfigurations(){
    return this.createAxiosInstance1().get(`${url1}admin-configurations`);
  }

  saveAdminConfigurations(configurations:any[]){
    return this.createAxiosInstance1().post(`${url1}save-admin-configurations`,configurations)
  }

  getConfigurationById(id:any){
    return this.axiosInstanceWithOutToken1().get(`${url1}get-admin-configuration-by-id/${id}`)
  }
}

export default new UserService();
