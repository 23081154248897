import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
} from "@mui/material";
import "./CustomModal.css";
import { StyledEngineProvider } from "@mui/material/styles";
import { ButtonInterface } from "../../types/ButtonInterface";

interface ModalProps {
  open: boolean;
  // onClose: () => void;
  heading?: string;
  children: React.ReactNode;
  cancelButton?: ButtonInterface;
  continueButton: ButtonInterface;
}

export const CustomModal = ({
  open,
  // onClose
  heading,
  children,
  cancelButton,
  continueButton,
}: ModalProps) => {

  return (
    <StyledEngineProvider injectFirst>
      <Dialog
        // maxWidth="xs"
        // fullWidth
        sx={{minWidth:"200px"}}
        open={open}
        //onClose={onClose}
        disableEscapeKeyDown={true}
        TransitionComponent={Grow}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {heading && (
          <DialogTitle id="modal-title" className="DialogTitle">
            {heading}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions >
        {cancelButton && (
          <Button
            variant="outlined"
            
            onClick={cancelButton.onClick}
            className="Button"
          >
            {cancelButton.label}
          </Button>
              )}
         {continueButton.label !=="" && <Button
            variant="outlined"
            onClick={continueButton.onClick}
            className="Button"
            disabled={continueButton.disabled}
          >
            {continueButton.label}
          </Button>}
        </DialogActions>
      </Dialog>
    </StyledEngineProvider>
  );
};
