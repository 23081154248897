export interface Question {
  id: number;
  skill: string;
  questionType: string;
  difficulty: string;
  questionText: string;
  executionText: string;
  addInfo?: string | null;
  language: string[];
  timeToSolve: number;
  positiveMarks: number;
  negativeMarks: number;
  createdDate?: string | null; // Change the type based on the actual date format you're using
  createdBy?: string | null;
  organization?: string | null;
  updatedDate?: string | null; // Change the type based on the actual date format you're using
  maximumCharacters: number;
  updatedBy?: string | null;
  status?: string | null;
  isBulkUpload: number;
  baseFlag: boolean;
  visibility: boolean;
  tags: string;
  answers?: MCQAnswer[]; // Define MCQAnswer interface if not done already
}

export interface MCQAnswer {
  id: number;
  optionValue: string;
  answerFlag: boolean;
  optionId: number;
}

export const defaultQuestion: Question = {
  id: 0,
  skill: "",
  questionType: "",
  difficulty: "",
  questionText: "",
  executionText: "",
  language: [],
  timeToSolve: 1,
  positiveMarks: 1,
  negativeMarks: 0,
  maximumCharacters: 0,
  isBulkUpload: 0,
  baseFlag: false,
  visibility: false,
  tags: "",
};
