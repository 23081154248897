import React, { useEffect, useState } from "react";
import {
  Card,
  ClickAwayListener,
  Collapse,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Popper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import the close icon from the appropriate icon library
import { AppBar, Toolbar, IconButton, Typography, styled } from "@mui/material";

import Badge from '@mui/material/Badge';
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from '@mui/icons-material/Notifications';
import rs_logo from "../assets/rs_logo.png";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import QuestionBankClient from 'src/clients/QuestionBankClient';
import download from './download.png';
import { Outlet, useNavigate } from "react-router-dom";

import {
  AccountCircle,
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";

import { Divider } from "antd";

import { useLocation } from "react-router-dom";

import { navbarStyles } from "./navbarStyles";

import Navbaritems from "./NavbarItems";

import UserService from "../../clients/UserService";
import { Global } from "@emotion/react";
interface Notification {
  id: number;
  message: string;
  createdTimeStamp: Date;
}

type Anchor = 'right';
const SideMenuDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",

    width: 230,

    borderRight: "1px solid",

    borderColor: theme.palette.divider,
  },
}));

const SideMenu: React.FC = () => {
  const [hovering, setHovering] = useState("");

  const [open, setOpen] = useState(false); // State to control popper visibility

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null); // Update type annotation of anchorEl

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const [hasReloaded, setHasReloaded] = useState(true);

  const [userName, setUserName] = useState<string>("");

  const navBarItems = Navbaritems;
  const [roleStatus, setRoleStatus] = useState(true);
  let role: boolean;
  let viewUserPermission: boolean;
  let editMasterdataPermission:boolean;
  let isRSAdmin:boolean;
  let ActiveUser:boolean=false;
  const CurrentUser = localStorage.getItem("currentUser")

  if (CurrentUser != null) {
    const loginUser = JSON.parse(CurrentUser);
    viewUserPermission = loginUser.permissionslist?.includes("View Users");
    role = loginUser.permissionslist?.includes("Roles And Permissions");
    editMasterdataPermission=loginUser.permissionslist?.includes("Edit Masterdata");
    isRSAdmin=loginUser.role.roleName=="RS_ADMIN"?true:false;
ActiveUser=loginUser.status=="ACTIVE"?true:false;
  }
  else {
    viewUserPermission = false;
    role = false;
    isRSAdmin=false;
  }
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [state, setState] = useState<{ [key in Anchor]: boolean }>({
    right: false,
  });


  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {

      return;
    }
    if (!open) {

      if (notifications.length > 0) {
        deleteNotifications();
      }

    }

    setState({ ...state, [anchor]: open });
  };

  const user = localStorage.getItem("currentUser");
  let loggeduserName: string;
  if (user) {
    loggeduserName = JSON.parse(user).userName;
  }

  const deleteNotifications = async () => {
    try {
      const response = await QuestionBankClient.deleteNotifications(loggeduserName);
      // console.log(response.ok)
 
      if (response.ok) {
        setNotifications([]);
      }

    }
    catch (error) {
      console.log(error);
    }
  }
  const fetchNotifications = async () => {

    try {
      const response = await QuestionBankClient.getNotifications(loggeduserName);

      if (response.ok) {
        const data = await response.json();

        const sortedData = data.sort(
          (a: Notification, b: Notification) =>
            new Date(b.createdTimeStamp).getTime() -
            new Date(a.createdTimeStamp).getTime()
        );
        setNotifications(sortedData);

      }
    } catch (error) {
      // console.log(error);
    }

  };
  useEffect(() => {
    const removeFiles = ["Clients","Configurations"]
    
     navBarItems[3].options?.filter((items:any) =>  
    {
      // removeFiles.includes(items.label)
      items.label.includes(removeFiles)
    })
    
    console.log(navBarItems[3],"navBarItems")
    if(user)
    {
      fetchNotifications();
    }

    

  }, []);


  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen); // Toggle the popper visibility

    setAnchorEl2(event.currentTarget); // Store the anchor element
  };

  const location = useLocation();

  const disablingFunction = (label: string) => {
    if (label == "Users") {
      if (viewUserPermission) {
        return false
      }
      else {
        return true
      }
    }
    else if (label == "Roles & Permissions") {
      if (role) {
        return false
      }
      else {
        return true
      }
    }
    else {
      return false;
    }
  }

  const handleIconClick = (itemId: string) => {
    if (expandedItems.includes(itemId)) {
      setExpandedItems(expandedItems.filter((item) => item !== itemId));
    } else {
      setExpandedItems([...expandedItems, itemId]);
    }
  };

  const isItemExpanded = (itemId: string) => {
    return expandedItems.includes(itemId);
  };

  const handleSignOut = () => {
    localStorage.setItem("role", String(false));

    localStorage.setItem("login", String(false));

    sessionStorage.setItem("flag", "false");


    localStorage.removeItem("currentUser");

    localStorage.removeItem("token");

    navigate("/login");

    setOpen(false);

    window.location.reload();
  };

  const handleIconClick2 = (
    event: React.MouseEvent<HTMLLIElement>,

    itemId: string
  ) => {
    setHovering(itemId);

    setAnchorEl(event.currentTarget); // Store the anchor element for the specific item

    setExpandedItems([itemId]); // Set the expanded items to only include the current item being hovered

  };

  const handlePopoverClose = () => {
    setAnchorEl(null); // Clear the anchor element when popover is closed

    setExpandedItems([]); // Reset the expanded items to an empty array

    setHovering("");

  };

  const handleChangePassword = () => {
    navigate("/resetPassword");
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const [isViewUser, setisViewUser] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const styles = navbarStyles(isOpen);

  useEffect(() => {

    const role = localStorage.getItem("role");

    if (role == "true") {
      setRoleStatus(JSON.parse(role));
    }

    const currentUser = localStorage.getItem("currentUser");

    if (currentUser) {
      const user = JSON.parse(currentUser).email;

      setUserName(user);


      if (
        UserService.getLoginUserObject().permissionslist?.includes("View Users")
      ) {

        setisViewUser(false);
      }
    }
  }, []);

  useEffect(() => {


    if (
      (location.pathname == "/" ||
        location.pathname == "/login" ||
        location.pathname == "/*") &&
      sessionStorage.getItem("flag") == "true"
    ) {
      handleSignOut();
    }
  }, [location, hasReloaded]);

  const navigations = (route: any) => {
    if (route != location.pathname) {
      navigate(route);
    }
    if (route !== "#") {
      if (route === location.pathname) {
        window.location.reload();
        navigate(route);
      }
    }
  }
  return (
    <div>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "white", height: "60px" }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,

              marginLeft: "10px",

              marginBottom: "10px",

              height: "100%",

              display: "flex",

              alignItems: "center",
              position: "relative",
              right: "19px",
            }}
          >
            <img src={rs_logo} width="80px" height="40px" />
          </Typography>
          
          {/* <IconButton edge="start" sx={{ color: "black", marginRight: "1.5%" }}> */}

          {/* <HelpOutline sx={{ color: "#fe524a" }} /> */}

          <div style={{ marginRight: "1%" }}>
            {localStorage.getItem("login") == "true" ? (
              <Typography color="#fe524a">{userName}</Typography>
            ) : (
              <Typography />
            )}
          </div>

          {/* </IconButton> */}
          {JSON.parse(localStorage.getItem("login") || "null") ?
            <IconButton
              edge="start"
              sx={{ color: "black" }}
              onClick={handleAccountClick}
            >
              <AccountCircle sx={{ color: "#fe524a" }} />
            </IconButton> : ""}
            {localStorage.getItem("login") == "true"  ?(
          <Box>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} sx={{border:"none"}}>
          <Badge color="primary" badgeContent={notifications.length} showZero sx={{".MuiBadge-badge":{
                    backgroundColor:"#fe524a",
                  }}}>
           <NotificationsIcon sx={{ color: "#fe524a" }} />
              </Badge>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Box sx={{ width: 250 }}>
            {notifications.length > 0 && (
                <List>
                  {notifications.map((notification) => (
                    <ListItem key={notification.id} disablePadding>
                      <ListItemText primary={notification.message} sx={{color:'#444343'}} />
                    </ListItem>
                  ))}
                </List>
              )}
              {notifications.length === 0 && (
              
               <Box sx={{textAlign:"center", paddingTop:"50%",fontWeight:"normal"}}><img src={download} alt="Notification icon" /><h4 style={{margin:0}}>No new Notifications</h4></Box>
                
              )}
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
          )
        :<Box></Box>
        }
        </Toolbar>
      </AppBar>

      <SideMenuDrawer
        variant="temporary"
        anchor="left"
        open={isOpen}
        onClose={toggleMenu}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            sx={{ height: "100%" }}
          >
            <CloseIcon
              sx={{ color: "#fe524a", "&:hover": { color: "lightpink" } }}
            />
          </IconButton>
        </Toolbar>

        <List disablePadding>
          {Navbaritems.map((item) => (item.condition &&
            <React.Fragment key={item.id}>
              <ListItem
                disablePadding
                // disabled={!item.condition}
                sx={styles.icons}
              >
                <ListItemButton
                  component="div"
                  onClick={() => handleIconClick(item.id.toString())}
                >
                  {isOpen ? (
                    <ListItemIcon sx={styles.icons}>{item.icon}</ListItemIcon>
                  ) : (
                    <ListItemIcon
                      sx={{ ...styles.icons, marginLeft: "20%" }}
                      onClick={() => navigations(item.route)}
                    >
                      {item.icon}
                    </ListItemIcon>
                  )}

                  {isOpen && (
                    <ListItemText
                      primary={item.label}
                      onClick={() => navigations(item.route)}
                    />
                  )}

                  {isOpen && item.options && item.options.length > 0 && (
                    <IconButton size="small" sx={styles.dropdownIcon}>
                      {isItemExpanded(item.id.toString()) ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </IconButton>
                  )}
                </ListItemButton>
              </ListItem>

              {item.options && item.options.length > 0 && (
                <Collapse in={isItemExpanded(item.id.toString())}>
                  <List disablePadding>
                    <Global
                      styles={{
                        ".MuiButtonBase-root.MuiListItemButton-root.Mui-selected": {
                          backgroundColor: "#FFCFCD",
                        },
                        ".MuiButtonBase-root.MuiListItemButton-root.Mui-selected:hover": {
                          backgroundColor: "#FFCFCD",
                        },
                      }}
                    />
                    {item.options.map((option) => (item.condition &&
                      <ListItem
                        key={option.id}
                        disablePadding
                        disabled={!item.condition}
                        onClick={() => {
                          if (!item.condition) {
                            return; // Exit the function if disabled is true
                          }

                          navigate(option.route);
                        }}
                      >
                        <ListItemButton component="div" selected={option.route === location.pathname}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#FFCFCD",
                            },
                          }}
                        >
                          <ListItemText primary={option.label} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </SideMenuDrawer>

      {JSON.parse(localStorage.getItem("login") || "null") && (
        <div style={{ paddingTop: "60px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={1.5}
              md={0.8}
              sx={{
                position: "fixed",

                height: "100%",

                overflowY: "auto",

                width: "100%",

                alignItems: "center",

                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.9)",
              }}
            >
              <div
                style={{
                  width: "100%",

                  alignItems: "center",

                  borderRight: "1px solid grey",

                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)",

                  height: "100%",
                }}
              >{ActiveUser?(<List>
                {JSON.parse(localStorage.getItem("login") || "null") ? (
                  <IconButton
                    edge="start"
                    sx={{ color: "black" }}
                    aria-label="menu"
                    onClick={toggleMenu}
                  >
                    <MenuIcon
                      sx={{
                        color: "#fe524a",
                        position: "relative",
                        right: "-19px",
                      }}
                    />
                  </IconButton>
                ) : (
                  ""
                )}
                {Navbaritems.map((item: any) => (item.condition &&
                  <React.Fragment key={item.id}>
                    <ListItem
                      key={item.id}
                      disabled={!item.condition}
                      // onMouseLeave={(event:React.MouseEvent<HTMLLIElement>)=>handlePopoverClose()}

                      {...(item.options && item.options.length > 0
                        ? {
                          onClick: (
                            event: React.MouseEvent<HTMLLIElement>
                          ) => handleIconClick2(event, item.id.toString()),
                        }
                        : {})}
                    >
                      <ListItemIcon
                        sx={{ color: "#fe524a", cursor: "pointer" }}
                        onClick={() => navigations(item.route)}
                      >
                        {item.icon}
                      </ListItemIcon>
                    </ListItem>

                    {item.options &&
                      item.options.length > 0 &&
                      item.id == parseInt(hovering) && (
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",

                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",

                            horizontal: "left",
                          }}
                        >
                          {item.options &&
                            item.options.length > 0 &&
                            item.id == parseInt(hovering) && (
                              <div
                                style={{ padding: "8px" }}
                                onMouseLeave={handlePopoverClose}
                              >
                                <List disablePadding>
                                  <Global
                                    styles={{
                                      ".MuiButtonBase-root.MuiListItemButton-root.Mui-selected": {
                                        backgroundColor: "#FFCFCD",
                                      },
                                      ".MuiButtonBase-root.MuiListItemButton-root.Mui-selected:hover": {
                                        backgroundColor: "#FFCFCD",
                                      },
                                    }}
                                  />
                                  {item.options.map((option: any) => (
                                    <ListItem
                                    disabled={
                                      option.label === "Users" ? !viewUserPermission :
                                      option.label === "Roles & Permissions" ? !role :
                                      option.label === "Edit Masterdata" ? !editMasterdataPermission :
                                      option.label === "Clients" ? !isRSAdmin :
                                      option.label === "Configurations" ? !isRSAdmin :
                                      false
                                  }
                                  
                                      key={option.id}
                                      disablePadding
                                      onClick={

                                        () => navigate(option.route)
                                      }

                                    // onClick={() => navigate(item.route)}
                                    >
                                      <ListItemButton selected={option.route === location.pathname}
                                        component="div"
                                        sx={{
                                          "&:hover": {
                                            backgroundColor: "#FFCFCD",
                                          },
                                        }}
                                      >
                                        <ListItemText
                                          sx={{ color: "black" }}
                                          primary={option.label}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  ))}
                                </List>
                              </div>
                            )}
                        </Popover>
                      )}
                  </React.Fragment>
                ))}
              </List>):(<></>)}
              </div>
            </Grid>

            <Grid
              item
              xs={10.5}
              md={11.2}
              sx={{ marginLeft: "auto", overflowY: "auto" }}
            >
              <Outlet />
            </Grid>
          </Grid>
        </div>
      )}

      {JSON.parse(localStorage.getItem("login") || "null") ? (
        <Popper open={open} anchorEl={anchorEl2} transition disablePortal>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Card
                sx={{
                  position: "fixed",

                  backgroundColor: "#FAEFEF;",

                  top: "10%",

                  right: 0,

                  width: "150px",

                  zIndex: 1,
                }}
              >
                <MenuList autoFocusItem={open} id="account-menu-list">
                  {/* <MenuItem onClick={handleClickAway} dense className="card">

                    Profile

                  </MenuItem> */}

                  <Divider style={{ margin: "0.5rem" }} />

                  <MenuItem
                    onClick={handleChangePassword}
                    dense
                    className="card"
                  >
                    Change Password
                  </MenuItem>

                  <Divider style={{ margin: "0.5rem" }} />

                  <MenuItem onClick={handleSignOut} dense className="card">
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Card>
            </ClickAwayListener>
          )}
        </Popper>
      ) : null}
    </div>
  );
};

export default SideMenu;