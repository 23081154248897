import { NoEncryption } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

// mui global styles here

export const dsgTheme = createTheme({
  palette: {
    primary: {
      main: "#FE524A",
    },
    secondary: {
      main: "#444343",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.sectionfieldcss": {
            paddingTop: "1.4%",
          },

          color: "#191f1c",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": { color: "#444343" },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        input: {
          color: "#191f1c",
        },

        underline: {
          "&:after": {
            border: "1px solid #adadad",
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: "#191f1c",
        },

        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #adadad",
            },
          },

          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #adadad",
            },

            "& .MuiStandardInput-notchedOutline": {
              border: "1px solid #adadad",
            },

            "&:after": {
              border: "1px solid #adadad",
            },
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h6: {
          color:"#FE524A",
          fontWeight: 600,
          textAlign: "center",
          textTransform: "capitalize",
          // Center the text horizontally and vertically
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
   
        },
        h5: {
          color: "#444343",
          fontWeight: 600,
          textAlign: "center",
          textTransform: 'capitalize',
          paddingTop: "0.7%"
        },

        body1: {
          fontSize: "14px",
          color: "#444343",
        },
        body2: {
          fontSize: "12px",
          padding: "3px",
          color: "#191F1C",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "8px 8px",
          fontSize: "14px",
          color: "#FE524A",
          border: "1px solid #FE524A",
          // textTransform:'capitalize',
          borderRadius: "4px",
          fontWeight: "medium",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          '&:hover': {
            backgroundColor: "#FE524A",
            color: "#FFFFFF",
            cursor: "pointer",
           
          },
          '&:disabled': {
            backgroundColor: "#D9D9D9",
            color: "#4F4F4F",
            border: "1px solid #4F4F4F",
            cursor: "not-allowed",
          
          },
          '&.navigate':{
            border:"none"
          },
          '&.infoicon': {
            '&:hover': {
              background: 'none',
              border: 'none',
              color:'#fe524a'
            }
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#fe524a",
        },
        outlined: {
          "& .MuiOutlinedInput": {
            styleOverrides: {
              input: {
                color: "#fe524a",
                border: "1px solid #adadad",
              },
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          width: "97%",
          float: "left",
          padding: "0px 0px 0px 24px",
          marginLeft: "-2%",
        },
        displayedRows: {
          float: "left",
          margin: "0px",
        },
        actions: {
          marginLeft: "70%",
          position: "sticky",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
      },
    },
     // Additional styles for .tableBody
     MuiTable: {
      styleOverrides: {
        root: {
          "& .tableBody": {
            width: "100%",
          }
        },
      },
    },

    // Additional styles for .tableContainer
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&:not(.MuiTableCell-head), &.MuiTableCell-head': {
            border: 'none',
          },
         padding:'8px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: "#faefef"
          },
          '&.summaryrow': {
            backgroundColor: "#ffffff"
          },
           height:"5%"
        },
      },
    },
    MuiRadio:{
      styleOverrides:{
        root:{
          "&.radiocss": {
            padding: 0,
            paddingLeft: 9,
          },
          color:"#fe524a",
        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          border: "#FE524A",

          color: "#FE524A",
        },

        root: {
          "&.Mui-checked": {
            color: "#FE524A",
          },
          "&.checkcss":{
          padding:0
          }
        },
      },
    },

    MuiChip: {
            styleOverrides: {
              outlined: {
                margin: "4px",
                display: "inline-flex",
              },
            },
          },
    MuiLink:{
      styleOverrides:{
        root:{
          cursor:"pointer",
          textDecorationColor:"#fe524a"
        }
      }
    },
    MuiDateCalendar:{
       styleOverrides:{
        root:{
          width:"277px",
        }
       }
    },
    MuiPickersLayout:{
      styleOverrides:{
        contentWrapper:{
         
          height:"270px",
          overflow:"overlay"
        },
      }
    }  
    // Additional styles for .tableHeader
    // MuiTableCell: {
    //   styleOverrides: {
    //     head: {
    //       fontWeight: "bold",
    //       color: "#191F1C",
    //       backgroundColor: "#faefef",
    //       alignItems: "center",
    //     },
    //   },
    // },
  },
});
