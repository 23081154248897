import React, { useEffect, useState } from "react";
import {
  getAssessmentHeader,
  getCandidateInformation,
  inviteCandidates,
} from "../../../clients/AssessmentsClient";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import "./InviteCandidates.css";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { dsgTheme } from "../../../styles/theme";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { AssessmentHeaderInterface } from "../../../types/AssessmentHeaderInterface";
import { formatDateStr } from "../../../utils/ValidationUtils";
import { MailRequest } from "../../../types/MailRequest";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import UserService from "src/clients/UserService";
import TokenService from "src/clients/TokenService";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";

const inviteCandidateTheme = createTheme({
  palette: {
    primary: {
      main: "#FE524A",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderRadius: "30px",
          display: "inline-flex",
          paddingLeft: "20px",
          paddingRight: "20px",
          color: "#FE524A",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          textAlign: "center",
          color: "#444343",
          fontWeight: 600,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          margin: "4px",
          display: "inline-flex",
        },
      },
    },
  },
});

const InviteCandidates = () => {
  const location = useLocation();
  const assessmentId = location.state?.assessmentId;
  var storedCombinedString = localStorage.getItem("currentUser");
  if (storedCombinedString != null) {
    var localStorageData = JSON.parse(storedCombinedString);
  }
  const [emailRequest, setEmailRequest] = useState<MailRequest>({
    subject: null,
    startDate: null,
    endDate: null,
    emails: [],
    invitedBy: localStorageData.email,
    firstNames: [],
    lastNames: [],
    assessmentId: assessmentId,
  });
  const [errors, setErrors] = useState({
    emailError: "",
    subjectError: "",
    startDateError: "",
    endDateError: "",
  });
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [assessmentData, setAssessmentData] =
    useState<AssessmentHeaderInterface>();
  const navigate = useNavigate();
  const [resetEmailRequest, setResetEmailRequest] = useState<boolean>(false);
  const [viewmode, setViewmode] = useState(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const handleModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    }).catch((error) => {
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      }else{
        console.log(error)
      }
    });
  };

  useEffect(() => {
    fetchAssessmentData();
    fetchCadidateInformationData();
  }, []);

  useEffect(() => {
    // Check the conditions and update viewmode accordingly
    if (
      assessmentData?.status === "COMPLETED" ||
      assessmentData?.status === "IN_PROGRESS"
    ) {
      const startDateString = emailRequest?.startDate;

      if (startDateString) {
        const startDate = new Date(startDateString);

        // Check if current time is 15 minutes after startDate
        if (new Date() >= new Date(startDate.getTime() + 15 * 60000)) {
          setViewmode(true);
        } else {
          // If not, set a timeout for the remaining time
          const remainingTime = startDate.getTime() + 15 * 60000 - new Date().getTime();
          const timeoutId = setTimeout(() => {
            setViewmode(true);
          }, remainingTime);

          // Cleanup the timeout to avoid memory leaks
          return () => clearTimeout(timeoutId);
        }
      }
    } else {
      setViewmode(false);
    }
  }, [assessmentData, emailRequest]);

  const fetchAssessmentData = async () => {
    const res = await getAssessmentHeader(assessmentId);
    if (res.ok) {
      const data = await res.json();
      setAssessmentData(data);
    }else if (res.status === 402) {
      setClientPlanExpiredModal(true);
    }
  };

  const fetchCadidateInformationData = async () => {
    const res = await getCandidateInformation(assessmentId);
    if (res.ok) {
      const data = await res.json();
      if (data[0]?.startDateTime != null && data[0]?.endDateTime != null) {
        setEmailRequest((prev) => ({
          ...prev,
          startDate: formatDateStr(
            data[0]?.startDateTime,
            "YYYY-MM-DD HH:mm:ss",
            true
          ),
          endDate: formatDateStr(
            data[0]?.endDateTime,
            "YYYY-MM-DD HH:mm:ss",
            true
          ),
        }));
      }
    }else if (res.status === 402) {
      setClientPlanExpiredModal(true);
    }
  };
  //Sending invitation
  const handleSendInvitation = async () => {
    
    if (!validateInviteCandidates()) {
      return;
    }

    // setReload(true);
    try {
      const res = await inviteCandidates(emailRequest);
      if (res.ok) {
        // setReload(false);
        toast.success("Invitation sent successfully", { autoClose: 1000 });
        setTimeout(() => {
          navigate("/assessmentlist");
        }, 3000);
      } else if (res.status === 400){
        setReload(false);
        toast.error(`Emails already sent to AssessmentId ${assessmentId}`, {
          autoClose: 1000,
        });
      }
        else if (res.status === 402) {
          setReload(false);
          setClientPlanExpiredModal(true);
        }
      else {
        setReload(false);
        toast.error("Failed to send invitation", { autoClose: 1000 });
      }
       
    } catch (error: any) {
      toast.error("Failed to send invitation", { autoClose: 1000 });
      if (error.response.status === 401) {
        setOpenSessionModal(true);
      }
    }
  };
  //validating the fields
  const validateInviteCandidates = (): boolean => {
    let isValid = true;

    if(emailRequest.emails.length > UserService.getLoginUserObject().clientPlans.subscriptionPlans.numberOfInvitations){
      toast.error("No of Invitations count has exceeded", {
        autoClose: 1000,
      });
      isValid = false;
    }
    if (!isCheckboxChecked && emailRequest.emails.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailError: "Please enter at least one candidate email.",
      }));
      isValid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        emailError: "",
      }));
    }
    if (isCheckboxChecked && emailRequest.emails.length === 0) {
      toast.error("Please enter at least one candidate email in CSV File", {
        autoClose: 1000,
      });
      isValid = false;
    }

    if (!emailRequest.subject) {
      setErrors((prev) => ({
        ...prev,
        subjectError: "Please enter a subject.",
      }));
      isValid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        subjectError: "",
      }));
    }

    if (!emailRequest.startDate) {
      setErrors((prev) => ({
        ...prev,
        startDateError: "Please enter a start date.",
      }));
      isValid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        startDateError: "",
      }));
    }

    if (!emailRequest.endDate) {
      setErrors((prev) => ({
        ...prev,
        endDateError: "Please enter a end date.",
      }));
      isValid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        endDateError: "",
      }));
    }
    if (emailRequest.startDate && emailRequest.endDate) {
      let endDate: any = new Date(emailRequest.endDate).getTime();
      let startDate: any = new Date(emailRequest.startDate).getTime();
      let totalTime: any = assessmentData?.totalTime;
      const fifteenMinutesInMillis = 15 * 60 * 1000; // 15 minutes in milliseconds
      const currentDateTime = new Date().getTime();
      
      if (endDate <= currentDateTime + fifteenMinutesInMillis) {
        toast.error("You cannot send the invitation within 15 minutes before the end date", {
        });
        isValid = false;
      }

      if (emailRequest.startDate > emailRequest.endDate) {
        setErrors((prev) => ({
          ...prev,
          startDateError: "Start date must not be greater than end date.",
        }));
        isValid = false;
      } else {
        setErrors((prev) => ({
          ...prev,
          startDateError: "",
        }));
      }

      // if ((endDate - startDate) / (1000 * 60) < totalTime) {
      //   toast.error(
      //     `StartDateTime and EndDateTime difference must be greater than ${
      //       totalTime
      //     } minutes`,{autoClose:1000}
      //   );
      //   isValid = false;
      // }
    }
    return isValid;
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter"].includes(evt.key)) {
      evt.preventDefault();
      const trimmedValue = value.trim();
      if (trimmedValue && emailValidation(trimmedValue)) {
        setEmailRequest((prev) => ({
          ...prev,
          emails: [...prev.emails,trimmedValue],
        }));
        setValue("");
        setErrors((prev) => ({
          ...prev,

          emailError: "",
        }));
      }
    }
  };

  console.log("emailRequest", emailRequest);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    
    setEmailRequest((prev) => ({
      ...prev,
      emails: [],
      firstNames: [],
      lastNames: [],
    }));

    const file = event.target.files?.[0];

    if (file) {
      let validEmailsFromCSV: string[] = [];
      let firstNames: string[] = [];
      let lastNames: string[] = [];
      let invalidRows: string[] = [];
      let duplicateEmailsErrors: string[] = [];

      const reader = new FileReader();

      reader.readAsText(file);
      reader.onload = (e) => {
        const contents = e.target?.result as string;

        const rows = contents.split("\n");

        // Validate header of emails csv
        const header = rows[0].trim().split(",");
        if (
          header.length !== 3 ||
          header[0].trim() !== "EMAIL" ||
          header[1].trim() !== "FIRSTNAME" ||
          header[2].trim() !== "LASTNAME"
        ) {
          toast.error(
            "Invalid CSV file. The file must have exactly one column with header 'EMAIL','FIRSTNAME','LASTNAME'.",
            { autoClose: 1000 }
          );
          event.target.value = "";
          return;
        }

        rows.slice(1).forEach((row, rowIndex) => {
          const rowData = row.trim().split(",");
          console.log(rowData);
          if (
            rowData.length === 3 &&
            rowData[0].trim() !== "" &&
            rowData[1].trim() !== "" &&
            rowData[2].trim() !== ""
          ) {
            const firstName = rowData[1].trim();
            const lastName = rowData[2].trim();
            const email = rowData[0].trim();
            if (
              emailValidation(email) &&
              !containsSpecialCharacters(firstName) &&
              !containsSpecialCharacters(lastName)
            ) {
              if (!validEmailsFromCSV.includes(email)) {
                validEmailsFromCSV.push(email);
                firstNames.push(firstName);
                lastNames.push(lastName);
                console.log(duplicateEmailsErrors.length);
              } else {
                duplicateEmailsErrors.push(`Row ${rowIndex + 2}`);
              }
            } else {
              invalidRows.push(`Row ${rowIndex + 2}`);
            }
          } else {
            if (
              rowData.length === 1 ||
              (rowData[0].trim() === "" &&
                rowData[1].trim() === "" &&
                rowData[2].trim() === "")
            ) {
              rows.slice(1);
            } else {
              invalidRows.push(`Row ${rowIndex + 2}`);
            }
          }
        });

        if (duplicateEmailsErrors.length > 0) {
          event.target.value = "";
          toast.error(
            `Duplicate email found in rows ${
              file.name
            } at ${duplicateEmailsErrors.join(", ")}.`,
            { autoClose: 1000 }
          );
        }
        if (invalidRows.length > 0) {
          event.target.value = "";
          toast.error(
            `Invalid rows found in the CSV file ${
              file.name
            } at ${invalidRows.join(", ")}.`,
            { autoClose: 1000 }
          );
        } else if (
          isCheckboxChecked &&
          invalidRows.length === 0 &&
          duplicateEmailsErrors.length === 0
        ) {
          setEmailRequest((prev) => ({
            ...prev,
            emails: validEmailsFromCSV,
            firstNames: firstNames,
            lastNames: lastNames,
          }));
          validEmailsFromCSV = [];
          firstNames = [];
          lastNames = [];
          invalidRows = [];
          event.target.value = "";
          toast.success(file.name + " uploaded successfully", {
            autoClose: 1000,
          });
        }
      };
    }
  };
  const containsSpecialCharacters = (str: string) => {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const containsSpecial = specialCharacters.test(str);
    console.log(
      `Checking if "${str}" contains special characters: ${containsSpecial}`
    );
    return containsSpecial;
  };

  const handleOnEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    setErrors((prev) => ({ ...prev, emailError: "" }));
    const trimmedValue = evt.target.value.trim();

    if (trimmedValue && emailValidation(trimmedValue)) {
      setErrors((prev) => ({
        ...prev,

        emailError: "Press enter to add the email as a chip.",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,

        emailError: "",
      }));
    }
  };

  const emailValidation = (email: string) => {
    let error: string = "";

    if (isEmailExists(email)) {
      error = `${email} has already been added `;
    }
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      setErrors((prev) => ({ ...prev, emailError: error }));
      return false;
    }
    return true;
  };

  const handleOnEmailDelete = (item: string) => {
    const emails: string[] = emailRequest.emails.filter((i) => i !== item);
    setEmailRequest((prev) => ({ ...prev, emails: emails }));
  };

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const isEmailExists = (email: string) => {
    return emailRequest.emails.includes(email);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked);
    if (!event.target.checked) {
      setEmailRequest((prev) => ({
        ...prev,
        emails: [],
        firstNames: [],
        lastNames: [],
      }));

      setErrors((prev) => ({ ...prev, emailError: "" }));
    }
  };

  const downloadFileAtURL = () => {
    const link = document.createElement("a");
    link.href = "Invite Candidates Template.csv";
    link.download = "Invite Candidates Template.csv";
    link.click();
  };

  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={dsgTheme}>
      <Card className="card">
        <Stack spacing={3} className="stack">
          <Typography variant="h5">Invite Candidates</Typography>
          <FormControl>
            <Box id="outer" >
              <Typography id="label" >Candidate Email: </Typography>
              <Autocomplete
                multiple
                freeSolo
                value={emailRequest.emails}
                options={[]}
                className="textFields"
                disabled={isCheckboxChecked || viewmode}
                disableClearable
                renderTags={(value) =>
                  !isCheckboxChecked &&
                  value.map((email) => (
                    <Chip
                      variant="outlined"
                      label={email}
                      onDelete={() => handleOnEmailDelete(email)}
                      className="chip"
                      key={email}
                      disabled={isCheckboxChecked || viewmode}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    value={value}
                    required
                    onKeyDown={handleKeyDown}
                    onChange={handleOnEmailChange}
                    helperText={!isCheckboxChecked && errors.emailError}
                    disabled={viewmode}
                  />
                )}
              />
            </Box>

            <Box id="outer">
              <FormControlLabel
                id="label"
                control={
                  <Checkbox
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Upload File:"
                sx={{ marginRight: "10px", fontSize: "medium" }}
                disabled={assessmentData?.status === "INVITED" || viewmode}
              

              />

              <Box className="upload-button-outer">
                <Button
                  sx={{ borderRadius: "30px" }}
                  className="selectedFile"
                  variant="outlined"
                  component="label"
                  disabled={!isCheckboxChecked}
                  onClick={downloadFileAtURL}
                >
                  Download Email Template
                </Button>
                <Button
                  sx={{ borderRadius: "30px" }}
                  className="selectedFile"
                  variant="outlined"
                  component="label"
                  disabled={!isCheckboxChecked}
                  endIcon={<FileUploadOutlinedIcon />}
                >
                  Upload File
                  <input
                    type="file"
                    accept=".csv"
                    onChange={
                      handleFileInputChange // Assuming handleFileInputChange is an async function
                    }
                    hidden
                  />
                </Button>
              </Box>
            </Box>

            <Box id="outer">
              <Typography id="label">Subject:</Typography>
              <TextField
                id="standard-basic"
                autoComplete="false"
                variant="standard"
                className="textFields"
                value={emailRequest.subject}
                name="subject"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const capitalizedValue = inputValue
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");

                  setEmailRequest((prev) => ({
                    ...prev,
                    subject: capitalizedValue,
                  }));
                }}
                helperText={errors.subjectError}
                disabled={viewmode}
              />
            </Box>

            <Box id="dateFields">
              <Box id="startDate">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date"
                    minDateTime={dayjs()}
                    readOnly={
                      assessmentData?.status === "INVITED" ||
                      assessmentData?.status === "IN_PROGRESS" ||
                      assessmentData?.status === "COMPLETED"
                    }
                    value={
                      assessmentData?.status === "INVITED" ||
                      assessmentData?.status === "IN_PROGRESS"||
                      assessmentData?.status === "COMPLETED"
                        ? dayjs(emailRequest.startDate)
                        : null
                    }
                    onChange={(date: dayjs.Dayjs | null) => {
                      setEmailRequest((prev) => ({
                        ...prev,
                        startDate: date
                          ? formatDateStr(
                              date.toDate(),
                              "YYYY-MM-DD HH:mm:ss",
                              true
                            )
                          : null,
                        endDate: date
                          ? dayjs(date)
                              .add(30, "minutes")
                              .format("YYYY-MM-DD HH:mm:ss")
                          : null,
                      }));
                    }}
                  />
                </LocalizationProvider>
                {errors.startDateError && (
                  <Typography
                    variant="caption"
                    color="error"
                    className="dateError"
                  >
                    {errors.startDateError}
                  </Typography>
                )}
              </Box>
              <Box id="endDate">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date"
                    value={dayjs(emailRequest.endDate)}
                    readOnly={
                      assessmentData?.status === "INVITED" ||
                      assessmentData?.status === "IN_PROGRESS" ||
                      assessmentData?.status === "COMPLETED"
                    }
                    minDateTime={
                      emailRequest.startDate
                        ? dayjs(emailRequest.startDate).add(30, "minutes")
                        : dayjs()
                    }
                    onChange={(date: dayjs.Dayjs | null) => {
                      setEmailRequest((prev) => ({
                        ...prev,
                        endDate: date
                          ? formatDateStr(
                              date.toDate(),
                              "YYYY-MM-DD HH:mm:ss",
                              true
                            )
                          : null,
                      }));
                    }}
                  />
                </LocalizationProvider>
                {errors.endDateError && (
                  <Typography
                    variant="caption"
                    color="error"
                    className="dateError"
                  >
                    {errors.endDateError}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box className="outerButton">
            {viewmode ? (
        <Button
          className="backButton"
          onClick={() => navigate('/assessmentlist')}
        >
          Back
        </Button>
      ) : (
        <Button
          className="sendInvitationButton"
          onClick={handleSendInvitation}
        >
          Send Invitation
        </Button>
      )}
            </Box>
          </FormControl>
        </Stack>
      </Card>
      <ToastContainer position="bottom-left" />
      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleModalCancel}
        onContinue={handleModalContinue}
      ></SessionExtensionModal>
      <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
           
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
    </ThemeProvider>
  );
};

export default InviteCandidates;
