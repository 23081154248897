import React, { useState } from "react";

import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import LinkExpired from "./LinkExpired";
import rs_logo from "../assets/rs_logo.png";
import { dsgTheme } from "../../styles/theme";
import AssessmentInProgress from "./AssessmentInProgress";
import ResponsiveAppBar from "./navbar";
import UserService from "src/clients/UserService";

const TermsConditions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true); // New state variable to track loading state

  const [checked, setChecked] = useState(false);
  const [firstName, setFirstName] = useState<string | null | undefined>("");
  const [lastName, setLastName] = useState<string | null | undefined>("");
  const [lastDegree, setLastDegree] = useState<string | null>("");
  const [yearPassed, setyearPassed] = useState<string | null>("");
  const [specialisation, setspecialisation] = useState<string | null>("");
  const [college, setcollege] = useState<string | null>("");
  const [phoneNumber, setPhoneNumber] = useState<string | null>("");
  const [email, setEmail] = useState("");
  const [flag, setflag] = useState(false);
  const [reason, setReason] = useState(1);
  const [reload,setReload] = useState<boolean>(false);
  var [assessmentId, setassessmentId] = useState("");
  const [isFirstNameDisabled, setIsFirstNameDisabled] = useState(false);
  const [isLastNameDisabled, setIsLastNameDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const candidate_host = process.env.REACT_APP_BASE_URL;

  let firstNameFlag = true;
  let lastNameFlag = true;
  let lastDegreeFlag = true;
  let yearPassedFlag = true;
  let instituteNameFlag = true;
  let branchFlag = true;
  let phoneNumberFlag = true;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    localStorage.setItem("candiate", String("true"));
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    var assessmentId = queryParams.get("assessmentId") || "";
    var email = queryParams.get("email") || "";
    axios
      .get(`${candidate_host}/candidates/decrypt/${assessmentId}/${email}`)
      .then((response) => {
        console.log(response.data);
        setassessmentId(response.data.assessmentid);
        setEmail(response.data.email);
        //set the field values if they already given by candidate
        if (response.data.firstName !== null) {
          setFirstName(response.data.firstName);
          setIsFirstNameDisabled(true);
        }
        if (response.data.lastName !== null) {
          setLastName(response.data.lastName);
          setIsLastNameDisabled(true);
        }
        setLastDegree(
          response.data.lastDegree !== null
            ? response.data.lastDegree
            : undefined
        );
        if (response.data.yearPassed != null && response.data.yearPassed != "0") {
          setyearPassed(
            response.data.yearPassed !== "null"
              ? response.data.yearPassed
              : undefined
          );
        }

        setcollege(
          response.data.college !== null ? response.data.college : undefined
        );
        setspecialisation(
          response.data.specialisation !== null
            ? response.data.specialisation
            : undefined
        );
        setPhoneNumber(
          response.data.phoneNumber !== null
            ? response.data.phoneNumber
            : undefined
        );
        axios
          .get(
            `${candidate_host}/candidates/check-end-time/${response.data.assessmentid}/${response.data.email}`
          )
          .then((respon) => {
            console.log(respon.data);
            setReason(respon.data.reason);
            setflag(!(respon.data.valid));
            setIsLoading(false); // Update loading state when flag is received
          })
          .catch((error) => {
            console.error("Error fetching flag:", error);
            toast.error(
              "Failed to update candidate details. Please try again."
            );
            setIsLoading(false); // Update loading state in case of an error
          });
      });
  }, []);

  const firstNameHelperText = () => {
    let firstNameText =
      firstName && validateNameWithNumericals(firstName)
        ? "First Name cannot have numericals"
        : firstName && validateNameWithSpecialCharacters(firstName)
          ? "First Name cannot have special characters"
          : firstName && validateNameWithAlphaNumerics(firstName)
            ? "First Name cannot have alphanumericals"
            : firstName && !validateNameWithAlphaSpecialCharacters(firstName)
              ? "First Name cannot have special characters"
              : "";
    if (firstNameText !== "") {
      firstNameFlag = false;
    } else {
      firstNameFlag = true;
    }
    return firstNameText;
  };
  const lastNameHelperText = () => {
    let lastNameText =
      lastName && validateNameWithNumericals(lastName)
        ? "Last Name cannot have numericals"
        : lastName && validateNameWithSpecialCharacters(lastName)
          ? "Last Name cannot have special characters"
          : lastName && validateNameWithAlphaNumerics(lastName)
            ? "Last Name cannot have alphanumericals"
            : lastName && !validateNameWithAlphaSpecialCharacters(lastName)
              ? "Last Name cannot have special characters"
              : "";
    if (lastNameText !== "") {
      lastNameFlag = false;
    } else {
      lastNameFlag = true;
    }
    return lastNameText;
  };
  const lastDegreeHelperText = () => {
    let lastDegreeText =
      lastDegree && validateNameWithNumericals(lastDegree)
        ? "Last Degree cannot have numericals"
        : lastDegree && validateNameWithSpecialCharacters(lastDegree)
          ? "Last Degree cannot have special characters"
          : lastDegree && validateNameWithAlphaNumerics(lastDegree)
            ? "Last Degree cannot have alphanumericals"
            : lastDegree && !validateNameWithAlphaSpecialCharacters(lastDegree)
              ? "Last Degree cannot have special characters"
              : "";
    if (lastDegreeText !== "") {
      lastDegreeFlag = false;
    } else {
      lastDegreeFlag = true;
    }
    return lastDegreeText;
  };
  const passedYearHelperText = () => {
    let passedYearText =
      yearPassed && !validateYearPassed(yearPassed)
        ? "Invalid Passout Year"
        : "";
    if (passedYearText !== "") {
      yearPassedFlag = false;
    } else {
      yearPassedFlag = true;
    }
    return passedYearText;
  };
  const instituteNameHelperText = () => {
    let collegeText =
      college && validateNameWithNumericals(college)
        ? "Institute Name cannot have numericals"
        : college && validateNameWithSpecialCharacters(college)
          ? "Institute Name cannot have special characters"
          : college && validateNameWithAlphaNumerics(college)
            ? "Institute Name cannot have alphanumericals"
            : college && !validateNameWithAlphaSpecialCharacters(college)
              ? "Institute Name cannot have special characters"
              : "";
    if (collegeText !== "") {
      instituteNameFlag = false;
    } else {
      instituteNameFlag = true;
    }
    return collegeText;
  };
  const branchHelperText = () => {
    let branchText =
      specialisation && validateNameWithNumericals(specialisation)
        ? "Branch cannot have numericals"
        : specialisation && validateNameWithSpecialCharacters(specialisation)
          ? "Branch cannot have special characters"
          : specialisation && validateNameWithAlphaNumerics(specialisation)
            ? "Branch cannot have alphanumericals"
            : specialisation &&
              !validateNameWithAlphaSpecialCharacters(specialisation)
              ? "Branch cannot have special characters"
              : "";
    if (branchText !== "") {
      branchFlag = false;
    } else {
      branchFlag = true;
    }
    return branchText;
  };
  const [videoChunks,setVideoChunks] = useState<number>(10000);
  console.log(videoChunks,"videoChuk=nsal")
  const phoneNumberHelperText = () => {
    let phoneNumberText =
      phoneNumber && !validatePhoneNumber(phoneNumber)
        ? "Invalid phone number"
        : "";
    if (phoneNumberText !== "") {
      phoneNumberFlag = false;
    } else {
      phoneNumberFlag = true;
    }
    return phoneNumberText;
  };

  useEffect(() => {
    setIsSubmitDisabled(
      !(
        checked &&
        firstName &&
        lastName &&
        lastDegree &&
        yearPassed &&
        specialisation &&
        college &&
        phoneNumber &&
        firstNameFlag &&
        lastNameFlag &&
        lastDegreeFlag &&
        instituteNameFlag &&
        branchFlag &&
        yearPassedFlag &&
        phoneNumberFlag
      )
    );
  });
  const handleNavigation = () => {
    if (
      checked &&
      firstName &&
      lastName &&
      lastDegree &&
      yearPassed &&
      specialisation &&
      college &&
      phoneNumber
    ) {
      // Resetting fields
      setFirstName("");
      setLastName("");
      setLastDegree("");
      setyearPassed("");
      setspecialisation("");
      setcollege("");
      setPhoneNumber("");
      const candidateData = {
        firstName,
        lastName,
        lastDegree,
        yearPassed,
        specialisation,
        college,
        phoneNumber,
      };

      localStorage.setItem("candidateName",firstName.split(" ").join("")+"_"+lastName.split(" ").join(""))
      localStorage.setItem("videoChunks",String(videoChunks))
      setReload(true)
      axios
        .put(
          `${candidate_host}/candidates/${email}/${assessmentId}`,
          candidateData
        )

        .then((response) => {
          setReload(false)
          const candidateId = response.data;
          console.log(candidateId);
          navigate(
            `/Instructions?assessmentId=${assessmentId}&candidateId=${candidateId}`
          );
        })

        .catch((error) => {
          setReload(false)
          console.error("Error updating candidate details:", error);

          alert("Failed to update candidate details. Please try again.");
        });
    } else {
      setIsSubmitDisabled(true);
      setReload(false)
      toast.warning(
        "Please fill in all the required fields and agree to the terms and conditions."
      );
    }
  };

  const validateNameWithAlphaNumerics = (value: string) => {
    const alphaNumericRegex =
      /^[A-Za-z!@#$%^&*()_+\-={};':"|,\\\ \[\].<>/?`~]+$/;
    return !alphaNumericRegex.test(value);
  };

  const validateNameWithAlphaSpecialCharacters = (value: string) => {
    const alphaSpecialRegex = /^[A-Za-z0-9\ ]+$/;
    return alphaSpecialRegex.test(value);
  };

  const validateNameWithNumericals = (value: string) => {
    const numericalRegex = /^[0-9]+$/;
    return numericalRegex.test(value);
  };

  const validateNameWithSpecialCharacters = (value: string) => {
    const specialRegex = /^[!@#$%^&*()_+\-={};':"|,\\\ \[\].<>/?~`]+$/;
    return specialRegex.test(value);
  };
  const validateYearPassed = (value: string) => {
    const specialRegex = /^(19[8-9]\d|20[0-9]\d)$/;
    const date = new Date().getFullYear() + 1;
    return specialRegex.test(value) && date >= Number(value);
  };

  const validatePhoneNumber = (value: string) => {
    const phoneNumberRegex = /^[6-9]{1}[0-9]{9}$/;
    return phoneNumberRegex.test(value);
  };

  useEffect(() => {
    UserService.getConfigurationById(1).then((res) => {
     
      if(res.status === 200){
        console.log(res.data,"setVideoChunksConfiguration")
        setVideoChunks(res.data.configurationValue)
      }else{
        console.log("error");
      }
    }).catch((error:any) => {

      console.log(error)
    })
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const assessmentId = queryParams.get("assessmentId") || "0";
    setEmail(email || "");
    setassessmentId(assessmentId);
  }, [location.search]);

  if (isLoading) {
    return <div>Loading...</div>; // Render a loading message or component while fetching flag
  }
  if (reason === 1) {
    return (
      <div>
        <AssessmentInProgress />
      </div>
    ); // or any loading component or message you want to show while fetching data
  }
  if (flag) {
    return (
      <div>
        <LinkExpired />
      </div>
    ); // or any loading component or message you want to show while fetching data
  }

  return (
    <div>
      <ResponsiveAppBar />
      <ThemeProvider theme={dsgTheme}>
        <div style={{ transform: "scale(0.9)" }}>
          <Typography variant="h5">Register</Typography>

          <div
            style={{
              display: "flex",

              justifyContent: "space-between",

              marginLeft: "5%",

              marginTop: "1%",

              marginBottom: "1%",
            }}
          >
            <div style={{ width: "45%" }}>
              <TextField
                type="text"
                label="First Name"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="First Name"
                value={firstName}
                disabled={isFirstNameDisabled}
                onChange={(e) => setFirstName(e.target.value)}
                helperText={firstNameHelperText()}
              />
            </div>

            <div style={{ width: "45%", marginLeft: "10%" }}>
              <TextField
                type="text"
                label="Last Name"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="Last Name"
                value={lastName}
                disabled={isLastNameDisabled}
                onChange={(e) => setLastName(e.target.value)}
                helperText={lastNameHelperText()}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",

              justifyContent: "space-between",

              marginLeft: "5%",

              marginTop: "2%",

              marginBottom: "2%",
            }}
          >
            <div style={{ width: "45%" }}>
              <TextField
                type="text"
                label="Last Degree"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="Last Degree"
                value={lastDegree}
                onChange={(e) => setLastDegree(e.target.value)}
                helperText={lastDegreeHelperText()}
              />
            </div>
            <div style={{ width: "45%", marginLeft: "10%" }}>
              <TextField
                type="text"
                label="Branch"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="Branch"
                value={specialisation}
                onChange={(e) => setspecialisation(e.target.value)}
                helperText={branchHelperText()}
              />
            </div>

          </div>

          <div
            style={{
              display: "flex",

              justifyContent: "space-between",

              marginLeft: "5%",

              marginTop: "2%",

              marginBottom: "2%",
            }}
          >
            <div style={{ width: "45%" }}>
              <TextField
                type="text"
                label="Institute Name"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="Institute Name"
                value={college}
                onChange={(e) => setcollege(e.target.value)}
                helperText={instituteNameHelperText()}
              />
            </div>

            <div style={{ width: "45%", marginLeft: "10%" }}>
              <TextField
                type="text"
                label="Passout Year"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="Passout Year"
                value={yearPassed}
                onChange={(e) => setyearPassed(e.target.value)}
                helperText={passedYearHelperText()}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",

              justifyContent: "space-between",

              marginLeft: "5%",

              marginTop: "2%",

              marginBottom: "2%",
            }}
          >
            <div style={{ width: "45%" }}>
              <TextField
                type="text"
                label="Phone No"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",
                  width: "85%",
                }}
                placeholder="Phone No"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={
                  phoneNumber && !validatePhoneNumber(phoneNumber)
                    ? true
                    : undefined
                }
                helperText={phoneNumberHelperText()}
              />
            </div>

            <div style={{ width: "45%" }}>
              <TextField
                type="text"
                label="Email"
                color="warning"
                size="small"
                required
                disabled
                variant="outlined"
                style={{
                  borderRadius: "4px",

                  boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",

                  width: "85%",

                  backgroundColor: "#D9D9D9",
                }}
                placeholder="Email"
                value={email}
              />
            </div>
          </div>

          <Box
            sx={{
              height: 250,

              backgroundColor: "#FFFFFF",

              marginLeft: "5%",

              marginTop: "1%",

              fontSize: "14px",

              fontWeight: "medium",

              boxShadow: "0px 2px 4px rgba(0,0,0,0.6)",

              width: "88%",

              display: "grid",

              gridTemplateRows: "auto 1fr auto",

              gap: "20px",

              overflow: "auto",
            }}
          >
            <div style={{ paddingRight: "10px", paddingLeft: "5px" }}>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "column",

                  marginTop: "16px",
                }}
              >
                <Stack
                  sx={{
                    marginLeft: "90%",

                    float: "right",

                    width: "100px",

                    height: "50px",

                    display: "flex",

                    justifyContent: "end",
                  }}
                >
                  <img src={rs_logo} alt="red-shift logo"></img>
                </Stack>
                <Stack>
                  <Typography>Assessment Terms and Conditions </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    These terms and conditions govern your participation in the
                    assessment conducted by Red-Shift Innovations Private Ltd. By
                    taking the assessment, you agree to comply with these Terms.
                    Please read them carefully before proceeding.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    Assessment Rules:
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    a. You must adhere to the rules and guidelines provided by
                    Red-Shift during the assessment.
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    b. Any form of cheating, plagiarism, or misconduct during
                    the assessment is strictly prohibited.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    Full-Screen Mode:
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    a. During the exam, you must maintain full-screen mode at
                    all times. Exiting from full-screen mode may result in the
                    termination of the exam.
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    b. Do not minimize or resize the exam window.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    Tab Switching:
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    a. You are prohibited from switching tabs or accessing other
                    applications or websites during the exam.
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    b. The use of any external resources or materials during the
                    exam is strictly prohibited.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    Assessment Duration:{" "}
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    a. The assessment will have a specified duration, including
                    time limits or deadlines for completing different sections
                    or tasks.
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    b. You must complete the assessment within the designated
                    timeframe.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    Assessment Content:{" "}
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    a. The assessment will consist of predetermined content and
                    format, including questions, tasks, or other evaluation
                    methods.
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    b. The Organization reserves the right to modify the
                    assessment content at its discretion.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    Privacy Policy:{" "}
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    a. The Organization will collect, store, and use your
                    personal data in accordance with applicable privacy laws and
                    its Privacy Policy.
                  </Typography>

                  <Typography sx={{ marginTop: "2px" }}>
                    b. By participating in the assessment, you consent to the
                    collection, storage, and use of your personal data as
                    outlined in the Privacy Policy.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    By proceeding with the assessment, you acknowledge that you
                    have read, understood, and agreed to these Terms. Failure to
                    comply with these Terms may result in disqualification or
                    other appropriate actions by the Red-Shift.
                  </Typography>

                  <Typography sx={{ marginTop: "16px" }}>
                    If you do not agree with these Terms, you should not proceed
                    with the assessment.
                  </Typography>
                </Stack>
              </Box>
            </div>
            <FormControlLabel
              sx={{ marginLeft: "1%", fontSize: "14px" }}
              control={
                <Checkbox
                  sx={{
                    color: "#fe524a",
                    justifySelf: "end",
                    "&.Mui-checked": { color: "#fe524a" },
                  }}
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
              }
              label="I Agree to terms and conditions"
            />
            <Button
              variant="outlined"
              sx={{
                borderColor: "#fe524a",

                color: "#fe524a",

                justifySelf: "end",

                "&:hover": { backgroundColor: "#fe524a", color: "#FFFFFF" },

                "& .Mui-disabled": {
                  backgroundColor: "#D9D9D9",
                  color: "#4F4F4F",
                  fontSize: "14px",
                  padding: "8px 0px",
                },

                float: "right",

                marginRight: "15px",

                marginBottom: "2%",
              }}
              onClick={handleNavigation}
              disabled={isSubmitDisabled}
            >
              SUBMIT
            </Button>
          </Box>
          <ToastContainer position="bottom-left" />
        </div>
      </ThemeProvider>
    </div>
  );
};

export default TermsConditions;
