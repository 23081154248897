import {
  Select,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import "./AssessmentReports.css";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme } from "@mui/material/styles";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { formatDateMonth, formatDateYear } from "./dateUtils";
import { useNavigate } from "react-router-dom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { error } from "console";
import UserService from "../../../clients/UserService";
import { dsgTheme } from "../../../styles/theme";
import Pagination from "src/components/pagination/Pagination";
import TokenService from "../../../clients/TokenService";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import {
  getTotalCandidates
} from "../../../clients/AssessmentsClient";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ToastContainer, toast } from "react-toastify";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";
enum Status {
  COMPLETED,
  INPROGRESS,
  CREATED,
}

interface AssessmentDataList {
  id: number;
  assessmentName: string;
  organizedby: string;
  passed: number;
  createdDate: Date;
  questionsPerSection: number;
  progress: number;
  totalMarks: number;
  invitedCount: number;
  status: string;
  organisation:string;
  month: any;
}

interface AssessmentFilter {
  assessmentName: string;
  month: string;
  year: string;
  minPassed: number;
  organizedBy: string;
}
interface CandidateInfo {
  firstName:string,
  lastName:string,
  emailId: string;
  status: string;
}
// const assessmentTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#FE524A",
//     },
//     secondary: {
//       main: "#444343",
//     },
//   },
//   components: {
//     MuiSelect: {
//       styleOverrides: {
//         icon: {
//           color: "#fe524a",
//         },
//         outlined: {
//           "& .MuiOutlinedInput": {
//             styleOverrides: {
//               input: {
//                 color: "#fe524a",
//                 border: "1px solid #fe524a",
//               },
//             },
//           },
//         },
//       },
//     },
//     MuiTablePagination: {
//       styleOverrides: {
//         toolbar: {
//           width: "97%",
//           float: "left",
//           padding: "0px 0px 0px 24px",
//           marginLeft: "-2%",
//         },
//         displayedRows: {
//           float: "left",
//           margin: "0px",
//         },
//         actions: {
//           marginLeft: "70%",
//           position: "sticky",
//         },
//       },
//     },
// });

export const AssessmentList: React.FC = () => {
  //all state variables used in this component
  const [candidateInfo, setCandidateInfo] = React.useState<CandidateInfo[]>([]);
  const [isFiltersDisabled, setIsFiltersDisabled] = React.useState(true);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [assessmentdata, setAssessmentData] = React.useState<
    AssessmentDataList[]
  >([]);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState("5");
  const [selectedRow, setSelectedRow] =
    React.useState<AssessmentDataList | null>(null);
  const [filters, setFilters] = React.useState<AssessmentFilter>({
    assessmentName: "",
    month: "",
    year: "",
    minPassed: 0,
    organizedBy: "",
  });
  
  const [sortField, setSortField] = useState(""); // Initially, no field is selected for sorting.
  const [sortDirection, setSortDirection] = useState("asc"); // Initially, sorting direction is ascending.
  const [organizedBy,setOrganizedBy] = useState<string[]>([]);
  const [month,setMonth] = useState<string[]>([]);
  
  const [year,setYear] = useState<string[]>([]);
  const [assessmentName,setAssessmentName] = useState<string[]>([]); 
  const [intitalAssessmentData,setInitialAssessmentData] = useState<AssessmentDataList[]>([]);
  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }

    const sortedData = [...filteredData].sort((a, b) => {
      switch (field) {
        case "assessment name":
          return sortDirection === "asc"
            ? a.assessmentName.localeCompare(b.assessmentName)
            : b.assessmentName.localeCompare(a.assessmentName);
        case "month":
          return sortDirection === "asc" ? a.month - b.month : b.month - a.month;
        case "progress":
          return sortDirection === "asc" ? a.progress - b.progress : b.progress - a.progress;
        case "invited count":
          return sortDirection === "asc" ? a.invitedCount - b.invitedCount : b.invitedCount - a.invitedCount;
        default:
          return  0;
      }
    });

    setAssessmentData(sortedData);
    console.log("sortedData",sortedData)
  };


  const navigate = useNavigate();
  //lables for table headers
  const Labels: string[] = [
    "S No",
    "Assessment Name",
    "Month",
    "Year",
    "Passed",
    "Organizer",
    "Progress",
    "Invited Count",
    "Candidate Status",
    "View Report",
  ];

  const loggedUser = localStorage.getItem("currentUser");
  const userDetails = loggedUser ? JSON.parse(loggedUser) : null;
  const permissions = userDetails?.permissionslist;
  let organisation: string
  const [reload,setReload] = useState<boolean>(false);
  if (loggedUser) {
    organisation = JSON.parse(loggedUser).clientPlans.clients.clientName;
  }
  //fetch api call
  useEffect(() => {  
    fetchData();
  }, []);
  const fetchData = async () => {
    setReload(true)
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/reports/assessments/` + organisation,
        config
      );

      if (response.ok) {
        setReload(false)
        const data = await response.json();
        data.sort(
          (a: AssessmentDataList, b: AssessmentDataList) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB.getTime() - dateA.getTime();
          }
        );

        const filterData:AssessmentDataList[] = data.filter(
          (assessment: AssessmentDataList) =>
            assessment.status !== "CANCELLED" &&
            assessment.organisation === organisation
        )
        setAssessmentData(filterData);
        setInitialAssessmentData(filterData)
        setOrganizedBy(Array.from(
          new Set(
            filterData?.map((assessment) => assessment.organizedby)
          )
        ).map((idx) => idx))
        setAssessmentName(Array.from(
          new Set(
            filterData?.map((assessment) => assessment.assessmentName)
          )
        ).map((idx) => idx))
        setMonth(Array.from(
          new Set(
            filterData?.map((assessment) =>
              formatDateMonth(assessment.createdDate)
            )
          )
        ).map((idx) => (   
            idx
        )))
        setYear(Array.from(
          new Set(
            filterData?.map((assessment) =>
              formatDateYear(assessment.createdDate)
            )
          )
        ).map((idx) => (   
            idx
        )))
        setCurrentPage(1);
      }
      else if(response.status===401)
      {
        setReload(false)
        setOpenSessionModal(true);
      }else if (response.status === 402) {
        setReload(false)
        setClientPlanExpiredModal(true)
      }
    } catch (error: any) {
      setReload(false)
      if (error.status === 401) {
        setOpenSessionModal(true);
      }else if (error.response.status === 402) {
        setClientPlanExpiredModal(true)
      }
      console.error("API request failed:", error.message);
    }
  };


  //pagination handling methods
  const rowsPerPageArray = ["5", "10", "15"];
  const assessmentsPerPage = Number(rows);
  const indexOfLastAssessment = currentPage * assessmentsPerPage;
  const indexOfFirstAssessment = indexOfLastAssessment - assessmentsPerPage;

  const handleChange = (event: SelectChangeEvent) => {
    setRows(event.target.value);
    setCurrentPage(1);
  };
  //handling row click or select
  const handleRowClick = (
    row: React.SetStateAction<AssessmentDataList | null>
  ) => {
    if (row) {
      setSelectedRow(row);
      var combinedString = JSON.stringify(row);
      localStorage.setItem("assessment", combinedString);
      navigate("/collegeResult");
    }
  };

  //filtering data functionality
  const applyFilters = (
    data: AssessmentDataList[],
    filter: AssessmentFilter
  ): AssessmentDataList[] => {
    return data.filter((item) => {
      if (
        filter.assessmentName &&
        !item.assessmentName.toLowerCase().includes(filter.assessmentName.toLowerCase())
      ) {
        return false;
      }
      if (filter.month && formatDateMonth(item.createdDate) !== filter.month) {
        return false;
      }
      if (filter.year && formatDateYear(item.createdDate) !== filter.year) {
        return false;
      }
      if (filter.minPassed && item.passed < filter.minPassed) {
        return false;
      }
      if (filter.organizedBy && item.organizedby !== filter.organizedBy) {
        return false;
      }
      return true;
    });
  };
  let filteredData = applyFilters(assessmentdata, filters);
  const handleFilterChange = (property: string, value: string | number) => {
    setIsFiltersDisabled(false);
    setCurrentPage(1);
    setFilters((prev) => ({ ...prev, [property]: value }));
  };
  //handling clear filter
  const handleClearFilters = () => {
    setFilters({
      assessmentName: "",
      month: "",
      year: "",
      minPassed: 0,
      organizedBy: "",
    });
    setIsFiltersDisabled(true);
    setAssessmentData(intitalAssessmentData)
  };


  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
    }).catch((error) => {
      if (error.response.status === 401) {
        console.log("Unauthorized path..");
        setOpenSessionModal(true);
      } else if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
    
      } else  {
        toast.error("Internal Server Error", { autoClose: 1000 });
      }
    });
  };
  const fetchCandidateEmails = async (assessmentId: number) => {
    console.log(assessmentId);
    try {
      const response = await getTotalCandidates(assessmentId);

      if (response.ok) {
        const data = await response.json();     
        setCandidateInfo(data);

        
          const excelData = [
            ['SNo','First Name','Last Name', 'Email Id', 'Status'],
            ...data.map((candidate: any, index: any) => [index + 1,candidate.firstName,candidate.lastName, candidate.emailId, candidate.status]),
          ];


          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(excelData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');


          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });


          const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
          const fileName = `Candidate_info_Id_${assessmentId}.xlsx`;
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true)
        }  else if(response.status === 401){
          setOpenSessionModal(true);
        }
    } catch (error:any) {
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true)
      }
      console.error('Error fetching candidates:', error);
      throw error;
    }

  };
  console.log(candidateInfo);


  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    // <MyContext.Provider value={contextValue}>

    <StyledEngineProvider injectFirst>
      <div>
        <ThemeProvider theme={dsgTheme}>
          <Stack direction={"column"} spacing={2} className="bodyStack">
            <Typography variant="h5" className="Typography">
              Assessment Reports
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              marginBottom="2%"
              alignItems="center"
            >
              <Box sx={{ marginRight: "auto" }}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography>Assessments per page</Typography>

                  <FormControl variant="standard">
                    <Select
                      disableUnderline={true}
                      value={rows}
                      label="Age"
                      onChange={handleChange}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {rowsPerPageArray.map((value, index) => (
                        <MenuItem value={value} key={value + String(index)}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography sx={{ color: "#191F1C" }}>
                    {indexOfLastAssessment > 0 ? indexOfFirstAssessment + 1 : 0}-
                    {indexOfLastAssessment > filteredData.length
                      ? indexOfLastAssessment -
                      (indexOfLastAssessment - filteredData.length)
                      : indexOfLastAssessment}{" "}
                    out of {filteredData.length}
                  </Typography>
                </Stack>
              </Box>

              <Pagination
                rows={rows}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalDataLength={filteredData.length}
                dataPerPage={assessmentsPerPage}
              />
            </Stack>
            <Stack direction="row" spacing={1} className="filterRowStack">
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                value={filters.assessmentName}
                onChange={(e, value) =>
                  handleFilterChange("assessmentName", value)
                }
                onInputChange={(e, value) =>
                  handleFilterChange("assessmentName", value)
                }
                options={assessmentName}
                className="assessmentFilter"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assessment Name"
                    name="assessmentName"
                    InputProps={{ ...params.InputProps, type: "search" }}
                    size="small"
                  />
                )}
              />

              <FormControl className="monthFilter" size="small">
                <InputLabel>Month</InputLabel>
                <Select
                  value={filters.month}
                  name="Month"
                  label="Month"
                  onChange={(e) => handleFilterChange("month", e.target.value)}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {month.map(idx => (
                    <MenuItem value={idx} key={idx}>
                    {idx}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="yearFilter" size="small">
                <InputLabel id="demo-simple-select-helper-label">
                  Year
                </InputLabel>
                <Select
                  value={filters.year}
                  name="Year"
                  label="Year"
                  onChange={(e) => handleFilterChange("year", e.target.value)}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {year.map(idx => (
                    <MenuItem value={idx} key={idx}>
                    {idx}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="passedFilter">
                <TextField
                  label="Min Passed"
                  value={filters.minPassed}
                  onChange={(e) =>
                    handleFilterChange(
                      "minPassed",
                      parseInt(e.target.value, 10)
                    )
                  }
                  type="number"
                  inputProps={{ min: 0 }}
                  size="small"
                />
              </FormControl>

              <FormControl className="organizerFilter" size="small">
                <InputLabel id="demo-simple-select-helper-label">
                  Organizer
                </InputLabel>
                <Select
                  value={filters.organizedBy}
                  name="Organizer"
                  onChange={(e) =>
                    handleFilterChange("organizedBy", e.target.value)
                  }
                  label="Organizer"
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {organizedBy.map(idx => (
                    <MenuItem value={idx} key={idx}>
                    {idx}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                onClick={handleClearFilters}
                disabled={isFiltersDisabled}
                className="clearFilterButton"
                variant="outlined"
              >
                <FilterAltOffIcon />
                Clear Filters
              </Button>
            </Stack>
            {filteredData?.length === 0 ? (

              <>

                <h3 style={{
                  color: "red",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "normal",
                }}>No assessments are available</h3>

              </>


            ) :(
              <Paper className="Paper">
                <TableContainer className="TableContainer">
                  <Table className="Table" stickyHeader aria-label="sticky table">
                    <TableHead className="TableHead">
                      <TableRow className="TableRow">
                        {Labels.filter((column) => column !== "S No").map(
                          (column) => (
                            <TableCell

                              onClick={() => {

                                if (["Assessment Name", "Progress", "Month", "Invited Count"].includes(column)) {

                                  const formattedColumn = column === "Assessment Name" ? "assessment name" : column.toLowerCase();


                                  handleSort(formattedColumn);
                                }

                              }}
                              className="noWrap"
                              key={column}
                              style={{
                                textAlign:
                                  column === "Assessment Name"
                                    ? "left"
                                    : "center",
                                color: "#444343",
                                fontWeight: "bold",
                                width:
                                  column === "Assessment Name" ? "15%" : "12.14%",
                                backgroundColor: "#FAEFEF",
                              }}
                            >
                              {column}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>


                    <TableBody className="TableBody">
                      {filteredData
                        .slice(indexOfFirstAssessment, indexOfLastAssessment)
                        .map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              className={index % 2 === 0 ? "oddRow" : "evenRow"}
                            >
                              <TableCell className="tooltip">
                                {row.assessmentName.length > 20 ? (
                                  <tr>
                                    <td>
                                      {row.assessmentName.substring(0, 20)}...
                                    </td>
                                    <td>
                                      <span className="tooltiptext">
                                        {row.assessmentName}
                                      </span>
                                    </td>
                                  </tr>
                                ) : (
                                  <span>{row.assessmentName}</span>
                                )}
                              </TableCell>
                              <TableCell align="center" className="TableCell">
                                <span>{formatDateMonth(row.createdDate)}</span>
                              </TableCell>
                              <TableCell align="center" className="TableCell">
                                <span>{formatDateYear(row.createdDate)}</span>
                              </TableCell>
                              {/* todo, it is a static filed , it has to be come from candidate reports table */}
                              <TableCell align="center" className="TableCell">
                                <span>{row.passed}</span>
                              </TableCell>
                              <TableCell align={"center"} className="TableCell">
                                <span>{row.organizedby}</span>
                              </TableCell>
                              <TableCell align="center" className="TableCell" >
                                {/* todo, field is not present in Assessment Report */}
                                <LinearProgress
                                  variant="determinate"
                                  value={row.progress}
                                  color={
                                    row.progress == 100 ? "success" : "primary"
                                  }
                                />
                                {row.progress}%
                              </TableCell>
                              <TableCell align="center" className="TableCell">
                                <span>{row.invitedCount}</span>
                              </TableCell>
                              <TableCell align="center">
                              {row.invitedCount > 0 ? (
       
                                <Button onClick={() => fetchCandidateEmails(row.id)} style={{ padding: '2%', minWidth: 'unset' }}  > {<FileDownloadIcon />} </Button>
                                ) : (
                                  
                                  <Button
                                    disabled
                                    style={{ padding: '2%', minWidth: 'unset' }}
                                  >
                                    {<FileDownloadIcon />}
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell align="center" className="TableCell">
                                <a

                                  onClick={() => {
                                    if (permissions.includes("View Student Results") && row.progress > 0) {
                                      handleRowClick(row);
                                    }
                                  }}
                                >
                                  <WysiwygIcon
                                    onClick={() => {
                                      if (permissions.includes("View Student Results") && row.status !== "CREATED" && row.status !=="IN_COMPLETE") {
                                        handleRowClick(row);
                                      }
                                    }}
                                    style={{ color: permissions.includes("View Student Results") && row.status !== "CREATED" && row.status !=="IN_COMPLETE"  ? "fe524a" : "4F4F4F" }}
                                    fontSize="small"
                                  />
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>

                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Stack>
        </ThemeProvider>

        <SessionExtensionModal
          open={openSessionModal}
          onClose={handleModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>
        <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
      </div>
    </StyledEngineProvider>

    // </MyContext.Provider>
  );
};
