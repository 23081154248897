import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AssessmentInProgress from "./AssessmentInProgress";
import CandidateService from "./CandidateService";
import UserService from "src/clients/UserService";
import { formatDateStr } from "src/utils/ValidationUtils";

interface Assessment {
  id: number;
  assessmentName: string;
  noOfSections: number;
  questionsPerSection: number;
  totalTime: number;
  totalMarks: number;
  shuffleQuestionsStatus:boolean;
  section: {
    sectionId: number;
    totalTime: number;
    totalMarks: number;
    sectionName: string;
    skillName: string;
    assessmentQuestionDetail: {
      questionId: number;
      assessmentQuestionNumber: number;
    }[];
  }[];
}

interface AssessmentProps {
  assessmentId: number;
  candidateId: number;
}

function CandidateMain() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const assessmentId = searchParams.get("assessmentId");
  // const candidateid = searchParams.get("candidateId");
  // const candidateId = candidateid ? parseInt(candidateid) : 0;
  const candidateId = location.state?.candidateId;
  const assessmentId = location.state?.assessmentId;
  const configurations = location.state?.configurations;
  const navigate = useNavigate();
  const [flag, setflag] = useState<boolean>(false);
  // const [videoChunks,setVideoChunks] = useState<number>(10000);
  const [assessment, setAssessment] = useState<Assessment>({} as Assessment);

  useEffect(() => {
    // UserService.getConfigurationById(1).then((res) => {
     
    //   if(res.status === 200){
    //     console.log(res.data,"setVideoChunksConfiguration")
    //     setVideoChunks(res.data.configurationValue)
    //   }else{
    //     console.log("error");
    //   }
    // }).catch((error:any) => {

    //   console.log(error)
    // })
    const candidateObject:any = {
      id: candidateId,
      actualStartTimeStamp: formatDateStr(new Date(), 'YYYY-MM-DDTHH:mm:ss',true)
    } 
    CandidateService.assessmentStarted(candidateObject);
    const fetchData = async () => {
      const credentials = `${process.env.REACT_APP_CANDIDATE_USERNAME}:${process.env.REACT_APP_CANDIDATE_PASSWORD}`;

      const encodedCredentials = btoa(credentials);
      const config = {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      };
      try {
        // eslint-disable-next-line no-useless-concat
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/candidates/assessments/assessment-details-by-id/${assessmentId}`,
          config
        );
        const jsonData = await response.json();
        console.log(jsonData);
        setAssessment(jsonData);
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    };
    fetchData();
    CandidateService.CandidateCheckSubmit(candidateId).then((response) => {
      setflag(response.data);
    });
  }, []);

  if (!assessment || !assessment.section) {
    return <div>Loading...</div>; // or any loading component or message you want to show while fetching data
  }

  if (flag) {
    navigate("/Candidate", {
      state: {
        candidateId: candidateId,
        assessment: assessment,
        configurations: configurations
        // videoChunks:videoChunks
      },
    });
  }

  // if (!flag) {
  //   return (
  //     <div>
  //       <AssessmentInProgress />
  //     </div>
  //   ); // or any loading component or message you want to show while fetching data
  // }
  return (
    <>
      {/* {flag && (
        <Candidate assessment={assessment} candidateId={candidateId} configurations={configurations} />
      )} */}

      {!flag && <AssessmentInProgress />}
    </>
  );
}

export default CandidateMain;
