import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import UserService from "../../clients/UserService";
import { CreateUser } from "../admin/create-user/CreateUser";
import CreateMasterData from "../admin/edit-masterdata/CreateMasterData";
import { ForgotPassword } from "../admin/forgot-password/ForgotPassword";
import ListOfClients from "../admin/list-of-clients/ListOfClients";
import ConfigurationsList from "../admin/list-of-configurations/ConfigurationsList";
import ListOfUsers from "../admin/list-of-users/ListOfUsers";
import { ResetPassword } from "../admin/reset-password/ResetPassword";
import { RolesAndPermissions } from "../admin/roles-and-permissions/RolesAndPermissions";
import { Login } from "../admin/sign-in/Login";
import AssessmentDetails from "../assessment/assessment-details/AssessmentDetails";
import Assessments from "../assessment/assessments/Assessments";
import InviteCandidates from "../assessment/invite-candidates/InviteCandidates";
import PreviewQuestions from "../assessment/preview-assessment/PreviewAssessment";
import AssessmentInProgress from "../candidate/AssessmentInProgress";
import Candidate from "../candidate/Candidate";
import CandidateMain from "../candidate/CandidateMain";
import FeedbackScreen from "../candidate/FeedbackScreen";
import Instructions from "../candidate/Instructions";
import LinkExpired from "../candidate/LinkExpired";
import TermsConditions from "../candidate/TermsConditions";
import ResponsiveAppBar from "../candidate/navbar";
import BulkUpload from "../question-bank/bulk-upload/BulkUpload";
import Question from "../question-bank/create-question/CreateQuestion";
import QuestionBank from "../question-bank/filter-questions/QuestionBank";
import { AssessmentList } from "../reports/assessment-reports/AssessmentReports";
import CandidateReports from "../reports/candidate-reports/CandidateReports";
import CandidateSpecificReports from "../reports/candidate-specific-reports/CandidateSpecificReports";
import ViewSectionDetails from "../reports/view-section-details/ViewSectionDetails";
import SideMenu from "./NavbarAndSidebar";
import Plans from "../admin/list-of-plans/Plans";
import NoData from "../no-data/NoData";



const Routers = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);



  const [viewUserPermission, setviewUserPermission] = useState(false);


  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await UserService.getisAuthenticated();

      setIsAuthenticated(isAuthenticated);
    };

    if (
      location.pathname == "/" ||
      location.pathname == "/login" ||
      location.pathname == "/*"
    ) {

      localStorage.setItem("login", String(false));
    }

    // let role:boolean;
    // let viewUserPermission:boolean;
    const CurrentUser = localStorage.getItem("currentUser");

    if (CurrentUser != null) {
      const loginUser = JSON.parse(CurrentUser);
      setviewUserPermission(loginUser.permissionslist?.includes("View Users"));
   
    }

    fetchData();
  }, []);

  return (
    <Routes>
      <Route path="/forgotPassword" element={<ForgotPassword />} />

      <Route path="/CandidateMain" element={<CandidateMain />} />

      <Route path="/Feedbackscreen" element={<FeedbackScreen />} />

      <Route path="/TermsConditions" index element={<TermsConditions />} />

      <Route path = "/Candidate" element={<Candidate />} />

      <Route path="/Instructions" element={<Instructions />} />

      <Route path="/LinkExpired" element={<LinkExpired />} />
      <Route path="/AssessmentInProgress" element={<AssessmentInProgress />} />
      <Route path="/ResponsiveAppBar" element={<ResponsiveAppBar />} />

      {isAuthenticated ? (
        <>
          <Route
            path="/assessments"
            element={
              <AssessmentDetails
                mode={""}
                onBack={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />
          

          <Route path="/" element={<SideMenu />}>
            <Route path="/assessmentlist" element={<Assessments />} />
            <Route path="/configurations" element={<ConfigurationsList />}/>
            <Route path="/subscription-plans" element={<Plans />}/>
            <Route
              path="/questionbank"
              element={
                <QuestionBank
                  id={undefined}
                  mode={""}
                  onBack={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              }
            />

            <Route path="/invite-candidate" element={<InviteCandidates />} />
            <Route path="/no-data" element={<NoData />} />
            <Route path="/listOfClients" element={<ListOfClients />} />
            <Route
              path="/preview"
              element={
                <PreviewQuestions
                  assessmentId={undefined}
                  mode={""}
                  onBack={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              }
            />

            <Route path="/createQuestion" element={<Question questionId={undefined} assessmentId={undefined} headerId={undefined} mode={""} onBack={function (): void {
              throw new Error("Function not implemented.");
            } } setQuestionId={undefined} />} />

            <Route path="/bulk-upload" element={<BulkUpload />} />

            <Route path="/assessment" element={<AssessmentList />} />

            <Route
              path="/specificReport"
              element={<CandidateSpecificReports />}
            />

            <Route
              path="/viewSectionDetails"
              element={<ViewSectionDetails />}
            />


            <Route path="/collegeResult" element={<CandidateReports />} />

            {{ viewUserPermission } ? (
              <>
                <Route path="/listOfUsers" element={<ListOfUsers />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
              </>
            )}

      
            <Route path="/editMasterData" element={<CreateMasterData/>}/>

            <Route path="/createUser" element={<CreateUser />} />

            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route
              path="/rolesAndPermissions"
              element={<RolesAndPermissions />}
            />

            <Route path="/" element={<Navigate to="/login" replace />} />

            <Route path="/*" element={<Login />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />

          {/* <Route path="/login" element={<Login />} /> */}

          <Route path="/*" index element={<Login />} />
        </>
      )}
    </Routes>
  );
};

export default Routers;
