import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../filter-questions/styles/question-bank.css";
// import QuestionBankClient from "../../clients/QuestionBankClient";

interface SelectComponentProps {
  selectUseState: string[];
  setSelectUseState: React.Dispatch<React.SetStateAction<string[]>>;
  array: string[];
  title: string;
  handleSkills?: string[] | undefined;
}

const SelectComponent: React.FC<SelectComponentProps> = (props) => {

  const [expand,setExpand] = useState<boolean>(false);
  // if(props.handleSkills){
  //   props.setSelectUseState(props.handleSkills)
  // }
  const addCheckbox = (value: string) => {
    if (props.selectUseState.includes(value)) {
      props.setSelectUseState((data) => {
        return data.filter((data_value) => data_value !== value);
      });
    } else {
      props.setSelectUseState([...props.selectUseState, value]);
    }
  };

  return (
    <Accordion onChange={() => setExpand(!expand)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fe524a" }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.array.map((data) => (
          <Typography
            key={data}
            style={{ display: "flex", alignItems: "center", color: "#191F1C" }}
          >
            <Checkbox
              sx={{ color: "#fe524a" }}
              size="small"
              checked={props.selectUseState.includes(data)}
              onChange={() => addCheckbox(data)}
              disabled={
                props.handleSkills !== undefined &&
                props.handleSkills.length > 0 &&
                !props.handleSkills
                  .map((skill) => skill.toLowerCase())
                  .includes(data.toLowerCase())
              }
            />
            {data}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SelectComponent;
