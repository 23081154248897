import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import logo from "../assets/rs_logo.png";
import { useEffect, useState } from "react";
import { ClientInterface } from "../admin/dummydata/ClientInterface";
import { getClientThankYouData } from "src/clients/CandidateClient";

const useStyles = {
  root: {
    display: "flex",

    flexDirection: "column",

    alignItems: "center",

    justifyContent: "center",

    minHeight: "100vh",
  },

  textField: {
    margin: 2,

    width: 600,

    "& .MuiInputBase-input": {
      borderBottom: "1px solid #FE524A",
    },

    "& .MuiInputBase-input:focus": {
      borderBottom: "2px solid #FE524A",
    },
  },

  buttonContainer: {
    display: "flex",

    justifyContent: "flex-end",

    marginTop: 2,

    width: "50%", // Added width to take full width of the container

    marginBottom: 2,
  },

  button: {
    display: "flex",

    float: "right",

    color: "#FE524A",

    backgroundColor: "transparent",

    border: "1px solid #FE524A",

    marginRight: 2,

    marginBottom: 2,

    "&:hover": {
      backgroundColor: "#FE524A",

      color: "#FFFFFF",

      border:'#FE524A',
    },
  },
};

const FeedbackScreen = () => {
  const handleSkip = () => {
    localStorage.removeItem("clientName")
    localStorage.removeItem("assessmentName")
    localStorage.removeItem("candidateName")
    localStorage.removeItem("videoChunks")
    window.close();
  };

  const comQuestion = (value: string) => {
    return (
      <Typography
        style={{ margin: "0", padding: "0", lineHeight: "auto" }}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  };

  const [organization,setOrganzation] = useState<ClientInterface>({
    id: 0,
    clientName: '',
    clientId:'',
    clientStatus:'',
    clientLogo: '',
    autoRenew: false
  });

  const [thankYouData,setThankYouData] = useState<string[]>([]);
  
  useEffect(() => {
    
  
    const assessmentName = localStorage.getItem("assessmentName")
   
    console.log("asssemsnserib",assessmentName)
    console.log(thankYouData)
    getClientThankYouData(assessmentName??'')
      .then((response) => response.json())
      .then((data) => {
        console.log(data,"data");
        setOrganzation(data);
        if(data.thankYouInformation !== null)
          setThankYouData(data.thankYouInformation.split("\\n"));
      })
      .catch((error) => {
        console.error("Error capturing video:", error);
      });
  },[])

  return (
    <Box sx={useStyles.root}>
      <Card sx={{ width: "80%" }}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box sx={{ float: "right" ,marginTop:'1%',marginRight:'0.5%'}}>
              <img
               src={organization.clientLogo === '' || organization.clientLogo === null || organization.clientLogo === undefined?logo:`data:image/png;base64,${organization.clientLogo}`}
                // src={logo}
                alt="red-shift logo"
                style={{ width: "100px", height: "50px" }}
              />
            </Box>
          </Grid>

          <CardContent sx={{ maxWidth: 960, padding: "24px" }}>
            <Typography variant="body1" component="p">
              {thankYouData.length === 0 || thankYouData[0] === ""?
              `We want to express our sincere gratitude for taking the time to
              complete the assessment for ${organization.clientName === ''?`Red-Shift`:organization.clientName}. Your participation is
              highly valued, and we appreciate your commitment and effort
              throughout the assessment process.`:comQuestion(thankYouData[0])

  }
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{ marginTop: "16px" }}
            >
              {thankYouData.length===0 || thankYouData[1] === ""?
              `Should you have any questions or require further information
              regarding the assessment or the next steps in the recruitment
              process, please do not hesitate to reach out to us. We are here to
              assist you and provide any necessary support.`: comQuestion(thankYouData[1])}
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{ marginTop: "16px" }}
            >
              {thankYouData.length===0 || thankYouData.slice(2,thankYouData.length).length === 0 ?
              ` Once again, thank you for your participation and for considering
              ${organization.clientName === ''?`Red-Shift`:organization.clientName} as part of your career journey. We wish you the best of
              luck, and we look forward to reviewing your results.`:comQuestion(thankYouData.slice(2,thankYouData.length).join(" "))
            }
             
            </Typography>

            <Button
              sx={useStyles.button}
              variant="outlined"
              color="primary"
              onClick={handleSkip}
            >
              Close
            </Button>
          </CardContent>
        </Grid>
      </Card>
    </Box>
  );
};

export default FeedbackScreen;
