import { Category, FileUploadOutlined } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "../filter-questions/styles/question-bank.css";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import QuestionBankClient from "../../../clients/QuestionBankClient";
import SelectComponent from "./SelectComponent";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "src/components/pagination/Pagination";
import { dsgTheme } from "../../../styles/theme";
import { CustomModal } from "src/components/modal/CustomModal";
import PreviewQuestions from "../../assessment/preview-assessment/PreviewAssessment";
import CreateQuestion from "../create-question/CreateQuestion";
import TokenService from "src/clients/TokenService";
import UserService from "src/clients/UserService";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import ViewDetails from "../AuditDetails";
import { SessionExpiredModal } from "src/components/admin/list-of-users/SessionExpiredModal";

interface QuestionBank {
  questionId: number;
  questionText: string;
  options: Options[];
  questionType: string;
  difficulty: string;
  skill: string;
  addInfo: string;
  createdDate: Date | string;
  createdBy: string;
  updatedDate: Date | string;
  updatedBy: String;
  negativeMarks: number;
  positiveMarks: number;
  timeToSolve: number;
  baseFlag: boolean;
  tags: string;
  organization: string;
  visibility: string;
}

interface Options {
  optionId: number;
  optionValue: string;
  answerFlag: string;
}

interface AssessmentQuestions {
  assessmentId: number;
  sections: {
    sectionId: number;
    questions: SkillAndQuestion[];
    totalMarks: number;
  }[];
}

interface SkillAndQuestion {
  skill: string;
  question: number;
}
interface QuestionsProps {
  id: any;
  mode: string;
  onBack: () => void;
}
interface QuestionBankSerachFilter {
  tags: string;
}

const QuestionBank = ({ id, mode, onBack: onBack }: QuestionsProps) => {
  //get the id from AssessmentDetails

  const location = useLocation();
  const [questionId, setQuestionId] = useState<number | undefined>(undefined);

  const viewMode =
    mode === "edit" || mode === "add" || mode === "clone" ? true : false;

  const navigate = useNavigate();

  const [questionIds, setQuestionIds] = useState<number[]>([]);

  const [showUser, setShowUser] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [difficulty, setDifficulty] = useState<string[]>([]);

  const [questionType, setQuestionType] = useState<string[]>([]);
  const [category, setCategory] = useState<string[]>([]);
  const [filters, setFilters] = useState<QuestionBankSerachFilter>({
    tags: "",
  });

  let userNamearray: string[] = [];
  const user = localStorage.getItem("currentUser");
  let userName;
  let userOrganization: string;
  let roleName;
  if (user) {
    userName = JSON.parse(user).userName;
    if (JSON.parse(user).role.roleName) {
      roleName = JSON.parse(user).role.roleName;
    }
    userOrganization = JSON.parse(user).clientPlans.clients.clientName;
  }

  userNamearray.push(userName);
  const [createdBy, setCreatedBy] = useState<string[]>([]);

  const [difficultyArray, setDifficultyArray] = useState<string[]>([]);
  const [categoryArray, setCategoryArray] = useState<string[]>([]);

  const [skillsArray, setSkillsArray] = useState<string[]>([]);
  let [saveAndContinueButton, setSaveAndContinueButton] =
    useState<boolean>(false);
  const [sectionListArray, setSectionListArray] = useState<string[]>([]);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [QuestionTypeArray, setQuestionTypeArray] = useState<string[]>([]);
  const isInputValid = (input: any) => input.length >= 3;
  const [handleSkills, setHandleSkills] = useState<string[]>([]);

  const [skills, setSkills] = useState<string[]>([]);
  const [selectSection, setSelectSection] = useState<string>("");
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] =
    useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [sections, setSections] = useState<any[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [selectedSectionSkillName, setSelectedSectionSkillName] =
    useState<string>("");

  const [showCreateQuestion, setShowCreateQuestion] = useState<boolean>(false);

  const [questionsPerSection, setQuestionPerSection] = useState(0);

  const comQuestion = (value: string) => {
    return <span dangerouslySetInnerHTML={{ __html: value }} />;
  };

  const [questionsData, setQuestionsData] = useState<QuestionBank[]>([]);

  const [singleQuestionsData, setSingleQuestionsData] =
    useState<QuestionBank>();

  const [rows, setRows] = useState(viewMode ? "4" : "3");
  const [deletedQuestion, setDeletedQuestion] = useState<number>();
  const rowsPerPageArray = viewMode ? ["4", "6", "11"] : ["3", "5", "10"];

  const [rawData, setRawData] = useState<QuestionBank[]>([]);

  const [assessmentQuestionsJson, setAssessmentQuestionJson] =
    useState<AssessmentQuestions>();
  let updatedQuestionJson: AssessmentQuestions = {
    assessmentId: id ? id : -1,
    sections: [],
  };

  const [hoverStates, setHoverStates] = useState<boolean[]>(
    Array(questionsData ? questionsData.length : 0).fill(false)
  );

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [checkSkills, setCheckSkills] = useState<any[]>([]);
  const [reload,setReload] = useState<boolean>(false);
  const questionsPerPage = Number(rows);

  const indexOfLastQuestion = currentPage * questionsPerPage;

  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const [relatedTags, setRelatedTags] = useState<string[]>([]);
  const [showTagPrompt, setShowTagPrompt] = useState(false);

  const filterArray = [
    ...skills,
    ...difficulty,
    ...questionType,
    ...createdBy,
    ...category,
  ];
  let createQuestionPermission: boolean = false;
  let editQuestionPermission: boolean = false;
  let deleteQuestionPermission: boolean = false;

  const CurrentUser = localStorage.getItem("currentUser");

  if (CurrentUser != null) {
    const loginUser = JSON.parse(CurrentUser);
    createQuestionPermission =
      loginUser.permissionslist?.includes("Create Question");
    editQuestionPermission =
      loginUser.permissionslist?.includes("Edit Question");
    deleteQuestionPermission =
      loginUser.permissionslist?.includes("Delete Question");
  }

  const handleFilterChange = (property: string, value: string) => {
    setCurrentPage(1);
    setFilters((prev) => ({ ...prev, [property]: value }));
  };

  const applyFilters = (
    data: QuestionBank[],
    filter: QuestionBankSerachFilter
  ): QuestionBank[] => {
    return data.filter((item) => {
      if (
        filter.tags &&
        !item.tags.toLowerCase().includes(filter.tags.toLowerCase())
      ) {
        return false;
      }

      return true;
    });
  };
  let filteredData: QuestionBank[] = applyFilters(questionsData, filters);

  const currentQuestions: QuestionBank[] = filteredData.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );
  useEffect(() => {
    const data = sections
      .find((section) => {
        return section.sectionName === selectSection;
      })
      ?.skillName.split(",");
    const skillsArray = Array.isArray(data) ? data : [];

    setSkills(skillsArray);
    setHandleSkills(skillsArray);
  }, [selectSection]);

  useEffect(() => {
    setHoverStates(Array(questionsData ? questionsData.length : 0).fill(false));
  }, [currentPage, showCreateQuestion]);

  useEffect(() => {
    sections.filter((section) => {
      if (section.sectionName == selectSection) {
        setSelectedSectionSkillName(section.skillName);
      }
    });
  }, [selectSection]);

  useEffect(() => {
    if (viewMode) {
      QuestionBankClient.getAssessmentHeader(id)

        .then((response) => {
          setQuestionPerSection(response.data.questionsPerSection);

          setSections(response.data.section);
          setSelectSection(response.data.section[0].sectionName);

          const filterSectionName = response.data.section.flatMap(
            (section: any) => section.sectionName
          );

          setSectionListArray(filterSectionName);

          const assessmentSections = response.data.section;

          const extractedQuestionIds = assessmentSections.flatMap(
            (section: any) =>
              section.assessmentQuestionDetail.map(
                (detail: any) => detail.questionId
              )
          );

          setQuestionIds(extractedQuestionIds);

          const skills = response.data.section.map((section: any) =>
            section.skillName.split(",")
          );
          setCheckSkills(skills);
          //   setHandleSkills(skills);
          //   setSkills(skills);
        })

        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSessionModal(true);
          } else if (error.response.status === 402) {
            setClientPlanExpiredModal(true);
          } else
            console.log(
              "The header which is not present in database 500 error"
            );
        });
    }
  }, [id, viewMode, showPreview]);

  useEffect(() => {
    setSaveAndContinueButton(
      checkSkills.every((skills, index) => {
        return skills.every((skill: string) => {
          return assessmentQuestionsJson?.sections[index].questions.some(
            (questionSkill) => {
              return skill
                .toUpperCase()
                .includes(questionSkill.skill.toUpperCase());
            }
          );
        });
      })
    );
  }, [assessmentQuestionsJson]);

  useEffect(() => {
    const selectedQuestions = questionIds;

    const storedPrevSelectTheQuestions = localStorage.getItem(
      "prevSelectTheQuestions"
    );

    if (storedPrevSelectTheQuestions) {
      const prevSelectTheQuestions = JSON.parse(storedPrevSelectTheQuestions);
      setAssessmentQuestionJson(prevSelectTheQuestions);
    }
  }, [questionIds]);

  useEffect(() => {
    QuestionBankClient.getQuestions(
      skills.length > 0 ? skills : handleSkills,
      questionType,
      category,
      difficulty
    )
      .then((response) => setRawData(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log("Internal server error");
        }
      });
    QuestionBankClient.getFilterType("category")

      .then((res) => setCategoryArray(res.data))

      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log("Internal server error");
        }
      });

    QuestionBankClient.getFilterType("difficulty")

      .then((res) => setDifficultyArray(res.data))

      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log("Internal server error");
        }
      });

    QuestionBankClient.getFilterType("skill")

      .then((res) => {
        setSkillsArray(res.data);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log("Internal server error");
        }
      });

    QuestionBankClient.getFilterType("QuestionType")

      .then((res) => setQuestionTypeArray(res.data))
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log("Internal server error");
        }
      });
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      localStorage.removeItem("prevSelectTheQuestions");

      setAssessmentQuestionJson(undefined);
    };
  });

  const callQuestionsApi = (
    skillType: any[],
    qType: any[],
    category: any[],
    difficultyType: any[]
  ) => {
    QuestionBankClient.getQuestions2(
      skillType,
      qType,
      difficultyType,
      category,
      createdBy
    )
      .then((response) => {
        const selectedQuestions = response.data.filter(
          (question: QuestionBank) => questionIds.includes(question.questionId)
        );
        const unSelectedQuestions = response.data.filter(
          (question: QuestionBank) => !questionIds.includes(question.questionId)
        );
        const sortedQuestions = [...selectedQuestions, ...unSelectedQuestions];
        setQuestionsData(sortedQuestions);

        setCurrentPage(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log("Internal server error");
        }
      });
  };

  useEffect(() => {
    if (!viewMode || skills.length > 0) {
      callQuestionsApi(skills, questionType, category, difficulty);
    } else if (handleSkills.length > 0) {
      callQuestionsApi(handleSkills, questionType, category, difficulty);
    }
  }, [
    skills,
    questionType,
    difficulty,
    showCreateQuestion,
    createdBy,
    category,
  ]);

  useEffect(() => {
    const mappedData: AssessmentQuestions = {
      assessmentId: id ? id : -1, // You can set the appropriate assessmentId here
      sections: sections.map((section) => ({
        sectionId: section.id,
        questions: section.assessmentQuestionDetail.map((question: any) => ({
          question: question.questionId,
          skill: question.skillName,
        })),
        totalMarks: section.totalMarks,
      })),
    };
    // Create a section object and push it to the updatedQuestionJson
    setAssessmentQuestionJson(mappedData);
  }, [sections]);

  useEffect(() => {
    if (!viewMode || skills.length > 0) {
      callQuestionsApi(skills, questionType, category, difficulty);
    } else if (handleSkills.length > 0) {
      callQuestionsApi(handleSkills, questionType, category, difficulty);
    }
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setRows(event.target.value);

    setCurrentPage(1);
  };

  const deleteChip = (showList: any) => {
    setSkills((data) => {
      return data.filter((skill) => skill !== showList);
    });

    //callQuestionsApi(skills, questionType, difficulty);
    setDifficulty((data) => {
      return data.filter((difficulty) => difficulty !== showList);
    });

    setQuestionType((data) => {
      return data.filter((questionType) => questionType !== showList);
    });
    setCategory((data) => {
      return data.filter((category) => category !== showList);
    });

    setCreatedBy((data) => {
      return data.filter((createdBy) => createdBy !== showList);
    });
  };

  const showingCompleteQuestion = (index: number) => {
    setHoverStates((prevHoverStates) => {
      const updatedHoverStates = [...prevHoverStates];

      updatedHoverStates[index] = !prevHoverStates[index];

      return updatedHoverStates;
    });
  };

  const callApi = () => {
    
    const updatedAssessmentQuestionsJson: AssessmentQuestions = {
      assessmentId: id ? id : -1, // Replace with a valid assessmentId
      sections: [...(assessmentQuestionsJson?.sections || [])], // Copy existing sections or initialize an empty array
    };

    assessmentQuestionsJson?.sections.map((section, index) => {
      let totalData = 0;
      section.questions.map((assessmentObject) => {
        const eachQuestionMark = rawData.find(
          (questionObject) =>
            questionObject.questionId === assessmentObject.question
        )?.positiveMarks;

        totalData += eachQuestionMark === undefined ? 0 : eachQuestionMark;
      });
      if (totalData !== undefined) {
        totalData = Number(totalData);
        if (
          assessmentQuestionsJson &&
          assessmentQuestionsJson.sections &&
          assessmentQuestionsJson.sections[index]
        ) {
          updatedAssessmentQuestionsJson.sections[index].totalMarks = totalData;
        }
      }
    });
    setReload(true);
    setAssessmentQuestionJson(updatedAssessmentQuestionsJson);

    QuestionBankClient.saveQuestions(assessmentQuestionsJson)
      .then((response) => {
        if (response.status === 200) {
          setReload(false)
          toast.success("Questions saved Successfully", { autoClose: 1000 });

          localStorage.removeItem("prevSelectTheQuestions");

          setAssessmentQuestionJson(undefined);

          setShowPreview(true);
        }
      })

      .catch((error) => {
        if (error.response.status === 401) {
          setReload(false)
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setReload(false)
          setClientPlanExpiredModal(true);
        } else {
          setReload(false)
          console.log("Internal server error");
        }
      });
  };

  let targetSectionIndex = sections.findIndex((section) => {
    return section.sectionName === selectSection;
  });

  const handleCheckboxChange = (dummy: { id: number }) => {
    const questionObject = filteredData.find(
      (data) => data.questionId === dummy.id
    );
    if (questionObject !== undefined)
      AddOrRemoveQuestionFromJson(questionObject, "not all");
  };

  const clearAllSelectComponents = () => {
    setDifficulty([]);

    setSkills([]);

    setQuestionType([]);
    setCategory([]);

    setCreatedBy([]);
  };

  const handleDeleteQuestion = (id: number) => {
    setDeleteId(id);
    setOpenDeleteQuestionModal(true);
  };
  const handleUserInfo = (a: any) => {
    setShowUser(true);
    setSingleQuestionsData(a);
  };

  const goToLoginPage = () => {
    navigate("/login");
    window.location.reload();
  };
  const handleModalCancel = () => {
    setOpenDeleteQuestionModal(false);
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  const handleSessionExtentionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };
  const handleModalConfirm = async () => {
    setOpenDeleteQuestionModal(false);
    QuestionBankClient.deleteQuestionById(deleteId)
      .then((response) => {
        if (response.status === 200) {
          setQuestionsData((data) =>
            data.filter((question) => question.questionId !== deleteId)
          );
          setCurrentPage(1);
          toast.success("Question Deleted successfully", { autoClose: 1000 });
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          toast.error("Something went wrong", { autoClose: 1000 });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          toast.error("Internal Server error", { autoClose: 1000 });
        }
      });
  };

  const AddOrRemoveQuestionFromJson = (
    question: QuestionBank,
    stringValue: string
  ) => {
    const updatedAssessmentQuestionsJson: AssessmentQuestions = {
      assessmentId: id ? id : -1, // Replace with a valid assessmentId
      sections: [...(assessmentQuestionsJson?.sections || [])], // Copy existing sections or initialize an empty array
    };

    const findQuestionInAnotherSection = assessmentQuestionsJson?.sections.some(
      (section) => {
        return (
          targetSectionIndex !== undefined &&
          assessmentQuestionsJson.sections[targetSectionIndex].sectionId !==
            section.sectionId &&
          section.questions.some((questionObject) => {
            return questionObject.question === question.questionId;
          })
        );
      }
    );

    if (
      targetSectionIndex !== undefined &&
      targetSectionIndex !== -1 &&
      !findQuestionInAnotherSection
    ) {
      const questionIndex = assessmentQuestionsJson?.sections[
        targetSectionIndex
      ]?.questions.findIndex(
        (findQuestion) => findQuestion.question === question.questionId
      );

      if (
        questionIndex !== -1 &&
        questionIndex !== undefined &&
        stringValue.includes("not all")
      ) {
        updatedAssessmentQuestionsJson.sections[targetSectionIndex].totalMarks =
          updatedAssessmentQuestionsJson.sections[targetSectionIndex]
            .totalMarks - question.positiveMarks;
        updatedAssessmentQuestionsJson.sections[
          targetSectionIndex
        ].questions.splice(questionIndex, 1);
      } else if (
        questionIndex !== -1 &&
        questionIndex !== undefined &&
        stringValue.includes("all")
      ) {
      } else {
        updatedAssessmentQuestionsJson.sections[targetSectionIndex].totalMarks =
          updatedAssessmentQuestionsJson.sections[targetSectionIndex]
            .totalMarks + question.positiveMarks;
        updatedAssessmentQuestionsJson?.sections[
          targetSectionIndex
        ]?.questions.push({
          skill: question?.skill,
          question: question?.questionId,
        });
      }
      setAssessmentQuestionJson(updatedAssessmentQuestionsJson);
    }
  };

  const selectAllQuestions = (value: boolean) => {
    if (value) {
      currentQuestions.map((question) => {
        AddOrRemoveQuestionFromJson(question, "all");
      });
    } else {
      currentQuestions.map((question) => {
        AddOrRemoveQuestionFromJson(question, "not all");
      });
    }
  };

  const handleSectionList = (event: SelectChangeEvent) => {
    setSelectSection(event.target.value as string);
  };

  
  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      {!showPreview && !showCreateQuestion && (
        <ThemeProvider theme={dsgTheme}>
          <Box
            className="container"
            marginRight="1%"
          >
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <Typography variant="h5">Question Bank</Typography>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Box sx={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    // className="buttonStyle"
                    disabled={viewMode}
                    onClick={() => {
                      localStorage.setItem(
                        "prevSelectTheQuestions",

                        JSON.stringify(assessmentQuestionsJson)
                      );

                      navigate("/bulk-upload", {
                        state: { headerId: id, mode: location.state?.mode },
                      });
                    }}
                  >
                    <FileUploadOutlined />
                    Upload in bulk
                  </Button>
                  {createQuestionPermission && (
                    <Button
                      // className="buttonStyle"
                      variant="outlined"
                      disabled={viewMode}
                      sx={{ ml: "10px" }}
                      onClick={() => {
                        localStorage.setItem(
                          "prevSelectTheQuestions",
                          JSON.stringify(assessmentQuestionsJson)
                        );

                        setShowCreateQuestion(true);
                      }}
                    >
                      <EditIcon />
                      Create Question
                    </Button>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={3}
                md={3}
                sx={{ mt: { md: "5.8%", xs: "13%" } }}
              >
                <Stack sx={{ direction: "flex", alignItems: "center" }}>
                  <Stack
                    spacing={2}
                    width="70%"
                  >
                    {viewMode && (
                      <Card>
                        {/* <Typography sx={{ padding: '10px', fontWeight: 'bold' }}>Skills</Typography> */}
                        <TableContainer
                          component={Paper}
                          sx={{ maxHeight: "8rem" }}
                        >
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Section
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Skills
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell sx={{ minWidth: 60 }}>
                                  {selectSection}
                                </TableCell>
                                <TableCell sx={{ minWidth: 10 }}>
                                  {selectedSectionSkillName
                                    .split(",")
                                    .map(
                                      (skill: string, skillIndex: number) => {
                                        const isSkillFound =
                                          assessmentQuestionsJson?.sections?.[
                                            targetSectionIndex
                                          ]?.questions?.some((findSkill) =>
                                            findSkill.skill.includes(skill)
                                          );

                                        const color = isSkillFound
                                          ? "green"
                                          : "red";

                                        return (
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              color,
                                            }}
                                            key={skillIndex}
                                          >
                                            {skillIndex > 0 && <span>, </span>}
                                            {skill + " "}
                                          </span>
                                        );
                                      }
                                    )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    )}

                    {filterArray.length > 0 && (
                      <Card sx={{ p: "2%" }}>
                        <Typography
                          sx={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",

                              fontSize: "15px",

                              ml: "3%",

                              mb: "4%",
                            }}
                          >
                            Filters
                          </Typography>

                          <Typography
                            sx={{
                              // color: "#2874f0",

                              color: "#fe524a",

                              fontSize: "12px",

                              cursor: "pointer",

                              mr: "2%",

                              mt: "1%",

                              mb: "4%",
                            }}
                            onClick={clearAllSelectComponents}
                          >
                            CLEAR ALL
                          </Typography>
                        </Typography>

                        {filterArray.map(
                          (showList, index) =>
                            (index < 4 || showMore) && (
                              <Chip
                                key={showList} // Make sure to provide a unique key for each mapped item
                                label={showList}
                                sx={{ m: "3px" }}
                                onDelete={() => deleteChip(showList)}
                              />
                            )
                        )}

                        {filterArray.length > 4 && (
                          <Typography
                            onClick={() => setShowMore(!showMore)}
                            sx={{
                              // color: "#2874f0",

                              color: "#fe524a",

                              fontSize: "13px",

                              mt: "3%",

                              ml: "2%",

                              cursor: "pointer",

                              textDecoration: "underline",
                            }}
                          >
                            {showMore ? "Show Less" : "Show More"}
                          </Typography>
                        )}
                      </Card>
                    )}
                    <Autocomplete
                      sx={{ width: "100%" }}
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      value={filters.tags}
                      onChange={(e, value) => handleFilterChange("tags", value)}
                      onInputChange={(e, value) => {
                        handleFilterChange("tags", value);
                      }}
                      options={
                        filters.tags.length > 1
                          ? Array.from(
                              new Set(
                                questionsData?.map((question) => question.tags)
                              )
                            )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Tags"
                          name="tags"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                    {viewMode && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sections
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectSection}
                          label="Sections"
                          IconComponent={KeyboardArrowDownIcon}
                          onChange={handleSectionList} // Update state on change
                        >
                          {sectionListArray.map((sectionList) => (
                            <MenuItem
                              key={sectionList}
                              value={sectionList}
                              sx={{ ml: "2%" }}
                            >
                              {sectionList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <SelectComponent
                      selectUseState={difficulty}
                      setSelectUseState={setDifficulty}
                      array={difficultyArray}
                      title="Difficulty"
                    />

                    <SelectComponent
                      selectUseState={skills}
                      setSelectUseState={setSkills}
                      array={skillsArray}
                      title="Skills"
                      handleSkills={
                        handleSkills.length !== 0 ? handleSkills : undefined
                      }
                    />

                    <SelectComponent
                      selectUseState={questionType}
                      setSelectUseState={setQuestionType}
                      array={QuestionTypeArray}
                      title="Question Type"
                    />
                    {/* <SelectComponent
                      selectUseState={createdBy}
                      setSelectUseState={setCreatedBy}
                      array={categoryArray}
                      title="Category"
                    /> */}
                    <SelectComponent
                      selectUseState={category}
                      setSelectUseState={setCategory}
                      array={categoryArray}
                      title="Category"
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid
                item
                xs={9}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ mt: "3%" }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    marginBottom="2%"
                    alignItems="center"
                  >
                    <Box sx={{ marginRight: "auto" }}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography sx={{ fontSize: "small" }}>
                          Questions per page
                        </Typography>

                        <FormControl variant="standard">
                          <Select
                            disableUnderline={true}
                            value={rows}
                            label="Age"
                            onChange={handleChange}
                            sx={{
                              fontSize: "13px",
                            }}
                          >
                            {rowsPerPageArray.map((value, index) => (
                              <MenuItem
                                value={value}
                                key={value + String(index)}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <Typography sx={{ color: "#191F1C" }}>
                          {indexOfLastQuestion > 0
                            ? indexOfFirstQuestion + 1
                            : 0}
                          -
                          {indexOfLastQuestion > questionsData.length
                            ? indexOfLastQuestion -
                              (indexOfLastQuestion - questionsData.length)
                            : indexOfLastQuestion}{" "}
                          out of {filteredData.length}
                        </Typography>
                      </Stack>
                    </Box>

                    <Pagination
                      rows={rows}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalDataLength={filteredData.length}
                      dataPerPage={questionsPerPage}
                    />
                  </Stack>
                </Grid>

                {viewMode && (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                  >
                    <Checkbox
                      size="small"
                      className="color-red"
                      disabled={assessmentQuestionsJson?.sections.some(
                        (section) => {
                          return (
                            targetSectionIndex !== undefined &&
                            assessmentQuestionsJson.sections[targetSectionIndex]
                              .sectionId !== section.sectionId &&
                            section.questions.some((question) => {
                              return currentQuestions.some((questionObject) => {
                                return (
                                  questionObject.questionId ===
                                  question.question
                                );
                              });
                            })
                          );
                        }
                      )}
                      checked={currentQuestions.every((obj) => {
                        return assessmentQuestionsJson?.sections[
                          targetSectionIndex
                        ].questions.some((item) => {
                          return item.question === obj.questionId;
                        });
                      })}
                      onChange={(event) =>
                        selectAllQuestions(event.target.checked ? true : false)
                      }
                    />

                    <Typography className="color-black">Select All</Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                >
                  <Box sx={{ width: "100%" }}>
                    {currentQuestions.length > 0 &&
                      currentQuestions.map((questions, index) => (
                        <Card
                          key={questions.questionId}
                          className="questionCard"
                        >
                          <Grid
                            item
                            xs={12}
                            display="flex"
                          >
                            <Grid
                              item
                              xs={0}
                            >
                              {viewMode && (
                                <Checkbox
                                  className="color-red"
                                  checked={assessmentQuestionsJson?.sections[
                                    targetSectionIndex
                                  ].questions.some((question) => {
                                    return (
                                      question.question === questions.questionId
                                    );
                                  })}
                                  disabled={assessmentQuestionsJson?.sections.some(
                                    (section) => {
                                      return (
                                        targetSectionIndex !== undefined &&
                                        assessmentQuestionsJson.sections[
                                          targetSectionIndex
                                        ].sectionId !== section.sectionId &&
                                        section.questions.some(
                                          (questionObject) => {
                                            return (
                                              questionObject.question ===
                                              questions.questionId
                                            );
                                          }
                                        )
                                      );
                                    }
                                  )}
                                  size="small"
                                  onChange={() => {
                                    handleCheckboxChange({
                                      id: questions.questionId,
                                    });
                                  }}
                                />
                              )}
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sx={{ mt: "1%" }}
                            >
                              <Grid
                                xs={12}
                                item
                                sx={{ display: "flex", cursor: "pointer" }}
                              >
                                <Grid
                                  xs={12}
                                  item
                                  onClick={() => {
                                    showingCompleteQuestion(index);
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      mb: "1%",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      sx={{ height: "auto" }}
                                      alignItems="baseline"
                                      className="questionsOptions"
                                    >
                                      <Typography>{`${
                                        index +
                                        1 +
                                        (currentPage - 1) * questionsPerPage
                                      }: `}</Typography>

                                      {comQuestion(questions.questionText)}
                                    </Box>
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={0}
                                  item
                                  display="flex"
                                  // justifyContent="space-between"
                                >
                                  {!viewMode && (
                                    <Stack
                                      spacing={1}
                                      direction="row"
                                    >
                                      {questions.baseFlag == false &&
                                        editQuestionPermission && 
                                    
                                            <EditOutlinedIcon fontSize="small" onClick={() => {
                                              setShowCreateQuestion(true);
                                              setQuestionId(
                                                questions.questionId
                                              );
                                            }}
                                            className="buttonStyle"
                                            />
                                          
                                        }
                                      {/* {questions.baseFlag == true &&
                                        editQuestionPermission &&
                                        userOrganization ==
                                          "Red-Shift Innovations Private Ltd" && (
                                          <Button
                                            onClick={() => {
                                              setShowCreateQuestion(true);
                                              setQuestionId(
                                                questions.questionId
                                              );
                                            }}
                                            className="delete-and-edit"
                                          >
                                            <EditOutlinedIcon fontSize="small" />
                                          </Button>
                                        )} */}

                                      {deleteQuestionPermission &&
                                        questions.baseFlag === false && (
                                          
                                            <DeleteOutlineIcon fontSize="small" onClick={() => {
                                              handleDeleteQuestion(
                                                questions.questionId
                                              );
                                              setDeletedQuestion(
                                                index +
                                                  1 +
                                                  (currentPage - 1) *
                                                    questionsPerPage
                                              );
                                            }}
                                            className="buttonStyle"
                                            />
                                          
                                        )}
                                      {/* {questions.baseFlag == true &&
                                        deleteQuestionPermission &&
                                        userOrganization ==
                                          "Red-Shift Innovations Private Ltd" && (
                                          <Button
                                            onClick={() => {
                                              handleDeleteQuestion(
                                                questions.questionId
                                              );
                                              setDeletedQuestion(
                                                index +
                                                  1 +
                                                  (currentPage - 1) *
                                                    questionsPerPage
                                              );
                                            }}
                                            className="delete-and-edit"
                                          >
                                            <DeleteOutlineIcon fontSize="small" />
                                          </Button>
                                        )} */}
                                    </Stack>
                                  )}
                                </Grid>
                              </Grid>

                              {questions.options.map((options, optionIndex) => (
                                <Typography
                                  key={options.optionId}
                                  fontSize="15px"
                                >
                                  {hoverStates[index] ? (
                                    <Box
                                      display="flex"
                                      sx={{ minHeight: "30px" }}
                                      alignItems="baseline"
                                      className="questionsOptions"
                                    >
                                      <Typography>
                                        {String.fromCharCode(65 + optionIndex)}.{" "}
                                      </Typography>

                                      {comQuestion(options.optionValue)}
                                    </Box>
                                  ) : null}
                                </Typography>
                              ))}

                              <Stack
                                direction="row"
                                sx={{ display: "flex", alignItems: "center" }}
                                spacing={2}
                              >
                                <Box>
                                  <Typography
                                    variant="body2"
                                    className="color-black"
                                  >
                                    Type: {questions.questionType + " "}
                                  </Typography>
                                </Box>
                                <Box sx={{ minWidth: "15%" }}>
                                  <Typography
                                    variant="body2"
                                    className="color-black"
                                  >
                                    Skills: {questions.skill}
                                  </Typography>
                                </Box>
                                <Box sx={{ minWidth: "13%" }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: "flex",

                                      alignItems: "center",

                                      mt: "1%",

                                      paddingBottom: "4%",
                                    }}
                                  >
                                    Level: {questions.difficulty}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: "flex",

                                      alignItems: "center",

                                      mt: "1%",

                                      paddingBottom: "4%",
                                    }}
                                  >
                                    <AccessAlarmIcon
                                      sx={{
                                        fontSize: "20px",
                                      }}
                                      className="color-red"
                                    />

                                    {questions.timeToSolve}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Tooltip title="Info">
                                    <Button
                                      onClick={() => handleUserInfo(questions)}
                                      className="infoicon"
                                    >
                                      <InfoIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                  {/* <div style={{fontSize:'15px'}}>info</div> */}
                                  {/* <Typography variant="body2"  style={{fontSize:'15px'}}>info</Typography> */}
                                </Box>
                                {/* <Box> */}
                                {/* <Tooltip title="Info">
                                    <Button
                                      onClick={() => handleUserInfo(questions)}
                                      className="infoicon"
                                    >
                                      <InfoIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </Box> */}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Card>
                      ))}
                    {!(currentQuestions.length > 0) &&
                    (skills.length > 0 ||
                      difficulty.length > 0 ||
                      questionType.length > 0 ||
                      filters.tags.length > 0 ||
                      category.length > 0 ||
                      createdBy.length > 0) ? (
                      <h3
                        style={{
                          color: "red",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "normal",
                        }}
                      >
                        The questions are empty, please do clear the filter
                      </h3>
                    ) : (
                      !(currentQuestions.length > 0) && (
                        <Box className="loader">
                          <CircularProgress disableShrink />
                        </Box>
                      )
                    )}
                  </Box>
                </Grid>

                <Grid
                  xs={12}
                  item
                >
                  {viewMode &&
                    assessmentQuestionsJson?.sections[targetSectionIndex]
                      ?.questions.length !== 0 && (
                      <Typography sx={{ mt: "1%" }}>
                        Selected{" "}
                        {
                          assessmentQuestionsJson?.sections[targetSectionIndex]
                            ?.questions.length
                        }{" "}
                        question(s)
                      </Typography>
                    )}
                </Grid>

                <Grid
                  xs={12}
                  item
                >
                  {viewMode && (
                    <Box sx={{ float: "right", mt: "1%" }}>
                      <Button
                        variant="outlined"
                        // className="buttonStyle"
                        onClick={() => {
                          localStorage.removeItem("prevSelectTheQuestions");
                          setAssessmentQuestionJson(undefined);

                          onBack();
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="outlined"
                        sx={{ ml: "10px" }}
                        // className="buttonStyle"
                        disabled={!saveAndContinueButton}
                        onClick={callApi}
                      >
                        Save & Continue
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <ToastContainer position="bottom-left" />
            <CustomModal
              open={openDeleteQuestionModal}
              cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
              continueButton={{
                label: "Confirm",
                onClick: handleModalConfirm,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
              >
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                  Are you sure,you want to delete the question No.{" "}
                  {deletedQuestion} from the Question Bank?
                </Typography>
              </Stack>
            </CustomModal>

            <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: goToLoginPage }}
              continueButton={{
                label: "",
                onClick: handleModalConfirm,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
              >
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                  Your subscription plan has expired; for further information,
                  please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
          </Box>
          <SessionExtensionModal
            open={openSessionModal}
            onClose={handleSessionExtentionModalCancel}
            onContinue={handleModalContinue}
          ></SessionExtensionModal>
        </ThemeProvider>
      )}

      {showPreview && (
        <PreviewQuestions
          assessmentId={id}
          mode={mode}
          onBack={() => {
            setShowPreview(false);
          }}
        />
      )}

      {showCreateQuestion && (
        <CreateQuestion
          questionId={questionId}
          setQuestionId={setQuestionId}
          assessmentId={undefined}
          headerId={id}
          mode={mode}
          onBack={() => {
            setShowCreateQuestion(false);
          }}
        />
      )}
      {showUser && singleQuestionsData != undefined && (
        <ViewDetails
          questionDetails={singleQuestionsData}
          onSave={() => {
            setShowUser(false);
          }}
        />
      )}
    </>
  );
};

export default QuestionBank;
