import * as React from "react";

import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";

import "./Assessments.css";

import styled from "@emotion/styled";

import { formatDate } from "./AssessmentUtils";

import { useEffect, useState } from "react";

import {
  deleteAssessment,
  getAssessments,
} from "../../../clients/AssessmentsClient";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import { ToastContainer, toast } from "react-toastify";
import { AssessmentHeaderInterface } from "../../../types/AssessmentHeaderInterface";
import { AssessmentSectionInterface } from "../../../types/AssessmentSectionInterface";

import AssessmentDetails from "../assessment-details/AssessmentDetails";

import { useLocation, useNavigate } from "react-router-dom";

import { CustomModal } from "../../modal/CustomModal";
import { dsgTheme } from "../../../styles/theme";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Pagination from "src/components/pagination/Pagination";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { width } from "@mui/system";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import UserService from "src/clients/UserService";
import TokenService from "src/clients/TokenService";
// const TableRowStyledStyled = styled(TableRow)`
//   &:nth-of-type(even) {
//     background-color: #faefef;
//   }
// `;

const defaultSections: AssessmentSectionInterface[] = [
  {
    id: 0,

    totalTime: 0,

    skillName: "",

    sectionName: "",

    totalMarks: 0,

    sectionId: 0,

    assessmentQuestionDetail: [
      {
        id: 0,
        skillName:'',
        questionId : 0,

        assessmentQuestionNumber: 0,
      },
    ],
  },
];

const defaultAssessments: AssessmentHeaderInterface[] = [
  {
    id: 0,

    assessmentName: "",

    noOfSections: 0,

    type: "",

    totalTime: 0,

    totalMarks: 0,

    organizedBy: "",

    updatedBy: "",

    updatedDate: "",

    invitedCandidatesCount: 0,

    createdDate: new Date(),

    cutOff: 0,

    startDateTime: new Date(),

    endDateTime: new Date(),

    status: "",

    section: defaultSections,

    shuffleQuestionsStatus:false,

    organisation: ""
  },
];
interface AssessmentFilter {
  assessmentName: string;
  organizedBy: string;
  status: string;
}

interface column {
  label: string;
}

const Assessments = () => {

  const [assessments, setAssessments] =
    React.useState<AssessmentHeaderInterface[]>(defaultAssessments);

  const [assessments1, setAssessments1] =
  React.useState<AssessmentHeaderInterface[]>(defaultAssessments);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = React.useState<
    AssessmentHeaderInterface | undefined
  >();

  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  
  const [mode, setMode] = useState<string>("add");
  const [reload,setReload] = useState<boolean>(false);
  const [launchButton, setLaunchButton] = useState<boolean>(true);
  // const [statusFlag, setStatusFlag]=useState<Boolean>();
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [filters, setFilters] = React.useState<AssessmentFilter>({
    assessmentName: "",
    organizedBy: "",
    status: "",
  });
  const [isFiltersDisabled, setIsFiltersDisabled] = React.useState(true);
  const navigate = useNavigate();

  let counter = 0;

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const selectedUserParam = searchParams.get("displaySuccess");

  const columns: column[] = [
    {
      label: "Select",
    },
    {
      label: "Assessment Name",
    },
    {
      label: "Organized By",
    },
    {
      label: "Status",
    },
    {
      label: "Created Date",
    },
    {
      label: "Duration(Min)",
    },
  ];

  const [sortField, setSortField] = useState(""); // Initially, no field is selected for sorting.
  const [sortDirection, setSortDirection] = useState("desc"); // Initially, sorting direction is ascending.
  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (field === "createdDate") {
        // Ensure valid dates are converted to numbers using getTime()
        const dateA = a.createdDate ? new Date(a.createdDate).getTime() : 0;
        const dateB = b.createdDate ? new Date(b.createdDate).getTime() : 0;
    
        return sortDirection === "asc"
          ? dateA - dateB
          : dateB - dateA;
      }
    
      // Uncomment to handle other fields like 'assessmentName' or 'status'
      // if (field === "assessmentName") {
      //   return sortDirection === "asc"
      //     ? a.assessmentName.localeCompare(b.assessmentName)
      //     : b.assessmentName.localeCompare(a.assessmentName);
      // } else if (field === "status") {
      //   const statusA = a.status.toString();
      //   const statusB = b.status.toString();
      //   return sortDirection === "asc"
      //     ? statusA.localeCompare(statusB)
      //     : statusB.localeCompare(statusA);
      // }
    
      return 0; // Default case if no sorting field matches
    });
    
    

    setAssessments(sortedData);
  };
  const handleModalCancel = () => {
    setOpenModal(false)
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
    }).catch((error) => {
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      }
    })
  };
  const fetchAssessments = async () => {
    try {
      const res = await getAssessments(organisation);

      if (res.ok) {
        setReload(false);
        const data = await res.json();


        setCurrentPage(1)
        data.sort(
          (a: AssessmentHeaderInterface, b: AssessmentHeaderInterface) => {
            const dateA = new Date(a.createdDate);

            const dateB = new Date(b.createdDate);

            return dateB.getTime() - dateA.getTime();
          }
        );

        const nonCancelledAssessments
          : AssessmentHeaderInterface[] = data.filter(
            (assessment: AssessmentHeaderInterface) =>
              assessment.status !== "CANCELLED"
          );
        console.log(nonCancelledAssessments);


        setAssessments(nonCancelledAssessments);
        setAssessments1(nonCancelledAssessments);
      }
      if (res.status === 401) {
        setOpenSessionModal(true);
        setReload(false);
      }else if (res.status === 402) {
        setReload(false);
        setClientPlanExpiredModal(true);
      }

      if (res.status === 0) {
        // Log the response for debugging purposes
        console.log(res);

        // Display "No assessments"
        return (
          <div>
            No assessments
          </div>
        );

        // Reload the page after 2.5 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    }

    catch (error: any) {
      console.log("name");

    }
  };





  const applyFilters = (
    data: AssessmentHeaderInterface[],
    filter: AssessmentFilter
  ): AssessmentHeaderInterface[] => {
    return data.filter((item) => {
      if (
        filter.assessmentName &&
        !item.assessmentName.toLowerCase().includes(filter.assessmentName.toLowerCase())
      ) {
        return false;
      }

      if (filter.status && item.status !== filter.status) {
        return false;
      }
      if (filter.organizedBy && item.organizedBy !== filter.organizedBy) {
        return false;
      }
      return true;
    });
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [rows, setRows] = useState("3");

  const rowsPerPageArray = ["3", "5", "10"];

  const assessmentsPerPage = Number(rows);

  const indexOfLastAssessment = currentPage * assessmentsPerPage;

  const indexOfFirstAssessment = indexOfLastAssessment - assessmentsPerPage;

  let filteredData = applyFilters(assessments, filters);

  const handleFilterChange = (property: string, value: string) => {
    setIsFiltersDisabled(false);

    setCurrentPage(1);

    setFilters((prev) => ({ ...prev, [property]: value }));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRows(event.target.value);

    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setFilters({
      assessmentName: "",
      organizedBy: "",
      status: "",
    });
    setIsFiltersDisabled(true);
    setAssessments(assessments1);
  };

  useEffect(() => {
    setReload(true);
    fetchAssessments();

  }, [showDetails]);

  const loader =()=>
  {

    <Box className="loader">
      <CircularProgress disableShrink />
    </Box>

  }
  const handleclone = () => {
    if (selectedRow) {
      setShowDetails(true);

      selectedRow.assessmentName = selectedRow.assessmentName + "_copy";

      setMode("clone");
    }
  };

  const handleView = () => {
    if (selectedRow) {
      setMode("view");

      setShowDetails(true);
    }
  };

  const handleLaunch = () => {
    if (selectedRow) {
      navigate("/invite-candidate", {
        state: { assessmentId: selectedRow.id },
      });

      setShowDetails(true);
    }
  };

  const handleEdit = () => {
    if (selectedRow) {
      setMode("edit");

      setShowDetails(true);

    }
  };

  const handleDelete = () => {
    if (selectedRow) {
      setMode("delete");

      setOpenModal(true);
    }
  };



  const user = localStorage.getItem("currentUser");
  let userName: string;
  let organisation: string
  if (user) {
    userName = JSON.parse(user).userName;
    organisation = JSON.parse(user).clientPlans.clients.clientName;
  }

  const handleModalConfirm = async () => {
    if (selectedRow) {
      deleteAssessment(selectedRow.id, userName).then(async () => {
        toast.success("Assessment deleted successfully", { autoClose: 1000 });
        setOpenModal(false);
        const res = await getAssessments(organisation);
        if (res.ok) {
          const data = await res.json();

          setAssessments(data);
        } else if (res.status === 402) {
          setClientPlanExpiredModal(true);
        }
        else {
          console.error("Error fetching assessments:", res);
        }
      });
    }
  };

  useEffect(()=>{
    console.log(assessments);
  }, [])

  const handleViewResults = () => {
    if (selectedRow) {
      var combinedString = JSON.stringify(selectedRow);
      localStorage.setItem("assessment", combinedString);
      navigate("/collegeResult");
      setShowDetails(true);
    }
  };

  useEffect(() => {
    if (selectedUserParam == "true" && counter == 0) {
      // let count=0
      toast.success("Password Updated Successfully", { autoClose: 1000 });
      counter += 1;
    }
  }, []);

  const handleCreateNewTest = () => {
    setMode("add");
    setShowDetails(true);
  };

  const handleRowSelect = (row: any) => {
    setAddButton(true);
    setSelectedRow(row);
    // if(row && row.endDate==null)
    // {
    //   setLaunchButton(true)
    // }
    // if (row && row.endDate) {
    //   const endDate = new Date(row.endDate);
    //   const fifteenMinutesAgo = new Date(endDate.getTime() - 15 * 60 * 1000);
    //   if (fifteenMinutesAgo < new Date()) {

    //     setLaunchButton(false)
    //   }
    //   else {

    //     setLaunchButton(true)
    //   }
    // }


  };

  const loggedUser = localStorage.getItem("currentUser");
  const userDetails = loggedUser ? JSON.parse(loggedUser) : null;
  const permissions = userDetails?.permissionslist;
  const [addButton, setAddButton] = useState<boolean>(
    !permissions.includes("Create Assessment")
  );

  const handleSessionModalCancel = () => {
    navigate('/login')
    window.location.reload();
  }



  const actionButtons = [
    {
      name: "View",

      onclick: handleView,

      enable: permissions.includes("View Assessments") && selectedRow && selectedRow.status !== "IN_COMPLETE",
    },

    {
      name: "Edit",

      onclick: handleEdit,

      enable:
        permissions.includes("Edit Assessment") &&
        selectedRow &&
        selectedRow.status !== "INVITED" &&
        selectedRow.status !== "COMPLETED" &&
        selectedRow.status !== "IN_PROGRESS" 
    },

    {
      name: "launch",

      onclick: handleLaunch,

      enable:
        permissions.includes("Launch Assessment") &&
        selectedRow && selectedRow.status!=="COMPLETED" &&
        selectedRow.status !== "IN_COMPLETE"

    },

    {
      name: "View Results",

      onclick: handleViewResults,

      enable:
        permissions.includes("View Assessment Results") &&
        selectedRow &&
        selectedRow.status !== "INVITED" &&
        selectedRow.status !== "CREATED" &&
        selectedRow.status !== "IN_PROGRESS" &&
        selectedRow.status !== "IN_COMPLETE"
    },

    {
      name: "Delete",

      onclick: handleDelete,

      enable:
        permissions.includes("Delete Assessment") &&
        selectedRow &&
        selectedRow.status !== "INVITED" &&
        selectedRow.status !== "COMPLETED" &&
        selectedRow.status !== "IN_PROGRESS",
    },

    {
      name: "clone",

      onclick: handleclone,

      enable: permissions.includes("Clone Assessment") && selectedRow && selectedRow.status !== "IN_COMPLETE",
    },
  ];

  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      <ThemeProvider theme={dsgTheme}>
        {!showDetails && (
          <Box className="Box">
            <Card className="Cardcss tableContainer">
              <CardContent>
                <Box sx={{ paddingBottom: "2%" }}>
                  <Typography variant="h5">Assessments</Typography>
                </Box>

                <Box className="filterRowS">
                  <Box className="filterInnerRow">
                    <Autocomplete
                      sx={{ width: "40%" }}
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      value={filters.assessmentName}
                      onChange={(e, value) =>
                        handleFilterChange("assessmentName", value)
                      }
                      onInputChange={(e, value) => {
                        handleFilterChange("assessmentName", value);
                      }}
                      options={Array.from(
                        new Set(
                          assessments?.map(
                            (assessment) => assessment.assessmentName
                          )
                        )
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assessment Name"
                          name="assessmentName"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                    <FormControl sx={{ width: "30%", height: "30px" }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        Organizer
                      </InputLabel>
                      <Select
                        value={filters.organizedBy}
                        name="Organizer"
                        onChange={(e) =>
                          handleFilterChange("organizedBy", e.target.value)
                        }
                        label="Organizer"
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        {" "}
                        {Array.from(
                          new Set(
                            assessments?.map(
                              (assessment) => assessment.organizedBy
                            )
                          )
                        ).map((idx) => (
                          <MenuItem value={idx} key={idx}>
                            {idx}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: "30%" }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        Status
                      </InputLabel>
                      <Select
                        value={filters.status}
                        name="Status"
                        onChange={(e) =>
                          handleFilterChange("status", e.target.value)
                        }
                        label="Status"
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        {" "}
                        {Array.from(
                          new Set(
                            assessments?.map((assessment) => assessment.status)
                          )
                        ).map((idx) => (
                          <MenuItem value={idx} key={idx}>
                            {idx}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      onClick={handleClearFilters}
                      disabled={isFiltersDisabled}
                      sx={{ width: "35%" }}
                      variant="outlined"
                    >
                      <FilterAltOffIcon />
                      Clear Filters
                    </Button>
                  </Box>
                  <Button
                    sx={{ padding: "15px", height: "40px" }}
                    className="createTestButton"
                    disabled={addButton}
                    onClick={handleCreateNewTest}
                  >
                    Create New Assessment
                  </Button>
                </Box>

                <Box></Box>
                <>
                  {filteredData.length <= 0 && (
                    <h3 style={{
                      color: "red",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "normal",
                    }}>No assessments are created</h3>
                  )}
                </>


                <>
                  {filteredData.length > 0 && (


                    <>




                      <Stack
                        direction="row"
                        spacing={2}
                        marginBottom="2%"
                        alignItems="center"
                      >
                        <Box sx={{ marginRight: "auto" }}>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography>Assessments per page</Typography>

                            <FormControl variant="standard">
                              <Select
                                disableUnderline={true}
                                value={rows}
                                label="Age"
                                onChange={handleChange}
                                sx={{
                                  fontSize: "13px",
                                }}
                              >
                                {rowsPerPageArray.map((value, index) => (
                                  <MenuItem value={value} key={value + String(index)}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <Typography sx={{ color: "#191F1C" }}>
                              {indexOfLastAssessment > 0 ? indexOfFirstAssessment + 1 : 0}-
                              {indexOfLastAssessment > filteredData.length
                                ? indexOfLastAssessment -
                                (indexOfLastAssessment - filteredData.length)
                                : indexOfLastAssessment}{" "}
                              out of {filteredData.length}
                            </Typography>
                          </Stack>
                        </Box>

                        <Pagination
                          rows={rows}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalDataLength={filteredData.length}
                          dataPerPage={assessmentsPerPage}
                        />
                      </Stack>

                      <TableContainer sx={{ maxHeight: 270 }} className="tableBody">
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                            <TableCell
                                  
                                  className="tableHeader"
                                  
                                >
                                  Select
                                </TableCell>

                                <TableCell
                              
                                  className="tableHeader"
                                  
                                >
                                  Assessment Name
                                </TableCell>

                                <TableCell
                              
                                  className="tableHeader"
                                  
                                >
                                  Organized By
                                </TableCell>

                                <TableCell
                            className="tableHeader"
                           
                          >
                            Status
                          </TableCell>

                                <TableCell
                                  sx={{display:'flex'}}
                                  className="tableHeader"
                                  onClick={() => handleSort("createdDate")}
                                >
                                  Created Date {sortDirection === "desc" ?<ArrowDropDownIcon className="arrow-icon"/>: <ArrowDropUpIcon className="arrow-icon"/>}

                                </TableCell>


                                
                                <TableCell
                              
                                  className="tableHeader"
                                  
                                >
                                  Duration(Min)
                                </TableCell>

                          
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {filteredData

                              .slice(indexOfFirstAssessment, indexOfLastAssessment)

                              .filter(
                                (assessment) => assessment.status != "CANCELLED"
                              )
                              .map((assessment, index) => (
                                <TableRow
                                  // hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                >
                                  <TableCell width={50}>
                                    <Radio className="radiocss"
                                      name={assessment.assessmentName}
                                      id={assessment.assessmentName}
                                      value={selectedRow?.assessmentName}
                                      onClick={() => {
                                        if (selectedRow === assessment) {
                                          // console.log("inside you")
                                          handleRowSelect(null);
                                          if (
                                            permissions.includes("Create Assessment")
                                          ) {
                                            setAddButton(false);
                                          }
                                        } else {
                                          handleRowSelect(assessment);
                                        }
                                      }}
                                      checked={selectedRow === assessment}
                                    // sx={{
                                    //   '& .MuiSvgIcon-root': {
                                    //     fontSize: 22,
                                    //   },
                                    // }}
                                    />
                                  </TableCell>
                                  <TableCell sx={{}} className="assessment" >
                                    {assessment.assessmentName}
                                  </TableCell>
                                  <TableCell width={150}>
                                    {assessment.organizedBy}
                                  </TableCell>
                                  <TableCell width={160}>
                                    {assessment.status}
                                  </TableCell>

                                  <TableCell width={170}>
                                    {formatDate(assessment.createdDate.toString())}
                                  </TableCell>

                                  <TableCell width={170}>
                                    {assessment.totalTime}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className="allTheBoxes">
                        {actionButtons.map((e, index) => {
                          return (
                            <Button
                              key={"action_" + index}
                              disabled={
                                !e.enable || selectedRow?.status === "CANCELLED"
                              }
                              // className="button"
                              onClick={e.onclick}
                            >
                              {e.name}
                            </Button>
                          );
                        })}
                      </Box>
                    </>

                  )}</>

              </CardContent>

              <ToastContainer position="bottom-left" />

              <CustomModal
                open={openModal}
                // onClose={handleModalCancel}
                // heading="Delete User"

                cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
                continueButton={{
                  label: "Confirm",
                  onClick: handleModalConfirm,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                  <Typography fontWeight="bold">
                    Are you sure,you want to delete the assessment ?(
                    {selectedRow?.assessmentName})
                  </Typography>
                </Stack>
              </CustomModal>
            </Card>
          </Box>

        )}

        <SessionExtensionModal
          open={openSessionModal}
          onClose={handleSessionModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>

        <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleSessionModalCancel }}
             continueButton={{
              label:"",
              onClick:handleSessionModalCancel
             }}
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
        {showDetails && (
          <AssessmentDetails
            mode={mode}
            assessmentDetails={selectedRow}
            onBack={() => {
              setSelectedRow(undefined);
              setShowDetails(false);
              setAddButton(!permissions.includes("Create Assessment"));
            }}
          />
        )}

      </ThemeProvider>
    </>
  );
};

export default Assessments;
