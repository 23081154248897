import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CreateClient.css";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { dsgTheme } from "src/styles/theme";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ClientInterface } from "src/components/admin/dummydata/ClientInterface";
import SubscriptionDetails from "../subscription/SubscriptionDetails";
import UserService from "src/clients/UserService";
import { ToastContainer, toast } from "react-toastify";
import { SubscriptionPlansInterface } from "../dummydata/SubscriptionPlansInterface";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import TokenService from "src/clients/TokenService";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import { CustomModal } from "../list-of-users/CustomModal";
import { UserDtoInterface } from "../dummydata/UserDtoInterface";
import CreateUserClass from "../create-user/CreateUserClass";
import { error } from "console";

interface ClientProps {
  selectedClientJson?: ClientPlanInterface;
  onBack: () => void;
  editable?: boolean;
  setNewPlan: (plan: SubscriptionPlansInterface) => void; // Callback function to update newPlan in the parent component
}

const CreateClient = ({ selectedClientJson, onBack }: ClientProps) => {
  const [clientPlanData, setClientPlanData] = useState<ClientPlanInterface>({
    id: 0,
    clientPlanStatus: "",
    planStartDate: new Date(),
    planEndDate: new Date(),
    subscriptionPlans: {
      id: 0,
      planName: "",
      timePeriod: 0,
      numberOfUsers: 0,
      numberOfInvitations: 0,
      cost: 0,
      createdBy: "",
      subscriptionPlanStatus: 'ACTIVE'
    },
    clients: {
      id: 0,
      clientName: "",
      clientId: "",
      clientStatus: "",
      clientLogo: "",
      autoRenew: true,
    },
  });

  const [clientData, setClientData] = useState<ClientInterface>(
    clientPlanData.clients ?? {
      id: 0,
      clientName: "",
      clientId: "",
      clientStatus: "",
      clientLogo: "",
      autoRenew: true
    }
  );

  const navigate = useNavigate();
  const [showSubscription, setShowSubscription] = useState<boolean>(false);

  const [userData, setUserData] = useState<UserDtoInterface>(
    CreateUserClass.User
  );
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [saveDisable, setSaveDisable] = useState<boolean>(true);

  console.log("userData", userData);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;

    if (
      ["firstName", "lastName", "email"].some((value) =>
        inputName.includes(value)
      )
    ) {
      setUserData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    } else {
      const updatedClientData = {
        ...clientData,
        [event.target.name]:
          event.target.name === "autoRenew"
            ? !clientData.autoRenew
            : event.target.value,
      };

      setClientData(updatedClientData);
    }
  };

  const handleSubscriptionLink = () => {
    setShowSubscription(true);
  };

  const handleFileChange = async (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile instanceof Blob) {
      const result = await encodeFileBase64bit(selectedFile);
      console.log(result);
      setClientData((prevState) => ({
        ...prevState,
        clientLogo: result,
      }));
    } else {
      // If no file is selected, set clientLogo to null
      setClientData((prevState) => ({
        ...prevState,
        clientLogo: "",
      }));
    }
  };

  console.log(clientData, "clientData");
  // const encodeFileBase64 = (file: any): Promise<string> => {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const dataURL = reader.result as string;
  //       resolve(dataURL); // Resolve the promise with the data URL
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  const encodeFileBase64bit = (file: any): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result as string;
        const base64String = dataURL.split(",")[1]; // Extract base64 string
        resolve(base64String);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const year = new Date().getFullYear();
    setClientData((prevState) => ({
      ...prevState,
      clientId: clientData?.clientName + "_" + year,
    }));
    setClientData((prevState) => ({
      ...prevState,
      clientStatus: "NEW",
    }));
  }, [clientData?.clientName]);

  const handleSessionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleSessionModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };
  const handleSubmit = async () => {
    console.log("client : ", clientData);

    const userNameObject =
      clientData?.clientName[0] +
      "_" +
      userData.firstName[0]?.toUpperCase() +
      userData.lastName[0]?.toUpperCase() +
      userData.lastName.slice(1, userData.lastName.length);

    if(userData.email.length >50){
      toast.error("Email length should not be greater than 50",{autoClose:1000})
    }
    else if (clientData != null) {

      await UserService.checkClientNameAndEmail(userData.email,clientData.clientName)
      .then((res:any) => {
        if(res.data === "Email and Client Name are unique"){
          UserService.createClient(clientData)
          .then((res: any) => {
            console.log(res.data);
            if (res.status === 406) {
              toast.error("Client Already Exists",{autoClose:1000});
            } else {
              setClientData(res.data);
              const dto = {
                userName: userNameObject,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                clientPlans: {
                  ...userData.clientPlans,
                  clients: res.data,
                  clientPlanStatus: "NEW",
                  planStartDate: dayjs(userData.clientPlans.planStartDate).format(
                    "YYYY-MM-DD"
                  ),
                  planEndDate: dayjs(userData.clientPlans.planEndDate).format(
                    "YYYY-MM-DD"
                  ),
                  subscriptionPlans: clientPlanData.subscriptionPlans
                },
                role: {
                  ...userData.role,
                  id: 2,
                  roleName: "ADMIN",
                },
                status: "NEW",
                createdBy: UserService.getLoginUserObject().userName,
              };
              console.log(dto, "my dto");
  
              UserService.saveUser(dto)
                .then((res) => {
                  if (res.status === 200) {
                    toast.success(`Client and user data saved successfully.`, {
                      autoClose: 1000,
                    });
                    setTimeout(() => {
                      handleSubscriptionLink();
                    }, 2000);
                  } else {
                    toast.error("Something went wrong.", { autoClose: 1000 });
                  }
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    setOpenSessionModal(true);
                  } else if (error.response.status === 402) {
                    setClientPlanExpiredModal(true);
                  } else if (error.response.status === 406) {
                    toast.error("Email already exists.", { autoClose: 1000 });
                  } else {
                    toast.error("Something went wrong.", { autoClose: 1000 });
                  }
                });
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              console.log("Unauthorized path..");
              setOpenSessionModal(true);
            } else if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
          
            } else if (error.response.status === 406) {
              toast.error("Client Already Exists", { autoClose: 1000 });
            }
          });
        }else{
          toast.error(res.data,{autoClose:1000});
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
      
        } else {
          toast.error("Internal Server Error", { autoClose: 1000 });
        }
      });
      
    }
  };

  useEffect(() => {
    if (
      clientData?.clientName.length < 2 ||
      clientData?.clientId == "" ||
      clientData?.clientLogo == "" ||
      userData?.firstName.length < 2 ||
      userData?.lastName.length < 2 ||
      userData?.email == "" ||
      !isEmail(userData?.email)
    ) {
      console.log(
        "status sda",
        clientData.clientLogo,
        clientData.clientId,
        clientData.clientName
      );
      setSaveDisable(true);
    } else {
      console.log(
        "status",
        clientData.clientLogo,
        clientData.clientId,
        clientData.clientName
      );
      setSaveDisable(false);
    }
  }, [
    clientData?.clientId,
    clientData?.clientLogo,
    clientData?.clientName,
    userData?.firstName,
    userData?.lastName,
    userData?.email,
  ]);

  const goToLoginPage = () => {
    navigate("/login");
    window.location.reload();
  };

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,6}$/.test(email);
  };
  return (
    <ThemeProvider theme={dsgTheme}>
      {!showSubscription && (
        <Container className="client-container" sx={{ top: "60%" }}>
          <Box>
            <Typography variant="h5" sx={{ padding: "3%" }}>
              Create Client
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={10}>
                    <TextField
                      type="text"
                      label="Client Name"
                      variant="outlined"
                      sx={{ width: "80%" }}
                      name="clientName"
                      value={clientData?.clientName}
                      onChange={handleInputChange}
                      required
                    />
                  </Stack>

                  <Stack direction="row" spacing={10}>
                    <TextField
                      type="text"
                      className="userfield"
                      label="Client Id"
                      variant="outlined"
                      name="clientId"
                      disabled
                      value={clientData?.clientId}
                      sx={{ width: "80%" }}
                      onChange={handleInputChange}
                      required
                    />
                  </Stack>
                  <Stack direction="row" spacing={10}>
                    <TextField
                      type="text"
                      className="userfield"
                      label="Email"
                      variant="outlined"
                      name="email"
                      value={userData?.email}
                      sx={{ width: "80%" }}
                      onChange={handleInputChange}
                      required
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={10}>
                    <TextField
                      type="text"
                      label="First Name"
                      variant="outlined"
                      sx={{ width: "80%" }}
                      name="firstName"
                      value={userData?.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </Stack>

                  <Stack direction="row" spacing={10}>
                    <TextField
                      type="text"
                      className="userfield"
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                      value={userData?.lastName}
                      sx={{ width: "80%" }}
                      onChange={handleInputChange}
                      required
                    />
                  </Stack>
                  <Stack direction="row" spacing={10}>
                    <div>
                      <input
                        type="file"
                        accept="image/*" // Limit to image files
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the default file input
                        id="logoUploadInput"
                      ></input>
                      <label htmlFor="logoUploadInput">
                        <Button className="logouploadcss" component="span">
                          {clientData?.clientLogo ? (
                            <img
                              src={`data:image/png;base64,${clientData?.clientLogo}`}
                              alt="Uploaded Logo"
                              style={{
                                width: "150px",
                                height: "80px",
                                marginTop: "10px",
                              }}
                            />
                          ) : (
                            <>Logo Upload</>
                          )}

                          <CloudUploadIcon sx={{ color: "#fe524a" }} />
                        </Button>
                      </label>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // paddingBottom: "3%",
                      }}
                    >
                      Auto Renew:
                      <Switch
                      checked={clientData.autoRenew}
                        size="small"
                        name="autoRenew"
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              marginTop="3%"
              justifyContent="flex-end"
              spacing={2}
              marginBottom="3%"
            >
              <Button variant="outlined" className="csButton" onClick={onBack}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={handleSubmit}
                disabled={saveDisable}
              >
                Save & Subscribe
              </Button>
            </Stack>
          </Box>
        </Container>
      )}
      {showSubscription && (
        <SubscriptionDetails
          selectedClientJson={{ ...clientPlanData, clients: clientData }}
          mode={"New"}
          onBack={() => {
            setShowSubscription(false);
          }}
        />
      )}
      <ToastContainer position="bottom-left" />
      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleSessionModalCancel}
        onContinue={handleSessionModalContinue}
      ></SessionExtensionModal>
      <CustomModal
        open={openClientPlanExpiredModal}
        cancelButton={{ label: "Sign out", onClick: goToLoginPage }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
          <Typography fontWeight="bold">
            Your subscription plan has expired; for further information, please
            contact our RS_ADMIN.
          </Typography>
        </Stack>
      </CustomModal>
    </ThemeProvider>
  );
};

export default CreateClient;
