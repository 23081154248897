export const formatDateMonth = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
  };
  return new Date(date).toLocaleString("en-IN", options);
};

export const formatDateYear = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
  };
  return new Date(date).toLocaleString("en-IN", options);
};
