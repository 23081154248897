import React, { useEffect, useState } from "react";

import {
  Box,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { dsgTheme } from "src/styles/theme";

import { ButtonInterface } from "src/types/ButtonInterface";

import { CustomModal } from "../modal/CustomModal";

import { AssessmentSectionInterface } from "src/types/AssessmentSectionInterface";

import { MasterDataInterface } from "src/types/MasterDataInterface";

import DeleteIcon from "@mui/icons-material/Delete";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getSection } from "src/clients/AssessmentsClient";
import { formatDate } from "../assessment/assessments/AssessmentUtils";

interface Options {
  optionId: number;

  optionValue: string;

  answerFlag: string;
}

interface QuestionBank {
  questionId: number;
  questionText: string;
  options: Options[];
  questionType: string;
  difficulty: string;
  skill: string;
  addInfo: string;
  createdDate: Date | string;
  createdBy: string;
  updatedDate: Date | string;
  updatedBy: String;
  negativeMarks: number;
  positiveMarks: number;
  timeToSolve: number;
  baseFlag: boolean;
  visibility: string;
  organization: string;
}

interface AssessmentSectionProps {
  questionDetails: QuestionBank;
  onSave: (questionDetails: QuestionBank) => void;
}

function ViewDetails({
  questionDetails,
  onSave: onBack,
}: AssessmentSectionProps) {
  const [openModal, setOpenModal] = useState(true);

  const [particularQuestionDetails, setParticularQuestionDetails] =
    useState<QuestionBank>(questionDetails);

  console.log(particularQuestionDetails);

  const handleCancelButton = () => {
    setOpenModal(false); //remove this if required and write cancel button functionality

    onBack(questionDetails);
  };

  const handleContinueButton = async () => {
    setOpenModal(false);

    onBack(questionDetails);
  };

  const cancelButton: ButtonInterface = {
    label: "Cancel",

    onClick: handleCancelButton,
  };

  const continueButton: ButtonInterface = {
    label: "Close",
    disabled: false,
    onClick: handleContinueButton,
  };

  return (
    <Box>
      <CustomModal
        open={openModal}
        // onClose={handleCloseModal}
        //  cancelButton={cancelButton}
        continueButton={continueButton}
      >
        <ThemeProvider theme={dsgTheme}>
          <Typography
            variant="h5"
            sx={{ paddingBottom: "2%" }}
          >
            Audit
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", padding: "10px", paddingBottom: "20px" }}>
              {/* <Typography>
                  <b>Number of Users:</b> 5
                </Typography>
                <Typography>
                  <b>Number of Invitations: </b> 100
                </Typography>
                <Typography>
                  <b>Plan Period: </b> 3 months
                </Typography>
                <Typography>
                  <b>Cost: </b> 10$
                </Typography> */}
              {particularQuestionDetails.updatedBy && (
                <Typography>
                  <b>Updated By: </b>
                  {particularQuestionDetails.updatedBy}
                </Typography>
              )}
              {particularQuestionDetails.updatedDate && (
                <Typography>
                  <b>Updated Date: </b>
                  {formatDate(particularQuestionDetails.updatedDate.toString())}
                </Typography>
              )}
              {particularQuestionDetails.createdBy && (
                <Typography>
                  <b>Created By: </b>
                  {particularQuestionDetails.createdBy}
                </Typography>
              )}
              {particularQuestionDetails.createdDate && (
                <Typography>
                  <b>Created Date: </b>
                  {formatDate(particularQuestionDetails.createdDate.toString())}
                </Typography>
              )}
              {/* {particularQuestionDetails.organization && (
                <Typography>
                  <b>Client: </b>{particularQuestionDetails.organization}
                </Typography>
              )} */}
            </Box>
          </Box>
        </ThemeProvider>
      </CustomModal>
    </Box>
  );
}

export default ViewDetails;
