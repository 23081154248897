import { ThemeProvider } from "@emotion/react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { dsgTheme } from "src/styles/theme";
import { ClientInterface } from "../dummydata/ClientInterface";
import { SubscriptionPlansInterface } from "../dummydata/SubscriptionPlansInterface";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UserService from "src/clients/UserService";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { formatDateStr } from "src/utils/ValidationUtils";
import { NewSubscriptionDetails } from "./NewSubscriptionDetails";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import { CustomModal } from "../list-of-users/CustomModal";

interface ClientProps {
  selectedClientJson?: ClientPlanInterface;
  mode: string;
  onBack: () => void;
}

const initialSubscriptionPlan: SubscriptionPlansInterface = {
  id: 0,
  planName: "",
  numberOfUsers: 0,
  numberOfInvitations: 0,
  cost: 0,
  createdBy: "",
  timePeriod: 0,
  subscriptionPlanStatus: ""
};

const clients: ClientInterface = {
  id: 0,
  clientName: "",
  clientId: "",
  clientStatus: "",
  clientLogo: "",
  autoRenew: false,
};
const defaultClientData: ClientPlanInterface = {
  id: 0,
  planStartDate: new Date(),
  planEndDate: new Date(),
  clientPlanStatus: "",
  subscriptionPlans: initialSubscriptionPlan,
  clients: clients,
};

const SubscriptionDetails = ({
  selectedClientJson,
  mode,
  onBack,
}: ClientProps) => {
  
  const [selectedPlanDetails, setSelectedPlanDetails] =
    useState<SubscriptionPlansInterface>();
  const [selectedClientData, setSelectedClientData] =
    useState<ClientPlanInterface>(defaultClientData);
  console.log(selectedClientData, "selectedClientData");
  
  const [selectedClientDataDuplicate, setSelectedClientDataDuplicate] =
    useState<ClientPlanInterface | undefined>(defaultClientData);
  const clientLogo = selectedClientJson?.clients?.clientLogo;
  const [startDateEditable, setStartDateEditable] = useState<boolean>(false);
  const navigate = useNavigate();
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [selectedPlanDetailsDuplicate, setSelectedPlanDetailsDuplicate] =
    useState<SubscriptionPlansInterface | undefined | null>();
  const [navigationFlag, setNavigationFlag] = useState<boolean>(false);
  const [showAddPlan, setShowAddPlan] = useState<boolean>(false);
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [planName, setPlanName] = useState<string>("");
  console.log(planName, "planName");
  const [minDate, setMinDate] = useState<Date>();
  const [openViewPlanModal, setOpenViewPlanModal] = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlansInterface[]
  >([]);
  const [reload,setReload] = useState<boolean>(false);
  const [backdrop, setBackDrop] = React.useState(false);

  // useEffect(() => {
  //   if(mode==="Edit" && selectedClientJson){

  //   }
  // },[])
  useEffect(() => {
    // UserService.getAllSubscriptionPlans()
    //   .then((res: any) => {
    //     console.log(res.data);
    //     setDemoPlanDetails(res.data);
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 401) {
    //       console.log("Unauthorized path..");
    //       setOpenSessionModal(true);
    //     } else if (error.response.status === 402) {
    //       setClientPlanExpiredModal(true);
    //     }
    //   });

    if (selectedClientJson !== undefined) {
      setSelectedClientData(selectedClientJson);
      setSelectedPlanDetails(selectedClientJson?.subscriptionPlans);
      setPlanName(selectedClientJson?.subscriptionPlans?.planName);
      console.log("selectClientJons", selectedClientJson.clients.id);
      if (mode === "Renew") {
        console.log("enterred in renew mode");
        UserService.getLatestDateForClientPlan(selectedClientJson.clients.id)
          .then((response: any) => {
            if (response.status === 200) {
              console.log(response.data, "latest Date");
              console.log(response.data.planStartDate, "adfbaefl");
              setSelectedClientData(response.data);
              setSelectedPlanDetails(response.data.subscriptionPlans);
              setPlanName(response.data.subscriptionPlans.planName);
              // if(response.data.subscriptionPlans.planName !== "" && response.data.subscriptionPlans.planName !== undefined || response.data.subscriptionPlans.planName !== null){
              //   handleChange(response.data.subscriptionPlans.planName)
              // }

              console.log("selectePlanDetails", selectedPlanDetails);

              let latestPlanStartDate = response.data.planStartDate;
              let latestPlanEndDate = response.data.planEndDate;

              setSelectedClientData((prev: ClientPlanInterface) => {
                const planStartDate = dayjs(latestPlanEndDate).add(1, "day");
                setMinDate(planStartDate.toDate());
                //       const remainingTimeMonths = dayjs(latestPlanEndDate).diff(latestPlanStartDate, 'months', true);

                // console.log("remainingTimeMonths", remainingTimeMonths);
                const remainingTime =
                  dayjs(latestPlanEndDate).diff(latestPlanStartDate);
                console.log("remainingTime", remainingTime / (1000 * 60));
                const planEndDate = planStartDate.add(remainingTime);

                return {
                  ...prev,
                  planStartDate: planStartDate.toDate(),
                  planEndDate: planEndDate.toDate(),
                };
              });
            } else {
              setMinDate(dayjs().toDate());
            }
          })
          .catch((error) => {
            if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
            }
            console.log("error", error);
          });
      }
    }
    setReload(true)
    UserService.getAllSubscriptionPlans()
      .then((res: any) => {
        setReload(false)
        const filterData:SubscriptionPlansInterface[] = res.data

        setSubscriptionPlans(filterData.filter((data) => data.subscriptionPlanStatus === "ACTIVE"))
        // setSubscriptionPlans(res.data);
      })
      .catch((error) => {
        setReload(false)
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          console.log(error);
        }
      });

    setSelectedClientDataDuplicate(selectedClientJson);
    // setSelectedPlanDetails(selectedClientJson?.subscriptionPlans);
    setSelectedPlanDetailsDuplicate(selectedClientJson?.subscriptionPlans);
    // setSelectedClientData
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedPlan = event.target.value.toString();
    const planDetails = subscriptionPlans.find((plan) => {
      return plan.planName === selectedPlan;
    });
    setSelectedPlanDetails(planDetails);
    console.log("Hi .. ", event.target.value);
    if (planDetails) {
      const prevNumberOfUsers =
        selectedPlanDetailsDuplicate?.numberOfUsers || 0;
      const selectedNumberOfUsers = planDetails.numberOfUsers;
      // if (prevNumberOfUsers > selectedNumberOfUsers) {
      //   setNavigationFlag(true);
      // } else {
      //   setNavigationFlag(false);
      // }
      setPlanName(planDetails.planName);
      setSelectedPlanDetails(planDetails);
    }
  };

  const handleConfirm = () => {
    console.log(selectedClientData.planStartDate, "planStartDate");
    console.log(dayjs().toDate(), "planStartDatekjsabdkajb");
    const dto = {
      planStartDate: dayjs(selectedClientData?.planStartDate).format(
        "YYYY-MM-DD"
      ),
      planEndDate: dayjs(selectedClientData?.planEndDate).format("YYYY-MM-DD"),
      clientPlanStatus:
        mode === "Edit"
          ? selectedClientData?.clientPlanStatus
          : dayjs(selectedClientData.planStartDate).format("YYYY-MM-DD") ===
            dayjs().format("YYYY-MM-DD")
          ? "ACTIVE"
          : "NEW",
      clients: selectedClientData?.clients,
      subscriptionPlans: selectedPlanDetails,
      // clients: {
      //   ...selectedClientData,
      //   planStartDate: selectedClientData?.planStartDate,

      // }
    };
    console.log("dto : ", dto);
    // if (!planStartDate.isAfter(currentDate) && mode !== "Edit") {
    //   console.log(planStartDate.isAfter(currentDate) + "hi..");
    //   toast.error("Please select correct start date time", { autoClose: 1000 });
    //   return;
    // }

    if (mode !== "Edit") {
      setBackDrop(true);
      setOpenConfirmModal(false);
      UserService.renewSubscription(dto)
        .then((res: any) => {
          console.log(res.data);
          setBackDrop(false);
          if (mode === "New") {
            toast.success("Plan Successfully subscribed", { autoClose: 1000 });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.success("Plan renewed successfully", { autoClose: 1000 });
            onBack();
          }
        })
        .catch((error: any) => {
          setBackDrop(false);
          if (error.response.status === 401) {
            console.log("Unauthorized path..");
            setOpenSessionModal(true);
          } else if (error.response.status === 402) {
            setClientPlanExpiredModal(true);
          }
        });
    } else {
      UserService.updateClientDetails(dto.clients)
        .then((res: any) => {
          console.log(res.data);
          window.location.reload();
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            console.log("Unauthorized path..");
            setOpenSessionModal(true);
          } else if (error.response.status === 402) {
            setClientPlanExpiredModal(true);
          }
        });
    }
  };

  const handleInputChange = (name: string, value: any) => {
    console.log(name, value);
    const currentDate = new Date();
    if (name === "autoRenew") {
      setSelectedClientData((prevState: ClientPlanInterface) => ({
        ...prevState,
        clients: {
          ...prevState.clients,
          [name]: !selectedClientData.clients.autoRenew,
        },
      }));
    } else {
      const currentYear = "_" + currentDate.getFullYear().toString();
      setSelectedClientData((prevState: ClientPlanInterface) => ({
        ...prevState,
        clients: {
          ...prevState.clients,
          [name]: value,
          clientId: value + currentYear,
        },
        // [name]: value,
        // clientId: value + currentYear
      }));
    }
  };

  useEffect(() => {
    if (selectedClientData?.planStartDate) {
      if (selectedPlanDetails?.timePeriod) {
        setSelectedClientData((prev: any) => ({
          ...prev,
          planEndDate: selectedClientData.planStartDate
            ? dayjs(selectedClientData.planStartDate)
                .add(selectedPlanDetails?.timePeriod, "month")
                .format("YYYY-MM-DD")
            : null,
        }));
      }
    }
  }, [selectedPlanDetails]);

  useEffect(() => {
    // Compare planStartDate and current date when selectedClientData or selectedPlanDetails change
    // if (selectedClientJson?.subscriptionPlans) {
    //   // if (selectedClientJson?.planStartDate) {
    //   //   console.log("selectClientJons",selectedClientJson)
    //   //   // const planStartDate = dayjs(selectedClientJson?.planStartDate);
    //   //   // const currentDate = dayjs();
    //   //   if (planStartDate.isAfter(currentDate)) {
    //   //     console.log(planStartDate.isAfter(currentDate) + "hi..")
    //   //     setStartDateEditable(false);
    //   //   } else {
    //   //     console.log("bye")
    //   //     setStartDateEditable(true);
    //   //   }
    //   // }
    // }
    if (mode === "Edit") {
      console.log("Mode edit");
      setStartDateEditable(true);
    }
  }, []);

  // const handleCheckBox = () => {
  //   setEditable((prev) => !prev);
  // };

  const handlePlanChange = (event: any) => {
    if (selectedPlanDetails) {
      const updatedPlan = {
        ...selectedPlanDetails,
        [event.target.name]: Number(event.target.value),
      };
      setSelectedPlanDetails(updatedPlan);
    }
  };

  console.log(selectedPlanDetails);
  const handleFileChange = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile instanceof Blob) {
      console.log("if conditions");
      const result = await encodeFileBase64bit(selectedFile);
      setSelectedClientData((prevState: ClientPlanInterface) => ({
        ...prevState,
        clients: {
          ...prevState.clients,
          clientLogo: result,
        },
      }));
    } else {
      console.log("else conditions");
      // If no file is selected, set clientLogo to null
      setSelectedClientData((prevState: any) => ({
        ...prevState,
        clientLogo: null,
      }));
    }
  };

  const AddNewPlanIntoListOfPlans = (plan: any) => {
    console.log(plan);
    console.log("list", plan);
    const va = [...subscriptionPlans, plan];
    setSubscriptionPlans(va);
  };

  const encodeFileBase64bit = (file: any): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result as string;
        const base64String = dataURL.split(",")[1]; // Extract base64 string
        resolve(base64String);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleAddNewPlan = () => {
    setShowAddPlan(true);
  };

  const handleSessionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleSessionModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  useEffect(() => {
    console.log(
      "selectedClientData",
      selectedClientData,
      "selectedClientDataDuplicate",
      selectedClientDataDuplicate
    );

    if (mode === "Edit") {
      if (
        selectedClientData?.clients?.clientName ===
          selectedClientDataDuplicate?.clients?.clientName &&
        selectedClientData?.clients?.clientLogo ===
          selectedClientDataDuplicate?.clients?.clientLogo &&
        selectedClientData?.clients?.autoRenew ===
          selectedClientDataDuplicate?.clients?.autoRenew
      ) {
        setDisableConfirm(true);
      } else {
        setDisableConfirm(false);
      }
    }
  }, [selectedClientData]);

  const areFieldsFilled = () => {
    return (
      selectedClientData?.clients?.clientName &&
      selectedPlanDetails?.planName &&
      selectedPlanDetails?.numberOfUsers &&
      selectedPlanDetails?.numberOfInvitations &&
      selectedPlanDetails?.cost &&
      selectedClientData?.planStartDate &&
      selectedClientData?.planEndDate
      // selectedClientData?.clients.clientLogo
    );
  };

  console.log("selectedClientData", selectedClientData);
  
  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <ThemeProvider theme={dsgTheme}>
      {!showAddPlan && (
        <Box sx={{ paddingLeft: "15%", paddingTop: "3%" }}>
          <Card sx={{ width: "70%", height: "95%" }}>
            <CardContent>
              <Box>
                <Box sx={{ paddingBottom: "2%", paddingTop: "2%" ,marginBottom: mode==="Edit"?'5%':'0%'}}>
                  <Typography variant="h5">
                    {mode === "Edit"
                      ? "Edit Client Details"
                      : mode === "Renew"
                      ? "Renew Subscription"
                      : "New Subscription"}
                  </Typography>
                </Box>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={7}>
                    <TextField
                      type="text"
                      label="Client Name"
                      variant="outlined"
                      size="small"
                      sx={{ width: mode === "Edit" ? "46%" : "100%" }}
                      name="clientName"
                      value={selectedClientData?.clients?.clientName}
                      onChange={(event) =>
                        handleInputChange("clientName", event?.target.value)
                      }
                      disabled={
                        true
                        // selectedClientData?.clients?.id !== 0 && !isEditable
                      }
                      // InputProps={{
                      //   readOnly: mode !== "Edit",
                      // }}
                      required
                    />
                    {mode === "Edit" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          // paddingBottom: "3%",
                        }}
                      >
                        Auto Renew:
                        <Switch
                          checked={selectedClientData?.clients?.autoRenew}
                          size="small"
                          name="autoRenew"
                          onChange={(event) =>
                            handleInputChange("autoRenew", event?.target.value)
                          }
                        />
                      </Box>
                    )}
                    {mode !== "Edit" && (
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Subscription Plans
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={planName}
                          label="Subscription Plans"
                          onChange={handleChange}
                          disabled={mode === "Edit"}
                        >
                          {subscriptionPlans.map((plan) => (
                            <MenuItem key={plan.id} value={plan.planName}>
                              {plan.planName}
                            </MenuItem>
                          ))}
                        </Select>
                        <Link
                          sx={{ fontSize: "14px", marginLeft: "1%" }}
                          onClick={() => {
                            setOpenViewPlanModal(true);
                          }}
                        >
                          View Plans
                        </Link>
                      </FormControl>
                    )}
                  </Stack>

                  {mode !== "Edit" && (
                    <Stack direction="row" spacing={7}>
                      {/* {(mode === "Edit") ? (
                      <TextField
                        type="text"
                        className="userfield"
                        label="Client Id"
                        variant="outlined"
                        name="clientId"
                        size="small"
                        value={selectedClientData?.clients?.clientId}
                        sx={{ width: "100%" }}
                        // disabled={!isEditable}
                        disabled
                        onChange={(event) =>
                          handleInputChange("clientId", event?.target.value)
                        }
                        InputProps={{
                          readOnly: mode !== "Edit",
                        }}
                      />
                    ) : ( */}
                      <TextField
                        type="text"
                        label="Number Of Invitations"
                        // className="userfield"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        // sx={{ width: "50%" }}
                        size="small"
                        name="noOfInvitations"
                        // InputProps={{ readOnly: mode !== "Edit" }}
                        InputLabelProps={{ shrink: true }}
                        value={selectedPlanDetails?.numberOfInvitations}
                        // onChange={handleNumberChange}
                        required
                        disabled
                      />

                      <TextField
                        type="text"
                        label="Number Of Users"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="noOfUsers"
                        value={selectedPlanDetails?.numberOfUsers}
                        InputProps={{ readOnly: mode !== "Edit" }}
                        InputLabelProps={{ shrink: true }}
                        disabled
                        required
                      />
                    </Stack>
                  )}
                  <Stack direction="row" spacing={8}>
                    <div style={{ display: "flex", width: "44%" }}>
                      {mode === "Edit" && (
                        <label
                          htmlFor="logoUploadInput"
                          style={{ width: "100%" }}
                        >
                          <Button
                            className="logouploadcss"
                            component="span"
                            fullWidth
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="logoUploadInput"
                            />
                            {selectedClientData?.clients?.clientLogo ? (
                              <img
                                src={`data:image/png;base64,${selectedClientData?.clients.clientLogo}`}
                                alt="Uploaded Logo"
                                style={{
                                  width: "50%",
                                  height: "80px",
                                  margin: "auto",
                                }}
                              />
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                  id="logoUploadInput"
                                />
                                Logo Upload
                              </>
                            )}
                            <CloudUploadIcon sx={{ color: "#fe524a" }} />
                          </Button>
                        </label>
                      )}
                    </div>
                  </Stack>
                  {mode !== "Edit" && (
                    <Stack direction="row" spacing={7}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Subscription Start Date"
                          minDate={
                            dayjs(minDate) ?? dayjs()
                            // selectedClientData?.planStartDate !== undefined ||
                            // selectedClientData?.planStartDate !== null
                            //   ? dayjs(selectedClientData.planStartDate)
                            //   : dayjs()
                          }
                          disabled={startDateEditable}
                          slotProps={{ textField: { size: "small" } }}
                          sx={{ width: "100%" }}
                          value={dayjs(selectedClientData?.planStartDate)}
                          onChange={(date: dayjs.Dayjs | null) => {
                            const timePeriod =
                              selectedPlanDetails?.timePeriod || 0;
                            console.log("time period : ", timePeriod);
                            setSelectedClientData((prev: any) => ({
                              ...prev,
                              planStartDate: date
                                ? formatDateStr(
                                    date.toDate(),
                                    "YYYY-MM-DD ",
                                    true
                                  )
                                : null,
                              planEndDate: date
                                ? dayjs(date)
                                    .add(timePeriod, "month")
                                    .format("YYYY-MM-DD")
                                : null,
                            }));
                          }}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Subscription End Date"
                          slotProps={{ textField: { size: "small" } }}
                          sx={{ width: "100%" }}
                          readOnly
                          disabled
                          // minDateTime={
                          //   selectedClientData?.planStartDate
                          //     ? dayjs(selectedClientData?.planStartDate).add(20, "day")
                          //     : dayjs()
                          // }
                          // onChange={(date: dayjs.Dayjs | null) => {
                          //   setSelectedClientData((prev: any) => ({
                          //     ...prev,
                          //     planEndDate: date
                          //       ? formatDateStr(
                          //         date.toDate(),
                          //         "YYYY-MM-DD HH:mm:ss",
                          //         true
                          //       )
                          //       : null,
                          //   }));
                          // }}
                          value={dayjs(selectedClientData?.planEndDate)}
                        />
                      </LocalizationProvider>
                    </Stack>
                  )}
                  {/* <FormControlLabel control={<Checkbox checked={editable} onChange={handleCheckBox} />} label="Enable Edit" onChange={handleCheckBox} /> */}
                </Stack>
              </Box>
              <Box>
                <Stack
                  direction="row"
                  marginTop="3%"
                  justifyContent="flex-end"
                  spacing={2}
                  marginBottom="3%"
                >
                  <Button
                    variant="outlined"
                    className="csButton"
                    onClick={() => {
                      mode === "Renew" || mode === "Edit"? onBack() : window.location.reload();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenConfirmModal(true);
                    }}
                    disabled={mode === "Edit"?disableConfirm:!areFieldsFilled() }
                  >
                    Confirm
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <ToastContainer position="bottom-left" />
        </Box>
      )}
      {showAddPlan && (
        <NewSubscriptionDetails
          setNewPlan={AddNewPlanIntoListOfPlans}
          onBack={() => {
            setShowAddPlan(false);
          }}
        />
      )}
      <CustomModal
        open={openConfirmModal}
        heading="Session Dialog"
        cancelButton={{
          label: "Cancel",
          onClick: () => {
            setOpenConfirmModal(false);
          },
        }}
        continueButton={{ label: "Confirm", onClick: handleConfirm }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />

          <Typography fontWeight="bold">
            {mode === "Edit"
              ? "Are you sure, do you want to edit this client"
              : "Are you sure, you want to subscribe this plan"}
          </Typography>
        </Stack>
      </CustomModal>
      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleSessionModalCancel}
        onContinue={handleSessionModalContinue}
      ></SessionExtensionModal>
      <CustomModal
        open={openClientPlanExpiredModal}
        cancelButton={{ label: "Sign out", onClick: handleSessionModalCancel }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
          <Typography fontWeight="bold">
            Your subscription plan has expired; for further information, please
            contact our RS_ADMIN.
          </Typography>
        </Stack>
      </CustomModal>
      <CustomModal
        open={openViewPlanModal}
        heading="Subscription Plans"
        cancelButton={{
          label: "Close",
          onClick: () => setOpenViewPlanModal(false),
        }}
      >
        <Stack direction="row">
          {/* <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} /> */}
          <Typography>
            <TableContainer sx={{ maxHeight: 500 }} className="tableBody">
              <Table>
                <TableHead className="table-row-odd">
                  <TableRow>
                    {/* <TableCell className="tableHeader">Select</TableCell> */}
                    <TableCell className="tableHeader">Plan Name</TableCell>
                    <TableCell className="tableHeader">
                      Plan Period(months)
                    </TableCell>
                    <TableCell className="tableHeader">No of Users</TableCell>
                    <TableCell className="tableHeader">
                      No of Invitations
                    </TableCell>
                    <TableCell className="tableHeader">
                      Amount($/month)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionPlans.length > 0 &&
                    subscriptionPlans.map((plan, index) => (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className={
                          plan?.id === selectedPlanDetails?.id
                            ? "selected-row"
                            : index % 2 === 0
                            ? "table-row-even"
                            : "table-row-odd"
                        }
                      >
                        {/* <TableCell>
                          <Radio
                            name="selectedClient"
                            checked={selectedPlanDetails?.id === plan?.id}
                            onClick={() => {
                              if (
                                selectedPlanDetails &&
                                selectedPlanDetails?.id === plan?.id
                              ) {
                                setSelectedPlanDetails(undefined);
                              } else {
                                setSelectedPlanDetails(plan);
                              }
                            }}
                            className="radiocss"
                          />
                        </TableCell> */}
                        <TableCell>{plan?.planName}</TableCell>
                        <TableCell>{plan?.timePeriod}</TableCell>
                        <TableCell>{plan?.numberOfUsers}</TableCell>
                        <TableCell>{plan?.numberOfInvitations}</TableCell>
                        <TableCell>{plan?.cost}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Stack>
      </CustomModal>
      <Backdrop
          sx={{ color: "red", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </ThemeProvider>
  );
};

export default SubscriptionDetails;
