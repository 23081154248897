
const assessment_host = process.env.REACT_APP_BASE_URL;

export const getClientThankYouData = (organization:string) => {

    const apiUrl = `${assessment_host}/clients/getClientDataForThankYou/${organization}`;
    return(fetch(apiUrl,{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }))
}