import React, { useEffect, useState } from "react";
import "./CandidateReports.css";

import {
  Button,
  createTheme,
  Box,
  StyledEngineProvider,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TextField,
  TableHead,
  Paper,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TableBody,
  Typography,
  CardMedia,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ExportToCsv } from "export-to-csv";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLocation, useNavigate } from "react-router-dom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import UserService from "../../../clients/UserService";
import { AssessmentHeaderInterface } from "../../../types/AssessmentHeaderInterface";
import { CandidateInformationInterface } from "../../../types/CandidateInformationInterface";
import { string } from "prop-types";
import { dsgTheme } from "../../../styles/theme";
import Item from "antd/es/list/Item";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";
import Pagination from "src/components/pagination/Pagination";
import TokenService from "../../../clients/TokenService";
import { handleDownloadApiCall, handlegetVideo } from "src/clients/CandidateReportsClient";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import { CustomModal } from "src/components/modal/CustomModal";
import { ButtonInterface } from "src/types/ButtonInterface";
import { get } from "lodash";
import { UserDtoInterface } from "src/components/admin/dummydata/UserDtoInterface";
import CreateUserClass from "src/components/admin/create-user/CreateUserClass";
interface CandidateReportDT {
  sectionName: string;
  actualAnswers: string[];
  questions: string[];
  candidateAnswer: string[];
}
interface CandidateReportsDTO {
  candidateReports: CandidateReportDT[];
}
interface CandidateResponseHeader {
  sectionId: number;
  sectionScore: number;
  skillName: string;
  correctAnswers: number;
  wrongAnswers: number;
  sectionName: string;
  sectionTotalScore: number;
  responseId: number;
  sectionStartTimestamp: Date;
  sectionEndTimestamp: Date;
}


const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

      width: 200,
    },
  },
};

const CandidateReports = () => {
  const [candidateData, setCandidateData] = useState<
    CandidateInformationInterface[]
  >([]);

  
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
    const [backdrop, setBackDrop] = React.useState(false);
  const [candidateReports, setCandidateReports] = useState<CandidateReportsDTO>(
    {
      candidateReports: [],
    }
  );
  const [college, setCollege] = React.useState<string[]>([]);

  const [percent, setPercent] = React.useState("");

  const [time, setTime] = React.useState("");

  // const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 5;

  const [reload,setReload] = useState<boolean>(false);

  const navigate = useNavigate();

  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  var storedCombinedString = localStorage.getItem("assessment");

  if (storedCombinedString != null) {
    var obj = JSON.parse(storedCombinedString);
  }

  const getTimeDifferenceInMinutes = (s: string, e: string): number => {
    const startDate = new Date(s);

    const endDate = new Date(e);

    const diffInMilliseconds = endDate.getTime() - startDate.getTime();

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    return diffInMinutes;
  };

  function sortByColleges () {
    const filterOutData = filterData.filter((val) => college.includes(val.college));
      return filterOutData.sort((a, b) => {
      // If both values start with "-", compare them normally
      if ((a.college === null || a.college === '') && (b.college === null || b.college === '')) {
        return 0;
      }
      
      // If only 'a' starts with "-", push it to the end
      if (a.college === null || a.college === '') {
        return 1;
      }

      // If only 'b' starts with "-", push it to the end
      if (b.college === null || b.college === '') {
        return -1;
      }

      // Otherwise, compare alphabetically
      return a.college.localeCompare(b.college)
    })
  }

  let filterData =
    percent === ""
      ? candidateData
      : candidateData.filter(
        (val) => (val.totalScore / obj.totalMarks) * 100 >= Number(percent) && String(val.result) !== "NOT_EVALUATED"
      );

  filterData =
    time === ""
      ? filterData
      : filterData?.filter(
        (val) =>
         val.totalTimeTaken >= Number(time)
      );

  // filterData =skill.length===0?filterData:filterData.filter(val=>skill.includes(val.skills))

  filterData =
    college.length === 0
      ? filterData
      : sortByColleges();

  const len = filterData.length;
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState("5");

  const rowsPerPageArray = ["5", "10", "15"];
  const questionsPerPage = Number(rows);

  const indexOfLastQuestion = currentPage * questionsPerPage;

  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  // const totalPages = Math.ceil(filterData.length / rowsPerPage);

  // const startIndex = (currentPage - 1) * rowsPerPage;

  // const endIndex = startIndex + rowsPerPage;

  const currentData = filterData.slice(indexOfFirstQuestion, indexOfLastQuestion);


  const clgList = Array.from(
    new Set(candidateData.map((item) => item.college))
  );

  useEffect(() => {
    // Fetch data when the component mounts

    fetchData();
  }, []);



  //const[sectionName,setSectionName]=useState<String[]>( candidateData.f.sectionDTOList.map((section) => section.sectionName))
  let sectionarray: string[] = []
  candidateData.forEach((candidate) => candidate.sectionDTOList.forEach((section) => sectionarray.push(section.sectionName)))
  var setValues = new Set(sectionarray);


  const columns = [
    "First Name",
    "Last Name",
    "Email",
    "College",
    "Last Degree",
    "Specialization",
    "Year Passed",
    "Total Marks",
    "Status",
    "Time Taken(in min)",
    ...Array.from(setValues)
  ];






  const fetchData = async () => {
    // Perform the API request or any asynchronous operation here
    setReload(true);
    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,

        // token: this.LoginUserObject.token
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/reports/assessment/${obj.id}`,

      config
    )
      .then(async (response) => {
        if (response.status === 402) {
          setReload(false);
          setClientPlanExpiredModal(true)
        } else if (response.status === 401) {
          setReload(false);
          setOpenSessionModal(true);

        } else {
          setReload(false);
          const data = await response.json()
          console.log("data", data)
          setCandidateData(data);
          setCurrentPage(1);
        }


      })

      .catch((error) => {
        setReload(false);
        if (error.response.status == 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true)
        } else {
          console.log(error);
        }
      });
  };

  const handleChangeCollege = (event: SelectChangeEvent<typeof college>) => {
    const {
      target: { value },
    } = event;

    setCollege(
      // On autofill we get a stringified value.

      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleModalSignOut = () => {
    //3

    navigate("/login");
  };

  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",

    month: "2-digit",

    year: "numeric",
  });

  const updatedFilterData = filterData.map((val) => {


    const start = val.actualStartTimestamp;

    const end = val.actualEndTimestamp;

    const timeTaken = getTimeDifferenceInMinutes(start, end);

    return { ...val, timeTaken };
  });

  const handleExport = () => {
    const csvExporter = new ExportToCsv({
      filename: obj.assessmentName + " Reports(" + formattedDate + ")",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: columns.map((column) => column),
    });

    const csvData = filterData.map(({
      id,
      assessmentId,
      status,
      phoneNumber,
      registeredTimestamp,
      actualStartTimestamp,
      actualEndTimestamp,
      sectionDTOList,
      ...rest
    }) => {


      const sectionScores: { [key: string]: number } = {};

      // Iterate through sectionDTOList to dynamically extract section scores
      sectionDTOList.forEach((section) => {
        sectionScores[section.sectionName] = section.sectionScore;
      });
      
      // Merge section scores with the rest of the data
      return {
        ...rest,
        ...sectionScores,
      };
    });
    console.log(csvData,"csvData")
    csvExporter.generateCsv(csvData);
  };

  const handleSubmit = (
    item: React.SetStateAction<CandidateInformationInterface | null>
  ) => {
    var combinedString = JSON.stringify(item);

    localStorage.setItem("candidate", combinedString);

    navigate("/specificReport");
  };
  const handleBack = () => {
    navigate("/assessment");
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRows(event.target.value);
    setCurrentPage(1);
  };
  function removeHtmlTags(input: string): string {
    const div = document.createElement('div');
    div.innerHTML = input;
    return div.textContent || div.innerText || '';
  }
  // Assuming candidateReports is the array of data received from the backend
  // ... your data here
  function convertToExcel(candidateReports: CandidateReportsDTO, item: any) {
    if (!candidateReports.candidateReports.length) {
      console.error("Candidate reports array is empty.");
      return;
    }

    const workbook = XLSX.utils.book_new();
    let name = "";

    const excelRowHeight = 20; // Updated row height

    candidateReports.candidateReports.forEach((report, index) => {
      const mainHeader =
        obj.assessmentName + " " + report.sectionName + " Reports";
      const sheetName = report.sectionName;
      const csvData: (string | null)[][] = [];
      csvData.push([mainHeader]);

      // Add column headers
      const columnHeaders = ["Questions", "Anticipated Answer", "Candidate Response"];
      csvData.push(columnHeaders);

      // Loop through the questions and create rows
      for (let i = 0; i < report.questions.length; i++) {
        const question = removeHtmlTags(report.questions[i]);
        const actualAnswer = report.actualAnswers[i] === undefined || report.actualAnswers[i] === null ? "-" : removeHtmlTags(report.actualAnswers[i]);
        const candidateAnswer = removeHtmlTags(report.candidateAnswer[i]) || "Not Answered";

        const rowData = [question || "", actualAnswer, candidateAnswer];
        csvData.push(rowData);
      }

      name = item.firstName;
      const worksheet = XLSX.utils.aoa_to_sheet(csvData);

      // Calculate cell widths based on content lengths
      const maxColumnWidths = [0, 0, 0]; // For Questions, Actual Answers, Candidate Answer

      for (let i = 2; i < csvData.length; i++) {
        for (let j = 0; j < 3; j++) {
          const cellContent = csvData[i][j];
          if (cellContent && cellContent.length > maxColumnWidths[j]) {
            maxColumnWidths[j] = cellContent.length;
          }
        }
      }



      worksheet["!cols"] = [
        { wch: maxColumnWidths[0] }, // Questions column width
        { wch: maxColumnWidths[1] }, // Actual Answers column width
        { wch: maxColumnWidths[2] }, // Candidate Answer column width
      ];

      for (let i = 2; i < csvData.length; i++) {
        worksheet["!rows"] = worksheet["!rows"] || [];
        worksheet["!rows"][i] = { hpt: excelRowHeight, hpx: excelRowHeight };
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, name + "_reports.xlsx");
  }
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const handleCancelButton = () => {
    setOpenModal(false);
    setVideoUrl(null);
  };


  const cancelButton: ButtonInterface = {
    label: "Cancel",
    onClick: handleCancelButton,
  };

  const getVideo = async (candidateId: number) => {
    setBackDrop(true);
    try {
      // Create the full URL for the video endpoint
      // const videoStreamUrl = `http://localhost:8082/reports/get-video?videoFileName=video_16.mp4`;
      const response = await handlegetVideo(candidateId);
      if (response.ok) {
        console.log(response, "response")
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setBackDrop(false);
        setVideoUrl(url);
        setOpenModal(true);
        console.log(videoUrl, "videoUrl")
      } else if (response.status === 402) {
        setBackDrop(false);
        setClientPlanExpiredModal(true)
      } else if (response.status === 401) {
        setBackDrop(false);
        setOpenSessionModal(true);
      }
      else {
        setBackDrop(false);
        setOpenModal(true);
        console.error('Failed to fetch video');
      }

    } catch (error:any) {
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true)
      } else if (error.response.status === 401) {
        setOpenSessionModal(true);
      }else{
        console.error("Error fetching the video", error);
      }
    }


    // try
    // {
    //   const response=await handlegetVideo(organizationName,assessmentName,candidateId);
    //   if (response.ok) {
    //     const blob = await response.blob();
    //     const url = URL.createObjectURL(blob);
    //     setVideoUrl(url);
    //     setOpenModal(true);
    //   }else if (response.status === 402) {
    //     setClientPlanExpiredModal(true)
    //   } else if(response.status === 401){
    //     setOpenSessionModal(true);
    //   }
    //     else {
    //     console.error('Failed to fetch video');
    //   }
    // }
    // catch (error:any) {
    //   if (error.response.status === 402) {
    //     setClientPlanExpiredModal(true)
    //   } else if(error.response.status === 401){
    //     setOpenSessionModal(true);
    //   }
    //   console.error('Error while fetching video', error);
    // }


  }

  const handleDownload = async (item: any) => {

    try {
      const res = await handleDownloadApiCall(item.id);
      if (res.ok) {
        const data = await res.json();
        setCandidateReports(data); // Update the state with the fetched data
        convertToExcel(data, item);
      } else if (res.status === 402) {
        setClientPlanExpiredModal(true)
      } else if (res.status === 401) {
        setOpenSessionModal(true);
      }
    }
    catch (error: any) {
      if (error.response.status === 401) {
        setOpenSessionModal(true);
      } else if (error.response.status === 402) {
        setClientPlanExpiredModal(true)
      }

    }




  };

  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload();
      })
    })
  };
  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dsgTheme}>
        <Box sx={{ marginLeft: "5%", marginRight: "5%" }}>
          <Stack>
            <Typography variant="h5" className="typography">
              {obj.assessmentName} Report
            </Typography>
          </Stack>

          {/* <Stack className="heading" direction="row">
            <Typography className="left">
              Showing {startIndex + 1}-{Math.min(endIndex, len)} Students out of{" "}
              {len}
            </Typography>

            {/* <Stack direction="row" spacing={2} className="right">
              <Button
                className="pageBot"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                <NavigateBeforeIcon className="padd"></NavigateBeforeIcon>
              </Button>

              <Button
                className="pageBot"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                <NavigateNextIcon className="padd"></NavigateNextIcon>
              </Button>
            </Stack> */}
          {/* </Stack> */}
          <Stack className="heading" direction="row">
            <Stack>
              <Box sx={{ marginRight: "auto" }}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ fontSize: "small" }}>
                    Candidates per page
                  </Typography>
                  <FormControl variant="standard">
                    <Select
                      disableUnderline={true}
                      value={rows}
                      label="Age"
                      onChange={handleChange}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {rowsPerPageArray.map((value, index) => (
                        <MenuItem value={value} key={value + String(index)}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography sx={{ color: "#191F1C" }}>
                    {indexOfLastQuestion > 0 ? indexOfFirstQuestion + 1 : 0}-
                    {indexOfLastQuestion > filterData.length
                      ? indexOfLastQuestion -
                      (indexOfLastQuestion - filterData.length)
                      : indexOfLastQuestion}{" "}
                    out of {filterData.length}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Stack>
              <Pagination
                rows={rows}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalDataLength={filterData.length}
                dataPerPage={questionsPerPage}
              />
            </Stack>
          </Stack>
          <Stack>
            <Stack
              direction="row-reverse"
              spacing={2}
              className="reverse"
              sx={{ marginBottom: "8px", float: "right" }}
            >
              <TextField
                size="small"
                className="field"
                id="outlined-controlled"
                label="Time-taken(in Mins)"
                value={time}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPage(1);
                  setTime(event.target.value);
                }}
              />

              <TextField
                size="small"
                className="field"
                id="outlined-controlled"
                label="Percent"
                value={percent}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPage(1);
                  setPercent(event.target.value);
                }}
              />

              <FormControl sx={{ m: 1, width: 180 }} size="small">
                <InputLabel>College</InputLabel>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  className="demo-multiple-checkbox1"
                  multiple
                  value={college}
                  onChange={handleChangeCollege}
                  IconComponent={KeyboardArrowDownIcon}
                  label="College"
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {clgList.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={college.indexOf(name) > -1} />

                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer className="top">
              <Table className="table" aria-label="sticky-table">
                <TableHead>
                  <TableRow
                    className="tableHeader"
                    // sx={{ backgroundColor: "#faefef" }}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TableCell
                      className="cell"
                      // align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      Candidate Name
                    </TableCell>

                    <TableCell
                      className="cell"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      E-mail
                    </TableCell>

                    <TableCell
                      className="cell"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      College
                    </TableCell>

                    <TableCell
                      className="cell"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      Percentage
                    </TableCell>

                    <TableCell
                      className="cell"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      Time-taken(in Mins)
                    </TableCell>
                    <TableCell
                      className="cell"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className="cell"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      Video
                    </TableCell>


                    <TableCell className="cell2"> </TableCell>
                  </TableRow>
                </TableHead>

                {currentData.map((item, index) => {
                  return (
                    <TableBody>
                      <TableRow
                        key={index}
                        className={index % 2 != 0 ? "evenFlex1" : "oddFlex1"}
                      >
                        <TableCell className="cell1" >
                          {String(item.result) === "NOT_ATTEMPTED" ?
                            `${item.firstName == null ? '-' : `${item.firstName} ${item.lastName}`}` :
                            <a
                              href=""
                              onClick={() => {
                                handleSubmit(item);
                              }}
                            >
                              {item.firstName} {item.lastName}
                            </a>
                          }
                        </TableCell>

                        <TableCell className="cell1" align="center">
                          {item.emailId}
                        </TableCell>

                        <TableCell className="cell1" align="center">
                          {item.college == null ? '-' : item.college}
                        </TableCell>

                        <TableCell className="cell1" align="center">
                          {String(item.result) === "NOT_EVALUATED" || String(item.result) === "NOT_ATTEMPTED" ? "-" :
                            item.totalScore > 0 ? Math.floor((item.totalScore / obj.totalMarks) * 100) : 0}
                        </TableCell>

                        <TableCell className="cell1" align="center">
                          {item.totalTimeTaken}
                        </TableCell>
                        <TableCell className="cell1" align="center">
                          {String(item.result).split("_").join(" ")}
                        </TableCell>
                        <TableCell className="cell1" align="center">
                          <Button
                            className="pageBot"
                            onClick={() => {
                              getVideo(item.id);
                            }}
                            disabled={String(item.result) === "NOT_ATTEMPTED"}
                          >

                            {<SmartDisplayIcon />}
                          </Button>
                          
                            <CustomModal
                              open={openModal}

                              // onClose={handleCloseModal}
                              continueButton={cancelButton}

                            >
                              {videoUrl ?(<video
                                disablePictureInPicture
                                disableRemotePlayback
                                controlsList="nodownload"
                                controls
                                loop
                                autoPlay={false}
                                width="400px"
                                height="400px"
                              >
                                <source src={videoUrl} />
                              </video>):"Video Not Exists"}
                            </CustomModal>
                        </TableCell>
                        <TableCell className="cell2" align="center">
                          <Button
                            disabled={String(item.result) === "NOT_EVALUATED" || String(item.result) === "NOT_ATTEMPTED"}
                            className="pageBot"
                            onClick={() => {
                              handleDownload(item);
                            }}
                          >
                            {<FileDownloadIcon />}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </Paper>

          {/* <Stack className="flex" direction="row" spacing={1}> */}

          <Button
            className="bot2"
            onClick={() => {
              handleBack();
            }}
          >
            Back
          </Button>

          <Button
            className="bot1"
            sx={{ float: "right" }}
            variant="contained"
            // disabled={candidateData.some((data) => String(data.result) === "NOT_EVALUATED" || String(data.result) === "NOT_ATTEMPTED")}
            onClick={handleExport}
            endIcon={<FileDownloadIcon />}
          >
            Download
          </Button>

          {/* </Stack> */}

          <SessionExtensionModal
            open={openSessionModal}
            onClose={handleModalCancel}
            onContinue={handleModalContinue}
          >
          </SessionExtensionModal>
          
          <CustomModal
            open={openClientPlanExpiredModal}
            cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
            continueButton={{ label: "", onClick: handleModalCancel }}
          >
            <Stack direction="row" spacing={1}>
              <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
              </Typography>
            </Stack>
          </CustomModal>
          <Backdrop
          sx={{ color: "red", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default CandidateReports;
