import React, { useState, useEffect } from 'react';
import { Stack, Typography, Grid,Box, ThemeProvider, Button } from '@mui/material';
import Container from '@mui/material/Container';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';
import UserService from '../../../clients/UserService';
import CreateUserClass from '../create-user/CreateUserClass';
import {  useNavigate } from 'react-router-dom';
import {SessionExtensionModal} from "../list-of-users/SessionExtensionModal";
import { dsgTheme } from '../../../styles/theme';
import TokenService from 'src/clients/TokenService';
import { CustomModal } from '../list-of-users/CustomModal';
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";



export const ResetPassword = () => {
  const [PreviousPassword, setPreviousPassword] = useState('');
  const [showPreviousPassword, setShowPreviousPassword] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState<{ valid: boolean; message: string }[]>([]);
  const [NewPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [Instructions, setInstructions] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [userData,setuserData]=useState(CreateUserClass.User)
  const[openSessionModal,setOpenSessionModal]=useState<boolean>(false);
  const[limitModal,setLimitModel]=useState<boolean>(false);
  

  const navigate=useNavigate();
  

  const checkPasswordStrength = (password: string) => {
    const validations = [
      { regex: /[a-z]/, message: 'At least one lowercase letter,' },
      { regex: /[A-Z]/, message: 'uppercase letter,' },
      { regex: /[0-9]/, message: 'digit,' },
      { regex: /[!@#$%^&*()\-_=+[\]{}|;:'",.<>?/~`]/, message: 'special character,' },
      { regex: /.{8,}/, message: 'Minimum 8 characters' },
    ];

    return validations.map(({ regex, message }) => ({
      valid: regex.test(password),
      message,
    }));
  };



  const handleModalCancel = () => {
  
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res)=>{
      UserService.extendToken(res.data).then((res1)=>{
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
          })
  };

  useEffect(()=>{
setuserData(UserService.getLoginUserObject());
  },[])

  useEffect(() => {
    setPasswordValidations(checkPasswordStrength(NewPassword));
  }, [NewPassword]);

  const handlePreviousPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousPassword(event.target.value);
    setPasswordError(false);
    setInstructions(false);
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    setPasswordError(false);
    setInstructions(true);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError(false);
    setConfirmPassword(event.target.value);
    setInstructions(false);
  };




  const handleNewPasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordVisibility = () => {

    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePreviousPasswordVisibility = () => {
    setShowPreviousPassword((prevShowPassword) => !prevShowPassword);
  };




  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_=+[\]{}|;:'",.<>?/~`]).{8,}$/;
  
   UserService.checkByUsernameAndPassword(UserService.getLoginUserObject().email,PreviousPassword).then((res)=>{
  console.log(res.data)
    if(res.data)
    {
      
      if (NewPassword.match(passwordRegex)) {
        if (NewPassword !== confirmPassword) {
          toast.error('Confirm Password does not match with New Password',{autoClose:1000});
        
        } 
        else
        {
         
          UserService.updatePassword(UserService.getLoginUserObject().email,NewPassword).then((res)=>{
          console.log(res.data)
            if(res.data=='OLD Password')
            {
              toast.error("Matches Old Password",{autoClose:1000})
            }
            else if(res.data=="UsersLimitExceded")
            {
            console.log("inside UsersLimitExceded")
setLimitModel(true)
            }
            else
            {
              console.log("inside UsersLimitExceded")
            toast.success('Password changed successfully',{autoClose:1000});
            if(UserService.getLoginUserObject().userName==undefined)
            {
             navigate('/login') 
            }
            
            else{
        
              setuserData((prevState) => {
                // Update the status to "ACTIVE"
                const updatedState = {
                    ...prevState,
                    status: "ACTIVE"
                };
            
                // Execute setLoginUserObject after the status is updated
                UserService.setLoginUserObject(updatedState);
            
                // Log the updated data
                console.log(updatedState);
            
                // Navigate to the desired location
                navigate(`/assessmentlist?displaySuccess=${true}`);
            
                // Reload the window
                // window.location.reload();
            
                // Return the updated state
                return updatedState;
            });
            
            }
          }
            
          }).catch(error=>{
            if(error.response.status==401)
            {
        setOpenSessionModal(true);
            }
            else if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
            }
          })
          
        }
      } else {
        toast.error('Password is too weak',{autoClose:1000});
      
      }
    }
    else{
      setPasswordError(true);
    }
   })
    
  };


  return (
  <ThemeProvider theme={dsgTheme}>
     
  <div>
    
    <Container className="changepassword-container">
      <Container className="changepassword-form-container">
        <form onSubmit={handleSubmit}>
         
            <Typography variant='h5' sx={{paddingBottom:'4%'}}>Reset Password</Typography>

          
            <Box sx={{paddingBottom:"2%",}}>
            {passwordError && (
              <Typography className="password-message" >
               Ouch! please check your password
              </Typography>
            )}
          </Box>
          <Stack direction="column" alignItems="center" spacing={2}>
          <TextField
              type={showPreviousPassword ? 'text' : 'password'}
              fullWidth
              id="filled-basic"
              size="small"
              value={PreviousPassword}
              label="Current Password"
              variant="outlined"
              onChange={handlePreviousPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePreviousPasswordVisibility} edge="end">
                      {showPreviousPassword ?  <Visibility /> :  <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            

            <TextField
              type={showPassword ? 'text' : 'password'}
              fullWidth
              id="filled-basic"
              size="small"
              value={NewPassword}
              label="New Password"
              variant="outlined"
              onChange={handleNewPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleNewPasswordVisibility} edge="end">
                      {showPassword ?  <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            
                      </Stack>
                     
            {Instructions && (
              <Grid container direction="row">
                {passwordValidations.map((validation, index) => (
                  <Grid item key={index}>
                    <Typography variant="caption" color={validation.valid ? 'green' : 'error'}>
                      {validation.message + '\t'}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}

              <Stack direction="column" alignItems="center" spacing={2} paddingTop="5%">

            <TextField
              id="standard-password-input"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              value={confirmPassword}
              size="small"
              autoComplete="current-password"
              variant="outlined"
              onChange={handleConfirmPasswordChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleConfirmPasswordVisibility} edge="end">
                      {showConfirmPassword ? <Visibility /> :  <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button type="submit">
             Update
            </Button>
</Stack>
        </form>
        
      </Container>
      
    </Container>
    
      <ToastContainer position='bottom-left'/>

      <SessionExtensionModal
          open={openSessionModal}
          onClose={handleModalCancel}
          onContinue={handleModalContinue}
        >
        </SessionExtensionModal>
        <CustomModal
          open={limitModal}

          heading="Session Dialog"
          cancelButton={{ label: "Okay", onClick: handleModalCancel }}

        >
          <Stack direction="row" spacing={1}>
            <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />


            <Typography fontWeight="bold">
              
Your password has been changed successfully. However, your subscription user limit has been reached. Please contact the Redshift Admin for further assistance.
            </Typography>
          </Stack>
        </CustomModal>
        <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
             
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
    </div>
    </ThemeProvider>
    
  );
};
