import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./CreateQuestionStyles.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preview from "./Preview";
import UserService from "../../../clients/UserService";
import { dsgTheme } from "../../../styles/theme";
import TokenService from "../../../clients/TokenService";
import PreviewQuestions from "../../assessment/preview-assessment/PreviewAssessment";
import QuestionBank from "../filter-questions/QuestionBank";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import { Question, defaultQuestion } from "./QuestionInterface";
import CreateQuestionService from "src/clients/CreateQuestionService";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";

interface ObjectState {
  questionText: string;
  executionText: string;
  answerText: string;
  positiveMarks: number;
  questionType: string;
  difficulty: string;
  skill: string;
  timeToSolve: number;
  negativeMarks: number;
  maximumCharacters: number;
  addInfo: string;
  isBulkUpload: string;
  id: number;
  tags: string;
  baseFlag: string;
  language: string[];
  visibility: boolean;
  createdDate: string | null;
  updatedDate: string | null;
}

interface PreviewProps {
  questionId: any;
  assessmentId: any;
  setQuestionId: any;
  headerId: any;
  mode: string;
  onBack: () => void;
}

//Initial state of the Object
const initialState: ObjectState = {
  questionText: "",
  executionText: "",
  answerText: "",
  positiveMarks: 1,
  questionType: "",
  difficulty: "",
  skill: "",
  timeToSolve: 1,
  negativeMarks: 0,
  maximumCharacters: 0,
  addInfo: "",
  isBulkUpload: "",
  id: 0,
  tags: "",
  baseFlag: "",
  language: [],
  visibility: false,
  createdDate: null,
  updatedDate: null,
};

const CreateQuestion = ({
  questionId,
  assessmentId,
  setQuestionId,
  headerId,
  mode,
  onBack: onBack,
}: PreviewProps) => {
  //Declaring the react-router-components
  const navigate = useNavigate();
  // const location = useLocation();

  //this is from preview asssement to update question
  //  const params = location.state?.questionId;
  //  const assessmentId = location.state?.assessmentId ;

  //  //This is from question details to create a question
  //  const headerId = location.state?.headerId;
  //  const mode = location.state?.mode;

  //Declaring the other variables
  const [ids, setIds] = useState<number[]>([]);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [object, setObject] = useState<Question>(defaultQuestion);
  const [clonedObject, setClonedObject] = useState<Question>(defaultQuestion);
  const [options, setOptions] = useState<string[]>([""]);
  const [clonedOptions, setClonedOptions] = useState<string[]>([]);
  const [correctAnswer, setCorrectAnswer] = useState<number>(0);
  const [clonedCorrectAnswer, setClonedCorrectAnswer] = useState<number>();
  const [clonedCheckCorrectAnswer, setClonedCheckCorrectAnswer] =
    useState<(number | null)[]>();
  const [checkCorrectAnswer, setCheckCorrectAnswer] = useState<
    (number | null)[]
  >([]);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [buttonFlag, setButtonFlag] = useState<boolean>();
  const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1/questionBank/`;
  const baseURL2 = `${process.env.REACT_APP_BASE_URL}/Masterdata/name/type/`;
  const formRef = useRef<HTMLFormElement>(null);
  const [createdDate, setCreatedDate] = useState<String>();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [showCreateQuestion, setShowCreateQuestion] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showQuestionBank, setShowQuestionBank] = useState<boolean>(false);
  const [previewCheck, setPreviewCheck] = useState<boolean>(true);
  const [updateButtonFlag, setUpdateButtonFlag] = useState<boolean>(true);
  const [tempFlag, setTempFlag] = useState<number>(0);
  const [descriptiveFlag, setDescriptiveFlag] = useState<boolean>(false);
  const [codingFlag, setCodingFlag] = useState<boolean>(false);
  //Multiple correct answers
  const [checkboxes, setCheckboxes] = useState(() => {
    const initialCheckboxes = options.map((option, index) => ({
      name: `Option ${index + 1}`,
      checked: false,
    }));
    return initialCheckboxes;
  });

  //Dropdown Lists
  let [difficultyList, setDifficultyList] = useState<string[]>([]);
  let [skillList, setSkillList] = useState<string[]>([]);
  
  let [questionTypeList, setQuestionTypeList] = useState<string[]>([]);
  const [languageList, setLanguageList] = useState<string[]>([]);
  //Setting the dropdown values before launching
  useEffect(() => {
    fetch(baseURL2 + `QuestionType`, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          setOpenSessionModal(true);
          throw new Error("Unauthorized: Please log in.");
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setQuestionTypeList(data);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
          console.log(error.response.data.message, "error");
        }
        console.error("Error:", error);
      });

    fetch(baseURL2 + `Language`, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          setOpenSessionModal(true);
          throw new Error("Unauthorized: Please log in.");
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setLanguageList(data);
        console.log("LANguageLIst : ", data);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
        console.error("Error:", error);
      });

    fetch(baseURL2 + `difficulty`, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          setOpenSessionModal(true);
          throw new Error("Unauthorized: Please log in.");
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setDifficultyList(data);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
        console.error("Error:", error);
      });

    fetch(baseURL2 + `skill`, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          setOpenSessionModal(true);
          throw new Error("Unauthorized: Please log in.");
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setSkillList(data);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
        console.error("Error:", error);
      });
  }, []);

  const formatDateToString = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //This will work only when edit function
  useEffect(() => {
    if (questionId) {
      getQuestionById();
      setButtonFlag(true);
    }
  }, []);

  
  const getQuestionById = () => {
    fetch(baseURL + `createQuestion/question/${questionId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          setOpenSessionModal(true);
          throw new Error("Unauthorized: Please log in.");
        } else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        const question = data;
        console.log(question);
        const updatedObject: Question = {
          id: question.id,
          questionText: question.questionText,
          executionText: question.executionText,
          questionType: question.questionType,
          difficulty: question.difficulty,
          skill: question.skill,
          addInfo: question.addInfo,
          isBulkUpload: question.isBulkUpload,
          timeToSolve: question.timeToSolve,
          maximumCharacters: question.maximumCharacters,
          positiveMarks: question.positiveMarks,
          negativeMarks: question.negativeMarks,
          tags: question.tags,
          baseFlag: question.baseFlag,
          language: question.language,
          visibility: question.visibility == "PUBLIC" ? true : false,
          createdDate: formatDateToString(new Date(question.createdDate)),
          updatedDate: question.updatedDate
            ? formatDateToString(new Date(question.updatedDate))
            : null,
        };
        const parsedDate = new Date(question.createdDate);
        setCreatedDate(formatDateToString(parsedDate));
        setObject(updatedObject);
        const optionsData = data.answers.map(
          (answer: any) => answer.optionValue
        );
        setOptions(optionsData.length == 0 ? [""] : optionsData);
        const idsData = data.answers.map((answer: any) => answer.id);
        setIds(idsData);
        const optionValues = data.answers
          .filter((answer: any) => answer.answerFlag === true)
          .map((answer: any) => answer.optionId);
        if (optionValues.length === 1 && updatedObject.questionType == "MCQ") {
          // setCorrectAnswer((prevcorrectAnswer) => {return optionValues[0] });
          setCorrectAnswer(optionValues[0]);
        } else {
          setCheckCorrectAnswer(optionValues);
        }
      })
      .catch((error) => {
        if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        }
        console.error("Error:", error);
      });
  };

  //Input change for all textfield and React Quill for object
  const handleInputChange = (name: string, value: any) => {
    setObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleQuestionTextChange = (value: string) => {
    handleInputChange("questionText", value);
  };

  const handleAnswerTextChange = (value: string) => {
    handleInputChange("answerText", value);
  };

  const handleExecutionTextChange = (value: string) => {
    handleInputChange("executionText", value);
  };

  // Add a new empty option to the array
  const handleAddNewOption = () => {
    if (options.length < 4) {
      setOptions((prevOptions) => [...prevOptions, ""]);
    }
  };

  useEffect(() => {
    if (object.questionType == "DESCRIPTIVE") {
      setDescriptiveFlag(true);
      setCodingFlag(false);
    } else if (object.questionType == "CODING") {
      setCodingFlag(true);
    } else {
      setDescriptiveFlag(false);
      setCodingFlag(false);
    }
  }, [object.questionType]);

  useEffect(() => {
    if (tempFlag <= 1) {
      setClonedObject(object);
      setClonedCorrectAnswer(correctAnswer);
      setClonedCheckCorrectAnswer(checkCorrectAnswer);
      setClonedOptions(options);
      setTempFlag(tempFlag + 1);
    }
    if (
      JSON.stringify(clonedObject) !== JSON.stringify(object) ||
      correctAnswer != clonedCorrectAnswer ||
      JSON.stringify(checkCorrectAnswer) !==
        JSON.stringify(clonedCheckCorrectAnswer) ||
      JSON.stringify(clonedOptions) !== JSON.stringify(options)
    ) {
      setUpdateButtonFlag(false);
    } else {
      setUpdateButtonFlag(true);
    }
  }, [object, correctAnswer, options, checkCorrectAnswer]);

  //changing options data
  const handleOptionChange = (index: any, value: any) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };
  //Removing option
  const removeOption = (index: any) => {
    if (
      correctAnswer == null ||
      (correctAnswer == index + 1 && correctAnswer == options.length)
    ) {
      setCorrectAnswer(0);
    }
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });
  };

  const handleVisibilityChange = () => {
    setObject({ ...object, visibility: !object.visibility });
  };

  //Dropdowns handling
  const handleSelectChange = (event: SelectChangeEvent, label: any) => {
    setObject((prevState) => ({
      ...prevState,
      [label]: event.target.value,
    }));
  };

  const handleLanguageChange = (
    event: SelectChangeEvent<typeof object.language>
  ) => {
    const {
      target: { value },
    } = event;
    setObject((prevState) => ({
      ...prevState,
      language: typeof value === "string" ? value.split(",") : value,
    }));
  };

  //Multiple correct answer handling
  const handleCheckboxChange = (index: any) => (event: any) => {
    const updatedCheckboxes = checkboxes.map((checkbox, checkboxIndex) => {
      if (index === checkboxIndex) {
        return {
          ...checkbox,
          checked: event.target.checked,
        };
      }
      return checkbox;
    });
    setCheckboxes(checkboxes);
    const checkedOptions: (number | null)[] = updatedCheckboxes
      .map((checkbox, checkboxIndex) =>
        checkbox.checked ? checkboxIndex + 1 : null
      )
      .filter(Boolean);
    setCheckCorrectAnswer(checkedOptions);
  };

  const handleCheckboxdataChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  //Setting the correct answer for MCQ
  const handleSelectCorrectChange = (event: SelectChangeEvent<any>) => {
    setCorrectAnswer(event.target.value);
  };

  //This is for multiple correct answers
  const updateCheckboxes = () => {
    const newCheckboxes = options.map((option, index) => ({
      name: `Option ${index + 1}`,
      checked: checkCorrectAnswer.includes(index + 1),
    }));
    setCheckboxes(newCheckboxes);
  };

  //This is for updating the correctAnswers
  useEffect(() => {
    updateCheckboxes();
  }, [correctAnswer, options, checkCorrectAnswer]);

  const HTMLDecode = (htmlString: string) => {
    const decodeHtml = (html: any) => {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    };
    const decodedString = decodeHtml(htmlString);
    return decodedString; // Since we are not rendering anything in this component, just returning the value
  };

  //Handling the sending data to preview component
  const handleSendData = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsPreviewMode(true);
  };

  //Handling the return data from preview component
  const handleReturnData = () => {
    setIsPreviewMode(false);
  };

  const handleModalSignOut = () => {
    navigate("/login");
  };

  const handleCancel = (event: any) => {
    setQuestionId(undefined);
    onBack();
  };

  function modifyHTMLString(value: string): string {
    return value.replace(/<img[^>]+>/g, (match) => {
      return match.replace(/(src=['"][^'"]+['"])([^>]*)>/, '$1 width="40%"$2>');
    });
  }
  const convertToPlainText = (html: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  //This will work for both Edit and create
  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement>,
    flag: boolean
  ) => {
    const user = localStorage.getItem("currentUser");
    let userName;
    let organization: string = "";
    if (user) {
      userName = JSON.parse(user).userName;
      organization = JSON.parse(user).clientPlans.clients.clientName;
    }
    const questionText: string = modifyHTMLString(object.questionText);
    // const executionText: string = modifyHTMLString(object.executionText);
    const requestBody = {
      id: questionId ? questionId : object.id,
      questionText: questionText.replaceAll("<p><br></p>", ""),
      executionText:
        object.questionType == "CODING"
          ? !object.executionText
            ? null
            : object.executionText.replaceAll("<p><br></p>", "")
          : null,
      questionType: object.questionType,
      difficulty: object.difficulty,
      skill: object.skill,
      addInfo: object.addInfo,
      tags: object.tags,
      isBulkUpload: object.isBulkUpload,
      maximumCharacters:
        object.questionType == "CODING" ? 0 : object.maximumCharacters,
      timeToSolve: object.timeToSolve,
      positiveMarks: object.positiveMarks,
      negativeMarks: object.negativeMarks,
      createdDate: null,
      createdBy: UserService.getLoginUserObject().userName,
      updatedDate: null,
      updatedBy: buttonFlag ? UserService.getLoginUserObject().userName : null,
      status: null,
      language: object.questionType == "CODING" ? object.language : null,
      visibility: object.visibility ? "PUBLIC" : "PRIVATE",
      baseFlag: false,
      // organization == "Red-Shift Innovations Private Ltd"
      //   ? true
      //   : false,
      organization: organization,
      answers:
        descriptiveFlag || object.questionType == "CODING"
          ? null
          : options.map((option, index) => ({
              id: ids[index],
              // optionValue: option.slice(3, option.length - 4),
              optionValue: modifyHTMLString(option).replaceAll("<p><br></p>", ""),
              answerFlag:
                object.questionType === "MCQ"
                  ? option ===
                    options[correctAnswer === null ? 1 : correctAnswer - 1]
                    ? 1
                    : 0
                  : checkCorrectAnswer.includes(index + 1)
                    ? 1
                    : 0,
              optionId: index + 1,
            })),
    };
    console.log("request body : ", requestBody);
    event.preventDefault();
    const uniqueSet = new Set(options);
    const uniqueValues = Array.from(uniqueSet);
    if (formRef.current && formRef.current.checkValidity()) {
      for (let i = 0; i < options.length; i++) {
        const k = options[i].slice(3, options[i].length - 4);
        if (
          (k.trim() === "" || k === "<br>") &&
          !object.questionType.includes("DESCRIPTIVE") &&
          !object.questionType.includes("CODING")
        ) {
          toast.error("Please fill all options...", { autoClose: 1000 });
          return;
        }
      }
      if (convertToPlainText(object.questionText) === "") {
        toast.error("Please fill Question description", { autoClose: 1000 });
        return;
      } else if (
        options.length <= 1 &&
        !object.questionType.includes("DESCRIPTIVE") &&
        !object.questionType.includes("CODING")
      ) {
        toast.error("Please fill atleast 2 options...", { autoClose: 1000 });
        return;
      } else if (
        options.length !== uniqueValues.length &&
        !object.questionType.includes("DESCRIPTIVE") &&
        !object.questionType.includes("CODING")
      ) {
        toast.error("Options should be unique", { autoClose: 1000 });
        return;
      } else if (
        object.questionType === "MCQ" &&
        correctAnswer === 0 &&
        !object.questionType.includes("DESCRIPTIVE") &&
        !object.questionType.includes("CODING")
      ) {
        toast.error("Please fill correct answer", { autoClose: 1000 });
        return;
      } else if (object.timeToSolve == 0) {
        toast.error("Expected time should be more than 0", { autoClose: 1000 });
        return;
      } else if (
        (object.questionType === "MSQ" &&
          checkCorrectAnswer.length === 0 &&
          !object.questionType.includes("DESCRIPTIVE") &&
          !object.questionType.includes("CODING")) ||
        correctAnswer == null ||
        options.length < correctAnswer
      ) {
        toast.error("Please fill correct answers", { autoClose: 1000 });
        return;
      } else if (
        object.negativeMarks > object.positiveMarks &&
        !object.questionType.includes("DESCRIPTIVE") &&
        !object.questionType.includes("CODING")
      ) {
        toast.error("Negative marks must not be greater than Postive marks");
        return;
      } else if (
        object.maximumCharacters < 1 &&
        object.questionType == "DESCRIPTIVE"
      ) {
        toast.error("Maximum characters cannot be ZERO");
        return;
      } else {
        CreateQuestionService.createQuestion(requestBody)
          .then((response) => {
            if (response.status == 200) {
              if (response.data) {
                if (buttonFlag) {
                  toast.success("Updated Successfully", { autoClose: 1000 });
                  setTimeout(() => {
                    setQuestionId(undefined);
                    onBack();
                  }, 1000);
                } else {
                  if (flag) {
                    toast.success("Submitted Successfully", {
                      autoClose: 1000,
                    });
                    setTimeout(() => {
                      setQuestionId(undefined);
                      onBack();
                    }, 2000);
                  } else {
                    toast.success("Submitted Successfully", {
                      autoClose: 1000,
                    });
                    handleReset(event);
                  }
                }
              } else {
                toast.error("Question already exists", { autoClose: 1000 });
              }
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              setOpenSessionModal(true);
              console.log("Unauthorized path.....");
            } else if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
            }
          });
      }
    } else {
      if (object.questionText === "") {
        toast.error("Please fill all details..", { autoClose: 1000 });
      }
      formRef.current!.reportValidity();
    }
  };

  //This will reset all the input fields
  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!buttonFlag) {
      const op = [""];
      options.splice(0);
      setOptions(op);
      setCheckCorrectAnswer([]);
      setCorrectAnswer(0);
      setObject(defaultQuestion);
    } else {
      getQuestionById();
    }
  };

  //This will save and create new component
  const handleSaveAndCreateNew = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleSubmit(event, false);
  };

  //This will save the component
  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(event, true);
  };

  const handleContextMenu = (event: any) => {
    event.preventDefault();
  };

  const handleModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  useEffect(() => {
    const modifiedHTMLString = object.questionText.replace(
      /<img[^>]+>/g,
      (match) => {
        return match.replace(
          /(src=['"][^'"]+['"])([^>]*)>/,
          '$1 width="50%" height="40%"$2>'
        );
      }
    );
    //  setModifiedHTMLString(modifiedHTMLString)
    object.questionText = modifiedHTMLString;
    if (
      object.questionText != "" &&
      (object.questionType == "MSQ"
        ? checkCorrectAnswer.length != 0
        : object.questionType == "MCQ"
          ? correctAnswer != 0
          : false) &&
      object.questionText != "<p><br></p>" &&
      object.questionType != "" &&
      object.difficulty != "" &&
      object.skill != "" &&
      object.tags != "" &&
      object.timeToSolve != 0 &&
      options.length >= 2
    ) {
      setPreviewCheck(false);
    } else {
      setPreviewCheck(true);
    }
  }, [object, options, correctAnswer, checkCorrectAnswer]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "clean"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["normal"], // You can remove this line
      ],
    },
  };

  return (
    <>
      {!showCreateQuestion && (
        <ThemeProvider theme={dsgTheme}>
          <Box>
            {!isPreviewMode ? (
              <Box>
                <form ref={formRef}>
                  <Typography
                    variant="h5"
                    sx={{ paddingBottom: "2%" }}
                  >
                    Question Details
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Box className="combinedStyles">Question Description</Box>
                      <Box className="containing">
                        <ReactQuill
                          style={{ height: "27vh" }}
                          modules={modules}
                          placeholder="Enter the Question description"
                          value={object.questionText}
                          onChange={handleQuestionTextChange}
                        />
                      </Box>
                    </Grid>
                    {object.questionType == "CODING" ? (
                      <Grid
                        item
                        xs={12}
                        md={6}
                      >
                        <Box className="combinedStyles">
                          Execution Description
                        </Box>
                        <Box className="containing">
                          <ReactQuill
                            style={{ height: "27vh" }}
                            placeholder="Enter the Execution description"
                            value={object.executionText}
                            onChange={handleExecutionTextChange}
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        md={6}
                      >
                        <Box className="combinedStyles">Answer Description</Box>
                        <Box
                          className="containing"
                          sx={{
                            "&:hover": {
                              cursor: "not-allowed",
                            },
                          }}
                        >
                          <ReactQuill
                            style={{ height: "27vh" }}
                            placeholder="Enter the Answer description"
                            // value={object.answerText}
                            onChange={handleAnswerTextChange}
                            readOnly={true}
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Box className="gridAdjustment">
                        {!descriptiveFlag &&
                          object.questionType != "CODING" && (
                            <Grid
                              item
                              xs={12}
                              md={12}
                            >
                              <Box className="combinedStyles">
                                Add New Option
                              </Box>
                            </Grid>
                          )}
                        <Grid
                          item
                          xs={12}
                          md={12}
                        >
                          {/* <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ textAlign: 'center', fontWeight: 600, color: '#fe524a' }}>
                              Visibility
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={object.visibility}
                              onChange={(event) => handleSelectChange(event, "visibility")}
                            >
                              <FormControlLabel value="Public" control={<Radio required />} label="Public" />
                              <FormControlLabel value="Private" control={<Radio required />} label="Private" />
                            </RadioGroup>
                          </FormControl> */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginRight: "10%",
                              alignItems: "baseline",
                            }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={object.visibility}
                                    onChange={(event) =>
                                      handleVisibilityChange()
                                    }
                                  />
                                }
                                label="PUBLIC"
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                      </Box>
                      {object.questionType != "DESCRIPTIVE" &&
                        object.questionType != "CODING" &&
                        options.map((option, index) => (
                          <Grid
                            container
                            key={index}
                          >
                            <Grid
                              item
                              xs={11}
                              md={11}
                            >
                              <Box className="containing-option">
                                {`Option ${index + 1}`}
                                <ReactQuill
                                  style={{
                                    maxHeight: "20vh",
                                    height: "auto",
                                    marginTop: "5px",
                                  }}
                                  placeholder={`Enter Option ${index + 1}`}
                                  modules={modules}
                                  value={option}
                                  onChange={(value) =>
                                    handleOptionChange(index, value)
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              md={1}
                            >
                              <Box className="icon-container">
                                {options.length > 1 ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                      marginTop: "2rem",
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      className="centered-icon"
                                      onClick={() => removeOption(index)}
                                    />
                                    <AddCircleOutlineSharpIcon
                                      onClick={handleAddNewOption}
                                    />
                                  </Box>
                                ) : (
                                  <Box sx={{ marginTop: "2rem" }}>
                                    <AddCircleOutlineSharpIcon
                                      onClick={handleAddNewOption}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                    <Box
                      className="gridAdjustment"
                      width={isMdScreen ? "50%" : "100%"}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                      >
                        <Grid
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                required
                                id="demo-simple-select-label"
                              >
                                QuestionType
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="QuestionType"
                                size="medium"
                                value={object.questionType}
                                onChange={(event) =>
                                  handleSelectChange(event, "questionType")
                                }
                                className="dropdownsStyles"
                                IconComponent={KeyboardArrowDownIcon}
                                required
                                disabled={assessmentId ? true : false}
                              >
                                {questionTypeList.map((option) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            md={6}
                            sx={{
                              "&:hover": {
                                cursor: "no-drop",
                              },
                            }}
                          >
                            {object.questionType === "MSQ" && (
                              <Accordion
                                expanded={expanded === "panel1"}
                                onChange={handleCheckboxdataChange("panel1")}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <KeyboardArrowDownIcon
                                      sx={{ color: "red" }}
                                    />
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  Correct Answer
                                </AccordionSummary>

                                <AccordionDetails>
                                  <Typography>
                                    {checkboxes.map((checkbox, index) => (
                                      <Stack key={index}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={checkbox.checked}
                                              onChange={handleCheckboxChange(
                                                index
                                              )}
                                              name={`checkbox${index + 1}`}
                                            />
                                          }
                                          label={checkbox.name}
                                        />
                                      </Stack>
                                    ))}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            {codingFlag ? (
                              <FormControl fullWidth>
                                <InputLabel
                                  required
                                  id="demo-simple-select-label"
                                >
                                  Language
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  multiple
                                  value={object.language}
                                  label="Language"
                                  onChange={handleLanguageChange}
                                  size="medium"
                                  className="dropdownsStyles"
                                  IconComponent={KeyboardArrowDownIcon}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  required
                                  disabled={assessmentId ? true : false}
                                >
                                  {languageList.map((option) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              object.questionType != "MSQ" && (
                                <FormControl fullWidth>
                                  <InputLabel
                                    required
                                    id="demo-simple-select-label"
                                  >
                                    Correct Answer
                                  </InputLabel>

                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="correctAnswer"
                                    size="medium"
                                    value={
                                      correctAnswer === 0 ||
                                      descriptiveFlag ||
                                      object.questionType == "CODING"
                                        ? ""
                                        : String(correctAnswer)
                                    }
                                    onChange={(event) =>
                                      handleSelectCorrectChange(event)
                                    }
                                    variant={
                                      descriptiveFlag ||
                                      object.questionType == "CODING"
                                        ? "filled"
                                        : "outlined"
                                    }
                                    disabled={
                                      descriptiveFlag ||
                                      object.questionType == "CODING"
                                    }
                                    IconComponent={KeyboardArrowDownIcon}
                                    className="dropdownsStyles"
                                  >
                                    {options.map((option, index) => (
                                      <MenuItem value={index + 1}>
                                        option {index + 1}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          marginTop={0}
                        >
                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                required
                                id="demo-simple-select-label"
                              >
                                Difficulty
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={object.difficulty}
                                label="Difficulty"
                                onChange={(event) =>
                                  handleSelectChange(event, "difficulty")
                                }
                                size="medium"
                                className="dropdownsStyles"
                                IconComponent={KeyboardArrowDownIcon}
                                required
                              >
                                {difficultyList.map((option) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <TextField
                              label="Expected Time (in Mins)"
                              inputProps={{ min: 0 }}
                              value={object.timeToSolve}
                              onChange={(event) => {
                                handleInputChange(
                                  "timeToSolve",
                                  Number(event.target.value)
                                );
                              }}
                              type="number"
                              required
                              fullWidth={true}
                              size="medium"
                              variant="outlined"
                              className="inlineStyles"
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          marginTop="auto"
                        >
                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <TextField
                              label="Positive Marks"
                              value={object.positiveMarks}
                              // InputProps={{ readOnly: true }}
                              inputProps={{ min: 1 }}
                              onChange={(event) =>
                                handleInputChange(
                                  "positiveMarks",
                                  Number(event.target.value)
                                )
                              }
                              type="number"
                              fullWidth
                              size="medium"
                              variant="outlined"
                              className="inlineStyles"
                            />
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <TextField
                              label={
                                object.questionType == "DESCRIPTIVE"
                                  ? "Maximum Characters"
                                  : "Negative Marks"
                              }
                              value={
                                object.questionType === "DESCRIPTIVE"
                                  ? object.maximumCharacters
                                  : object.negativeMarks
                              }
                              inputProps={{ min: 0 }}
                             
                              onChange={(event) =>
                                handleInputChange(
                                  object.questionType === "DESCRIPTIVE"
                                    ? "maximumCharacters"
                                    : "negativeMarks",
                                  Number(event.target.value)
                                )
                              }
                              variant={
                                object.questionType === "CODING"
                                  ? "filled"
                                  : "outlined"
                              }
                              disabled={
                                object.questionType === "CODING"
                              }
                              type="number"
                              fullWidth
                              size="medium"
                              className="inlineStyles"
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          marginTop="auto"
                        >
                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                required
                                id="demo-simple-select-label"
                              >
                                Skill
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={object.skill}
                                label="Skill"
                                onChange={(event) =>
                                  handleSelectChange(event, "skill")
                                }
                                size="medium"
                                className="dropdownsStyles"
                                IconComponent={KeyboardArrowDownIcon}
                                required
                                disabled={assessmentId ? true : false}
                              >
                                {skillList.map((option) => (
                                  <MenuItem disabled={object.questionType === "CODING" && !["JAVA","C#"].some((actualOption) => actualOption === option)} value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            md={6}
                          >
                            <TextField
                              label="Tags"
                              value={object.tags}
                              onChange={(event) =>
                                handleInputChange("tags", event.target.value)
                              }
                              fullWidth
                              size="medium"
                              variant="outlined"
                              className="inlineStyles"
                              required
                              disabled={assessmentId ? true : false}
                              inputProps={{
                                minLength: 2,
                                maxLength: 10,
                                // readOnly:
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Stack
                    marginTop="3vh"
                    marginBottom="2vh"
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Button
                        type="submit"
                        variant="outlined"
                        onClick={(event) => handleCancel(event)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="outlined"
                        disabled={previewCheck}
                        onClick={(event) => handleSendData(event)}
                      >
                        Preview
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={(event) => handleReset(event)}
                      >
                        Reset
                      </Button>
                      {!buttonFlag ? (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="flex-end"
                        >
                          <Button
                            type="submit"
                            variant="outlined"
                            onClick={(event) => handleSave(event)}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={(event) => handleSaveAndCreateNew(event)}
                          >
                            Save And Create New
                          </Button>
                        </Stack>
                      ) : (
                        <Button
                          type="submit"
                          variant="outlined"
                          onClick={(event) => handleSubmit(event, false)}
                          disabled={updateButtonFlag}
                        >
                          Update
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                  <SessionExtensionModal
                    open={openSessionModal}
                    onClose={handleModalCancel}
                    onContinue={handleModalContinue}
                  ></SessionExtensionModal>
                  <ToastContainer position="bottom-left" />
                </form>
              </Box>
            ) : (
              <Preview
                sharedData={object}
                optionsData={options}
                checkCorrectAnswer={checkCorrectAnswer}
                correctAnswer={correctAnswer}
                onReturnData={handleReturnData}
              />
            )}
          </Box>
          <CustomModal
            open={openClientPlanExpiredModal}
            cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Your subscription plan has expired; for further information,
                please contact our RS_ADMIN.
              </Typography>
            </Stack>
          </CustomModal>
        </ThemeProvider>
      )}

      {showPreview && (
        <PreviewQuestions
          assessmentId={assessmentId}
          mode={mode}
          onBack={() => {
            // setShowPreview(false);
          }}
        />
      )}

      {showQuestionBank && (
        <QuestionBank
          id={headerId}
          mode={mode}
          onBack={() => {
            // setShowQuestionBank(false);
          }}
        />
      )}
    </>
  );
};

export default CreateQuestion;
