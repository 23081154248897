import {
  Button,
  Card,
  CardContent,
  Stack,
  StyledEngineProvider,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { dsgTheme } from "src/styles/theme";
import { SubscriptionPlansInterface } from "src/types/SubscriptionPlans";
import "./NewSubscriptionDetails.css";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import UserService from "src/clients/UserService";
import { Navigate, useNavigate } from "react-router-dom";
import { ClientInterface } from "../dummydata/ClientInterface";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import { CustomModal } from "../list-of-users/CustomModal";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

interface newSubscriptionDetailsProps {
  setNewPlan: (plan: SubscriptionPlansInterface | undefined) => void;
  onBack: () => void; // Callback function to update newPlan in the parent component
}
export const NewSubscriptionDetails = ({
  setNewPlan,
  onBack,
}: newSubscriptionDetailsProps) => {
  const CurrentUser = localStorage.getItem("currentUser");
  const navigate = useNavigate();
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  const [subscriptionPlans, setSubscriptionPlans] =
    useState<SubscriptionPlansInterface>({
      id: 0,
      cost: 0.0,
      createdBy: CurrentUser != null ? JSON.parse(CurrentUser).userName : "",
      numberOfInvitations: 0,
      numberOfUsers: 0,
      planName: "",
      timePeriod: 0,
      subscriptionPlanStatus: "ACTIVE",
    });

  const handleTextChange = (event: any) => {
    

    const updatedData = {
      ...subscriptionPlans,
      [event.target.name]: event.target.name!=="planName"? parseFloat(event.target.value): event.target.value
      
    };
    setSubscriptionPlans(updatedData);
  };

  const handleSessionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleSessionModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data)
        .then((res1) => {
          TokenService.setToken(res1.data);
          setOpenSessionModal(false);
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.status === 402) {
            setClientPlanExpiredModal(true);
          }
        });
    });
  };
  const alphaNumericRegex = /^(?=[a-zA-Z])(?=.*[a-zA-Z])[a-zA-Z0-9]*$/;
  const handleCancel = () => {
    onBack();
  };
  const handleAddPlan = () => {
   
    
    if (!alphaNumericRegex.test(subscriptionPlans.planName)) {
      toast.error(
        "Plan Name Should Start with alphabet and shouldn't contain any special characters",{autoClose:1000}
      );
      return;
    } else if (!Number.isInteger(Number(subscriptionPlans.timePeriod))) {
      toast.error("Plan period must be a positive integer without decimals.",{autoClose:1000});
      return;
    } else if (!Number.isInteger(Number(subscriptionPlans.numberOfUsers))) {
      toast.error(
        "Number of Users must be a positive integer without decimals.",{autoClose:1000}
      );
      return;
    } else if (
      !Number.isInteger(Number(subscriptionPlans.numberOfInvitations))
    ) {
      toast.error(
        "number of Invitations must be a positive integer without decimals.",{autoClose:1000}
      );
      return;
    } else if (!Number(subscriptionPlans.cost > 0)) {
      toast.error("Amount must be greater than zero.",{autoClose:1000});
      return;
    } else if(subscriptionPlans.planName.length < 2){
      toast.error("Plan Name should be greater than 1.",{autoClose:1000});
    }
    else {
      UserService.newSubscription(subscriptionPlans)
        .then((res: any) => {
          setNewPlan(res.data);
          toast.success("Subscription plan saved successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {
            onBack();
          }, 3000);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSessionModal(true);
          } else if (error.response.status === 402) {
            setClientPlanExpiredModal(true);
          } else if (error.response.status === 406) {
            toast.error("Plan Name Already Exists", { autoClose: 2000 });
          } else {
            toast.error("Something went wrong", { autoClose: 2000 });
          }
        });
    }
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dsgTheme}>
        <Box className="completebox">
          <Card>
            <CardContent>
              <Box sx={{ paddingBottom: "3%" }}>
                <Typography variant="h5">New Subscription Plan</Typography>
              </Box>
              <Box className="insidebox">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    value={"Plan Name"}
                    disabled
                    className="inputField"
                  />
                  <TextField
                    size="small"
                    name="planName"
                    variant="outlined"
                    value={subscriptionPlans.planName}
                    required
                    onChange={handleTextChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    value={"Plan period(months)"}
                    disabled
                    className="inputField"
                  />
                  <TextField
                    size="small"
                    className={``}
                    inputProps={{ min: 1}}
                    name="timePeriod"
                    variant="outlined"
                    type="number"
                    value={subscriptionPlans.timePeriod}
                    required
                    onChange={handleTextChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    value={"Number of Users"}
                    disabled
                    className="inputField"
                  />
                  <TextField
                    size="small"
                    className={``}
                    inputProps={{ min: 1 }}
                    name="numberOfUsers"
                    variant="outlined"
                    type="number"
                    value={subscriptionPlans.numberOfUsers}
                    required
                    onChange={handleTextChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    value={"Number of Invitations"}
                    disabled
                    className="inputField"
                  />

                  <TextField
                    size="small"
                    className={``}
                    inputProps={{ min: 1 }}
                    name="numberOfInvitations"
                    variant="outlined"
                    type="number"
                    value={subscriptionPlans.numberOfInvitations}
                    required
                    onChange={handleTextChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    value={"Amount($/month)"}
                    disabled
                    className="inputField"
                  />
                  <TextField
                    size="small"
                    className={``}
                    name="cost"
                    inputProps={{ min: 0 }}
                    variant="outlined"
                    type="number"
                    value={subscriptionPlans.cost}
                    required
                    onChange={handleTextChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1%",
                  }}
                >
                  <input
                    value={"Created By"}
                    disabled
                    className="inputField"
                  />
                  <TextField
                    size="small"
                    className={``}
                    name="createdBy"
                    variant="outlined"
                    value={subscriptionPlans.createdBy}
                    required
                    disabled
                    onChange={handleTextChange}
                  />
                </Box>
              </Box>
              <Box className="buttonBoxCss">
                <Button
                  className="click"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  className="click"
                  variant="outlined"
                  onClick={handleAddPlan}
                  disabled={
                    !(
                      subscriptionPlans.planName !== "" &&
                      subscriptionPlans.timePeriod !== 0 &&
                      subscriptionPlans.numberOfUsers !== 0 &&
                      subscriptionPlans.numberOfInvitations !== 0 &&
                      subscriptionPlans.cost !== 0 &&
                      subscriptionPlans.createdBy !== ""
                    )
                  }
                >
                  Add Plan
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <ToastContainer position="bottom-left" />
        <SessionExtensionModal
          open={openSessionModal}
          onClose={handleSessionModalCancel}
          onContinue={handleSessionModalContinue}
        ></SessionExtensionModal>
        <CustomModal
          open={openClientPlanExpiredModal}
          cancelButton={{
            label: "Sign out",
            onClick: handleSessionModalCancel,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
            <Typography fontWeight="bold">
              Your subscription plan has expired; for further information,
              please contact our RS_ADMIN.
            </Typography>
          </Stack>
        </CustomModal>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
