import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api/assessment/`;
const candidate_host = process.env.REACT_APP_BASE_URL;

class CandidateService {
  getQuestions = async () => {
    return axios.get(baseURL + `${1}/section/${1}`);
  };

  assessmentStarted(candidateObject:any) {
    return axios.post(`${candidate_host}/candidates/assessment/start`,candidateObject);
  }

  assessmentEnded(id: number) {
    return axios.post(`${candidate_host}/candidates/assessment/complete/${id}`);
  }

  CandidateCheckSubmit(id: number) {
    return axios.get(`${candidate_host}/candidates/check-submitted/${id}`);
  }

  sendEmail(assessmentid: number, candidateid: number) {
    return axios.post(
      `${candidate_host}/email/${assessmentid}/${candidateid}/notify`
    );
  }
}
export default new CandidateService();
