import "./ViewSectionDetails.css";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { CandidateResponsesListInterface } from "../../../types/CandidateResponsesListInterface";
import { dsgTheme } from "../../../styles/theme";
import Pagination from "src/components/pagination/Pagination";
import TokenService from "../../../clients/TokenService";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import UserService from "src/clients/UserService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { env } from "process";
import { ToastContainer, toast } from "react-toastify";
import { QuestionInterface } from "src/types/QuestionInterface";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";
interface CandidateResponseHeader {
  sectionId: number;
  sectionScore: number;
  skillName: string;
  sectionName: string;
  responseId: number;
  sectionStartTimestamp: Date;
  sectionEndTimestamp: Date;
}

interface SaveDescriptiveMarks {
  questionId: number;
  marks: any;
  questionType: string;
}

function ViewSectionDetails() {
  const [responseData, setResponseData] = useState<
    CandidateResponsesListInterface[]
  >([]);

  const location = useLocation();

  const [code, setCode] = useState<string>("");
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  
  const booleanValue = location.state?.booleanValue;
  
  const [sectionName, setSectionName] = useState<string>("");
  const navigate = useNavigate();
  var storedCombinedString = localStorage.getItem("candidate");
  if (storedCombinedString != null) {
    var row = JSON.parse(storedCombinedString);
    var username = row.firstName;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState("2");
  const [addMarks, setAddMarks] = useState<SaveDescriptiveMarks[]>([]);
  const [checkCode,setCheckCode] = useState<boolean>(false);
  const [disableSave,setDisableSave] = useState<boolean>(false);
  
  const questionsPerPage = Number(rows);
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const QuestionDetails = responseData?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  var storedCombinedString1 = localStorage.getItem("viewSectionDetails");
  if (storedCombinedString1 != null) {
    var row = JSON.parse(storedCombinedString1);
  }

  const CandidateResponseHeader = row;
  const candidateResponseId = row.responseId;
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  //Time taken calculation
  const startDateObj = new Date(CandidateResponseHeader.sectionStartTimestamp);
  const endDateObj = new Date(CandidateResponseHeader.sectionEndTimestamp);
  const timeDiffMilliseconds = endDateObj.getTime() - startDateObj.getTime();
  const timeDiffMins = Math.floor(timeDiffMilliseconds / (1000 * 60));
  const [apicalled, setapicalled] = useState<boolean>(false);


  useEffect(() => {
    const initialData:SaveDescriptiveMarks[] = responseData.map((item) => ({
      questionId: item.questionId,
      marks: item.response === "" ? 0 : "", // You can set the initial marks as needed
      questionType: item.questionsDto.questionType,
    }));

    console.log(initialData,"initialData")
    setAddMarks(initialData);
    console.log("iseEffect",addMarks)

    const isEvaluate = responseData.findIndex((data) => !data.evaluate);


    if (booleanValue && isEvaluate !== -1) {
      console.log("Suresh",checkCode)
      if(!checkCode){
        setCurrentPage(Math.ceil((isEvaluate + 1) / questionsPerPage));
      }
    } else {
      console.log("mahesh")
        setCurrentPage(1);
    }
  }, [responseData]);

  useEffect(() => {
    fetchData();
  }, [apicalled === true]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/reports/candidateResponseHeader/${candidateResponseId}`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`,
        },
      }
    );
    const data = await response.json();
    

    if (response.ok) {
      
      setapicalled(false);
      setSectionName(data.sectionName);
      const responseDtoList = data.candidateMcqResponseDTOList.filter(
        (mcqList: any) => {
          return (
            mcqList.questionsDto?.questionType === "DESCRIPTIVE" ||
            mcqList.questionsDto?.questionType === "CODING"
          );
        }
      );

      if (booleanValue && responseDtoList.every((data: any) => data.evaluate)) {
        handleBack();
      }
      // const descriptiveObjects = data.candidateMcqResponseDTOList.questionsDto.filter((question:any) => question.questionType.includes("DESCRIPTIVE"));
      setResponseData(
        sortQuestionDetails(
          booleanValue ? responseDtoList : data.candidateMcqResponseDTOList
        )
      );
      // setCurrentPage(1);
    } else if(response.status === 401){
      setOpenSessionModal(true)
    }
      else if (response.status === 402) {
      setClientPlanExpiredModal(true);
    }
  };

  const sortQuestionDetails = (questionDetails: any) => {
    return questionDetails.sort(
      (a: any, b: any) => a.assessmentQuestionNo - b.assessmentQuestionNo
    );
  };

  function handleBack() {
    navigate("/specificReport");
  }
  const comQuestion = (value: any) => {
    return <Typography dangerouslySetInnerHTML={{ __html: value }} />;
  };

  const handleModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };



  const handleAllotedMarks = (event: any, index: number) => {
    const inputValue = Number(event.target.value);

    if (
      inputValue <=
      responseData[(currentPage - 1) * Number(rows) + index]?.questionsDto
        .positiveMarks
    ) {

      console.log(index,"INdex")
      console.log("currentPage",currentPage)
      console.log(responseData,"resData")
      if(currentPage === 0)
          setCurrentPage(1);
      setAddMarks((prevAddMarksStates) => {
        const updatedHoverStates = [...prevAddMarksStates];
        
        updatedHoverStates[
          (currentPage - 1) * Number(rows) + index
        ].questionId =
          responseData[(currentPage - 1) * Number(rows) + index]?.questionId;
        updatedHoverStates[
          (currentPage - 1) * Number(rows) + index
        ].questionType =
          responseData[
            (currentPage - 1) * Number(rows) + index
          ]?.questionsDto.questionType;
        updatedHoverStates[(currentPage - 1) * Number(rows) + index].marks =
          inputValue;
        return updatedHoverStates;
      });

    }
    console.log(addMarks,"Addmarks")
  };
  const handleRunCode = async (
    questiondetails: CandidateResponsesListInterface
  ) => {
    // mycode = mycode.replace(/\n/g, '\n');

    const requestBody = {
      code: questiondetails.response,
      candidateResponseId: candidateResponseId,
      questionId: questiondetails.questionId,
      language: questiondetails.language,
    };
   
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/candidates/RunTheProgram`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${TokenService.getToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if(response.status === 401){
        setOpenSessionModal(true);
      }else if(!response.ok) {
        const errorMessage = await response.json();
        setCheckCode(true)
        setapicalled(true)
        
      } else {
        const result = await response.json();
        setCheckCode(true)
        setapicalled(true)
      }
    } catch (error: any) {
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      }else if(error.response.status === 401){
        setOpenSessionModal(true);
      }
     
      
    }
  };
  const ApiCallToSaveDescriptiveMarks = (data: any) => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/candidates/save-marks-descriptive-responses`;
    return fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  
  const saveAllDescriptiveResponses = async () => {

    const requestBody = {
      candidateResponseHeaderId: candidateResponseId,
      marksObject: addMarks.slice(indexOfFirstQuestion, indexOfLastQuestion),
    };

    if(requestBody.marksObject.some((question) => 
      question.marks<0)){
      toast.error(`Negative marks are not allowed`,{autoClose:1000});
    }else if(requestBody.marksObject.some((question) => QuestionDetails.some(currentQuestion => question.questionId === currentQuestion.questionId && currentQuestion.questionsDto.questionType === "CODING" && currentQuestion.output === null && currentQuestion.response.length>0))){
      toast.error(`Run the code before saving the marks`,{autoClose:1000});
    }
      else{
        setDisableSave(true);
        setTimeout(() => {
        setDisableSave(false);
        },2000)
      try {
        const res = await ApiCallToSaveDescriptiveMarks(requestBody);
        if (res.ok) {
          setCheckCode(false);
          toast.success(
            `Marks are updated successfully for Section ${sectionName}`,
            { autoClose: 1000 }
          );
          
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else if (res.status === 402) {
          setClientPlanExpiredModal(true);
        }else if(res.status === 401){
          setOpenSessionModal(true);
        } 
        else {
          toast.error("Something went wrong", { autoClose: 1000 });
        }
      } catch (error: any) {
        toast.error("Something went wrong", { autoClose: 1000 });
        if (error.response.status === 401) {
          setOpenSessionModal(true);
        } else if (error.response.status === 402) {
          setClientPlanExpiredModal(true);
        } else {
      
        }
      }
    }
   
  };

 
  return (
    <>
      <ThemeProvider theme={dsgTheme}>
        <Typography variant="h5">
          {username}'s {sectionName} Response
        </Typography>
        <Box className="headBox">
          <Typography>
            Section Score : {CandidateResponseHeader.sectionScore}
          </Typography>
          <Typography>
            Time taken : {timeDiffMins} {timeDiffMins < 2 ? `min` : `mins`}
          </Typography>
        </Box>
        <Box className="paginationBox">
          <Box className="paginationText">
            <Typography
              component={"div"}
              variant="body1"
            >
              Showing {indexOfLastQuestion > 0 ? indexOfFirstQuestion + 1 : 0}
              {indexOfLastQuestion <= responseData?.length
                ? ` - ${indexOfLastQuestion} questions `
                : " question "}
              out of {responseData?.length}
            </Typography>
          </Box>
          <Pagination
            rows={rows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalDataLength={responseData.length}
            dataPerPage={questionsPerPage}
          />
        </Box>

        {QuestionDetails?.map((questionDetails, questionDetailsIndex) => (
          <Box key={questionDetailsIndex}>
            <Paper
              elevation={3}
              className="paper"
            >
              <Box
                className="paperInnerBox"
                display="flex"
                alignItems="center"
              >
                <Typography
                  component={"div"}
                  variant="body1"
                  className="questionBox1"
                >
                  Question {questionDetails?.assessmentQuestionNo} :
                </Typography>

                <Typography
                  component={"div"}
                  variant="body2"
                  style={{
                    maxHeight: "100px",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  {questionDetails.questionsDto &&
                    questionDetails.questionsDto?.questionText &&
                    comQuestion(questionDetails.questionsDto?.questionText)}
                </Typography>
              </Box>

              {questionDetails.questionsDto.questionType == "DESCRIPTIVE" ? (
                <Box className="paperInnerBox" sx={{display:'flex',alignItems:'center'}}>
                  <Typography
                    component={"div"}
                    variant="body1"
                    className="questionBox1"
                  >
                    Response :
                  </Typography>
                  <Box>
                    <Typography
                      component={"div"}
                      className="optionBox"
                    >
                      {questionDetails.response === "" ? (
                        <Typography
                          className="responseBoxError"
                          fontSize="12px"
                        >
                          NOT ATTEMPTED
                        </Typography>
                      ) : (
                        questionDetails.response
                      )}
                    </Typography>
                  </Box>
                </Box>
              ) : questionDetails.questionsDto.questionType === "CODING" ? (
                <Box
                  className="paperInnerBox"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: questionDetails.questionsDto.questionType === "CODING" && questionDetails.output === null ? "flex-start":"space-around",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      component={"div"}
                      variant="body1"
                      marginRight="1%"
                      sx={{whiteSpace:'nowrap'}}
                    >
                      Response :
                    </Typography>
                    {questionDetails.response === "" ? (
                      <Typography
                        className="responseBoxError"
                        fontSize="12px"
                        sx={{whiteSpace:'nowrap'}}
                      >
                        NOT ATTEMPTED
                      </Typography>
                    ) : (
                      <TextField
                        value={questionDetails.response}
                        multiline
                        sx={{
                          width: "26Em",
                          maxHeight: "200px",
                          overflowY: "auto",
                        }}
                      />
                    )}
                  </Box>

                  {questionDetails.response !== "" &&
                    questionDetails.output && (
                    questionDetails.output.substring(0, 8) === "success:" ? (
                      <Box
                        sx={{
                          display: "flex",marginLeft:'5%'
                        }}
                      >
                        <Typography
                          component={"div"}
                          variant="body1"
                          sx={{marginRight:'1%',whiteSpace:'nowrap'}}
                        >
                          Output :
                        </Typography>
                        <TextField
                          value={questionDetails.output.substring(8,questionDetails.output.length)}
                          multiline
                          sx={{
                            width: "26Em",
                            maxHeight: "200px",
                            overflowY: "auto",
                            "& .MuiInputBase-input": { color: "green" }, // Text color
                            "& .MuiInputLabel-root": { color: "green" },
                          }}
                        />
                      
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",marginLeft:'5%'
                        }}
                      >
                        <Typography
                          component={"div"}
                          variant="body1"
                          sx={{marginRight:'1%',whiteSpace:'nowrap'}}
                        >
                          Error :
                        </Typography>
                        <TextField
                          value={questionDetails.output}
                          multiline
                          sx={{
                            width: "26Em",
                            maxHeight: "200px",
                            overflowY: "auto",
                            "& .MuiInputBase-input": { color: "red" }, // Text color
                            "& .MuiInputLabel-root": { color: "red" },
                          }}
                        />
                      
                      </Box>
                    ))}
                </Box>
              ) : (
                <Box className="paperInnerBox">
                  <Typography
                    component={"div"}
                    variant="body1"
                    className="questionBox1"
                  >
                    Options :
                  </Typography>
                  <Box>
                    {questionDetails.questionsDto &&
                      questionDetails.questionsDto.options.map(
                        (options, optionsIndex) => (
                          <Typography
                            component={"div"}
                            className="optionBox"
                            key={optionsIndex}
                          >
                            {String.fromCharCode(optionsIndex + 65)}.{" "}
                            {comQuestion(options.optionValue)}
                          </Typography>
                        )
                      )}
                  </Box>
                </Box>
              )}

              {questionDetails.questionsDto.questionType === "DESCRIPTIVE" ? (
                <Box
                  className="paperLastBox"
                  sx={{ justifyContent: "space-between" }}
                >
                  <Box className="paperFinalBox" sx={{display:'flex',alignItems:'center'}}>
                    <Typography
                      component={"div"}
                      variant="body1"
                    >
                      Marks :
                    </Typography>
                    <Box className="optionsBox1">
                      <Typography
                        component={"div"}
                        variant="body2"
                        // sx={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "center",
                        // }}
                      >
                        {booleanValue ? (
                          <>
                            <TextField
                              variant="standard"
                              type="number"
                              sx={{
                                "& input": {
                                  fontSize: "13px",
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              value={
                                QuestionDetails[questionDetailsIndex].evaluate
                                  ? QuestionDetails[questionDetailsIndex].marks
                                  : String(
                                      addMarks[
                                        (currentPage === 0?1:currentPage - 1) * Number(rows) +
                                          questionDetailsIndex
                                      ]?.marks
                                    )
                              }
                              disabled={
                                QuestionDetails[questionDetailsIndex].evaluate
                              }
                              onChange={(event) => {
                                handleAllotedMarks(event, questionDetailsIndex);
                              }}
                              inputProps={{
                                min: 0,
                                style: { textAlign: "center" },
                              }}
                            />
                            /{questionDetails.questionsDto.positiveMarks}
                          </>
                        ) : questionDetails.evaluate ? (
                          <Typography>{questionDetails.marks}</Typography>
                        ) : (
                          <Typography
                            className="responseBoxError"
                            fontSize="12px"
                          >
                            NOT EVALUATED
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : questionDetails.questionsDto.questionType === "CODING" ? (
                <Box
                  className="paperLastBox"
                  sx={{ justifyContent: "space-between" }}
                >
                  {questionDetails.response !== "" && questionDetails.output === null && booleanValue && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{marginTop:'0.5%'}}
                      disabled={questionDetails.response === ""}
                      onClick={() => handleRunCode(questionDetails)}
                    >
                      Run Code
                    </Button>
                  )}
                  {questionDetails.response !== "" && (
                    <Box className="paperFinalBox" sx={{display:'flex',alignItems:'center'}}>
                      <Typography
                        component={"div"}
                        variant="body1"
                      >
                        Marks: 
                      </Typography>
                      <Box className="optionsBox1">
                      <Typography
                        component={"div"}
                        variant="body2"
                        // sx={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "center",
                        // }}
                      >
                        {booleanValue ? (
                          <>
                            <TextField
                              variant="standard"
                              type="number"
                              sx={{
                                "& input": {
                                  fontSize: "13px",
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              value={
                                QuestionDetails[questionDetailsIndex].evaluate
                                  ? QuestionDetails[questionDetailsIndex].marks
                                  : String(
                                      addMarks[
                                        (currentPage ===0?1:currentPage - 1) * Number(rows) +
                                          questionDetailsIndex
                                      ]?.marks
                                    )
                              }
                              disabled={
                                QuestionDetails[questionDetailsIndex].evaluate
                              }
                              onChange={(event) => {
                                handleAllotedMarks(event, questionDetailsIndex);
                              }}
                              inputProps={{
                                min: 0,
                                style: { textAlign: "center" },
                              }}
                            />
                            /{questionDetails.questionsDto.positiveMarks}
                          </>
                        ) : questionDetails.evaluate ? (
                          <Typography>{questionDetails.marks}</Typography>
                        ) : (
                          <Typography
                            className="responseBoxError"
                            fontSize="12px"
                          >
                           NOT EVALUATED
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box className="paperLastBox">
                  <Box className="paperFinalBox">
                    <Typography
                      component={"div"}
                      variant="body1"
                    >
                      Attempted Answer :
                    </Typography>

                    <Box className="optionsBox1">
                      {String.fromCharCode(
                        parseInt(questionDetails.response) + 64
                      ) === "@" ? (
                        <Typography
                          component={"div"}
                          variant="body2"
                          className="responseBoxError"
                        >
                          NOT ANSWERED
                        </Typography>
                      ) : (
                        (() => {
                          let response2 = "";
                          questionDetails.questionsDto?.options.forEach(
                            (macqAnswer) => {
                              if (macqAnswer.answerFlag) {
                                response2 += macqAnswer.optionId.toString();
                              }
                            }
                          );
                          
                          return (
                            questionDetails.questionsDto &&
                            questionDetails.questionsDto.options.map(
                              (macqAnswer, macqAnswerIndex) => {
                                if (
                                  questionDetails.response
                                    .split("")
                                    .includes(String(macqAnswer.optionId))
                                ) {
                                  return (
                                    <Typography
                                      component={"div"}
                                      variant="body2"
                                      key={macqAnswerIndex}
                                      className={
                                        questionDetails.response
                                          .split("")
                                          .sort()
                                          .join("") == response2
                                          ? "responseBox"
                                          : "responseBoxError"
                                      }
                                    >
                                      {String.fromCharCode(
                                        macqAnswer.optionId + 64
                                      ) + " "}
                                    </Typography>
                                  );
                                }
                                return null;
                              }
                            )
                          );
                        })()
                      )}
                    </Box>
                  </Box>

                  <Box className="paperFinalBox" sx={{display:'flex',alignItems:'center'}}>
                    <Typography
                      component={"div"}
                      variant="body1"
                    >
                      Actual Answer :
                    </Typography>
                    <Box className="optionsBox1">
                      {questionDetails.questionsDto &&
                        questionDetails.questionsDto?.options.map(
                          (macqAnswer, macqAnswerIndex) => {
                            if (macqAnswer.answerFlag) {
                              return (
                                <Typography
                                  key={macqAnswerIndex}
                                  component={"div"}
                                  variant="body2"
                                >
                                  {String.fromCharCode(macqAnswerIndex + 65)}
                                </Typography>
                              );
                            }
                            return null;
                          }
                        )}
                    </Box>
                  </Box>
                  <Box className="paperFinalBox" sx={{display:'flex',alignItems:'center'}}>
                    <Typography
                      component={"div"}
                      variant="body1"
                    >
                      Marks:
                    </Typography>

                    <Box className="optionsBox1">
                      {
                        // String.fromCharCode(
                        //   parseInt(questionDetails.response) + 64
                        // ) === "@" ? (
                        //   <Typography
                        //     component={"div"}
                        //     variant="body2"
                        //     className="responseBoxError"
                        //   >
                        //     NOT ANSWERED
                        //   </Typography>
                        // ) :
                        (() => {
                          let response2 = "";
                          questionDetails.questionsDto?.options.map(
                            (macqAnswer) => {
                              if (macqAnswer.answerFlag) {
                                response2 += macqAnswer.optionId.toString();
                              }
                            }
                          );
                        
                          return (
                            questionDetails.questionsDto && (
                              <Typography
                                component={"div"}
                                variant="body2"
                                // key={macqAnswerIndex}
                              >
                                {questionDetails.response
                                  .split("")
                                  .sort()
                                  .join("") == response2
                                  ? questionDetails.questionsDto.positiveMarks
                                  : questionDetails.response === "0" 
                                    ? 0
                                    : -questionDetails.questionsDto
                                        .negativeMarks}
                              </Typography>
                            )
                            // questionDetails.questionsDto.options.map(
                            //   (macqAnswer, macqAnswerIndex) => {
                            //     if (
                            //       questionDetails.response
                            //         .split("")
                            //         .includes(String(macqAnswer.optionId))
                            //     ) {
                            //       return (
                            //         <Typography
                            //           component={"div"}
                            //           variant="body2"
                            //           key={macqAnswerIndex}
                            //         >
                            //           {questionDetails.response.split('').sort().join('') == response2
                            //               ? questionDetails.questionsDto.positiveMarks
                            //               :  questionDetails.questionsDto.negativeMarks}
                            //         </Typography>
                            //       );
                            //     }
                            //     return null;
                            //   }
                            // )
                          );
                        })()
                      }
                    </Box>
                  </Box>
                </Box>
              )}
            </Paper>
          </Box>
        ))}

        <Box className="actionButtons2">
          <Button
            className="button2"
            onClick={handleBack}
            sx={{ alignItems: "left" }}
          >
            Back
          </Button>
          {booleanValue && (
            <Button
              // disabled={!addMarks.every((object) => object.marks > -1)}
              disabled={
                disableSave || 
                (addMarks
                  .slice(indexOfFirstQuestion, indexOfLastQuestion)
                  .some((object) => {
                    return object.marks === "";
                  }) ||
                QuestionDetails.every((questionObject) => {
                  return questionObject.evaluate;
                }))
              }
              onClick={saveAllDescriptiveResponses}
            >
              Save
            </Button>
          )}
          <SessionExtensionModal
            open={openSessionModal}
            onClose={handleModalCancel}
            onContinue={handleModalContinue}
          ></SessionExtensionModal>
          <CustomModal
            open={openClientPlanExpiredModal}
            cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Your subscription plan has expired; for further information,
                please contact our RS_ADMIN.
              </Typography>
            </Stack>
          </CustomModal>
        </Box>
        <ToastContainer position="bottom-left" />
      </ThemeProvider>
    </>
  );
}

export default ViewSectionDetails;
