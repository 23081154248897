
import roleClass from './roleClass'
import PermissionsClass from './PermissionsClass'

class RolesAndPermissionClass  {

    Role_Permission={
        id:0,
        role:roleClass.role,
        permission:PermissionsClass.Permission,
        permissionFlag:'',
        createdDate:'',
        updatedDate:'',
        createdBy:'',
        permissionslist:[''],
        updatedBy:''


    }
}
export default new RolesAndPermissionClass()