import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { Outlet } from "react-router-dom";
import { dsgTheme } from "../../styles/theme";
import rs_logo from "../assets/rs_logo.png";

function ResponsiveAppBar() {
  return (
    <>
      <ThemeProvider theme={dsgTheme}>
        <AppBar position="static" sx={{ backgroundColor: "white" }}>
          <Container maxWidth="xl">
            <Toolbar sx={{ display: "flex" }}>
              <Typography>
                <img src={rs_logo} width="50%" height="50%" alt="logo" />
              </Typography>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  flexGrow: 0.8,
                }}
              >
                Welcome To Redshift Assessment
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
        <Outlet />
      </ThemeProvider>
    </>
  );
}

export default ResponsiveAppBar;
