import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";


import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import "./AssessmentDetails.css";

import {
  cloneAssessmentDetails,
  getAllConfigurations,
  getConfigurationById,
  getSection,
  newAssessmentDetails,
  postAssessmentConfig,
  updateAssessmentDetails,
} from "../../../clients/AssessmentsClient";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { AssessmentHeaderInterface } from "../../../types/AssessmentHeaderInterface";
import { AssessmentConfigurationInterface } from "../../../types/AssessmentConfigurationInterface";
import { MasterDataInterface } from "../../../types/MasterDataInterface";
import { AssessmentSectionInterface } from "../../../types/AssessmentSectionInterface";
import AssessmentConfiguration from "../assessments/ConfigurationModal";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { dsgTheme } from "../../../styles/theme";

import PreviewQuestions from "../preview-assessment/PreviewAssessment";
import QuestionBank from "../../question-bank/filter-questions/QuestionBank";
import SectionDetails from "./SectionDetails";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import UserService from "src/clients/UserService";
import TokenService from "src/clients/TokenService";
import { constant, update } from "lodash";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";

interface AssessmentDetailsProps {
  assessmentDetails?: AssessmentHeaderInterface;

  mode: string;

  onBack: () => void;
}

const AssessmentDetails = ({
  assessmentDetails,

  mode,

  onBack,
}: AssessmentDetailsProps) => {
  const [assessmentData, setAssessmentData] =
    useState<AssessmentHeaderInterface>({
      id: assessmentDetails?.id ?? 0,

      assessmentName: assessmentDetails?.assessmentName ?? "",

      createdDate: assessmentDetails?.createdDate ?? "",

      totalTime: assessmentDetails?.totalTime ?? 0,

      organizedBy: assessmentDetails?.organizedBy ?? "",

      noOfSections: assessmentDetails?.noOfSections ?? 0,

      // questionsPerSection: assessmentDetails?.questionsPerSection ?? 0,

      type: assessmentDetails?.type ?? "%",

      totalMarks: assessmentDetails?.totalMarks ?? 0,

      updatedBy: assessmentDetails?.updatedBy ?? "",

      updatedDate: assessmentDetails?.updatedDate ?? new Date().toISOString(),

      invitedCandidatesCount: 0,

      cutOff: assessmentDetails?.cutOff ?? 0,

      startDateTime: assessmentDetails?.startDateTime ?? new Date(),

      endDateTime: assessmentDetails?.endDateTime ?? new Date(),

      status: assessmentDetails?.status ?? "IN_COMPLETE",

      section: assessmentDetails?.section ?? [],
      shuffleQuestionsStatus: assessmentDetails?.shuffleQuestionsStatus ?? false,

      organisation: assessmentDetails?.organisation ?? ""
    });
    
    const[modes,setmodes]=useState<string>(mode);
    const [reload,setReload] = useState<boolean>(false);

  const [disabling, setDisabling] = useState(false);

  useEffect(() => {
    console.log(assessmentData);
  }, [])

  const [originalAssessmentData, setOriginalAssessmentData] = useState<AssessmentHeaderInterface>({
    id: assessmentDetails?.id ?? 0,

    assessmentName: assessmentDetails?.assessmentName ?? "",

    createdDate: assessmentDetails?.createdDate ?? "",

    totalTime: assessmentDetails?.totalTime ?? 0,

    organizedBy: assessmentDetails?.organizedBy ?? "",

    noOfSections: assessmentDetails?.noOfSections ?? 0,

    type: assessmentDetails?.type ?? "%",

    totalMarks: assessmentDetails?.totalMarks ?? 0,

    updatedBy: assessmentDetails?.updatedBy ?? "",

    updatedDate: assessmentDetails?.updatedDate ?? new Date().toISOString(),

    invitedCandidatesCount: 0,

    cutOff: assessmentDetails?.cutOff ?? 0,

    startDateTime: assessmentDetails?.startDateTime ?? new Date(),

    endDateTime: assessmentDetails?.endDateTime ?? new Date(),

    status: assessmentDetails?.status ?? "CREATED",

    section: assessmentDetails?.section ?? [],

    shuffleQuestionsStatus: assessmentDetails?.shuffleQuestionsStatus ?? false,
    organisation: assessmentDetails?.organisation ?? ""

  });
  const [Sections, setSections] = useState<MasterDataInterface[]>([]);

  const loggedUser = localStorage.getItem("currentUser");
  const userDetails = loggedUser ? JSON.parse(loggedUser) : null;
  const permissions = userDetails?.permissionslist;
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const [showSections, setShowSections] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showQuestionBank, setShowQuestionBank] = useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  // const [shuffleQuestionstoggleStatus,setShuffleQuestionsToggleStatus]=useState<boolean>(false);

  const [configurationDetails, setConfigurationDetails] = useState<
    AssessmentConfigurationInterface[]
  >([]);

  const [PrevConfigurationDetails, setPrevConfigurationDetails] = useState<
    AssessmentConfigurationInterface[]
  >([]);


  const [assessmentSectionDetails, setAssessmentSectionDetails] = useState<
    AssessmentSectionInterface[]
  >(assessmentData.section);


  let duration: number = 0;
  assessmentSectionDetails.forEach((a) => duration += Number(a.totalTime))

  const [responseId, setResponseId] = useState<number>(0);

  const [isEdited, setIsEdited] = useState<boolean>(false);

  const [shuffleQuestionsDisable, setshuffleQuestionsDisable] = useState<boolean>(false);

  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const enableSave = assessmentData
    ? assessmentData.assessmentName !== "" &&
    assessmentData.organizedBy !== "" &&
    assessmentData.cutOff > 0 &&
    assessmentData.cutOff <= 100 &&
    assessmentSectionDetails.length > 0
    : false;


  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload()
      })
    }).catch((error) => {
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      }
    })
  };


  let edit = false;

  let view = false;

  let add = false;

  let clone = false;

  if (modes === "edit") {
    edit = true;
  } else if (modes === "view") {
    view = true;
  } else if (modes === "clone") {
    clone = true;
  } else {
    add = true;
  }

  const user = localStorage.getItem("currentUser");
  let userName;
  let organisation: string;
  if (user) {
    userName = JSON.parse(user).userName;
    organisation = JSON.parse(user).clientPlans.clients.clientName;
  }
  if (add === true) {
    assessmentData.organizedBy = userName;

  }
  if (edit === true) {
    assessmentData.updatedBy = userName;

  }
  if (clone === true) {
    assessmentData.organizedBy = userName;

  }
  useEffect(() => {
    fetchSections();

    if (add === true || clone === true) {
      setIsEdited(true);
    }
    else if (view === true) {
      setshuffleQuestionsDisable(true)
    }

  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchSections = async () => {
    const res = await getSection();
    try {
      if (res.ok) {
        const data = await res.json();

        setSections(data);
      } else if (res.status === 401) {
        console.log(res.status)
        setOpenSessionModal(true);
        console.log("Failed to load sections");
      }else if (res.status === 402) {
        setClientPlanExpiredModal(true);
      }
    } catch (error: any) {
      console.log(res.status)
      console.error(error)
    }
  };

  const fetchData = async () => {
    if (assessmentData.id) {
      try {
        const response = await getConfigurationById(assessmentData.id);
        const responseJson = await response.json();
        setConfigurationDetails(responseJson);
        setPrevConfigurationDetails(responseJson);
        if (response.status === 401) {
          setOpenSessionModal(true);
        }else if (response.status === 402) {
          setClientPlanExpiredModal(true);
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);

      }
    } else {
      const response = await getAllConfigurations();
      if (response.ok) {
        const masterData = await response.json();
        settingConfigurationFromMaster(masterData);
      }
      else if (response.status === 401) {
        setOpenSessionModal(true);
      }else if (response.status === 402) {
        setClientPlanExpiredModal(true);
      }

    }
  };

  function settingConfigurationFromMaster(masterData: MasterDataInterface[]) {
    const newConfigurationList: AssessmentConfigurationInterface[] =
      masterData.map((item, index) => {
        if (index <= 4) {
          return {
            configurationName: item.name,
            configurationFlag: true,
            assessmentId: 0,
            description: item.description,
          };
        }
        else {
          return {
            configurationName: item.name,
            configurationFlag: false,
            assessmentId: 0,
            description: item.description,
          };
        }
      });
    setConfigurationDetails(newConfigurationList);
  }

  const navigate = useNavigate();



  const handleLinkClick = () => {
    setShowConfig(true);
  };

  const handleSectionLinkClick = () => {
    //navigate('/SectionDetails');
    setShowSections(true);
  };

  const handlenextScreen = () => {
    if (modes === "view") {
      setShowPreview(true);

    } else {
      setShowQuestionBank(true);
    }
  };



  function goToInviteCandidates() {
    if (responseId === 0) {
      navigate("/invite-candidate", {
        state: { assessmentId: assessmentData.id },
      });
    }
    else {
      navigate("/invite-candidate", {
        state: { assessmentId: responseId },
      });
    }
  }

  const handleShuffleQuestionsChange = () => {

    // setAssessmentData(up(!assessmentData.shuffleQuestionsStatus);
    setAssessmentData((prevState) => ({
      ...prevState,
      "shuffleQuestionsStatus": !prevState.shuffleQuestionsStatus
    }));

  }

  useEffect(() => {
    console.log(assessmentData.shuffleQuestionsStatus)
  }, [assessmentData.shuffleQuestionsStatus])

  const handleNumberChange = (event: any) => {

    const updatedData = {
      ...assessmentData,

      [event.target.name]: event.target.name === "videoChunks"?Number(event.target.value)*1000:Number(event.target.value),
    };

    setAssessmentData(updatedData);

    setIsEdited(true);
  };

  console.log(assessmentData,"assessmentData")
  const handleTextChange = (event: any) => {
    const updatedData = {
      ...assessmentData,

      [event.target.name]: event.target.value,
    };

    setAssessmentData(updatedData);

    setIsEdited(true);
  };

  const handleSaveChange = async () => {
    
    assessmentData.totalTime = duration;
    assessmentData.assessmentName = assessmentData.assessmentName.trim();
    assessmentData.section = assessmentSectionDetails
    let numberOfsections = 0;
    assessmentSectionDetails.forEach((a) => numberOfsections++)
    assessmentData.noOfSections = numberOfsections;
    
        if (modes === "clone") {

      assessmentData.status = "CREATED";
      assessmentData.organisation = organisation;
      
      const res = await cloneAssessmentDetails(assessmentData);

      if (res.ok) {
        const responseJson = await res.json();
        setResponseId(responseJson.id);
        saveConfiguration(assessmentData ? assessmentData.id : undefined);
        setOriginalAssessmentData(assessmentData);
        setAssessmentSectionDetails(assessmentData.section)
        setPrevConfigurationDetails(configurationDetails);
        toast.success("Cloned Assessment saved successfully", { autoClose: 1000 });
        if (isEdited === true) {
          setIsEdited(false);
        }
      }else if (res.status === 402) {
        setClientPlanExpiredModal(true);
      }
       else {
        toast.error("Failed to clone the Assessment", { autoClose: 1000 });
      }
    }

    if (modes === "edit") {
      if(responseId!=0)
      {
        assessmentData.id=responseId;
      }
      assessmentData.organisation = organisation;
      console.log(assessmentData);

      const status = assessmentData.section.every((section) => {
        return section.skillName.split(",").every((skill) => {
          return section.assessmentQuestionDetail.some((question) => {return question.skillName === skill})
        })
      })

      assessmentData.status = status?"CREATED":"IN_COMPLETE"
      const res = await updateAssessmentDetails(assessmentData);

      if (res.ok) {
        ;
        const responseJson:AssessmentHeaderInterface = await res.json();
        saveConfiguration(assessmentData ? assessmentData.id : undefined);
        // setPrevAssessmentSectionDetails(PrevAssessmentSectionDetails);
        setOriginalAssessmentData(assessmentData);
        setAssessmentSectionDetails(assessmentData.section)
        setPrevConfigurationDetails(configurationDetails);
        toast.success(assessmentData.assessmentName + " updated successfully", { autoClose: 1000 });

        if (isEdited === true) {
          setIsEdited(false);
        }
      }else if (res.status === 402) {
        ;
        setClientPlanExpiredModal(true);
      }
       else {
        ;
        toast.error("Failed to update the Assessment", { autoClose: 1000 });
      }
    }
    if (modes === "add") {
      assessmentData.organisation = organisation;
      console.log(organisation);
      const res = await newAssessmentDetails(assessmentData);

      if (res.ok) {
        ;
        const responseJson = await res.json();
        console.log(responseJson);

        setResponseId(responseJson.id);

        saveConfiguration(responseJson.id);

        setOriginalAssessmentData(assessmentData);
        //  setPrevAssessmentSectionDetails(PrevAssessmentSectionDetails);
        setAssessmentSectionDetails(assessmentData.section)
        setPrevConfigurationDetails(configurationDetails)
        setmodes("edit");
        toast.success(assessmentData.assessmentName + " saved successfully", { autoClose: 1000 });

console.log(modes);
        if (isEdited === true) {
          setIsEdited(false);
        }
      }else if (res.status === 402) {
        setClientPlanExpiredModal(true);
      } 
      else if (res.status === 500) {
        toast.error(assessmentData.assessmentName + " already exists", { autoClose: 1000 });
      } else {
        toast.error("Failed to add new Assessment", { autoClose: 1000 });
      }
    }
console.log(modes);

  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [shuffledList, setShuffledList] = useState([1, 2, 3, 4, 5, 6, 7, 8]);

  useEffect(() => {

    // Function to shuffle the list
    const shuffleList = ({ ...item }) => {
      // Using Fisher-Yates shuffle algorithm
      for (let i = shuffledList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
      }
      // Update the state with the shuffled list
      setShuffledList([...shuffledList]);
      console.log(shuffledList)
    };


    shuffleList(shuffledList)

  }, [assessmentData.shuffleQuestionsStatus])


  useEffect(() => {
    // console.log("enteredd into comparision ",configurationDetails)
    let temp = true;
    if (originalAssessmentData.assessmentName != assessmentData.assessmentName || assessmentData.cutOff != originalAssessmentData.cutOff || assessmentData.shuffleQuestionsStatus != originalAssessmentData.shuffleQuestionsStatus || assessmentSectionDetails != originalAssessmentData.section || configurationDetails != PrevConfigurationDetails) {
      setDisabling(false)

    }
    else {
      console.log("entered else", temp)
      if (modes == "clone" && temp == true) {
        setDisabling(false)
        temp = false
        console.log("entered", temp)
      }
      else {
        setDisabling(true)
      }

    }
  }, [assessmentData, originalAssessmentData, assessmentSectionDetails, configurationDetails, PrevConfigurationDetails])


  const saveConfiguration = (assessmentId: number | undefined) => {
    configurationDetails.forEach((config: { assessmentId: any }) => {
      config.assessmentId = assessmentId;
    });



    postAssessmentConfig(configurationDetails);
  };

  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <>
      {!showPreview && !showConfig && !showQuestionBank && !showSections && (
        <ThemeProvider theme={dsgTheme}>
          <Box className="completeBox">
            <Card>
              <CardContent>
                <Box sx={{ paddingBottom: "5%" }}>
                  <Typography variant="h5">Assessment Details</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "3%",
                  }}
                >
                  <Typography sx={{ width: "20%" }}>
                    Assessment Name*:
                  </Typography>
                  <TextField size="small"
                    className={`textfieldscss ${view === true ? "view-mode" : ""
                      }`}
                    name="assessmentName"
                    variant="outlined"
                    value={assessmentData && assessmentData.assessmentName && assessmentData.assessmentName
                      .toLowerCase()
                      .split(' ')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                    }
                    required
                    onChange={
                      edit === true
                        ? handleTextChange
                        : view === true
                          ? undefined
                          : handleTextChange
                    }
                    InputProps={view ? { readOnly: true } : {}}
                    helperText={
                      !assessmentData?.assessmentName && (edit || clone)
                        ? "AssessmentName is Required"
                        : null
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "3%",
                  }}>Shuffle Questions:<Switch size="small" checked={assessmentData.shuffleQuestionsStatus} onChange={handleShuffleQuestionsChange} disabled={shuffleQuestionsDisable} /></Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "3%",
                  }}
                >
                  <Typography sx={{ width: "20%" }}>Cut Off*(%):</Typography>
                  <TextField sx={{ width: '5rem' }} size="small"
                    className={`${view === true ? "view-mode" : ""
                      }`}
                    variant="outlined"
                    name="cutOff"
                    type="number"
                    value={String(assessmentData && assessmentData.cutOff)}
                    required
                    onChange={
                      edit === true
                        ? handleNumberChange
                        : view === true
                          ? undefined
                          : handleNumberChange
                    }
                    InputProps={{
                      readOnly: view ? true : false,
                      inputProps: {
                        min: 0, 
                      },
                    }}                    
                    helperText={
                      (!assessmentData?.cutOff && (edit || clone)
                        ? "Cut Off is Required"
                        : null) ||
                      (assessmentData.cutOff < 0
                        ? "Minimum value is 1"
                        : null) ||
                      (assessmentData.cutOff > 100
                        ? "Maximum value is 100"
                        : null)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "3%",
                  }}
                >
                 
                </Box>
                <Box sx={{ paddingBottom: "2%", paddingTop: "1%" }}>
                  <Link onClick={handleSectionLinkClick}>
                    {view === true ? `View Sections` : `Edit Sections*`}
                  </Link>
                </Box>
                <Box className="configurationcss">
                  <Link onClick={handleLinkClick}>
                    {view === true
                      ? `View Configurations`
                      : `Edit Configurations`}
                  </Link>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box className="backBox">
                    <Button
                      className="click"
                      variant="outlined"
                      onClick={onBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box className="buttonsBox">
                    <Button
                      className="click"
                      variant="outlined"
                      disabled={
                        view === true ||
                        disabling ||
                        !enableSave ||
                        (add === true && configurationDetails.length <= 0)
                      }
                      onClick={handleSaveChange}
                    >
                      Save
                    </Button>
               
                    <Button
                      className="minwidthcss"
                      variant="outlined"
                      onClick={goToInviteCandidates}
                      disabled={
                        !permissions.includes("Launch Assessment") ||
                        view === true ||
                        assessmentData.section.length === 0 ||
                        assessmentData.section.some(
                          (section) =>
                            !section.assessmentQuestionDetail ||
                            section.assessmentQuestionDetail.length === 0
                        ) || (assessmentData.section.length !== assessmentSectionDetails.length) ||
                        isEdited
                      }
                    >
                      Invite Candidates
                    </Button>

                    <Button
                      className="minwidthcss"
                      variant="outlined"
                      onClick={handlenextScreen}
                      disabled={isEdited || view === true &&
                        (assessmentData.section.length === 0 ||
                          assessmentData.section.some(
                            (section) =>
                              !section.assessmentQuestionDetail ||
                              section.assessmentQuestionDetail.length === 0
                          ))}
                    >
                      {edit === true || clone === true
                        ? `Edit `
                        : view === true
                          ? `View `
                          : `Add `}
                      Questions
                    </Button>
                  </Box>
                </Box>
              </CardContent>
              <SessionExtensionModal
                open={openSessionModal}
                onClose={handleModalCancel}
                onContinue={handleModalContinue}
              >
              </SessionExtensionModal>
            </Card>

          </Box>


          <ToastContainer position="bottom-left" />
          <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
             
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>

        </ThemeProvider>

      )}
      {showConfig && (
        <AssessmentConfiguration
          configurationDetails={configurationDetails}
          mode={modes}
          onSave={(configurationList) => {
            setShowConfig(false);

            setConfigurationDetails(configurationList);
          }}
        />
      )}
      {showSections && (
        <SectionDetails
          section={assessmentSectionDetails}
          mode={modes}
          onSave={(assessmentSections) => {
            setShowSections(false);
            setAssessmentSectionDetails(assessmentSections);
          }}
        />
      )}
      {showPreview && (
        <PreviewQuestions
          assessmentId={assessmentData?.id}
          mode={modes}
          onBack={() => {
            setShowPreview(false);
          }}
        />
      )}

      {showQuestionBank && (
        <QuestionBank
          id={responseId === 0 ? assessmentData?.id : responseId}
          mode={modes}
          onBack={() => {
            setShowQuestionBank(false);
          }}
        />
      )}
    </>
  );
};

export default AssessmentDetails;
