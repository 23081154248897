import TokenService from "./TokenService";

const assessment_host = process.env.REACT_APP_BASE_URL;
const candidate_host = process.env.REACT_APP_BASE_URL;

export const getAssessments = (organisation:string) => {
  const apiUrl = `${assessment_host}/assessments/allAssessments/`+organisation;
  console.log(apiUrl)
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const previewAssessment = (assessmentId: number) => {
  const apiUrl = `${assessment_host}/assessments/questions/${assessmentId}`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const getAssessmentHeader = (id: number) => {
  const apiUrl = `${assessment_host}/assessments/${id}`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const getCandidateInformation = (assessmentId: number) => {
  const apiUrl = `${candidate_host}/candidates/candidates/${assessmentId}`;
  return fetch(apiUrl);
};


export const getTotalCandidates = (id: number) => {
  const apiUrl= `${candidate_host}/candidates/candidates/${id}`;
  console.log(apiUrl)
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const inviteCandidates = (data: any) => {
  const apiUrl = `${candidate_host}/candidates/sending-email`;
  return fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const newAssessmentDetails = async (data: any) => {
  const apiUrl = `${assessment_host}/assessments`;
  return fetch(apiUrl, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const updateAssessmentDetails = async (data: any) => {
  const apiUrl = `${assessment_host}/assessments/updateAssessmentHeader/${data.id}`;
  return fetch(apiUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
    body: JSON.stringify(data),
  });
};

export const getSection = () => {
  const apiUrl = `${assessment_host}/Masterdata/type/Skill`;

  return fetch(apiUrl,{
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const deleteAssessment = (assessmentId: any, updatedBy:string) => {
  const apiUrl = `${assessment_host}/assessments/${assessmentId}/${updatedBy}`;

  return fetch(apiUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete assessment");
      }
      return response.json();
    })
    .then()
    .catch((error) => {
      // Handle the error here
      console.error(error);
    });
};

export const getAllConfigurations = async () => {
  const apiUrl = `${assessment_host}/Masterdata/type/Configuration`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

//get Master data Details based on type
export const getAllByType=async(type:string)=>{
  const apiUrl=`${assessment_host}/Masterdata/type/${type}`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
}

export const getAllHeaders=async()=>{
  const apiUrl=`${assessment_host}/Masterdata/allheaders`;
  return fetch(apiUrl,{
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
}

export const postMasterDetail = async (data: any) => {
  const apiUrl = `${assessment_host}/Masterdata/postmasterdetail`;
  return fetch(apiUrl, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const updateMasterDetail=async (data: any)=>{
  const apiUrl = `${assessment_host}/Masterdata/updatemasterdetail`;
  return fetch(apiUrl, {
    method:"PATCH",
    body:JSON.stringify(data),
    headers: {
      "Content-Type":"application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const getConfigurationById = (assessmentId: number) => {
  const apiUrl = `${assessment_host}/configurations/sendAllConfigurations/${assessmentId}`;
  return fetch(apiUrl,{
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};

export const postAssessmentConfig = (configurationCheckList: any) => {
  const updateConfigurationsUrl = `${assessment_host}/configurations/updateConfigurations`;

  fetch(updateConfigurationsUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
    body: JSON.stringify(configurationCheckList),
  })
    .then((updateResponse) => {
      if (!updateResponse.ok) {
        throw new Error("Request failed");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteAssessmentQuestionDetails = (
  assessmentQuestionDetailId: any
) => {
  const apiUrl = `${assessment_host}/assessments/assessmentQuestionDetail/${assessmentQuestionDetailId}`;
  return fetch(apiUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete assessment");
      }
      return response.json();
    })
    .then()

    .catch((error) => {
      console.error(error);
    });
};

export const cloneAssessmentDetails = async (data: any) => {
  const apiUrl = `${assessment_host}/assessments/cloneAssessment`;
  return fetch(apiUrl, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  });
};
