import { StyledEngineProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routers from "./components/menu-bar/Routers";


function App() {
  const [toggleFlag, setToggleFlag] = useState(false);
  const toggleSettings = () => {
    toggleFlag ? setToggleFlag(true) : setToggleFlag(false);
  };

  // useEffect(() => {
  //   // Fetch the video from the backend
  //   const fetchVideo = async () => {
  //     try {
  //       // Create the full URL for the video endpoint
  //       const videoStreamUrl = `http://localhost:8082/reports/get-video?videoFileName=video_16.mp4`;
        
  //       setVideoUrl(videoStreamUrl);
  //     } catch (error) {
  //       console.error("Error fetching the video", error);
  //     }
  //   };

  //   fetchVideo();
  // }, []);
  return (
    <StyledEngineProvider injectFirst>
 {/* <div>
      {videoUrl ? (
        <video controls width="600">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </div> */}
      <Router>
        <Routers />
        <Outlet />
      </Router>
      {/* <IDE /> */}
    </StyledEngineProvider>
  );
}

export default App;
