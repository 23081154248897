
 class roleClass {
  role=({
    id:0,
    roleName:''
  });

  setroles(value:any)
  {
    this.role=value
  }

  getroles()
  {
    return this.role
  }
}
export default new roleClass();
