import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { dsgTheme } from "src/styles/theme";
import { ButtonInterface } from "src/types/ButtonInterface";
import { CustomModal } from "src/components/modal/CustomModal";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";

interface ClientPlanProps {
  planDetails: ClientPlanInterface;
  onSave: (questionDetails: ClientPlanInterface) => void;
}

function AuditDetails({ planDetails, onSave: onBack }: ClientPlanProps) {
  const [openModal, setOpenModal] = useState(true);

  const handleContinueButton = async () => {
    setOpenModal(false);

    onBack(planDetails);
  };

  const continueButton: ButtonInterface = {
    label: "Close",
    disabled: false,
    onClick: handleContinueButton,
  };

  return (
    <Box>
      <CustomModal
        open={openModal}
        continueButton={continueButton}
      >
        <ThemeProvider theme={dsgTheme}>
          <Typography variant="h5" sx={{ paddingBottom: "2%" }}>
            {planDetails.subscriptionPlans.planName}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", padding: "10px", paddingBottom: "20px" }}>
              {planDetails && (
                <>
                  <Typography>
                    <b style={{width:'80%'}}>Number of Users: </b>
                    {planDetails.subscriptionPlans.numberOfUsers}
                  </Typography>
                  <Typography>
                    <b>Number of Invitations: </b>
                    {planDetails.subscriptionPlans.numberOfInvitations}
                  </Typography>
                  <Typography>
                    <b>Plan Period(months): </b>
                    {planDetails.subscriptionPlans.timePeriod}
                  </Typography>
                  <Typography>
                    <b>Cost: </b>
                   ${ planDetails.subscriptionPlans.cost}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </CustomModal>
    </Box>
  );
}

export default AuditDetails;
