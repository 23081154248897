import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

const AssessmentInProgress: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
        width: "50%",
        margin: "auto",
        marginTop: "15%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Access Denied
      </Typography>
      <IconButton>
        <WarningAmberIcon sx={{ fontSize: "500%", color: "#fe524a" }} />
      </IconButton>
      <Typography variant="body1" gutterBottom>
        The assessment you are trying to access is already in progress or has
        been attempted.
      </Typography>
    </Box>
  );
};

export default AssessmentInProgress;
