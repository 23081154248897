import axios from "axios";
import TokenService from "./TokenService";
const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1/questionBank/`;

class CreateQuestionService {
  createAxiosInstance = () => {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
  }

  createQuestion = (requestBody: any) => {
    return this.createAxiosInstance().post(`${baseURL}createQuestion/question`, requestBody);
  }

}

export default new CreateQuestionService();
