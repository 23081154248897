import "./CandidateSpecificReports.css";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../../clients/UserService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { PieChart, Pie, Cell } from "recharts";
import { dsgTheme } from "../../../styles/theme";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import TokenService from "../../../clients/TokenService";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";
import { CustomModal } from "src/components/admin/list-of-users/CustomModal";

interface CandidateResponseHeader {
  sectionId: number;
  sectionScore: number;
  skillName: string;
  correctAnswers: number;
  wrongAnswers: number;
  attempted: number;
  unAttempted: number;
  sectionName: string;
  sectionTotalScore: number;
  responseId: number;
  sectionStartTimestamp: Date;
  sectionEndTimestamp: Date;
  evaluated:boolean
}
interface DataItem {
  name: string;
  value: number;
}
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#87CEFA",
  "#FFBB28",
  "#EE82EE",
  "#FF8042",
  "#FFA500",
  "#00FFFF",
];

const CandidateSpecificReports: React.FC = () => {
  const navigate = useNavigate();
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [sectionList, setSectionList] = useState<CandidateResponseHeader[]>([]);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);

  var storedCombinedString1 = localStorage.getItem("candidate");
  if (storedCombinedString1 != null) {
    var row = JSON.parse(storedCombinedString1);
  }
  var storedCombinedString2 = localStorage.getItem("assessment");

  if (storedCombinedString2 != null) {
    var obj = JSON.parse(storedCombinedString2);
  }
  const handleModalCancel = () => {
    navigate('/login')
    window.location.reload();
  };


  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data)
        setOpenSessionModal(false)
        window.location.reload();
      })
    }).catch((error) => {
      if(error.response.status === 401){
        setOpenSessionModal(true)
      }
      if (error.response.status === 402) {
        setClientPlanExpiredModal(true)
      }
    })
  };
  
  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/reports/candidates/sectionDetails/${row.id}/${row.assessmentId}`,
        config
      )
        .then(async (response) => {
          console.log(response,"response")
          if(response.status === 401){
            setOpenSessionModal(true);
          }else if(response.status === 402){
            setClientPlanExpiredModal(true);
          }else{
            setSectionList(await response.json());
          }
          
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log("mahesh")
            setOpenSessionModal(true);
          } else if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
          }
        });
    };
    fetchData();
  }, []);
  // if (!sectionList || sectionList.length === 0) {
  //   return null; // You can return a loading indicator or any other UI for the empty state
  // }
  console.log(sectionList,"sectionList");

  const newData: DataItem[] = sectionList.map((section) => ({
    name: section.sectionName,
    value: (section.sectionScore / (obj.totalMarks / sectionList.length)) * 100,
  }));

  // const data = [...newData];
  const handleSubmit = () => {
    navigate("/collegeResult");
  };

  const handleChartClick = (entry: any, value:boolean) => {
    if (entry) {
      // Perform navigation based on the selected cell's name
      const selectedName = entry;
      let candidateResponseId;
      sectionList.map((section) => {
        if (section.sectionName === selectedName) {
          candidateResponseId = section.responseId;
          var viewSectionDetails = JSON.stringify(section);
          console.log("viewSectionDetails",viewSectionDetails);
          localStorage.setItem("viewSectionDetails", viewSectionDetails);
          if(value){
            navigate("/viewSectionDetails",{
              state: { booleanValue: value}
            });
          }
          else{
            navigate("/viewSectionDetails");
          }
          
        }
      });
    }
  };
  const data = sectionList.map((section, index) => ({
    label: section.evaluated?section.sectionName:`${section.sectionName}(Not Evaluated)`,
    WrongAnswers: section.evaluated?section.wrongAnswers:0,
    Attempted: section.evaluated?section.attempted:0,
    CorrectAnswers: section.evaluated?section.correctAnswers:0,
    UnAttempted: section.evaluated?section.unAttempted:0,
  }));
  const handleBarClick = (
    data: { [x: string]: { label: any } },
    index: string | number
  ) => {
    if (data && data.payload && data.payload.label) {
      const clickedLabel = data.payload.label;
      handleChartClick(clickedLabel,false);
      // Now you can use the clicked label in your desired way
    }
  };

  return (
    <ThemeProvider theme={dsgTheme}>
      <Box>
        <Typography variant="h5" className="typography">
          {obj.assessmentName}- {row.firstName}'s Report
        </Typography>
        <Box className="boxa">
          <Box className="boxb">
            <BarChart width={400} height={200} data={data}>
              <XAxis dataKey="label" />
             <YAxis />
              <Tooltip />
              <Legend />
               <Bar
                dataKey="WrongAnswers"
                fill="#ff3d3a"
                barSize={20}
                onClick={handleBarClick}
                style={{ marginRight: "20%" }}
              />

              <Bar
                dataKey="Attempted"
                fill="#ffb677"
                barSize={20}
                onClick={handleBarClick}
                style={{ marginRight: "20%" }}
              />
              <Bar
                dataKey="CorrectAnswers"
                fill="#3cb371"
                barSize={20}
                onClick={handleBarClick}
                style={{ marginRight: "20%" }}
              />

              <Bar
                dataKey="UnAttempted"
                fill="#5f6caf"
                barSize={20}
                onClick={handleBarClick}
                style={{ marginRight: "20%" }}
              />
            </BarChart>
          </Box>
          <Box className="boxc">
            <ul
              style={{ listStyleType: "none", padding: 0, marginLeft: "20px" }}
            >
              {newData.map((entry, index) => (
                <li
                  key={`legend-item-${index}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",

                      cursor: "pointer",
                    }}
                  />
                  <span>&#11162; {entry.name}</span>
                  <Link
                    sx={{ paddingLeft: 1 }}
                    onClick={() => handleChartClick(entry.name,false)}
                    // sx={{
                    //   border: "none",
                    //   cursor: "pointer",
                    //   "&:hover": {
                    //     backgroundColor: "#FFFFFF",
                    //     color: "#FE524A",
                    //     cursor: "pointer",
                    //   },
                    // }}
                  >
                    View Report
                  </Link>
                 {!sectionList[index].evaluated && <Link 
                  sx={{ paddingLeft: 1 }}
                  onClick={() => handleChartClick(entry.name,true)}
                  >
                    Evaluate Marks
                  </Link>
                  }
                </li>
              ))}
            </ul>
          </Box>
        </Box>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Back
        </Button>
      </Box>
      <SessionExtensionModal
            open={openSessionModal}
            onClose={handleModalCancel}
            onContinue={handleModalContinue}
          >
          </SessionExtensionModal>
          <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>
    </ThemeProvider>
  );
};
export default CandidateSpecificReports;
