import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Stack,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ThemeProvider } from "@mui/material/styles";
import "./PreviewAssessment.css";
import {  useNavigate } from "react-router";
import {
  deleteAssessmentQuestionDetails,
  previewAssessment,
} from "../../../clients/AssessmentsClient";
import { AssessmentSectionDTO } from "../../../types/AssessmentSectionDto";
import { AssessmentQuestionDetailDTO } from "../../../types/AssessmentQuestionDTO";
import { dsgTheme } from "../../../styles/theme";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InviteCandidates from "../invite-candidates/InviteCandidates";
import Pagination from "src/components/pagination/Pagination";
import CreateQuestion from "../../question-bank/create-question/CreateQuestion";
import { CustomModal } from "src/components/modal/CustomModal";
import UserService from "src/clients/UserService";
import TokenService from "src/clients/TokenService";
import { SessionExtensionModal } from "src/components/admin/list-of-users/SessionExtensionModal";

interface PreviewProps {
  assessmentId: any;
  mode: string;
  onBack?: () => void;
}
const PreviewQuestions = ({
  assessmentId,
  mode,
  onBack: onBack,
}: PreviewProps) => {
  const navigate = useNavigate();
  const isView = mode === "view" ? true : false;

  const [sectionData, setSectionData] = useState<AssessmentSectionDTO[]>([]);
  const [questionData, setQuestionData] = useState<
    AssessmentQuestionDetailDTO[]
  >([]);
  const [dropDownIndex, setDropDownIndex] = useState(0);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [showCreateQuestion, setShowCreateQuestion] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [questionId, setQuestionId] = useState<number | undefined>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState("2");
  const loggedUser = localStorage.getItem("currentUser");
  const userDetails = loggedUser ? JSON.parse(loggedUser) : null;
  const permissions = userDetails?.permissionslist;
  const questionsPerPage = Number(rows);
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentData = questionData.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const dropDownHandleChange = (event: any) => {
    setCurrentPage(1);
    setDropDownIndex(Number(event.target.value));
  };
  function handleEdit(questionId: number) {
    setQuestionId(questionId);
    setShowCreateQuestion(true);
    setShowPreview(true);
  }

  function handleDelete(id: number) {
    setDeleteId(id);
    setOpenDeleteModal(true);
  }

  // render once
  useEffect(() => {
    fetchData();
  }, [questionId]);

  const updateSectionData = () => {
    setQuestionData(sectionData[dropDownIndex]?.assessmentQuestionDetail || []);
  };

  useEffect(() => {
    updateSectionData();
  }, [sectionData]);

  useEffect(() => {
    updateSectionData();
    setCurrentPage(1);
  }, [dropDownIndex]);

  const fetchData = async () => {
    try {
      const response = await previewAssessment(assessmentId);
      const data = await response.json();
      if (response.ok) setCurrentPage(1);
      setSectionData(data.section);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      if (error.response.status === 401) {
        setOpenSessionModal(true);
      } else if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      }
    }
  };

  const handleModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleModalDeleteCancel = () => {
    setOpenDeleteModal(false);
  }

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  const handleModalConfirm = async () => {
    setOpenDeleteModal(false);
    deleteAssessmentQuestionDetails(deleteId).then(() => {
      // navigate("/questionbank", { state: { id: assessmentId, mode: mode } });
      if (onBack) onBack();
    });
  };

  const handleModalSignOut = () => {
    navigate("/login");
  };

  function handleInvite() {
    navigate("/invite-candidate", { state: { assessmentId } });
  }

  const comQuestion = (value: any) => {
    return <Typography dangerouslySetInnerHTML={{ __html: value }} />;
  };

  if (sectionData.length === 0) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <>
      {!showPreview && (
        <ThemeProvider theme={dsgTheme}>
          <Box>
            <Typography variant="h5">Preview Assessment</Typography>
            <Box className="dropdown">
              <FormControl>
                <InputLabel>Section</InputLabel>
                <Select
                  value={dropDownIndex}
                  label="Section"
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(e) => dropDownHandleChange(e)}
                  sx={{ width: "10rem" }}
                >
                  {sectionData.map((section, index) => (
                    <MenuItem
                      key={index}
                      value={index}
                    >
                      {section.sectionName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="paginationBox">
              <Box className="paginationText">
                <Typography
                  component={"div"}
                  variant="body1"
                >
                  Showing{" "}
                  {indexOfLastQuestion > 0 ? indexOfFirstQuestion + 1 : 0}
                  {indexOfLastQuestion <= questionData.length
                    ? `-${indexOfLastQuestion} questions out of ${questionData.length}`
                    : ` question out of ${questionData.length}`}
                </Typography>
              </Box>
              <Pagination
                rows={rows}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalDataLength={questionData.length}
                dataPerPage={questionsPerPage}
              />
            </Box>

            <Box>
              {currentData.map(
                (assessmentQuestion, assessmentQuestionIndex) => (
                  <Box key={dropDownIndex + assessmentQuestionIndex}>
                    <Paper
                      elevation={3}
                      className="paper"
                    >
                      <Box>
                        <Box className="paperOutterBox">
                          <Box className="paperInnerBox">
                            <Typography
                              component={"div"}
                              variant="body1"
                              className="questionBox"
                            >
                              {assessmentQuestion.assessmentQuestionNumber}:
                            </Typography>

                            <Typography
                              component={"div"}
                              variant="body2"
                            >
                              {comQuestion(
                                assessmentQuestion.question.questionText
                              )}
                            </Typography>
                          </Box>

                          {!assessmentQuestion.question.baseFlag && <Box className="actionIcons">
                            
                             <EditOutlinedIcon className={isView || !permissions.includes("Edit Question")?"disabled-button":"buttonStyle"}
                              onClick={() =>{
                                if(!isView && permissions.includes("Edit Question")){
                                  handleEdit(assessmentQuestion.questionId)
                                }
                              }
                              }
                              
                              />
                              
                              <DeleteOutlineIcon 
                               className={isView || !permissions.includes("Delete Question")?"disabled-button":"buttonStyle"}
                               onClick={() =>{
                                if(!isView && permissions.includes("Delete Question")){
                                  handleDelete(assessmentQuestion.id)
                                }
                              }
                              }
                              />
                            
                          </Box>
                          }
                        </Box>

                       {(assessmentQuestion.question.questionType !== "DESCRIPTIVE" && assessmentQuestion.question.questionType !== "CODING") &&
                        <Box className="paperInnerBox">
                              <Typography
                                component={"div"}
                                variant="body1"
                                className="questionBox"
                              >
                                Options :
                              </Typography>
                              <Box className="options1">
                                {assessmentQuestion.question.options.map(
                                  (macqAnswer, macqAnswerIndex) => (
                                    <Typography
                                      key={macqAnswerIndex}
                                      component={"div"}
                                      className="optionBox"
                                    >
                                      {String.fromCharCode(
                                        macqAnswerIndex + 65
                                      )}
                                      .{comQuestion(macqAnswer.optionValue)}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            </Box>
                       }
                            
                          

                        <Box className="paperLastBox">
                          {!assessmentQuestion.question.questionType.includes(
                            "DESCRIPTIVE"
                          ) ||
                            (assessmentQuestion.question.questionType.includes(
                              "CODING"
                            ) && (
                              <Box className="paperFinalBox">
                                <Typography
                                  component={"div"}
                                  variant="body1"
                                >
                                  Answer:
                                </Typography>
                                <Box className="optionsBox">
                                  <Typography
                                    component={"div"}
                                    variant="body2"
                                    sx={{ padding: "3px" }}
                                  >
                                    {assessmentQuestion.question.options
                                      .map(
                                        (macqAnswer, macqAnswerIndex: any) => {
                                          if (macqAnswer.answerFlag) {
                                            return String.fromCharCode(
                                              macqAnswerIndex + 65
                                            );
                                          }
                                          return null;
                                        }
                                      )
                                      .filter((answer) => answer !== null)
                                      .join(", ")}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}

                          <Box className="paperFinalBox">
                            <Typography
                              component={"div"}
                              variant="body1"
                            >
                              Type:
                            </Typography>
                            <Typography
                              component={"div"}
                              variant="body2"
                              sx={{ padding: "3px" }}
                            >
                              {assessmentQuestion.question.questionType}
                            </Typography>
                          </Box>

                          <Box className="paperFinalBox">
                            <Typography
                              component={"div"}
                              variant="body1"
                            >
                              Skills:
                            </Typography>
                            <Typography
                              component={"div"}
                              variant="body2"
                              sx={{ padding: "3px" }}
                            >
                              {assessmentQuestion.question.skill}
                            </Typography>
                          </Box>

                          <Box className="paperFinalBox">
                            <Typography
                              component={"div"}
                              variant="body1"
                            >
                              Positive Marks:
                            </Typography>
                            <Typography
                              component={"div"}
                              variant="body2"
                              sx={{ padding: "3px" }}
                            >
                              {assessmentQuestion.question.positiveMarks}
                            </Typography>
                          </Box>
                          {!["DESCRIPTIVE","CODING"].includes(assessmentQuestion.question.questionType) && <Box className="paperFinalBox">
                            <Typography
                              component={"div"}
                              variant="body1"
                            >
                              Negative Marks:
                            </Typography>
                            <Typography
                              component={"div"}
                              variant="body2"
                              sx={{ padding: "3px" }}
                            >
                              {assessmentQuestion.question.negativeMarks}
                            </Typography>
                          </Box>
}
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                )
              )}
            </Box>

            <Box className="actionButtons">
              {isView ? (
                <>
                  <Button
                    onClick={() => {
                      // onBack();
                      window.location.reload();
                      navigate("/assessmentlist");
                    }}
                  >
                    View Assessments
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      // onBack();
                      window.location.reload();
                      navigate("/assessmentlist");
                    }}
                  >
                    View Assessments
                  </Button>
                  <Button onClick={handleInvite} disabled={!permissions.includes("Launch Assessment")}>Invite</Button>
                </>
              )}
            </Box>
          </Box>

          <CustomModal
            open={openDeleteModal}
            // onClose={handleModalCancel}
            // heading="Delete User"

            cancelButton={{ label: "Cancel", onClick: handleModalDeleteCancel }}
            continueButton={{
              label: "Confirm",
              onClick: handleModalConfirm,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Are you sure,you want to delete the question from this
                assessment?
              </Typography>
            </Stack>
          </CustomModal>

          <CustomModal
            open={openClientPlanExpiredModal}
            cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
            continueButton={{
              label: "",
              onClick: handleModalCancel,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Your subscription plan has expired; for further information,
                please contact our RS_ADMIN.
              </Typography>
            </Stack>
          </CustomModal>
          <SessionExtensionModal
            open={openSessionModal}
            onClose={handleModalCancel}
            onContinue={handleModalContinue}
          ></SessionExtensionModal>
        </ThemeProvider>
      )}

      {showCreateQuestion && (
        <CreateQuestion
          questionId={questionId}
          assessmentId={assessmentId}
          headerId={undefined}
          mode={mode}
          onBack={() => {
            setShowCreateQuestion(false);
            setShowPreview(false);
          }}
          setQuestionId={setQuestionId}
        />
      )}
    </>
  );
};

export default PreviewQuestions;
