import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Radio,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dsgTheme } from "src/styles/theme";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CreateClient from "../create-client/CreateClient";
import SubscriptionDetails from "../subscription/SubscriptionDetails";
import { ClientInterface } from "../dummydata/ClientInterface";
import UserService from "src/clients/UserService";
import { CustomModal } from "src/components/modal/CustomModal";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import TokenService from "src/clients/TokenService";
import { ClientPlanInterface } from "../dummydata/ClientPlanInterface";
import Pagination from "src/components/pagination/Pagination";
import { SubscriptionPlansInterface } from "../dummydata/SubscriptionPlansInterface";
import { NewSubscriptionDetails } from "../subscription/NewSubscriptionDetails";

interface PlanProps {
  onBack ?: () => void;
}

const Plans = ({ onBack }: PlanProps) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlansInterface[]
  >([]);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
  const [openAddNewPlan, setOpenAddNewPlan] = useState<boolean>(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState<
    SubscriptionPlansInterface | undefined | null
  >();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [rows, setRows] = useState("3");
  const [currentPage, setCurrentPage] = useState(1);
  const plansPerPage = Number(rows);
  const indexOfLastPlan = currentPage * plansPerPage;
  const indexOfFirstPlan = indexOfLastPlan - plansPerPage;

  const handleChange = (event: any) => {
    setRows(event.target.value);
    setCurrentPage(1);
  };

  const rowsPerPageArray = ["3", "5", "10"];
  const currentPlans = subscriptionPlans.slice(
    indexOfFirstPlan,
    indexOfLastPlan
  );

  useEffect(() => {
    getAllSubscriptionPlans();
  }, []);

  const getAllSubscriptionPlans = () => {
    UserService.getAllSubscriptionPlans()
    .then((res: any) => {
      setSubscriptionPlans(res.data);
      setCurrentPage(1);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        setOpenSessionModal(true);
      } else if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      } else {
        console.log(error);
      }
    });
  }

  const AddNewPlanIntoListOfPlans = (plan: any) => {
    console.log(plan)
    console.log("list", plan)
    const va = [...subscriptionPlans, plan]
    setSubscriptionPlans(va);
  };

  const handleSessionModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleSessionModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  const handleModalCancel = () => {
    setOpenDeleteModal(false);
  };

  const  handleModalContinue = () => {
    UserService.deleteSubscription(selectedPlanDetails?.id ?? -1).then((res) => {
      toast.success("Plan status changed successfully",{autoClose:2000})

      setSubscriptionPlans(prev => {
        const index = prev.findIndex(object => object.id === selectedPlanDetails?.id);
    
        if (index !== -1) { // Check if the index is found
            const updatedPlans = [...prev]; // Create a copy of the array
            updatedPlans[index] = {
                ...updatedPlans[index], // Spread the existing object
                subscriptionPlanStatus: selectedPlanDetails?.subscriptionPlanStatus==="ACTIVE"?"INACTIVE":"ACTIVE" // Update the desired property
            };
            return updatedPlans; // Return the updated array
        } else {
            return prev; // If index is not found, return the previous state unchanged
        }
    });
    
      setSelectedPlanDetails(undefined)
    }).catch((error) => {
      console.log(error,"error")
      if (error.response.status === 401) {
        setOpenSessionModal(true);
      } else if (error.response.status === 402) {
        setClientPlanExpiredModal(true);
      }else if (error.response.status === 404) {
        console.log("Ho")
          toast.error("Selected Plan can't be deleted",{autoClose:2000});
        
      } 
      else {
        console.log(error);
      }
    })
    setOpenDeleteModal(false);
  }

  return (
    <ThemeProvider theme={dsgTheme}>
      {!openAddNewPlan && (
        <Container className="mainContainer tableContainer">
          <Box>
            <Typography variant="h5" sx={{ padding: "2%" }}>
              Subscription Plans
            </Typography>
            <Grid item xs={12} sx={{ mt: "3%" }}>
              <Stack
                direction="row"
                spacing={2}
                marginBottom="2%"
                alignItems="center"
              >
                <Box sx={{ marginRight: "auto" }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: "small" }}>
                      Plans per page
                    </Typography>

                    <FormControl variant="standard">
                      <Select
                        disableUnderline={true}
                        value={rows}
                        label="Age"
                        onChange={handleChange}
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        {rowsPerPageArray.map((value, index) => (
                          <MenuItem value={value} key={value + String(index)}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography sx={{ color: "#191F1C" }}>
                      {indexOfFirstPlan + 1}-
                      {indexOfFirstPlan + currentPlans.length} out of{" "}
                      {subscriptionPlans.length}
                    </Typography>
                  </Stack>
                </Box>

                <Pagination
                  rows={rows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalDataLength={subscriptionPlans.length}
                  dataPerPage={plansPerPage}
                />
              </Stack>
            </Grid>
            <TableContainer sx={{ maxHeight: 300 }} className="tableBody">
              <Table>
                <TableHead className="table-row-odd">
                  <TableRow>
                    <TableCell className="tableHeader">Select</TableCell>
                    <TableCell className="tableHeader">Plan Name</TableCell>
                    <TableCell className="tableHeader">
                      Plan Period(months)
                    </TableCell>
                    <TableCell className="tableHeader">No of Users</TableCell>
                    <TableCell className="tableHeader">
                      No of Invitations
                    </TableCell>
                    <TableCell className="tableHeader">
                      Amount($/month)
                    </TableCell>
                    <TableCell className="tableHeader">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {currentPlans.length > 0 &&
                    currentPlans.map((plan, index) => (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className={
                          plan?.id === selectedPlanDetails?.id
                            ? "selected-row"
                            : index % 2 === 0
                            ? "table-row-even"
                            : "table-row-odd"
                        }
                      >
                        <TableCell>
                          <Radio
                            name="selectedClient"
                            checked={selectedPlanDetails?.id === plan?.id}
                            onClick={() => {
                              if (
                                selectedPlanDetails &&
                                selectedPlanDetails?.id === plan?.id
                              ) {
                                setSelectedPlanDetails(undefined);
                              } else {
                                setSelectedPlanDetails(plan);
                              }
                            }}
                            className="radiocss"
                          />
                        </TableCell>
                        <TableCell>{plan?.planName}</TableCell>
                        <TableCell>{plan?.timePeriod}</TableCell>
                        <TableCell>{plan?.numberOfUsers}</TableCell>
                        <TableCell>{plan?.numberOfInvitations}</TableCell>
                        <TableCell>{plan?.cost}</TableCell>
                        <TableCell>{plan?.subscriptionPlanStatus}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack
            className="stack-root"
            direction="row"
            justifyContent="flex-end"
            position="relative"
            spacing={2}
          >
           {onBack && <Button variant="outlined" className="button" onClick={onBack}>
              Back
            </Button>}
            {selectedPlanDetails && (
              <Button
                variant="outlined"
                className="button"
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                {selectedPlanDetails.subscriptionPlanStatus==="ACTIVE"?"INACTIVATE":"ACTIVATE"}
              </Button>
            )}
            <Button
              variant="outlined"
              className="button"
              disabled={selectedPlanDetails !== undefined}
              onClick={() => {
                setOpenAddNewPlan(true);
              }}
            >
              Add New Plan
            </Button>
          </Stack>
        </Container>
      )}
      {openAddNewPlan && (
        <NewSubscriptionDetails
        setNewPlan={
            AddNewPlanIntoListOfPlans
          }
          onBack={() => {
            setOpenAddNewPlan(false);
          }}
        />
      )}
      <ToastContainer position="bottom-left"/>
      <SessionExtensionModal
        open={openSessionModal}
        onClose={handleSessionModalCancel}
        onContinue={handleSessionModalContinue}
      ></SessionExtensionModal>
      <CustomModal
        open={openDeleteModal}
        heading="Session Dialog"
        cancelButton={{ label: "Cancel", onClick: handleModalCancel }}
        continueButton={{ label: "Confirm", onClick: handleModalContinue }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />

          <Typography fontWeight="bold">
            Are you sure, you want to {selectedPlanDetails?.subscriptionPlanStatus === "ACTIVE"?"Inactivate ":"Activate "} 
            {selectedPlanDetails?.planName}?
          </Typography>
        </Stack>
      </CustomModal>
      <CustomModal
        open={openClientPlanExpiredModal}
        cancelButton={{ label: "Sign out", onClick: handleSessionModalCancel }}
        continueButton={{
          label: "",
          onClick: handleSessionModalCancel,
        }}
      >
        <Stack direction="row" spacing={1}>
          <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
          <Typography fontWeight="bold">
            Your subscription plan has expired; for further information, please
            contact our RS_ADMIN.
          </Typography>
        </Stack>
      </CustomModal>
    </ThemeProvider>
  );
};
export default Plans;
