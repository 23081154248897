export const navbarStyles = (isMenuOpen: boolean) => ({
  drawer: {
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      borderRight: 0,
      color: "#fe524a",
      boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.6)",
      marginTop: "0px",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      transition: "width 0.3s ease",
    },
    "& .Mui-selected": {
      color: "red",
    },
  },
  blurredContent: {
    filter: isMenuOpen ? "blur(4px)" : "none",
    transition: "filter 0.3s ease",
    pointerEvents: isMenuOpen ? "none" : "auto",
  },
  icons: {
    color: "#fe524a",
    textAlign: "left",
    cursor: "pointer",
  },
  componentContainer: {
    marginTop: "64px",
    padding: "16px",
  },
  dropdownIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "40px",
    color: "#fe524a",
    cursor: "pointer",
  },
  expandedIcon: {
    transform: isMenuOpen ? "rotate(90deg)" : "rotate(0)",
  },
});
