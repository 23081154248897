import {  Stack, TextField, Typography } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import React from "react";
import "src/components/question-bank/filter-questions/styles/question-bank.css";

interface PaginationProps {
  rows: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalDataLength: number;
  dataPerPage:number;
}

const Pagination: React.FC<PaginationProps> = (props) => {

  const handlePreviousPage = () => {
    if (props.currentPage > 1) props.setCurrentPage((prevPage) => prevPage - 1);
  };

  if(props.totalDataLength === 0){
    props.setCurrentPage(1)
  }
  
  const handlePaginationPageNumber = (event: any) => {

    const inputPageNumber = Number(event.target.value);
    if (
      inputPageNumber <= Math.ceil(props.totalDataLength / props.dataPerPage)
    ) {
      props.setCurrentPage(Math.ceil(inputPageNumber));
    }
    
  };

  const handleNextPage = () => {
    if (props.currentPage < props.totalDataLength / Number(props.rows))
      props.setCurrentPage((prevPage) => prevPage + 1);
  };
  return (
    <Stack direction='row' spacing={2} sx={{justifyContent: 'flex-end',alignItems:'center'}}>
      <KeyboardDoubleArrowLeftIcon
        className={
          props.currentPage > 1
            ? "paginationEnable enablePagination"
            : "paginationDisable disablePagination"
        }
        onClick={() => props.setCurrentPage(1)}
      />

      <ChevronLeftOutlinedIcon
        className={
          props.currentPage > 1
            ? "paginationEnable enablePagination"
            : "paginationDisable disablePagination"
        }
        onClick={handlePreviousPage}
      />
    
      <TextField
        variant="standard"
        // size="small"
        sx={{
          width:'5%',
          display:'flex',
          alignItems:'center',
          "& input":{
            fontSize:"13px",
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
        }}
        inputProps={{min: 0, style: { textAlign: 'center' }}}
        value={String(props.currentPage)}
        onChange={(event) => {handlePaginationPageNumber(event)}}
        type="number"
      />
      <Typography>
        of &ensp; {Math.ceil(props.totalDataLength / props.dataPerPage)}
      </Typography>
      <ChevronRightOutlinedIcon
        className={
          props.currentPage < props.totalDataLength / props.dataPerPage
            ? "paginationEnable enablePagination"
            : "paginationDisable disablePagination"
        }
        onClick={handleNextPage}
      />
      <KeyboardDoubleArrowRightIcon
        className={
          props.currentPage < props.totalDataLength / props.dataPerPage
            ? "paginationEnable enablePagination"
            : "paginationDisable disablePagination"
        }
        onClick={() => {
          props.setCurrentPage(Math.ceil(props.totalDataLength / props.dataPerPage));
        }}
      />
    </Stack>
  );
};

export default Pagination;
