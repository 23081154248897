import React, { useEffect, useState } from "react";

import {
  Radio,
  Container,
  Stack,
  Typography,
  ThemeProvider,
  Checkbox,
  Grid,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import CreateUserClass from "../create-user/CreateUserClass";

import { useLocation, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import { CustomModal } from "./CustomModal";

import { SessionExtensionModal } from "./SessionExtensionModal";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import "./ListOfUsers.css";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
} from "@mui/material";

import "./ListOfUsers.css";

import UserService from "../../../clients/UserService";

import { dsgTheme } from "../../../styles/theme";
import TokenService from "src/clients/TokenService";
import Pagination from "src/components/pagination/Pagination";

const ListOfUsers: React.FC = () => {
  const location = useLocation();
  const numberOfUsers = location.state?.numberOfUsers;

  const [selectedRow, setSelectedRow] = useState<number>(-1);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [listofUsers, setlistOfUsers] = useState([CreateUserClass.User]);
  const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
  useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deleteUserObject, setdeleteUserObject] = useState(
    CreateUserClass.User
  );
  const [reload,setReload] = useState<boolean>(false);
  const userObject = localStorage.getItem("currentUser");
  const [isSelected, setIsSelected] = useState<boolean>(true);
  const [statusbutton, setStatusbutton] = useState("USER STATUS");
  const [timerRunning] = useState(true);
  const [countdown, setCountdown] = useState(330);
  const [EditStatus, setEditStatus] = useState<boolean>(true);
  const [CreateUserPermission, setCreateUserPermissions] =
    useState<boolean>(false);
  const [EditUserPermission, setEditUserPermission] = useState<boolean>(true);
  const [DeleteUserPermission, setDeleteUserPermission] =
    useState<boolean>(false);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [clientFlag, setClientFlag] = useState<boolean>(false);
  const [disableDeleteUser, setDisableDeleteUser] = useState<boolean>(false);
  const [hoverState,setHoverState] = useState<boolean>(false);
  // const [ExceptionErrorMessage, setExceptionErrorMessage] =
  //   useState<string>("");
  const [selectedRows, setSelectedRows] = useState<
    { id: number; status: string }[]
  >([]);
  const navigate = useNavigate();

  const [rows, setRows] = useState("3");
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = Number(rows);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const handleChange = (event: any) => {
    setRows(event.target.value);

    setCurrentPage(1);
  };
  const rowsPerPageArray = ["3", "5", "10"];
  const currentUser = listofUsers.slice(indexOfFirstUser, indexOfLastUser);
  const user = localStorage.getItem("currentUser");
  var userRole: string;
  var userOrganization: number = 0;
  if (user) {
    userOrganization = JSON.parse(user).clientPlans.clients.id;
    userRole = JSON.parse(user).role.roleName;
  }

  useEffect(() => {
    if (numberOfUsers) {
      setClientFlag(true);
    }
  }, []);

  useEffect(() => {
    if (openSessionModal && timerRunning) {
      const modalTimer = setTimeout(() => {
        setCountdown(330);
      }, 10000);
      const countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
        // console.log(countdown, "countdown")
      }, 1000);
      return () => {
        clearTimeout(modalTimer);
        clearInterval(countdownTimer);
      };
    }
  }, [openSessionModal]);

  useEffect(() => {
    if (countdown == 1) {
      navigate(`/login`);
    }
  }, [countdown]);

  const handleRowSelect = (id: number, status: string) => {
    if (selectedRow === id) {
      setSelectedRow(-1);
      setStatusbutton("USER STATUS");
      setIsSelected(true);
    } else {
      setSelectedRow(id);
      if (id === UserService.getLoginUserObject().id) {
        setIsSelected(true);
      } else {
        if (status === "ACTIVE") {
          setStatusbutton("INACTIVATE");
        } else if (status === "INACTIVE") {
          setStatusbutton("ACTIVATE");
        }
        setIsSelected(false);
      }
    }
  };

  // Function to extract and set the message content
  const extractMessage = (passesMessage: any) => {
    try {
      // Parse the JSON message
      const parsedMessage = JSON.parse(passesMessage);

      // Extract the message content
      const messageContent = parsedMessage.message;
      const errorMessageObject = JSON.parse(messageContent)[0];
      const subscriptionMessage = errorMessageObject.message;

      // Update the state with the message content
      return subscriptionMessage;
    } catch (error) {
      console.error("Error parsing JSON message:", error);
    }
  };

  useEffect(() => {
    if (
      UserService.getLoginUserObject().permissionslist.includes("Create User")
    ) {
      setCreateUserPermissions(true);
    }
    if (
      UserService.getLoginUserObject().permissionslist.includes("Edit User")
    ) {
      setEditUserPermission(false);
    }
    if (
      UserService.getLoginUserObject().permissionslist.includes(
        "Active/Inactive User"
      )
    ) {
      setDeleteUserPermission(true);
    }
    localStorage.setItem("sidebar", String(true));
    if (userRole === "RS_ADMIN") {
      // Call getAllUsersForAdmin() if the user role is RS_ADMIN
      UserService.getAllUsersForAdmin()
        .then((res) => {
          console.log("resRS : ", res.data);
          setlistOfUsers(res.data[1]);
          setCurrentPage(1);
          // setCountOfTotalUsers(res.data[0][0]);
          if (res.status === 500) {
            alert("Please refresh the page.");
          }
        })
        .catch((error) => {
          console.log("error",error)
          if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
            setErrorMessage(error.response.data)
            console.log(error.response.data, "asljdasdn");
          }else if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
            setErrorMessage(error.response.data)
            console.log(error.response.data, "asljdasdn");
          }
           else if (error.response.status == 401) {
            setOpenSessionModal(true);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      // Call getAllUsers() for other roles
      UserService.getAllUsers(userOrganization)
        .then((res) => {
          console.log("res : ", res.data);
          setlistOfUsers(res.data[1]);
          setCurrentPage(1);
          // setCountOfTotalUsers(res.data[0][0]);
          if (res.status === 500) {
            alert("Please refresh the page.");
          }
        })
        .catch((error) => {
          if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
            setErrorMessage(error.response.data)
            console.log(error.response.data, "asljdasdn");
          } else if (error.response.status == 401) {
            setOpenSessionModal(true);
          } else {
            toast.error("Something went wrong");
          }
        });
    }

    localStorage.setItem("login", String(true));

    localStorage.setItem("page", String(true));

    const currentUser = localStorage.getItem("currentUser");
  }, []);

  useEffect(() => {
    for (let j = 0; j < listofUsers.length; j++) {
      if (
        listofUsers[j].id == selectedRow &&
        listofUsers[j].status == "INACTIVE"
      ) {
        setEditStatus(true);
        break;
      } else {
        setEditStatus(false);
      }
    }
  }, [selectedRow]);

  

  
  const handleClick = (buttontype: string) => {
    if (buttontype === "editUser") {
      if (selectedRow === -1) {
        toast.error("Please select a user", {
          icon: <ErrorOutlineIcon sx={{ color: "#fe524a" }} />,
          autoClose: false,
        });
      } else if(checkUserPermisssion(selectedRow)){
        toast.error("you don't have permission to edit the RS_Admin", {
          autoClose: 1000,
        });
      }else {
        navigate(`/createUser?selectedUser=${selectedRow}`);
      }
    } else if (buttontype === "CreateUser") {
      navigate("/createUser");
    }
  };

  useEffect(() => {
    let selectedObject: any | undefined;
    const countActiveUsers = (cid: number) => {
      let list: any = [];
      console.log(listofUsers);
      for (let i = 0; i < listofUsers.length; i++) {
        if (
          listofUsers[i].clientPlans.clients?.id == cid &&
          listofUsers[i].status == "ACTIVE"
        ) {
          list.push(listofUsers[i]);
        }
      }
      console.log(list.length);
      return list.length;
    };

    // Use the find method to find the user with the selectedRow
    selectedObject = listofUsers.find((c) => selectedRow === c.id);
    console.log(selectedObject, "selectedObject");
    if (selectedObject?.clientPlans.clients.clientStatus == "INACTIVE") {
      setDisableDeleteUser(true);
      setEditStatus(true);
    } else {
      if (selectedObject?.status == "INACTIVE") {
        if (
          countActiveUsers(selectedObject.clientPlans.clients?.id) <
          selectedObject.clientPlans.subscriptionPlans?.numberOfUsers
        ) {
          setDisableDeleteUser(false);
        } else {
          setDisableDeleteUser(true);
        }
      } else {
        setDisableDeleteUser(false);
      }
    }
  }, [selectedRow]);

  const handleDelete = () => {
    if (selectedRow === -1) {
      toast.error("Please select a user", {
        icon: <ErrorOutlineIcon sx={{ color: "#fe524a" }} />,
        autoClose: false,
      });
    } else if(checkUserPermisssion(selectedRow)){
      toast.error("you don't have permission to inactivate the RS_Admin", {
        autoClose: 1000,
      });
    }else{
      UserService.getDetailsByID(selectedRow)

        .then((res) => {
          console.log(res.data, 'askjdbas')
          setdeleteUserObject(res.data);
        })

        .catch((error) => {
          if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
            setErrorMessage(error.response.data)
            console.log(error.response.data, "asljdasdn");
          }else if (error.response.status == 401) {
            setOpenSessionModal(true);
          } else {
            toast.error("Something went wrong");
          }
        });

      setOpenModal(true);
    }
  };

  const handlecustomModalCancel = () => {
    setOpenModal(false);
  };

  const handleModalConfirm = () => {
    for (let i = 0; i < listofUsers.length; i++) {
      if (listofUsers[i].id == selectedRow) {
        if (listofUsers[i].status == "ACTIVE") {
          UserService.getDetailsByID(listofUsers[i].id).then((res) => {
            UserService.deleteUser(res.data).then((res1) => {
              if (res1.data.status == "INACTIVE") {
                listofUsers[i].status = "INACTIVE";
                setStatusbutton("ACTIVATE");
                setEditStatus(true);
                setOpenModal(false);
                toast.success("User INACTIVATED successfully", {
                  autoClose: 1000,
                });
              }
            });
          }).catch((error) => {
            if (error.response.status === 402) {
              setClientPlanExpiredModal(true);
            }
          });
        } else {
          UserService.getDetailsByID(listofUsers[i].id).then((res) => {
            UserService.ActivateUser(res.data).then((res1) => {
              if (res1.data.status == "ACTIVE") {
                setEditStatus(false);
                listofUsers[i].status = "ACTIVE";
                setStatusbutton("INACTIVATE");
                setOpenModal(false);
                toast.success("User ACTIVATED successfully", {
                  autoClose: 1000,
                });
              }
            }).catch((error) => {
              if (error.response.status === 402) {
                setClientPlanExpiredModal(true);
              }
            });
          });
        }
      }
    }
  };

  const handleModalCancel = () => {
    navigate("/login");
    window.location.reload();
  };

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data)
        .then((res1) => {
          TokenService.setToken(res1.data);
          setOpenSessionModal(false);
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
            setErrorMessage(error.response.data)
            console.log(error.response.data, "asljdasdn");
          } else {
            toast.error("Something went wrong");
          }
        });
    });
  };
  const handleCheckboxSelect = (id: number, status: string) => {
    const isSelected = selectedRows.some((row) => row.id === id);

    if (isSelected) {
      // Deselect the row
      const updatedSelectedRows = selectedRows.filter((row) => row.id !== id);
      setSelectedRows(updatedSelectedRows);
    } else {
      // Select the row
      setSelectedRows([...selectedRows, { id, status }]);
    }
  };


  const checkUserPermisssion = (selectedRow:number):boolean => {
    return listofUsers.some((user) => user.id === selectedRow && user.role.roleName === "RS_ADMIN" && UserService.getLoginUserObject().role.roleName !== "RS_ADMIN")
      // return listofUsers.some((user) => user.role.roleName === "RS_ADMIN" && UserService.getLoginUserObject().role.roleName !== "RS_ADMIN");
  }

  const handleSave = () => {
    console.log(selectedRows);
    if (selectedRows.length <= numberOfUsers) {
      UserService.editUser(selectedRows)
        .then((res: any) => {
          toast.error(`Saved successfully`);
          navigate("/assessmentlist");
          console.log(res.data);
        })
        .catch((error) => {
          if (error.response.status === 402) {
            setClientPlanExpiredModal(true)
            setErrorMessage(error.response.data)
            console.log(error.response.data, "asljdasdn");
          } else if (error.response.status == 401) {
            setOpenSessionModal(true);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      toast.error(`Please select ${numberOfUsers} users`);
    }
  };

  const handleClear = () => {
    setSelectedRows([]);
  };



console.log(JSON.parse(userObject ?? "").clientPlans.subscriptionPlans.numberOfUsers,"iuabds")
  const renderTableRows = () => {
    return currentUser.map((user, index) => (
      <TableRow
        key={user.id}
        className={
          user.id === selectedRow
            ? "selected-row"
            : index % 2 === 0
            ? "table-row-even"
            : "table-row-odd"
        }
        onClick={() => handleRowSelect(user.id, user.status)}
      >
        <TableCell>
          <Radio
            name="selectedUser"
            checked={selectedRow === user.id}
            sx={{
              color: "#fe524a",

              "&.Mui-checked": {
                color: "#fe524a",
              },
            }}
            className="radiocss"
          />
        </TableCell>

        <TableCell onMouseEnter={() =>setHoverState(true)} onMouseLeave={() => setHoverState(false)}>{hoverState?user.userName:user.userName.slice(0,10)}</TableCell>

        <TableCell>{user.email}</TableCell>

        <TableCell>{user.status}</TableCell>

        <TableCell sx={{ textTransform: "capitalize" }}>
          {user.role.roleName === "RS_ADMIN"
            ? "RS_Admin"
            : user.role.roleName.toLowerCase()}
        </TableCell>

        <TableCell>{user?.clientPlans?.clients?.clientName}</TableCell>
      </TableRow>
    ));
  };

  const renderTableRowsCheckboxes = () => {
    return currentUser.map((user, index) => (
      <TableRow
        key={user.id}
        className={
          selectedRows.some((row) => row.id === user.id)
            ? "selected-row"
            : index % 2 === 0
            ? "table-row-even"
            : "table-row-odd"
        }
        onClick={() => handleCheckboxSelect(user.id, user.status)}
      >
        <TableCell>
          <Checkbox
            name={`selectedUser-${user.id}`}
            checked={selectedRows.some((row) => row.id === user.id)}
            onChange={() => handleCheckboxSelect(user.id, user.status)}
            sx={{
              color: "#fe524a",
              "&.Mui-checked": {
                color: "#fe524a",
              },
            }}
            className="checkbox-css"
          />
        </TableCell>

        <TableCell>{user.userName}</TableCell>

        <TableCell>{user.email}</TableCell>

        <TableCell>{user.status}</TableCell>

        <TableCell sx={{ textTransform: "capitalize" }}>
          {user.role.roleName === "RS_ADMIN"
            ? "RS_Admin"
            : user.role.roleName.toLowerCase()}
          <TableCell>{user.clientPlans.clients?.clientName}</TableCell>
        </TableCell>
      </TableRow>
    ));
  };
  
  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <ThemeProvider theme={dsgTheme}>
      <div>
        <Container className="mainContainer tableContainer">
          <Box height="auto">
            <Typography variant="h5" sx={{ padding: "2%" }}>
              List Of users
            </Typography>
            <Grid item xs={12} sx={{ mt: "3%" }}>
              <Stack
                direction="row"
                spacing={2}
                marginBottom="2%"
                alignItems="center"
              >
                <Box sx={{ marginRight: "auto" }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: "small" }}>
                      Users per page
                    </Typography>

                    <FormControl variant="standard">
                      <Select
                        disableUnderline={true}
                        value={rows}
                        label="Age"
                        onChange={handleChange}
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        {rowsPerPageArray.map((value, index) => (
                          <MenuItem value={value} key={value + String(index)}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography sx={{ color: "#191F1C" }}>
                      {indexOfFirstUser + 1}-
                      {indexOfFirstUser + currentUser.length} out of{" "}
                      {listofUsers.length}
                    </Typography>
                  </Stack>
                </Box>

                <Pagination
                  rows={rows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalDataLength={listofUsers.length}
                  dataPerPage={usersPerPage}
                />
              </Stack>
            </Grid>

            <TableContainer sx={{ maxHeight: 300 }} className="tableBody">
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="table-row-odd">
                  <TableRow>
                    <TableCell className="tableHeader">Select</TableCell>

                    <TableCell className="tableHeader">User name</TableCell>

                    <TableCell className="tableHeader">Email</TableCell>

                    <TableCell className="tableHeader">Status</TableCell>

                    <TableCell className="tableHeader">Role</TableCell>
                    <TableCell className="tableHeader">
                      Organization Name
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {clientFlag ? renderTableRowsCheckboxes() : renderTableRows()}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Stack
            className="stack-root"
            direction="row"
            justifyContent="flex-end"
            position="relative"
            spacing={2}
          >
            {clientFlag ? (
              <>
                <Button
                  variant="outlined"
                  className="button"
                  // disabled={isSelected || !DeleteUserPermission}
                  onClick={handleSave}
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  variant="outlined"
                  // disabled={isSelected || EditStatus || EditUserPermission}
                  className="button"
                  onClick={handleClear}
                >
                  clear
                </Button>
              </>
            ) : (
              <>
                {!isSelected ? (
                  <Button
                    variant="outlined"
                    className="button"
                    disabled={
                      isSelected || !DeleteUserPermission || disableDeleteUser
                    }
                    onClick={handleDelete}
                  >
                    {statusbutton}
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  type="submit"
                  variant="outlined"
                  disabled={isSelected || EditStatus || EditUserPermission}
                  className="button"
                  onClick={() => handleClick("editUser")}
                >
                  Edit User
                </Button>
              </>
            )}

            {true && (
              <Button
                type="submit"
                variant="outlined"
                className="button"
                disabled={
                  !isSelected ||
                  !CreateUserPermission ||
                  (JSON.parse(userObject ?? "").clientPlans.clients
                  .clientName !== "Red-Shift Innovations Private Ltd" &&
                  listofUsers.filter(
                    (users) =>
                      users.clientPlans.clients.clientName ===
                      JSON.parse(userObject ?? "").clientPlans.clients
                        .clientName
                  ).length >= JSON.parse(userObject ?? "").clientPlans.subscriptionPlans.numberOfUsers)
                  // || (JSON.parse(userObject ?? '').clientPlans.clients.clientName !== "Red-Shift Innovations Private ltd"
                  //   && listofUsers.length >
                  // )
                }
                onClick={() => handleClick("CreateUser")}
              >
                Create New User
              </Button>
            )}
          </Stack>
        </Container>
        <CustomModal
              open={openClientPlanExpiredModal}
              cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
             
            >
              <Stack direction="row" spacing={1}>
                <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
                <Typography fontWeight="bold">
                Your subscription plan has expired; for further information, please contact our RS_ADMIN.
                </Typography>
              </Stack>
            </CustomModal>

        <ToastContainer position="bottom-left" />

        <SessionExtensionModal
          open={openSessionModal}
          onClose={handleModalCancel}
          onContinue={handleModalContinue}
        ></SessionExtensionModal>

        <CustomModal
          open={openModal}
          heading="Session Dialog"
          cancelButton={{ label: "Cancel", onClick: handlecustomModalCancel }}
          continueButton={{label: "Confirm", onClick: handleModalConfirm}}
       
        >
          <Stack direction="row" spacing={1}>
            <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Are you sure you want to {statusbutton} the user? (
                {deleteUserObject.userName})
              </Typography>
            
          </Stack>
        </CustomModal>
      </div>
    </ThemeProvider>
  );
};

export default ListOfUsers;
