
 class PermissionsClass {
  Permission=({
    id:0,
    permissionName:''
  });

  setroles(value:any)
  {
    this.Permission=value
  }

  getroles()
  {
    return this.Permission
  }
}
export default new PermissionsClass();