import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { dsgTheme } from "src/styles/theme";
import "./configurations.css";
import { AdminConfigurationInterface } from "../dummydata/AdminConfigurationInterface";
import UserService from "src/clients/UserService";
import { ToastContainer, toast } from "react-toastify";
import { SessionExtensionModal } from "../list-of-users/SessionExtensionModal";
import { useNavigate } from "react-router-dom";
import TokenService from "src/clients/TokenService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { CustomModal } from "../list-of-users/CustomModal";
const ConfigurationsList = () => {

    const [configurations, setConfigurations] = useState<AdminConfigurationInterface[]>([]);
    const [oldConfigurations, setOldConfigurations] = useState<AdminConfigurationInterface[]>([]);
    const [enableSaveButton, setEnableSaveButton] = useState<boolean>(true);
    const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
    const [openClientPlanExpiredModal, setClientPlanExpiredModal] =
    useState<boolean>(false);
    const [reload,setReload] = useState<boolean>(false);
    const navigate = useNavigate();
    
    useEffect(() => {
      setReload(true)
        UserService.getAdminConfigurations().then((res) => {
          setReload(false)
            if(res.status === 200){
                
                setConfigurations(res.data)
                setOldConfigurations(res.data)
            }else if(res.status === 401){
              setOpenSessionModal(true);
            }else if(res.status === 402){
              setClientPlanExpiredModal(true)
            }
              else{
                toast.error(`Something went wrong`)
            }
        }).catch((error:any) => {
          setReload(false)
            console.log(error)
        })
    },[])

    useEffect(() => {

      setEnableSaveButton(
      configurations.every((newValue,index) => {
        return newValue.configurationValue === oldConfigurations[index].configurationValue
      }))
    },[configurations])
   
    const handleModalCancel = () => {
      navigate("/login");
      window.location.reload();
    };
    
    function handleChange(event: any,index:number): void {
        
      console.log(event.target.value,"asnasdn")
      const updateConfigurations = [...configurations];

      updateConfigurations[index] = {
        ...updateConfigurations[index],
        configurationValue: index===0?event.target.value*1000:event.target.value === ""?0:event.target.value,
        updatedBy: UserService.getLoginUserObject().userName,
        updatedDate: new Date()
      };
      // Set the updated configurations array to state
      setConfigurations(updateConfigurations);
    }

  function handleSave(): void {
    
    if(configurations[0].configurationValue <= 9999 || configurations[0].configurationValue > 60000){
      toast.error(`Chunks duration must be less than 60 seconds and greater than or equal to 10 seconds`,{autoClose:1000});
    }else if(configurations[1].configurationValue < 5 || configurations[1].configurationValue > 60){
      toast.error(`Remind renew must be less or equal to 60 days and greater than 5 days`,{autoClose:1000});
    }else if(configurations[2].configurationValue < 5 || configurations[1].configurationValue > 10){
      toast.error(`Delete video's must be greater than 5 days and less than or equal to 10 days`,{autoClose:1000});
    }
    else{
      UserService.saveAdminConfigurations(configurations).then((res) => {
        if(res.status === 200){
          toast.success(`Configurations changed successfully`,{autoClose:1000})
          setTimeout(() => {
            window.location.reload();
          },2000)
        
        }else{
          toast.error(`Something went wrong`)
        }
      }).catch((error) => {
        console.log("error");
      })
    }
  }

  const handleModalContinue = () => {
    UserService.EncryptEmail().then((res) => {
      UserService.extendToken(res.data).then((res1) => {
        TokenService.setToken(res1.data);
        setOpenSessionModal(false);
        window.location.reload();
      });
    });
  };

  if (reload) {
    return (
      <Box className="loader">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  
  return (
    <ThemeProvider theme={dsgTheme}>
      <Container className="configurations-container">
        <Box>
          <Typography variant="h5" sx={{marginBottom:'4%'}}>Configurations</Typography>
        </Box>
        <Box>
          <Box>
            {" "}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "3%",
              }}
            >
              <Typography sx={{width:'30%'}}>Proctoring Capture(sec)*:</Typography>
              <TextField
                size="small"
                sx={{width:'15%'}}
                name={configurations[0]?.configurationName}
                value={String(configurations[0]?.configurationValue/1000)}
                variant="outlined"
                onChange={(event) => handleChange(event,0)}                
                type="number"
                required
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "3%",
              }}
            >
              <Typography sx={{width:'30%'}}>Reminder Email(days)*:</Typography>
              <TextField
                 size="small"
                 sx={{width:'15%'}}
                 name={configurations[1]?.configurationName}
                 value={String(configurations[1]?.configurationValue)}
                 variant="outlined"
                 onChange={(event) => handleChange(event,1)}                
                 type="number"
                 required
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "3%",
              }}
            >
              <Typography sx={{width:'30%'}}>Delete Video's(days)*:</Typography>
              <TextField
                 size="small"
                 sx={{width:'15%'}}
                 name={configurations[2]?.configurationName}
                 value={String(configurations[2]?.configurationValue)}
                 variant="outlined"
                 onChange={(event) => handleChange(event,2)}                
                 type="number"
                 required
              />
            </Box>
          </Box>
          <Stack
            direction="row"
            marginTop="3%"
            justifyContent="flex-end"
            spacing={2}
            marginBottom="3%"
          >
            <Button variant="outlined" disabled={enableSaveButton} onClick={handleSave}>Save</Button>

          </Stack>
        </Box>
      </Container>
      <SessionExtensionModal
            open={openSessionModal}
            onClose={handleModalCancel}
            onContinue={handleModalContinue}
          ></SessionExtensionModal>
          <CustomModal
            open={openClientPlanExpiredModal}
            cancelButton={{ label: "Sign out", onClick: handleModalCancel }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <ReportProblemOutlinedIcon style={{ color: "#fe524a" }} />
              <Typography fontWeight="bold">
                Your subscription plan has expired; for further information,
                please contact our RS_ADMIN.
              </Typography>
            </Stack>
          </CustomModal>
      <ToastContainer position="bottom-left"/>
    </ThemeProvider>
  );
};

export default ConfigurationsList;
