
import { ClientInterface } from '../dummydata/ClientInterface';
import { ClientPlanInterface } from '../dummydata/ClientPlanInterface';
import { SubscriptionPlansInterface } from '../dummydata/SubscriptionPlansInterface';
import roleClass from '../roles-and-permissions/roleClass';


const subscriptionPlans:SubscriptionPlansInterface={
  id: 0,
  planName: '',
  numberOfUsers:0,
  numberOfInvitations:0,
  cost:0,
  createdBy: '',
  timePeriod: 0,
  subscriptionPlanStatus: ''
}

const clients:ClientInterface={
  id: 0,
  clientName: '',
  clientId:'',
  clientStatus:'',
  clientLogo: '',
  autoRenew: false
}

const clientPlans: ClientPlanInterface = {
  id : 0,
  planStartDate: new Date(),
  planEndDate: new Date(),
  clientPlanStatus: '',
  subscriptionPlans: subscriptionPlans,
  clients: clients
}
 class CreateUserClass{
  
    User={
      id:0,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        clientPlans: clientPlans,
        role: roleClass.role,
        status:'',
        updatedBy:'',
        createdBy:'',
        permissionslist:[''],
        token:''
      }; 

      setUser(value:any)
      {
        this.User=value;
      }

      getUser()
      {
        return this.User;
      }
 
}
export default new CreateUserClass()
